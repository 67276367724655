import { Tour } from 'ui-smartforce-settings';
import { TOURS, TOURS_CONFIG } from '../Constants/Tours';

export function getToursByPlanAndRole(
  plan: string,
  free: boolean,
  role: string
): Tour[] {
  let result: Tour[] = [];
  const byRole = TOURS_CONFIG.find((c) => c.role === role);

  if (byRole) {
    const byPlan = byRole.plans.find((p) => p.name === plan);
    if (byPlan) {
      for (let circuit of byPlan.circuits) {
        const tour = TOURS.find((t) => t.id === circuit);
        if (tour) {
          result = [...result, tour];
        }
      }
    }
  }

  return result;
}
