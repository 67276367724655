import React from 'react';
import { useHistory } from 'react-router-dom';
import ErrorPage from '../../Components/ErrorPage/ErrorPage';

export const ErrorView = (): React.ReactElement<{}> => {
  const history = useHistory();

  const onGoHome = () => {
    history.replace('/cc');
  };

  return (
    <ErrorPage
      icon="Announcement"
      title="Sorry, something went wrong."
      onGoHome={onGoHome}
    />
  );
};
