import React from 'react';
import styles from './RankingCard.module.scss';
import { RankingCardConfig } from '../../../../Models';
import { RankingCardBody } from './RankingCardBody/RankingCardBody';
import { RankingCarHeader } from './RankingCarHeader/RankingCarHeader';

export interface RankingCardProps extends RankingCardConfig {}

export const RankingCard = (
  props: RankingCardProps
): React.ReactElement<RankingCardProps> => {
  return (
    <div className={styles.rankingCard}>
      <RankingCarHeader
        elements_label={props.elements_label}
        value_label={props.value_label}
        info_message={props.info_message}
      />

      <RankingCardBody
        tooltipLabel={props.tooltip_label}
        list={props.ranking_list}
      />
    </div>
  );
};
