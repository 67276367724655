import React, { useContext, useEffect, useState } from 'react';
import styles from './SoftwareUpdatedModal.module.scss';
import { SFButton, SFDialog, SFThemeType } from 'sfui';
import {
  MediaContext,
  User,
  UserContext,
  isRoleOfficer
} from 'ui-smartforce-settings';
import { ThemeTypeContext } from 'ui-smartforce-settings';

function getImageSrc(themeType: SFThemeType, isOfficer: boolean): string {
  const backgroundImageBase = `${
    process.env.REACT_APP_IMAGES_BASE_URL
  }/new_version_notification/${themeType === 'day' ? 'DM' : 'NM'}_${
    process.env.REACT_APP_CC_VERSION
  }`;

  const backgroundImageDefault = `${backgroundImageBase}.${process.env.REACT_APP_CC_VERSION_IMAGE_FORMAT}`;
  const backgroundImageOfficer = `${backgroundImageBase}_OFFICER.${process.env.REACT_APP_CC_VERSION_IMAGE_FORMAT}`;

  return isOfficer ? backgroundImageOfficer : backgroundImageDefault;
}

export interface SoftwareUpdatedModalProps {
  isOpen: boolean;
  onClick: () => void;
}

export const SoftwareUpdatedModal = ({
  onClick,
  isOpen
}: SoftwareUpdatedModalProps): React.ReactElement<SoftwareUpdatedModalProps> => {
  const user = useContext(UserContext).user as User;
  const { themeType } = useContext(ThemeTypeContext);
  const { isPhone } = useContext(MediaContext);
  const [imgSrc, setImgSrc] = useState(
    getImageSrc(themeType, isRoleOfficer(user.role.id))
  );

  useEffect(() => {
    setImgSrc(getImageSrc(themeType, isRoleOfficer(user.role.id)));
  }, [themeType, user.role.id]);

  const onImgError = () => {
    if (imgSrc.includes('OFFICER')) {
      setImgSrc(getImageSrc(themeType, false));
    }
  };

  return (
    <SFDialog
      PaperProps={{ className: styles.softwareUpdatedModal }}
      open={isOpen}
    >
      <div className={styles.content}>
        <img
          className={styles.image}
          src={imgSrc}
          alt=""
          onError={onImgError}
        />

        <div className={styles.text}>
          <h2 className={styles.title}>You are up to date</h2>
          <span className={styles.subTitle}>
            A new version of CitizenContact is ready to use. Please refresh any
            other CitizenContact tab.
          </span>
        </div>

        <div className={styles.updateButton}>
          <SFButton size="large" onClick={onClick} fullWidth={isPhone}>
            Got It
          </SFButton>
        </div>
      </div>
    </SFDialog>
  );
};
