import React, { useState } from 'react';
import styles from './IncidentNumberModal.module.scss';
import { SFButton, SFDialog, SFTextField } from 'sfui';
import { MediaContext } from 'ui-smartforce-settings';
import { isIncidentNumberValid } from '../../Helpers';
import { INCIDENT_NUMBER_HELP_MSG } from '../../Constants/Messages';

export interface IncidentNumberModalProps {
  value: string;
  isOpen: boolean;
  onClick: (value: string) => void;
}

export const IncidentNumberModal = ({
  value,
  isOpen,
  onClick
}: IncidentNumberModalProps): React.ReactElement<IncidentNumberModalProps> => {
  const { isPhone } = React.useContext(MediaContext);
  const [newValue, setNewValue] = useState<string>(value);

  return (
    <SFDialog
      PaperProps={{ className: styles.incidentNumberModal }}
      open={isOpen}
    >
      <div className={styles.content}>
        <h2 className={styles.title}>Edit Incident Number</h2>
        <span className={styles.subTitle}>
          The following Incident Number was used in another report. To submit
          this report correctly, edit the number down below.
        </span>

        <SFTextField
          label="Incident Number"
          value={newValue}
          autoFocus // eslint-disable-line jsx-a11y/no-autofocus
          onChange={(event): void => setNewValue(event.target.value)}
          helperText={INCIDENT_NUMBER_HELP_MSG}
          error={!isIncidentNumberValid(newValue)}
        />

        <SFButton
          size="large"
          onClick={() => onClick(newValue)}
          fullWidth={isPhone}
          disabled={!isIncidentNumberValid(newValue) || newValue === value}
        >
          Save
        </SFButton>
      </div>
    </SFDialog>
  );
};
