import React from 'react';
import { SFButton, SFDialog, SFScrollable } from 'sfui';
import { SaveObjectPanelProps } from '../SaveObjectPanel';
import { SaveObjectPanelHeader } from '../SaveObjectPanelHeader/SaveObjectPanelHeader';
import styles from './SaveObjectDialog.module.scss';

export interface SaveObjectDialogProps extends SaveObjectPanelProps {}

export const SaveObjectDialog = ({
  isOpen,
  title,
  children,
  saveBtnLabel,
  isSaveDisabled,
  onClose,
  onSave
}: SaveObjectDialogProps): React.ReactElement<SaveObjectDialogProps> => {
  return (
    <SFDialog
      className={styles.saveObjectDialog}
      open={isOpen}
      transitionDuration={{
        enter: 360,
        exit: 360
      }}
    >
      <SaveObjectPanelHeader title={title} onClose={onClose} />

      <SFScrollable
        className={styles.content}
        containerClassName={styles.scrollableContainer}
      >
        {children}
      </SFScrollable>

      <footer>
        <SFButton
          className={styles.discardBtn}
          sfColor="grey"
          variant="text"
          size="large"
          onClick={onClose}
        >
          Discard
        </SFButton>

        <SFButton
          className={styles.saveBtn}
          size="large"
          fullWidth
          disabled={isSaveDisabled}
          onClick={(event) => {
            event.currentTarget.disabled = true;
            onSave();
          }}
        >
          {saveBtnLabel}
        </SFButton>
      </footer>
    </SFDialog>
  );
};
