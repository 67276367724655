import React from 'react';
import { useHistory } from 'react-router-dom';
import ErrorPage from '../../Components/ErrorPage/ErrorPage';

export const UserIdErrorView = (): React.ReactElement<{}> => {
  const history = useHistory();

  const onGoHome = () => {
    history.replace('/login');
  };

  return (
    <ErrorPage
      icon="Announcement"
      title="There is already a user with the officer ID you entered."
      onGoHome={onGoHome}
    />
  );
};
