import React from 'react';
import styles from './IncidentNumberView.module.scss';
import { SFScrollable, SFTextField } from 'sfui';
import FooterButtons, {
  FooterButtonAction
} from '../FooterButtons/FooterButtons';
import { UserContext } from 'ui-smartforce-settings';
import { isIncidentNumberValid } from '../../../../Helpers';
import { INCIDENT_NUMBER_HELP_MSG } from '../../../../Constants/Messages';

export interface IncidentNumberViewProps {
  onCancel: () => void;
  onContinue: (value: string) => void;
}

const getIncidentNumberGenerated = (officerId: string): string => {
  const timestamp: string = new Date().getTime().toString();

  //Remove possible special characters from officer id
  const officerIdWithoutSpecial: string = officerId.replace(
    /[^A-Za-z0-9-]/g,
    ''
  );

  return `${timestamp.substring(0, 10)}-${officerIdWithoutSpecial}`;
};

const IncidentNumberView = (
  props: IncidentNumberViewProps
): React.ReactElement<IncidentNumberViewProps> => {
  const { user } = React.useContext(UserContext);

  const [incidentNumber, setIncidentNumber] = React.useState(
    getIncidentNumberGenerated(user?.officer_id as string)
  );

  const continueButton: FooterButtonAction = {
    label: 'Continue',
    disabled: !isIncidentNumberValid(incidentNumber),
    onClick: () => props.onContinue(incidentNumber)
  };

  return (
    <div className={`${styles.incidentNumberView} ${styles.withFooterButtons}`}>
      <SFScrollable
        className={styles.scrollable}
        containerClassName={styles.scrollableContainer}
      >
        <div className={styles.content}>
          <h2 className={styles.title}>Add Incident Number</h2>
          <p className={styles.subTitle}>
            To continue, please complete the Incident Number.
          </p>

          <div className={styles.form}>
            <SFTextField
              label="Incident Number"
              value={incidentNumber}
              autoFocus // eslint-disable-line jsx-a11y/no-autofocus
              onChange={(event): void => setIncidentNumber(event.target.value)}
              helperText={INCIDENT_NUMBER_HELP_MSG}
              error={!isIncidentNumberValid(incidentNumber)}
            />
          </div>
        </div>
      </SFScrollable>

      <footer>
        <FooterButtons
          onCancel={props.onCancel}
          buttonAction={continueButton}
        />
      </footer>
    </div>
  );
};

export default IncidentNumberView;
