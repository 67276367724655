import React from 'react';
import { SFButton, SFIconButton, SFMenu, SFMenuItem } from 'sfui';
import { ReportListType } from '../../../../Services/ReportService';
import styles from './ReportCardHeaderActions.module.scss';

export interface ReportCardHeaderActionsProps {
  type: ReportListType;
  onViewHistory: () => void;
  onScanQrCode: () => void;
  onSeeFullReport: () => void;
}

export const ReportCardHeaderActions = ({
  type,
  onViewHistory,
  onScanQrCode,
  onSeeFullReport
}: ReportCardHeaderActionsProps): React.ReactElement<ReportCardHeaderActionsProps> => {
  const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<any>();

  const onClick = (e: any) => {
    if (!isMenuOpen) {
      setAnchorEl(e.currentTarget);
      setIsMenuOpen(true);
    }
  };

  return (
    <div className={styles.reportCardHeaderActions}>
      <div className={styles.actionsMenu}>
        <SFIconButton
          className={styles.btnMenu}
          sfIcon="Other"
          sfSize="medium"
          onClick={onClick}
          aria-label="More Options"
        />

        <SFMenu
          open={isMenuOpen}
          anchorEl={anchorEl}
          onClose={() => setIsMenuOpen(false)}
        >
          <SFMenuItem
            onClick={() => {
              onViewHistory();
              setIsMenuOpen(false);
            }}
          >
            View history
          </SFMenuItem>

          <SFMenuItem
            onClick={() => {
              onScanQrCode();
              setIsMenuOpen(false);
            }}
          >
            Scan QR code
          </SFMenuItem>

          <SFMenuItem
            onClick={() => {
              onSeeFullReport();
              setIsMenuOpen(false);
            }}
          >
            See full report
          </SFMenuItem>
        </SFMenu>
      </div>

      <div className={styles.actionsButtons}>
        <SFButton
          className={styles.btnHistory}
          variant="outlined"
          onClick={onViewHistory}
        >
          View History
        </SFButton>

        <SFButton variant="outlined" onClick={onScanQrCode}>
          Scan QR Code
        </SFButton>

        <SFButton variant="outlined" onClick={onSeeFullReport}>
          See Full Report
        </SFButton>
      </div>
    </div>
  );
};
