import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SFCard } from 'sfui';
import {
  CustomerContext,
  MediaContext,
  TourContext,
  UserContext,
  formatDateString
} from 'ui-smartforce-settings';
import { FullReportContext } from '../../Context/FullReportContext';
import { dispatchMainAlertEvent } from '../../Events/MainAlertEvent';
import { ReportValue, VersionValueType } from '../../Models';
import { getReport, ReportListType } from '../../Services/ReportService';
import Loader from '../Loader/Loader';

import { ContactMap } from './ContactMap/ContactMap';
import styles from './ReportCard.module.scss';
import { ReportCardHeader } from './ReportCardHeader/ReportCardHeader';
import { ReportCardInfo } from './ReportCardInfo/ReportCardInfo';
import {
  ReportHistory,
  ReportHistoryItem
} from './ReportHistory/ReportHistory';
import { ReportHistoryAvatar } from './ReportHistoryAvatar/ReportHistoryAvatar';
import { ReportHistoryPanel } from './ReportHistoryPanel/ReportHistoryPanel';
import { canAmendReport } from '../../Helpers';
import { ReportListItem } from '../ReportList/ReportList';

export interface ReportCardProps {
  report: ReportListItem;
  reportListType: ReportListType;
  showAmend: boolean;
  onScanQrCode: (activeReport: ReportValue) => void;
}

export const ReportCard = ({
  report,
  reportListType,
  showAmend,
  onScanQrCode
}: ReportCardProps): React.ReactElement<ReportCardProps> => {
  const location = useLocation();
  const history = useHistory();
  const { isLargeScreen } = React.useContext(MediaContext);
  const { showFullReport } = useContext(FullReportContext);
  const { user } = useContext(UserContext);
  const { customer } = useContext(CustomerContext);
  const {
    tour,
    step: tourStep,
    onClose: onTourClose
  } = useContext(TourContext);
  const [isHistoryOpen, setIsHistoryOpen] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [reportActive, setReportActive] = React.useState<ReportValue>(report);
  const [selectedItem, setSelectedItem] = React.useState<number>(0);
  const isAmendDisabled: boolean =
    !showAmend || !canAmendReport(report, user, customer);

  useEffect(() => {
    if (
      !isLargeScreen &&
      report.tooltipType &&
      (tour?.id === 6 || tour?.id === 10) &&
      tourStep === 1
    ) {
      setIsHistoryOpen(true);
    }
  }, [isLargeScreen, tour, tourStep, report.tooltipType]);

  const onViewHistory = () => {
    setIsHistoryOpen(true);
  };

  const onReportHistoryClick = async (
    item: ReportHistoryItem,
    index: number
  ) => {
    if (
      report.versions &&
      report.versions?.length > 1 &&
      index !== selectedItem
    ) {
      onTourClose([
        { tourId: 6, step: 1 },
        { tourId: 10, step: 1 }
      ]);
      setIsHistoryOpen(false);
      setIsLoading(true);

      try {
        const versionCreatedAt: string = report.versions[index].created_at;

        const reportActive = await getReport(
          reportListType,
          report.general_information.incident_number as string,
          versionCreatedAt
        );

        setSelectedItem(index);
        setReportActive(reportActive);
        setIsLoading(false);
      } catch (e) {
        dispatchMainAlertEvent({
          message:
            'The selected version could not be loaded correctly. Please try again.',
          autoHideDuration: 3600
        });

        setIsLoading(false);
      }
    }
  };

  const onAmend = () => {
    history.push('/cc/amend', {
      report,
      type: reportListType,
      redirectTo: location.pathname
    });
  };

  const onSeeFullReport = () => {
    showFullReport(reportActive);
  };

  const getHistoryItems = (
    versions: VersionValueType[]
  ): ReportHistoryItem[] => {
    return versions.map((version: VersionValueType) => ({
      version: version.v,
      title: formatDateString(version.created_at, 'L - HH:mm'),
      subtitle:
        version.v === 0
          ? 'Original version'
          : version.v === (report.versions?.length as number) - 1
          ? 'Current version'
          : '',
      children: (
        <ReportHistoryAvatar
          name={version.author.name}
          title={version.author.title?.name}
          avatar={version.author.avatar_thumbnail_url}
        />
      )
    }));
  };

  const mapCoords =
    reportActive.general_information.location.address?.coords ||
    report.general_information.device?.coords;

  return (
    <SFCard sfElevation={2} containerClassName={styles.reportCard}>
      {isLoading && <Loader />}

      {!isLoading && (
        <React.Fragment>
          <div className={styles.reportData}>
            <ReportCardHeader
              type={reportListType}
              className={styles.header}
              incidentNumber={
                report.general_information.incident_number as string
              }
              onScanQrCode={() => onScanQrCode(reportActive)}
              onViewHistory={onViewHistory}
              onSeeFullReport={onSeeFullReport}
            />

            <ContactMap className={styles.map} coords={mapCoords} />

            <ReportCardInfo className={styles.info} report={reportActive} />
          </div>

          {isLargeScreen && (
            <div className={styles.reportHistory}>
              <ReportHistory
                items={getHistoryItems(report.versions as VersionValueType[])}
                isAmendDisabled={isAmendDisabled}
                selectedIndex={selectedItem}
                reportListType={reportListType}
                tooltipType={report.tooltipType}
                onItemClick={onReportHistoryClick}
                onAmend={onAmend}
              />
            </div>
          )}

          <ReportHistoryPanel
            isOpen={isHistoryOpen}
            onClose={() => {
              onTourClose([
                { tourId: 6, step: 1 },
                { tourId: 10, step: 1 }
              ]);
              setIsHistoryOpen(false);
            }}
          >
            <ReportHistory
              items={getHistoryItems(report.versions as VersionValueType[])}
              isAmendDisabled={isAmendDisabled}
              selectedIndex={selectedItem}
              reportListType={reportListType}
              tooltipType={report.tooltipType}
              onItemClick={onReportHistoryClick}
              onAmend={onAmend}
            />
          </ReportHistoryPanel>
        </React.Fragment>
      )}
    </SFCard>
  );
};
