import React from 'react';
import { useHistory } from 'react-router';
import { SFIcon, SFPaper } from 'sfui';
import { OnlineStatusContext } from '../../Context/OnlineStatusContext';
import styles from './NoConnectionView.module.scss';

export interface NoConnectionViewProps {}

export const NoConnectionView = (
  props: NoConnectionViewProps
): React.ReactElement<NoConnectionViewProps> => {
  const history = useHistory();
  const { isOnline } = React.useContext(OnlineStatusContext);

  React.useEffect(() => {
    if (isOnline) {
      history.push('/');
    }
  }, [isOnline, history]);
  return (
    <SFPaper className={styles.noConnectionView}>
      <div className={styles.icon}>
        <SFIcon icon="Cloud-Off" size={96} />
      </div>

      <h1 className={styles.title}>No internet connection.</h1>

      <p className={styles.subText}>You can try later.</p>
    </SFPaper>
  );
};
