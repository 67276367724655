import React from 'react';
import styles from './SetUpAgency.module.scss';
import { SFButton, SFScrollable } from 'sfui';
import { isOriValid } from '../../../../../../Helpers';
import {
  isFieldEmpty,
  Customer,
  AgencyForm,
  isEmailValid
} from 'ui-smartforce-settings';

export interface SetUpAgencyProps {
  agency: Partial<Customer>;
  badge: string | Blob | undefined | null;
  isOriTaken: boolean;
  onChange: (agency: Partial<Customer>) => void;
  onBadgeChange: (badge: Blob) => void;
  onSave: () => void;
}

export const SetUpAgency = ({
  agency,
  badge,
  isOriTaken,
  onChange,
  onBadgeChange,
  onSave
}: SetUpAgencyProps): React.ReactElement<SetUpAgencyProps> => {
  const [isEmailError, setIsEmailError] = React.useState<boolean>(false);

  const isFormValid =
    !isFieldEmpty(agency.ori) &&
    isOriValid(agency.ori as string) &&
    !isFieldEmpty(agency.full_name) &&
    !isFieldEmpty(agency.ori) &&
    !isFieldEmpty(agency.state_name) &&
    !isFieldEmpty(agency.phone1) &&
    !isFieldEmpty(agency.email) &&
    !isFieldEmpty(agency.timezone);

  const onClick = () => {
    const checkEmail: boolean = isEmailValid(agency.email as string);
    setIsEmailError(!checkEmail);

    if (checkEmail) {
      onSave();
    }
  };

  return (
    <div className={styles.setUpAgency}>
      <SFScrollable className={styles.scrollable}>
        <AgencyForm
          value={agency}
          badge={badge}
          isNew={true}
          isEmailError={isEmailError}
          isOriTaken={isOriTaken}
          onChange={onChange}
          onBadgeChange={onBadgeChange}
        />
      </SFScrollable>

      <div className={styles.actionBtn}>
        <SFButton
          size="large"
          fullWidth
          disabled={!isFormValid}
          onClick={onClick}
        >
          Continue
        </SFButton>
      </div>
    </div>
  );
};
