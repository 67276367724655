import React from 'react';
import { SFTooltip, SFTooltipProps } from 'sfui';
import { isMobile } from '../../Helpers';
export interface TextOverflowTooltipProps extends SFTooltipProps {}

const isTextOverflown = (element: HTMLElement) => {
  return element.scrollWidth > element.clientWidth;
};

export const TextOverflowTooltip = ({
  children,
  title,
  ...props
}: TextOverflowTooltipProps): React.ReactElement<TextOverflowTooltipProps> => {
  const [isTooltipOpen, setIsTooltipOpen] = React.useState<boolean>(false);
  const refItem = React.useRef<HTMLElement>();

  const handleOverflow = () => {
    setIsTooltipOpen(!refItem.current || !isTextOverflown(refItem.current));
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleOverflow);

    return () => {
      window.removeEventListener('resize', handleOverflow);
    };
  });

  React.useEffect(() => {
    handleOverflow();
  }, []);

  return (
    <SFTooltip
      {...props}
      disableFocusListener
      disableTouchListener={isTooltipOpen && isMobile()}
      disableHoverListener={isTooltipOpen}
      title={title}
    >
      {React.cloneElement(children, {
        ref: refItem
      })}
    </SFTooltip>
  );
};
