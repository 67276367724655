import Dexie from 'dexie';
import { ReportValue } from '../Models';
import { PENDING_REPORTS_NEW } from '../Constants/Events';

const NewPendingReportEvent = new Event(PENDING_REPORTS_NEW);

class ReportDatabase extends Dexie {
  reports: Dexie.Table<ReportValue, number>;

  constructor(databaseName: string) {
    super(databaseName);
    this.version(1).stores({
      reports:
        '++id, user, general_information.incident_number, general_information.date, general_information.type'
    });

    this.reports = this.table('reports');
  }
}

export class PendingReportsServiceClass {
  private db: ReportDatabase;

  constructor() {
    this.db = new ReportDatabase('SF-CC-Reports');
  }

  public async getUserReports(user: string): Promise<ReportValue[]> {
    return await this.db.reports.where({ user }).toArray();
  }

  public async getUserLastReports(
    user: string,
    limit: number
  ): Promise<ReportValue[]> {
    return await this.db.reports
      .where({ user })
      .reverse()
      .limit(limit)
      .toArray();
  }

  public async getUserReportsCount(user: string): Promise<number> {
    return await this.db.reports.where({ user }).count();
  }

  public async saveReport(user: string, report: ReportValue): Promise<void> {
    await this.db.reports.put({ user, ...report });
    document.dispatchEvent(NewPendingReportEvent);
  }

  public async deleteReport(id: number): Promise<void> {
    await this.db.reports.delete(id);
  }
}

const PendingReportsService = new PendingReportsServiceClass();
export default PendingReportsService;
