import React from 'react';
import styles from './NetworkStatus.module.scss';
import { SFBadge, SFTooltip } from 'sfui';
import { OnlineStatusContext } from '../../Context/OnlineStatusContext';
import { useLocation } from 'react-router-dom';
import { OnlinePendingButton } from './OnlinePendingButton/OnlinePendingButton';
import { OnlineDoneButton } from './OnlineDoneButton/OnlineDoneButton';
import { OfflineButton } from './OfflineButton/OfflineButton';
import { ApiError, ReportValue } from '../../Models';
import {
  PendingReportInfo,
  PendingReportsMenu
} from './PendingReportsMenu/PendingReportsMenu';
import PendingReportsService from '../../Services/PendingReportsService';
import {
  PENDING_REPORTS_NEW,
  SESSION_EXPIRE,
  UPDATE_REPORT_LIST
} from '../../Constants/Events';
import { sendReport } from '../../Services/ReportService';
import { IncidentNumberModal } from '../IncidentNumberModal/IncidentNumberModal';
import { FailedUploadModal } from '../FailedUploadModal/FailedUploadModal';
import {
  UserContext,
  dispatchCustomEvent,
  isPlanConnect,
  useSubscription
} from 'ui-smartforce-settings';
import { TooltipTransition } from './TooltipTransition';
import { isUnauthorized } from '../../Helpers/errors';
import { isOfflinePath } from '../../Helpers';

export interface NetworkStatusProps {}

export const NetworkStatus = (): React.ReactElement<NetworkStatusProps> => {
  const location = useLocation();
  const { isOnline } = React.useContext(OnlineStatusContext);
  const subscription = useSubscription('cc');
  const { user } = React.useContext(UserContext);
  const [isTooltipOpen, setIsTooltipOpen] = React.useState<boolean>(false);
  const [pendingReports, setPendingReports] = React.useState<
    PendingReportInfo[]
  >([]);
  const [totalPendingReports, setTotalPendingReports] =
    React.useState<number>(0);
  const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [isIncidentNumberModalOpen, setIsIncidentNumberModalOpen] =
    React.useState<boolean>(false);
  const [isFailedUploadModalOpen, setIsFailedUploadModalOpen] =
    React.useState<boolean>(false);
  const [failedReport, setFailedReport] = React.useState<ReportValue>();

  const getMenuPendingReports = (
    reports: ReportValue[]
  ): PendingReportInfo[] => {
    return reports.map((report: ReportValue) => ({
      incidentNumber: report.general_information.incident_number as string,
      date: report.general_information.date as string
    }));
  };

  React.useEffect(() => {
    let suscribed: boolean = true;

    const getReports = async () => {
      const reports: ReportValue[] =
        await PendingReportsService.getUserLastReports(
          user?.email as string,
          5
        );

      if (suscribed) {
        const pendingReports: PendingReportInfo[] =
          getMenuPendingReports(reports);
        const total: number = await PendingReportsService.getUserReportsCount(
          user?.email as string
        );

        setTotalPendingReports(total);
        setPendingReports(pendingReports);
      }
    };

    document.addEventListener(PENDING_REPORTS_NEW, getReports);

    getReports();

    return () => {
      suscribed = false;
      document.removeEventListener(PENDING_REPORTS_NEW, getReports);
    };
  }, [user]);

  const showTooltip = () => {
    setIsTooltipOpen(true);

    setTimeout(() => {
      setIsTooltipOpen(false);
    }, 3600);
  };

  React.useEffect(() => {
    if (!isOnline && isOfflinePath(location.pathname)) {
      showTooltip();
    }
  }, [isOnline, location.pathname]);

  const onOfflineClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    showTooltip();

    setIsMenuOpen(true);
  };

  const onOnlineClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsMenuOpen(true);
  };

  const onRetry = async () => {
    setIsUploading(true);
    let areFailedReports: boolean = false;
    let areSavedReports: boolean = false;

    const reportsToUpload: ReportValue[] =
      await PendingReportsService.getUserReports(user?.email as string);

    for (let report of reportsToUpload) {
      try {
        await sendReport(report);
        await PendingReportsService.deleteReport(report.id as number);
        areSavedReports = true;
      } catch (e) {
        const error: ApiError = e as ApiError;
        console.error('NetworkStatus::Retry', error);

        if (isUnauthorized(error)) {
          dispatchCustomEvent(SESSION_EXPIRE);
        } else if (error.detail === 'CREATED_REPORT_ALREADY_EXISTS') {
          setFailedReport({ ...report });
          setIsIncidentNumberModalOpen(true);
          break;
        } else {
          areFailedReports = true;
        }
      }
    }

    setIsFailedUploadModalOpen(areFailedReports);
    setTotalPendingReports(
      await PendingReportsService.getUserReportsCount(user?.email as string)
    );
    setPendingReports(
      getMenuPendingReports(
        await PendingReportsService.getUserReports(user?.email as string)
      )
    );
    setIsUploading(false);
    setIsMenuOpen(true);

    if (areSavedReports) {
      dispatchCustomEvent(UPDATE_REPORT_LIST);
    }
  };

  const onIncidentNumberModalClick = async (
    newIncidentNumber: string
  ): Promise<void> => {
    const reportUpdated: ReportValue = { ...(failedReport as ReportValue) };
    reportUpdated.general_information.incident_number = newIncidentNumber;

    await PendingReportsService.saveReport(
      user?.email as string,
      reportUpdated
    );

    setFailedReport(undefined);
    setIsIncidentNumberModalOpen(false);
    await onRetry();
  };

  const refContainer = React.useRef(null);

  return (
    <React.Fragment>
      <SFTooltip
        classes={{
          popper: styles.popper,
          tooltipPlacementLeft: styles.tooltipPlacementLeft
        }}
        title="Working offline"
        placement="left"
        open={isTooltipOpen}
        TransitionComponent={TooltipTransition}
      >
        <div className={styles.networkStatus} ref={refContainer}>
          <SFBadge
            className={`${styles.pendingReportsBadge} ${
              isPlanConnect(subscription?.plan) ? styles.isDisabled : ''
            }`}
            value={totalPendingReports}
            overlap="circular"
            size="medium"
          >
            {isOnline && pendingReports.length === 0 && (
              <OnlineDoneButton onClick={onOnlineClick} />
            )}
            {isOnline && pendingReports.length > 0 && (
              <OnlinePendingButton onClick={onOnlineClick} />
            )}
            {!isOnline && <OfflineButton onClick={onOfflineClick} />}
          </SFBadge>
        </div>
      </SFTooltip>

      <PendingReportsMenu
        anchorEl={refContainer.current}
        pendingReports={pendingReports}
        totalPendingReports={totalPendingReports}
        isOpen={isMenuOpen}
        isUploading={isUploading}
        onClose={() => setIsMenuOpen(false)}
        onRetry={onRetry}
      />

      <IncidentNumberModal
        key={`Incident-Number-${failedReport?.id}`}
        value={failedReport?.general_information.incident_number as string}
        isOpen={isIncidentNumberModalOpen}
        onClick={onIncidentNumberModalClick}
      />

      <FailedUploadModal
        isOpen={isFailedUploadModalOpen}
        onClick={() => setIsFailedUploadModalOpen(false)}
      />
    </React.Fragment>
  );
};
