import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.scss';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { AuthProvider } from './Context/AuthContext';
import { Metric } from 'web-vitals';
import { SW_UPDATE } from './Constants/Events';
import { setNewVersionAvailable } from './Services/SoftwareUpdateService';
import {
  StatesListConfigProvider,
  ThemeTypeProvider,
  dispatchCustomEvent
} from 'ui-smartforce-settings';

const isProductionEnv: boolean = process.env.NODE_ENV === 'production';

ReactDOM.render(
  <Router>
    {/* <React.StrictMode> */}
    <ThemeTypeProvider>
      <AuthProvider>
        <StatesListConfigProvider>
          <App />
        </StatesListConfigProvider>
      </AuthProvider>
    </ThemeTypeProvider>
    {/* </React.StrictMode> */}
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

// Custom onUpdate event from Smartforce Team
serviceWorkerRegistration.register({
  onUpdate: async (registration) => {
    // We want to run this code only if we detect a new service worker is
    // waiting to be activated.
    if (registration && registration.waiting) {
      setNewVersionAvailable();
      console.log('ServiceWorker::onUpdate');
      // Makes Workbox call skipWaiting()
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      // Once the service worker is unregistered, we can reload the page to let
      // the browser download a fresh copy of our app (invalidating the cache)

      setTimeout(() => {
        dispatchCustomEvent(SW_UPDATE);
      }, 200);
    }
  }
});

if (isProductionEnv) {
  const sendToGoogleAnalytics = ({
    name,
    delta,
    id
  }: Partial<Metric>): void => {
    if (name) {
      gtag('event', name, {
        event_category: 'Web Vitals',
        // The `id` value will be unique to the current page load. When sending
        // multiple values from the same page (e.g. for CLS), Google Analytics can
        // compute a total by grouping on this ID.
        //(note: requires `eventLabel` to be a dimension in your report).
        event_label: id,
        // Google Analytics metrics must be integers, so the value is rounded.
        // For CLS the value is first multiplied by 1000 for greater precision
        // (note: increase the multiplier for greater precision if needed).
        value: delta
          ? Math.round(name === 'CLS' ? delta * 1000 : delta)
          : undefined,
        // Use a non-interaction event to avoid affecting bounce rate.
        non_interaction: true
      });
    }
  };
  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals(sendToGoogleAnalytics);
}

console.log(`Citizen Contact Version ${process.env.REACT_APP_CC_VERSION}`);
