import React from 'react';
import styles from './SubscriptionStateView.module.scss';
import { SFIcon, SFLink, SFPaper, SFText } from 'sfui';

export type SubscriptionStateType = 'paused' | 'canceled';

export interface SubscriptionStateViewProps {
  state: SubscriptionStateType;
}

export const SubscriptionStateView = ({
  state
}: SubscriptionStateViewProps): React.ReactElement<SubscriptionStateViewProps> => {
  return (
    <SFPaper className={styles.subscriptionStateView}>
      <div className={styles.content}>
        <SFIcon className={styles.icon} icon="Announcement" size={96} />
        <h2
          className={styles.title}
        >{`Your subscription has been ${state}`}</h2>
      </div>
      <div className={styles.contact}>
        <SFText type="component-2">Would you like to contact us?</SFText>
        <SFText type="component-2">Click on the link below.</SFText>
        <br />
        <SFLink
          color="primary"
          sfSize="medium"
          href="https://smartforce.atlassian.net/servicedesk/customer/portals"
          target="_blank"
          rel="noreferrer"
        >
          SmartForce® Support
        </SFLink>
      </div>
    </SFPaper>
  );
};
