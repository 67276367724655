import React from 'react';
import { InputProps } from '../FieldInput';
import { SFPeopleOption } from 'sfui';
import { LocationAreaType } from '../../../../../Models';
import { AreaSelect } from '../../../../../Components/AreaSelect/AreaSelect';

const formatValueToPeoplePicker = (
  value: LocationAreaType[]
): SFPeopleOption[] => {
  return value.map((v: LocationAreaType) => {
    return {
      name: v.name,
      asyncObject: { id: v.id }
    };
  });
};

const formatPeoplePickerToValue = (
  value: SFPeopleOption[]
): LocationAreaType[] => {
  return value.map((v: SFPeopleOption) => ({
    id: v.asyncObject.id,
    name: v.name
  }));
};

export const AreasField = (
  props: InputProps
): React.ReactElement<InputProps> => {
  const onPeoplePickerChange = (value: SFPeopleOption[]) => {
    props.onChange(
      value.length > 0 ? formatPeoplePickerToValue(value) : undefined
    );
  };

  const value = props.value
    ? formatValueToPeoplePicker(props.value as LocationAreaType[])
    : [];

  return (
    <AreaSelect label="Areas" value={value} onChange={onPeoplePickerChange} />
  );
};
