import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

export interface RouteOnlineGuardProps extends RouteProps {
  isOnline: boolean;
}

export const RouteOnlineGuard = ({
  children,
  path,
  isOnline,
  ...rest
}: RouteOnlineGuardProps) => (
  <Route
    {...rest}
    children={() =>
      isOnline === true ? children : <Redirect to="/no-connection" />
    }
  />
);
