import React from 'react';
import styles from './ReportHistoryAvatar.module.scss';
import { Avatar } from 'ui-smartforce-settings';
import { SFText } from 'sfui';

export interface ReportHistoryAvatarProps {
  name: string;
  title: string;
  avatar?: string | null;
}

export const ReportHistoryAvatar = ({
  name,
  title,
  avatar
}: ReportHistoryAvatarProps): React.ReactElement<ReportHistoryAvatarProps> => {
  return (
    <div className={styles.reportHistoryAvatar}>
      <Avatar name={name} url={avatar} size="extraSmall" />

      <div>
        {title && (
          <SFText
            className={styles.title}
            type="component-messages"
            sfColor="neutral"
          >
            {title}
          </SFText>
        )}

        <SFText
          className={styles.text}
          type="component-2-medium"
          sfColor="neutral"
        >
          {name}
        </SFText>
      </div>
    </div>
  );
};
