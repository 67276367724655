import React from 'react';
import { SFAutocompleteChip } from 'sfui';
import { InputProps } from '../FieldInput';

export const AutocompleteChipField = (
  props: InputProps
): React.ReactElement<InputProps> => {
  const value: string[] = props.value ? (props.value as string[]) : [];

  return (
    <SFAutocompleteChip
      label={props.label}
      value={value}
      required={props.required}
      options={props.options ? props.options : []}
      onChange={props.onChange}
    />
  );
};
