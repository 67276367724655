import React from 'react';
import styles from './MissingInformationModal.module.scss';
import { SFButton, SFDialog, SFText } from 'sfui';

export interface MissingInformationModalProps {
  isOpen: boolean;
  messages: string[];
  onClose: () => void;
}

export const MissingInformationModal = ({
  isOpen,
  messages,
  onClose
}: MissingInformationModalProps): React.ReactElement<MissingInformationModalProps> => {
  return (
    <SFDialog
      PaperProps={{ className: styles.missingInformationModal }}
      open={isOpen}
    >
      <div className={styles.content}>
        <h2 className={styles.title}>Complete missing information</h2>

        <div className={styles.messages}>
          <SFText type="component-1">
            To submit this report, please complete the missing information
            listed below:
          </SFText>

          <ul className={styles.messageList}>
            {messages.map((message: string, index: number) => (
              <li key={`missing-info-message-${index}`}>
                <SFText type="component-1" sfColor="error">
                  {message}
                </SFText>
              </li>
            ))}
          </ul>
        </div>

        <SFButton
          variant="contained"
          sfColor="blue"
          size="large"
          onClick={onClose}
        >
          I Understand
        </SFButton>
      </div>
    </SFDialog>
  );
};
