import React, { useContext } from 'react';
import styles from './AnalyticsMain.module.scss';
import { AnalyticsActivate } from './AnalyticsActivate/AnalyticsActivate';
import { AnalyticsWidgets } from './AnalyticsWidgets/AnalyticsWidgets';
import {
  UserContext,
  checkPermissions,
  isPlanAnalytics,
  useSubscription
} from 'ui-smartforce-settings';
import { AGENCY_ANALYTICS_WATCH } from '../../../Constants/RolesAndPermissions';

export const AnalyticsMain = (): React.ReactElement<{}> => {
  const { user } = useContext(UserContext);
  const subscription = useSubscription('cc');

  const hasAnalytics: boolean =
    isPlanAnalytics(subscription?.plan) ||
    checkPermissions(AGENCY_ANALYTICS_WATCH, user?.role.permissions);

  return (
    <div className={styles.analyticsMain}>
      {subscription && !hasAnalytics && <AnalyticsActivate />}
      {hasAnalytics && <AnalyticsWidgets />}
    </div>
  );
};
