import React from 'react';
import styles from './FilterChipList.module.scss';
import { SFChip, SFText } from 'sfui';

export interface FilterChipDataValue {
  label: string;
  selected: boolean;
}

export interface FilterChipListProps {
  title: string;
  selected: string[];
  options: string[];
  onChange: (selected: string[]) => void;
}

export const FilterChipList = ({
  title,
  selected,
  options,
  onChange
}: FilterChipListProps): React.ReactElement<FilterChipListProps> => {
  const onOptionClick = (option: string, selectedIndex: number) => {
    if (selectedIndex !== -1) {
      onChange([
        ...selected.slice(0, selectedIndex),
        ...selected.slice(selectedIndex + 1)
      ]);
    } else {
      onChange([...selected, option]);
    }
  };

  return (
    <div className={styles.filterChipList}>
      <SFText type="component-2">{title}</SFText>
      <div className={styles.chipList}>
        {options.map((option: string, index: number) => {
          const selectedIndex: number = selected.indexOf(option);

          return (
            <SFChip
              className={`${styles.chip} ${
                selectedIndex !== -1 ? styles.chipSelected : ''
              }`}
              key={`${option}-${index}`}
              sfColor="primary"
              variant="outlined"
              label={option}
              onClick={() => onOptionClick(option, selectedIndex)}
            />
          );
        })}
      </div>
    </div>
  );
};
