import React from 'react';
import styles from './FilterCategoryForm.module.scss';
import set from 'lodash.set';
import get from 'lodash.get';
import unset from 'lodash.unset';
import {
  Field,
  FieldValueType,
  FiltersCategoryConfig,
  FiltersCategoryValueType,
  FiltersSection,
  FiltersSubSection
} from '../../../../../../Models';
import { FieldInput } from '../../../../ReportWizard/FieldInput/FieldInput';
import { SFText } from 'sfui';
import { clearEmptyObjects, cloneObject } from '../../../../../../Helpers';
import { isFieldEmpty } from 'ui-smartforce-settings';

export interface FilterCategoryFormProps {
  config: FiltersCategoryConfig;
  value: FiltersCategoryValueType;
  onChange: (value: FiltersCategoryValueType) => void;
}

export const FilterCategoryForm = ({
  config,
  value,
  onChange
}: FilterCategoryFormProps): React.ReactElement<FilterCategoryFormProps> => {
  const onFieldChange = (
    section: FiltersSection,
    subSection: FiltersSubSection,
    fieldName: string,
    fieldValue: FieldValueType
  ) => {
    let newValue = cloneObject(value);
    let path: string = fieldName;

    if (subSection.name) {
      path = `${subSection.name}.${path}`;
    }

    if (section.name) {
      path = `${section.name}.${path}`;
    }

    if (!isFieldEmpty(fieldValue)) {
      set(newValue, path, fieldValue);
    } else {
      unset(newValue, path);
      clearEmptyObjects(newValue);
    }

    onChange(newValue);
  };

  return (
    <div className={styles.filterCategoryForm}>
      {config.sections.map((section: FiltersSection, index: number) => {
        const sectionKey: string = section.name ?? `section-${index}`;
        return (
          <div key={sectionKey} className={styles.section}>
            <SFText type="component-title">{section.label}</SFText>

            {section.sub_sections.map(
              (subSection: FiltersSubSection, index: number) => (
                <div
                  key={`${sectionKey}-subsection-${index}`}
                  className={styles.subsection}
                >
                  {subSection.title && (
                    <SFText type="component-1-medium">
                      {subSection.title}
                    </SFText>
                  )}

                  {subSection.fields.map((field: Field) => {
                    let fieldValue: FieldValueType;
                    let path: string = field.name;

                    if (subSection.name) {
                      path = `${subSection.name}.${path}`;
                    }

                    if (section.name) {
                      path = `${section.name}.${path}`;
                    }

                    fieldValue = get(value, path);

                    return (
                      <FieldInput
                        activeOnly={false}
                        key={field.name}
                        field={field}
                        value={fieldValue}
                        onChange={(fieldValue: FieldValueType) =>
                          onFieldChange(
                            section,
                            subSection,
                            field.name,
                            fieldValue
                          )
                        }
                      />
                    );
                  })}
                </div>
              )
            )}
          </div>
        );
      })}
    </div>
  );
};
