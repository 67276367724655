import React, { useContext, useMemo } from 'react';
import styles from './OnboardingOwner.module.scss';
import { Elements } from '@stripe/react-stripe-js';
import { OnboardingOwnerStart } from './OnboardingOwnerStart/OnboardingOwnerStart';
import { OnboardingWizard } from './OnboardingWizard/OnboardingWizard';
import {
  CustomerContext,
  loadStripeAPI,
  SubscriptionContext,
  getAppSubscription
} from 'ui-smartforce-settings';

type ActiveViewType = 'start' | 'wizard';

export const OnboardingOwner = (): React.ReactElement<{}> => {
  const { customer } = useContext(CustomerContext);
  const subscriptions = useContext(SubscriptionContext).subscriptions;
  const hasOtherSubscription =
    subscriptions.length > 0 && !getAppSubscription(subscriptions, 'cc');
  const stripeAPI = useMemo(
    () => loadStripeAPI(`${process.env.REACT_APP_STRIPE_KEY}`),
    []
  );
  const [activeView, setActiveView] = React.useState<ActiveViewType>(
    hasOtherSubscription ? 'wizard' : 'start'
  );

  return (
    <div className={styles.onboardingOwner}>
      {activeView === 'start' && (
        <OnboardingOwnerStart
          isInactive={customer?.status === 'Inactive'}
          onClick={() => setActiveView('wizard')}
        />
      )}

      <div
        className={`${activeView !== 'start' ? styles.content : styles.hide}`}
      >
        <Elements stripe={stripeAPI}>
          <OnboardingWizard
            show={activeView === 'wizard'}
            onClose={() => setActiveView('start')}
          />
        </Elements>
      </div>
    </div>
  );
};
