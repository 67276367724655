import React from 'react';
import styles from './ItemList.module.scss';
import { getItemSummary } from '../../../../Helpers';
import { ItemListConfig, ItemValueType } from '../../../../Models';
import { SFText } from 'sfui';

export interface ItemListProps {
  title: string;
  items: ItemValueType[];
  itemsName: string;
  config: ItemListConfig;
}

export const ItemList = ({
  title,
  items,
  itemsName,
  config
}: ItemListProps): React.ReactElement<ItemListProps> => {
  return (
    <div className={styles.itemList}>
      <div className={styles.h4}>{title}</div>

      {items.map((item: ItemValueType, index: number) => (
        <div className={styles.infoItem} key={`${itemsName}-${index}`}>
          <SFText
            type="component-messages"
            sfColor="neutral"
          >{`${itemsName.toUpperCase()} ${index + 1}`}</SFText>
          <span>{getItemSummary(config, item)}</span>
        </div>
      ))}
    </div>
  );
};
