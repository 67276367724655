import React from 'react';
import { SFPaper } from 'sfui';
import { OnboardingOfficer } from './OnboardingOfficer/OnboardingOfficer';
import { OnboardingOwner } from './OnboardingOwner/OnboardingOwner';
import { UserContext, isRoleOwner } from 'ui-smartforce-settings';

export interface OnboardingProps {}

export const Onboarding = (
  props: OnboardingProps
): React.ReactElement<OnboardingProps> => {
  const { user } = React.useContext(UserContext);
  const userRole: string = user?.role?.id as string;

  return (
    <SFPaper>
      {isRoleOwner(userRole) && <OnboardingOwner />}
      {!isRoleOwner(userRole) && <OnboardingOfficer />}
    </SFPaper>
  );
};
