import React from 'react';
import styles from './WizardFinishContent.module.scss';
import { SFBlueMainLight, SFButtonProps, SFIcon, SFText } from 'sfui';

export interface WizardFinishContentProps {
  icon: string;
  message: string;
  title: string;
  ButtonElement: React.ReactElement<SFButtonProps>;
}

export const WizardFinishContent = (
  props: WizardFinishContentProps
): React.ReactElement<WizardFinishContentProps> => {
  return (
    <div className={styles.wizardFinishContent}>
      <div className={styles.icon}>
        <SFIcon icon={props.icon} size={96} color={SFBlueMainLight} />
      </div>

      <h2 className={styles.title}>{props.title}</h2>

      <SFText type="component-2" sfColor="neutral">
        {props.message}
      </SFText>

      {props.ButtonElement && (
        <div className={styles.button}>{props.ButtonElement}</div>
      )}
    </div>
  );
};
