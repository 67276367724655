import styles from './CheckboxField.module.scss';
import { SFCheckbox } from 'sfui';
import { InputProps } from '../FieldInput';
import React from 'react';

export const CheckboxField = (
  props: InputProps
): React.ReactElement<InputProps> => {
  const value = (props.value as string[]) || [];

  const isOptionChecked = (option: string) => {
    return value && value.indexOf(option) !== -1;
  };

  const onOptionChange = (option: string, checked: boolean) => {
    if (checked) {
      if (props.single_choice === option) {
        props.onChange([option]);
      } else {
        props.onChange([option, ...value]);
      }
    } else {
      const index = value.indexOf(option);
      const newValue: string[] = [
        ...value.slice(0, index),
        ...value.slice(index + 1)
      ];
      props.onChange(newValue.length > 0 ? newValue : undefined);
    }
  };

  return (
    <div className={styles.field}>
      <label className={styles.fieldLabel}>
        {props.label} {props.required ? '*' : ''}
      </label>

      <div className={styles.options}>
        {props.options?.map((option: string) => (
          <SFCheckbox
            key={option}
            label={option}
            disabled={
              option !== props.single_choice &&
              value.length === 1 &&
              props.single_choice === value[0]
            }
            checked={isOptionChecked(option)}
            onChange={(e, checked) => onOptionChange(option, checked)}
          />
        ))}
      </div>

      {props.helperText && (
        <p
          className={`${styles.helperText} ${props.error ? styles.error : ''}`}
        >
          {props.helperText}
        </p>
      )}
    </div>
  );
};
