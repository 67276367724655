import React from 'react';
import styles from './CompleteDebitInformationView.module.scss';
import { useLocation } from 'react-router-dom';
import { CompleteDebitInformation } from 'ui-smartforce-settings';

interface CompleteDebitInformationViewState {
  url: string;
}

export interface CompleteDebitInformationViewProps {}

export const CompleteDebitInformationView = (
  props: CompleteDebitInformationViewProps
): React.ReactElement<CompleteDebitInformationViewProps> => {
  const location = useLocation();
  const locationState: CompleteDebitInformationViewState =
    location.state as CompleteDebitInformationViewState;

  const onFinish = () => {
    if (locationState && locationState.url) {
      window.location.href = locationState.url;
    }
  };

  return (
    <div className={styles.completeDebitInformationView}>
      <CompleteDebitInformation onFinish={onFinish} />
    </div>
  );
};
