import React from 'react';
import styles from './FilterSelectorItem.module.scss';
import { SFCheckbox } from 'sfui';

export interface FilterMapOption {
  label: string;
  color: string;
}

export interface FilterSelectorItemProps {
  option: FilterMapOption;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

export const FilterSelectorItem = ({
  option,
  checked,
  onChange
}: FilterSelectorItemProps): React.ReactElement<FilterSelectorItemProps> => {
  return (
    <div className={styles.filterSelectorItem}>
      <div
        style={{
          backgroundColor: option.color
        }}
        className={styles.circleColor}
      />

      <span className={styles.label}>{option.label}</span>

      <SFCheckbox
        checked={checked}
        onChange={(_e, checked) => onChange(checked)}
        role="checkbox"
        aria-label={option.label}
        aria-checked={checked}
      />
    </div>
  );
};
