import React, { useContext } from 'react';
import styles from './MyWidgetHeader.module.scss';
import { SFButton } from 'sfui';
import { MediaContext } from 'ui-smartforce-settings';

export interface MyWidgetHeaderProps {
  className?: string;
  disabled: boolean;
  onAddWidgets: () => void;
}

export const MyWidgetHeader = ({
  className = '',
  disabled,
  onAddWidgets
}: MyWidgetHeaderProps): React.ReactElement<MyWidgetHeaderProps> => {
  const { isPhone } = useContext(MediaContext);

  return (
    <div className={`${styles.myWidgetHeader} ${className}`}>
      <h2>My widgets</h2>

      <SFButton
        variant="contained"
        sfColor="blue"
        size={isPhone ? 'large' : 'medium'}
        fullWidth={isPhone}
        onClick={onAddWidgets}
        disabled={disabled}
      >
        Add Widgets
      </SFButton>
    </div>
  );
};
