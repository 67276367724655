import React from 'react';
import { WidgetList } from '../../../../../Components/WidgetList/WidgetList';
import { AnalyticsPeriod, Widget } from '../../../../../Models';

export interface MyWidgetsProps {
  widgets: Widget[];
  draggable: boolean;
  period: AnalyticsPeriod;
  onRemove: (id: number) => void;
  onReorder: (ids: number[]) => void;
}

export const MyWidgets = ({
  widgets,
  draggable,
  period,
  onRemove,
  onReorder
}: MyWidgetsProps): React.ReactElement<MyWidgetsProps> => {
  return (
    <WidgetList
      widgets={widgets}
      draggable={draggable}
      isActive
      period={period}
      onRemove={onRemove}
      onReorder={onReorder}
    />
  );
};
