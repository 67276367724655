import React from 'react';
import styles from './MultipleReportsModalSelectListOption.module.scss';
import { SFIcon } from 'sfui';
import { formatDateString } from 'ui-smartforce-settings';

export interface MultipleReportsModalSelectListOptionProps {
  incident_date: string;
  author_name: string;
  isSelected: boolean;
  onClick: () => void;
}

export const MultipleReportsModalSelectListOption = ({
  incident_date,
  author_name,
  isSelected,
  onClick
}: MultipleReportsModalSelectListOptionProps): React.ReactElement<MultipleReportsModalSelectListOptionProps> => {
  const onKeyUp = (e: React.KeyboardEvent<HTMLLIElement>) => {
    if (e.key === 'Enter') {
      onClick();
    }
  };

  return (
    <li
      className={`${styles.multipleReportsModalSelectListOption} ${
        isSelected ? styles.isSelected : ''
      }`}
      role="menuitem"
      onClick={onClick}
      onKeyUp={onKeyUp}
    >
      <span className={styles.listOptionIcon}>
        <SFIcon icon="Blank-File" size={22} />
      </span>

      <div className={styles.listOptionLabel}>
        <p className={styles.labelDate}>
          {formatDateString(incident_date, 'L - HH:mm')}
        </p>
        <p className={styles.labelAuthor}>{author_name}</p>
      </div>
    </li>
  );
};
