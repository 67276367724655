import React, { useContext } from 'react';
import {
  AppEnv,
  MediaContext,
  SFTopBar,
  SFTopBarProps,
  TourContext,
  User,
  UserContext,
  checkPermissions,
  hideTours,
  logout
} from 'ui-smartforce-settings';
import { USER_REPORTS_CREATE } from '../../Constants/RolesAndPermissions';
import { NetworkStatus } from '../NetworkStatus/NetworkStatus';
import { useHistory } from 'react-router-dom';

export interface CCTopBarProps
  extends Omit<
    SFTopBarProps,
    'onLogout' | 'product' | 'enviroment' | 'officerCardUrl'
  > {}

export const CCTopBar = (
  props: CCTopBarProps
): React.ReactElement<CCTopBarProps> => {
  const history = useHistory();
  const { isFeatureReminderOpen, setIsFeatureReminderOpen, onDisableReminder } =
    useContext(TourContext);
  const { isLargeScreen } = useContext(MediaContext);
  const user = React.useContext(UserContext).user as User;

  const showNetworkStatus = checkPermissions(
    USER_REPORTS_CREATE,
    user.role.permissions
  );

  const onLogout = () => {
    logout();
    history.push('/login');
  };

  const onGotIt = (checked: boolean) => {
    if (checked) {
      hideTours(process.env.REACT_APP_SETTINGS_API_BASE_URL as string, 'cc');
      onDisableReminder();
    }
    setIsFeatureReminderOpen(false);
  };

  return (
    <SFTopBar
      {...props}
      officerCardUrl={process.env.REACT_APP_CC_OFFICER_CARD_URL as string}
      enviroment={process.env.REACT_APP_ENV as AppEnv}
      product="cc"
      onLogout={onLogout}
      actions={showNetworkStatus && <NetworkStatus />}
      featureReminderProps={{
        open: !isLargeScreen && isFeatureReminderOpen,
        onGotIt
      }}
    />
  );
};
