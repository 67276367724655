import React, { Fragment, useEffect, useRef } from 'react';
import styles from './WidgetList.module.scss';
import { Masonry } from '../Masonry/Masonry';
import { MasonryElement } from '../Masonry/MasonryElement/MasonryElement';
import { WidgetCard } from '../WidgetCard/WidgetCard';
import { AnalyticsPeriod, Widget } from '../../Models/Widget';
import { cloneDiv, drawGoogleMap, getWidgetColSize } from '../../Helpers';
import { WIDGET_DOUGHNUT, WIDGET_RATE_CARD } from '../../Constants/Analytics';

function getMapClone(target: HTMLDivElement): HTMLDivElement {
  const clone = cloneDiv(target);

  const clonePanel = clone.querySelector(
    'div[class*=WidgetPanel_widgetPanel_]'
  ) as HTMLDivElement;

  if (clonePanel) {
    clonePanel.style.height = '100%';
    clonePanel.style.top = '0';
  }

  const cloneMap = clone.querySelector(
    'div[class*=GoogleMap_module_googleMap]'
  ) as HTMLDivElement;

  if (cloneMap) {
    const targetMap = target.querySelector(
      'div[class*=GoogleMap_module_googleMap]'
    ) as HTMLDivElement;
    const canvas = drawGoogleMap(targetMap);
    cloneMap.innerHTML = '';
    cloneMap.appendChild(canvas);
  }

  return clone;
}

function getDoughnutClone(target: HTMLDivElement): HTMLDivElement {
  const clone = cloneDiv(target);

  // Check if its a Doughnut, and remove overflow from list
  const targetDoughnut = target.querySelector(
    'div[class*=DoughnutInformation_doughnutInformation]'
  ) as HTMLDivElement;

  if (targetDoughnut) {
    // Get doughnut list container
    const targetDoughnutList = targetDoughnut.querySelector(
      'div[class*=DoughnutInformation_content]'
    ) as HTMLDivElement;

    const itemHeight = targetDoughnutList.children[0].clientHeight;
    const childrenTotalHeight =
      targetDoughnutList.childElementCount * itemHeight;
    const doughHeight = targetDoughnutList.clientHeight;
    const diffHeight = childrenTotalHeight - doughHeight;

    // If childrens height it's greater than container height then remove some children
    if (diffHeight > 0) {
      // Calculate the amount of children to remove
      const removeCount = Math.round(diffHeight / itemHeight);

      // Get list container from the clone
      const doughListClone = clone.querySelector(
        'div[class*=DoughnutInformation_doughnutInformation] div[class*=DoughnutInformation_content]'
      ) as HTMLDivElement;

      // Remove from the bottom of the list
      for (let i = 0; i < removeCount; i++) {
        if (doughListClone.lastChild)
          doughListClone.removeChild(doughListClone.lastChild);
      }
    }
  }

  return clone;
}

function getDragImageCloneFn(widgetName: string) {
  if (widgetName.includes('_map')) {
    return getMapClone;
  } else if (widgetName === WIDGET_DOUGHNUT) {
    return getDoughnutClone;
  } else {
    return undefined;
  }
}

export interface WidgetListProps {
  widgets: Widget[];
  draggable?: boolean;
  isActive?: boolean;
  period: AnalyticsPeriod;
  onAdd?: (id: number) => void;
  onRemove?: (id: number) => void;
  onReorder?: (ids: number[]) => void;
}

export const WidgetList = ({
  widgets,
  draggable = false,
  isActive = false,
  period,
  onAdd,
  onRemove,
  ...props
}: WidgetListProps): React.ReactElement<WidgetListProps> => {
  const smallWidgets = widgets.filter(
    (w: Widget) => w.component.name === WIDGET_RATE_CARD
  );

  const otherWidgets = widgets.filter(
    (w: Widget) => w.component.name !== WIDGET_RATE_CARD
  );

  const refSmallOrder = useRef<number[]>([]);
  const refOtherOrder = useRef<number[]>([]);

  useEffect(() => {
    refSmallOrder.current = Array.from(Array(smallWidgets.length).keys());
    refOtherOrder.current = Array.from(Array(otherWidgets.length).keys());
  }, [smallWidgets, otherWidgets]);

  const id = Date.now();
  const ids: string[] = draggable ? [`${id}-small`, `${id}-others`] : [];

  const onReorder = () => {
    props.onReorder &&
      props.onReorder([
        ...refSmallOrder.current.map((i: number) => smallWidgets[i].id),
        ...refOtherOrder.current.map((i: number) => otherWidgets[i].id)
      ]);
  };

  const onReorderSmall = (indexes: number[]) => {
    refSmallOrder.current = indexes;
    onReorder();
  };

  const onReorderOther = (indexes: number[]) => {
    refOtherOrder.current = indexes;
    onReorder();
  };

  return (
    <div className={styles.widgetList}>
      {widgets.length > 0 && (
        <Fragment>
          <Masonry
            draggable={draggable}
            id={ids[0]}
            onUpdateOrder={(indexes: number[]) => onReorderSmall(indexes)}
          >
            {smallWidgets.map((widget: Widget) => (
              <MasonryElement
                key={widget.id}
                id={widget.id}
                size={getWidgetColSize(widget.component.name)}
              >
                <WidgetCard
                  widget={widget}
                  isActive={isActive}
                  period={period}
                  onAdd={onAdd}
                  onRemove={onRemove}
                />
              </MasonryElement>
            ))}
          </Masonry>

          <Masonry
            draggable={draggable}
            id={ids[1]}
            onUpdateOrder={(indexes: number[]) => onReorderOther(indexes)}
          >
            {otherWidgets.map((widget: Widget) => (
              <MasonryElement
                key={widget.id}
                id={widget.id}
                size={getWidgetColSize(widget.component.name)}
                getDragImageClone={
                  draggable
                    ? getDragImageCloneFn(widget.component.name)
                    : undefined
                }
              >
                <WidgetCard
                  widget={widget}
                  isActive={isActive}
                  period={period}
                  onAdd={onAdd}
                  onRemove={onRemove}
                />
              </MasonryElement>
            ))}
          </Masonry>
        </Fragment>
      )}
    </div>
  );
};
