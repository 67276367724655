import React from 'react';
import styles from './ReportHistoryPanel.module.scss';
import { SFButton, SFDrawer, SFIconButton, SFScrollable } from 'sfui';
import { MediaContext } from 'ui-smartforce-settings';

export interface ReportHistoryPanelProps {
  isOpen: boolean;
  children: React.ReactNode;
  onClose: () => void;
}

export const ReportHistoryPanel = ({
  isOpen,
  children,
  onClose
}: ReportHistoryPanelProps): React.ReactElement<ReportHistoryPanelProps> => {
  const { isPhone } = React.useContext(MediaContext);

  return (
    <SFDrawer
      PaperProps={{ className: styles.reportHistoryPanel }}
      open={isOpen}
      anchor={isPhone ? 'bottom' : 'right'}
      onClose={onClose}
    >
      <header>
        <SFIconButton sfIcon="Close" sfSize="medium" onClick={onClose} />
      </header>

      <SFScrollable className={styles.content}>{children}</SFScrollable>

      <footer>
        <SFButton size="large" variant="text" sfColor="grey" onClick={onClose}>
          Close
        </SFButton>
      </footer>
    </SFDrawer>
  );
};
