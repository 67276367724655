import React from 'react';
import { ReportListType } from '../../../Services/ReportService';
import styles from './ReportCardHeader.module.scss';
import { ReportCardHeaderActions } from './ReportCardHeaderActions/ReportCardHeaderActions';
import { SFText } from 'sfui';

export interface ReportCardHeaderProps {
  type: ReportListType;
  className?: string;
  incidentNumber: string;
  onViewHistory: () => void;
  onScanQrCode: () => void;
  onSeeFullReport: () => void;
}

export const ReportCardHeader = ({
  type,
  className = '',
  incidentNumber,
  onViewHistory,
  onScanQrCode,
  onSeeFullReport
}: ReportCardHeaderProps): React.ReactElement<ReportCardHeaderProps> => {
  return (
    <div className={`${styles.reportCardHeader} ${className}`}>
      <div className={styles.incidentNumber}>
        <SFText type="component-messages" sfColor="neutral">
          INCIDENT NUMBER
        </SFText>
        <SFText type="component-title">{incidentNumber}</SFText>
      </div>

      <ReportCardHeaderActions
        type={type}
        onViewHistory={onViewHistory}
        onScanQrCode={onScanQrCode}
        onSeeFullReport={onSeeFullReport}
      />
    </div>
  );
};
