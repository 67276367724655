export const ANALYTICS_COLOR_BLUE = '#0D69F2';
export const ANALYTICS_COLOR_LIGHTBLUE = '#3DC4F5';
export const ANALYTICS_COLOR_GREEN = '#40BF62';
export const ANALYTICS_COLOR_YELLOW = '#F5D93D';
export const ANALYTICS_COLOR_ORANGE = '#F59C3D';
export const ANALYTICS_COLOR_RED = '#EB4747';
export const ANALYTICS_COLOR_PINK = '#F075DB';
export const ANALYTICS_COLOR_PURPLE = '#B447EB';

export const ANALYTICS_COMMON_PALETTE = [
  ANALYTICS_COLOR_BLUE,
  ANALYTICS_COLOR_LIGHTBLUE,
  ANALYTICS_COLOR_GREEN,
  ANALYTICS_COLOR_YELLOW,
  ANALYTICS_COLOR_ORANGE,
  ANALYTICS_COLOR_RED,
  ANALYTICS_COLOR_PINK,
  ANALYTICS_COLOR_PURPLE
];

export const ANALYTICS_DAY_PALETTE = [
  ...ANALYTICS_COMMON_PALETTE,
  '#66A3FF',
  '#99E4FF',
  '#84E09D',
  '#FFF099',
  '#FFCE99',
  '#FA9E9E',
  '#FBB6F0',
  '#DB9EFA'
];

export const ANALYTICS_NIGHT_PALETTE = [
  ...ANALYTICS_COMMON_PALETTE,
  '#003D99',
  '#0096CC',
  '#1F7A37',
  '#CCAD00',
  '#CC6900',
  '#C20A0A',
  '#D742BE',
  '#840AC2'
];
