import React, { useEffect, useState } from 'react';
import styles from './AnalyticsWidgetsLoader.module.scss';
import { SFSpinner, SFText } from 'sfui';
import { setInterval } from 'timers';

const MESSAGES: string[] = [
  'Please wait while we collect your results...',
  'We are processing your request...',
  'This may take a while...'
];

const MESSAGE_DONE = 'Done!';
const MESSAGE_LOOP_TIME = 3000;

export interface AnalyticsWidgetsLoaderProps {
  done: boolean;
}

export const AnalyticsWidgetsLoader = ({
  done
}: AnalyticsWidgetsLoaderProps): React.ReactElement<AnalyticsWidgetsLoaderProps> => {
  const [messageIndex, setMessageIndex] = useState<number>(0);

  useEffect(() => {
    let subscribed = true;
    let interval = setInterval(() => {
      if (subscribed) {
        setMessageIndex((i) => {
          let newIndex = i + 1;
          if (newIndex === MESSAGES.length) newIndex = 1;
          return newIndex;
        });
      }
    }, MESSAGE_LOOP_TIME);

    return () => {
      subscribed = false;
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={styles.analyticsWidgetsLoader}>
      <div className={styles.content}>
        <SFSpinner className={styles.spinner} size={40} />
        <div className={styles.messageContainer}>
          {MESSAGES.map((message, index) => (
            <SFText
              key={`analytics-loader-message-${index}`}
              className={
                !done && index === messageIndex ? styles.show : styles.hide
              }
              type="component-1-extraBold"
              sfColor="neutral"
            >
              {message}
            </SFText>
          ))}

          <SFText
            className={done ? styles.show : styles.hide}
            type="component-1-extraBold"
            sfColor="neutral"
          >
            {MESSAGE_DONE}
          </SFText>
        </div>
      </div>
    </div>
  );
};
