import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import styles from './ReportsFilterModal.module.scss';
import moment from 'moment';
import get from 'lodash.get';
import { DateRange, NumberRange, ReportListFilters } from '../../../../Models';
import { handleError } from '../../../../Helpers';
import {
  BASIC_FILTERS_MIN_DATE,
  BasicFilters
} from './BasicFilters/BasicFilters';
import { AdvancedFilters } from './AdvancedFilters/AdvancedFilters';
import { useHistory } from 'react-router-dom';
import { UserGroupsContext } from '../../ReportWizard/ContactFormView/ItemListForm/ItemListForm';
import {
  isEqualObject,
  Group,
  getGroups,
  isPlanAnalytics,
  PanelModal,
  useSubscription,
  TourTooltip,
  TourContext
} from 'ui-smartforce-settings';
import { SFButton } from 'sfui';

function isDateRangeInvalid(dateRange: DateRange, minDate?: string): boolean {
  if (!dateRange) {
    return false;
  }

  const dateToday: string = moment().endOf('day').toISOString();
  const dateFrom: string | undefined = dateRange.dateFrom;
  const dateTo: string | undefined = dateRange.dateTo;

  if (dateFrom) {
    if (
      !moment(dateFrom).isValid() ||
      (minDate && dateFrom < minDate) ||
      dateFrom > dateToday
    ) {
      return true;
    }

    if (dateTo) {
      if (moment(dateTo).diff(dateFrom, 'days') > 365) {
        return true;
      }

      if (
        !moment(dateTo).isValid() ||
        dateTo < dateFrom ||
        dateTo > dateToday
      ) {
        return true;
      }
    } else if (!dateRange.exactDate) {
      return true;
    }

    return false;
  } else {
    return true;
  }
}

function isNumberRangeInvalid(value: NumberRange | undefined): boolean {
  if (value) {
    if (value.from !== undefined && value.to !== undefined) {
      if (value.from > value.to) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  } else {
    return false;
  }
}

function areFiltersInvalid(
  filters: ReportListFilters,
  hasAnalytics: boolean
): boolean {
  if (
    isDateRangeInvalid(
      filters.general_information?.date as DateRange,
      hasAnalytics ? undefined : BASIC_FILTERS_MIN_DATE
    )
  ) {
    return true;
  }

  const citizensAge = get(filters, 'citizens.perceived_information.age') as
    | NumberRange
    | undefined;
  const officersAge = get(filters, 'officers.officer_information.age') as
    | NumberRange
    | undefined;

  if (isNumberRangeInvalid(citizensAge) || isNumberRangeInvalid(officersAge)) {
    return true;
  }

  return false;
}

export interface ReportsFilterModalProps {
  filters: ReportListFilters;
  isOpen: boolean;
  onSubmit: (filters: ReportListFilters) => void;
  onClose: () => void;
}

export const ReportsFilterModal = ({
  filters,
  isOpen,
  onClose,
  onSubmit
}: ReportsFilterModalProps): React.ReactElement<ReportsFilterModalProps> => {
  const history = useHistory();
  const subscription = useSubscription('cc');
  const {
    status: tourStatus,
    onNext: onTourNext,
    onEnd: onTourEnd,
    onClose: onTourClose,
    setIsFeatureReminderOpen
  } = useContext(TourContext);
  const [groups, setGroups] = useState<Group[]>([]);
  const [formValues, setFormValues] = useState<ReportListFilters>({});

  const refIsPristine = useRef<boolean>(true);

  const hasAnalytics: boolean = isPlanAnalytics(subscription?.plan);

  const isActionButtonDisabled: boolean =
    isEqualObject(formValues, filters) ||
    areFiltersInvalid(formValues, hasAnalytics);

  useEffect(() => {
    if (isOpen) {
      refIsPristine.current = true;
    }
  }, [isOpen]);

  useEffect(() => {
    if (refIsPristine.current && !isActionButtonDisabled) {
      refIsPristine.current = false;
      onTourNext({ tourId: 7, step: 2 });
    }
  }, [isActionButtonDisabled, onTourNext]);

  useEffect(() => {
    let isSubscribed: boolean = true;

    const init = async () => {
      try {
        const groups = await getGroups(
          process.env.REACT_APP_SETTINGS_API_BASE_URL as string
        );

        if (isSubscribed) {
          setGroups(groups);
        }
      } catch (e) {
        handleError(e, history);
      }
    };

    init();

    // Unsuscribed when cleaning up
    return () => {
      isSubscribed = false;
    };
  }, [history]);

  useEffect(() => {
    setFormValues(filters);
  }, [filters]);

  const onModalDiscard = () => {
    onTourClose([7]);
    setFormValues(filters);
    onClose();
  };

  const onClearAll = () => {
    setFormValues({});
  };

  const onClickShowResults = () => {
    if (tourStatus === 'active') {
      onTourEnd();
      setIsFeatureReminderOpen(true);
    }

    onSubmit(formValues);
  };

  const className: string = `${styles.reportsFilterModal} ${
    hasAnalytics ? styles.showAdvanced : ''
  }`;

  return (
    <PanelModal
      classes={{
        dialog: {
          root: className,
          paper: styles.dialogPaper,
          container: styles.dialogContainer,
          header: styles.dialogHeader,
          content: styles.dialogContent
        },
        drawer: {
          paper: className,
          content: styles.drawerContent
        }
      }}
      headerTitle={hasAnalytics ? 'Advanced Filters' : 'Filter by'}
      isOpen={isOpen}
      actionButton={{
        label: 'Show Results',
        disabled: isActionButtonDisabled,
        onClick: () => onSubmit(formValues),
        visible: 'drawer'
      }}
      onClose={onClose}
    >
      <Fragment>
        {!hasAnalytics && (
          <BasicFilters
            hasAnalytics={hasAnalytics}
            value={formValues}
            onClear={onClearAll}
            onChange={(value: ReportListFilters) => setFormValues(value)}
          />
        )}

        {hasAnalytics && (
          <UserGroupsContext.Provider value={groups}>
            <AdvancedFilters
              value={formValues}
              onChange={(value: ReportListFilters) => setFormValues(value)}
            />
          </UserGroupsContext.Provider>
        )}

        <div className={styles.actions}>
          <SFButton
            variant="text"
            sfColor="grey"
            size="large"
            onClick={onModalDiscard}
          >
            Discard
          </SFButton>

          <TourTooltip
            title="Show your results"
            description='Once you have applied all the filters you need, click the "Show Results" button to view your search.'
            step={3}
            lastStep={3}
            tourId={7}
            width="fit"
            placement="top-end"
            topZIndex
          >
            <SFButton
              disabled={isActionButtonDisabled}
              onClick={onClickShowResults}
              size="large"
            >
              Show Results
            </SFButton>
          </TourTooltip>
        </div>
      </Fragment>
    </PanelModal>
  );
};
