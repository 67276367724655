import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import styles from './AnalyticsWidgets.module.scss';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import { SFScrollable } from 'sfui';
import { WidgetsSection } from './WidgetsSection/WidgetsSection';
import { MyWidgets } from './MyWidgets/MyWidgets';
import {
  AddWidgetModal,
  WidgetItemProps
} from './AddWidgetModal/AddWidgetModal';
import {
  AnalyticsConfig,
  AnalyticsFiltersForm,
  AnalyticsFiltersRequest,
  AnalyticsPeriod,
  Widget,
  WidgetComponent
} from '../../../../Models/Widget';
import {
  addFavourites,
  getConfig,
  getNewWidgets,
  getNonFavWidgets,
  getSuggestions,
  getUserWidgets,
  isWidgetStorageEvent,
  removeFavourite,
  updateFavourites
} from '../../../../Services/WidgetService';
import { handleError, isAnalyticsNotFound } from '../../../../Helpers';
import {
  Customer,
  CustomerContext,
  UserContext,
  checkPermissions
} from 'ui-smartforce-settings';
import { AGENCY_ANALYTICS_SETTINGS_CREATE } from '../../../../Constants/RolesAndPermissions';
import { MyWidgetHeader } from './MyWidgetHeader/MyWidgetHeader';
import { NoAnalyticsReady } from './NoAnalyticsReady/NoAnalyticsReady';
import { WIDGET_RATE_CARD } from '../../../../Constants/Analytics';
import { Divider } from '../../../../Components/Divider/Divider';
import { useDispatchMainAlert } from '../../../../Hooks';
import { WidgetsFilter } from './WidgetsFilter/WidgetsFilter';
import { FilterByModal } from './FilterByModal/FilterByModal';
import { AnalyticsWidgetsLoader } from './AnalyticsWidgetsLoader/AnalyticsWidgetsLoader';

function formatFilterDate(date: Date, timezone: string): string {
  const newDate = moment.tz(moment(date).format('YYYY-MM-DD'), timezone);
  return newDate.toISOString();
}

function getRequestFilters(
  filters: AnalyticsFiltersForm,
  timezone: string
): AnalyticsFiltersRequest {
  let request: AnalyticsFiltersRequest = {
    period: filters.period,
    filters: {}
  };

  if (
    filters.period === 'custom' &&
    filters.dateRange?.from &&
    filters.dateRange?.to
  ) {
    const to = moment(filters.dateRange.to).add(1, 'day').toDate();

    request.filters.general_information = {
      date: {
        from: formatFilterDate(filters.dateRange.from, timezone),
        to: formatFilterDate(to, timezone)
      }
    };
  }

  if (filters.officerOption === 'name' && filters.officer) {
    request.filters.officers = {
      officer_information: {
        id: filters.officer?.asyncObject.id
      }
    };
  } else if (filters.officerOption === 'group' && filters.group) {
    request.filters.officers = {
      officer_information: {
        groups: filters.group?.map((g) => g.asyncObject.id)
      }
    };
  }

  if (filters.area) {
    request.filters.general_information = {
      ...request.filters.general_information,
      location: { areas: filters.area.map((a) => a.asyncObject.id) }
    };
  }

  return request;
}

function insertWidgetInOrder(list: Widget[], newWidget: Widget): Widget[] {
  if (list.length === 0) {
    return [newWidget];
  }

  const index = list.findIndex(
    (widget: Widget) => widget.position > newWidget.position
  );

  if (index === -1) {
    return [...list, newWidget];
  }

  return [
    ...list.slice(0, index),
    newWidget,
    ...list.slice(index, list.length)
  ];
}

const filterRemovedWidgets = (
  defaultWidgets: Widget[],
  currentWidgets: Widget[],
  widgetId: number
) => {
  const widgetRemoved = defaultWidgets.find((w: Widget) => w.id === widgetId);

  return defaultWidgets.filter((w: Widget) =>
    [...currentWidgets, widgetRemoved].includes(w)
  );
};

export interface AnalyticsWidgetsProps {}

export const AnalyticsWidgets = (
  props: AnalyticsWidgetsProps
): React.ReactElement<AnalyticsWidgetsProps> => {
  const defaultSuggestedWidgetsRef = useRef<Widget[]>();
  const defaultNewWidgetsRef = useRef<Widget[]>();
  const history = useHistory();
  const { user } = useContext(UserContext);
  const customer = useContext(CustomerContext).customer as Customer;

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isDoneFetching, setIsDoneFetching] = React.useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [isNotAnalyticsReady, setIsNotAnalyticsReady] =
    React.useState<boolean>(false);
  const [modalTitle, setModalTitle] = React.useState<string>('');
  const [modalWidgets, setModalWidgets] = React.useState<WidgetItemProps[]>([]);
  const [period, setPeriod] = React.useState<AnalyticsPeriod>();

  const [userWidgets, setUserWidgets] = useState<Widget[]>([]);
  const [suggestedWidgets, setSuggestedWidgets] = useState<Widget[]>([]);
  const [newWidgets, setNewWidgets] = useState<Widget[]>([]);
  const [nonFavWidgets, setNonFavWidgets] = useState<Widget[]>([]);

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [filters, setFilters] = useState<AnalyticsFiltersForm>({
    period: 'monthly',
    officerOption: 'name'
  });

  const hasSettingsPermission = checkPermissions(
    AGENCY_ANALYTICS_SETTINGS_CREATE,
    user?.role.permissions
  );

  useDispatchMainAlert();

  useEffect(() => {
    // Declare local storage change event listener variable
    // Declared here to remove it on useEffect clean function
    let storageEventListener: {
      (e: StorageEvent): Promise<void>;
    };

    setIsLoading(true);
    setIsDoneFetching(false);
    setIsNotAnalyticsReady(false);

    const getWidgets = async () => {
      try {
        const config: AnalyticsConfig = await getConfig(
          getRequestFilters(filters, customer.timezone)
        );

        // Function to update the different widget states
        const updateWidgets = () => {
          const userWidgets = getUserWidgets(config);
          const defaultSuggestedWidgets = getSuggestions(config);
          const defaultNewWidgets = getNewWidgets(config);

          defaultSuggestedWidgetsRef.current = defaultSuggestedWidgets;
          defaultNewWidgetsRef.current = defaultNewWidgets;

          setUserWidgets(userWidgets);
          setNonFavWidgets(getNonFavWidgets(config));
          setSuggestedWidgets(
            defaultSuggestedWidgets.filter(
              (w: Widget) => !userWidgets.includes(w)
            )
          );
          setNewWidgets(
            defaultNewWidgets.filter((w: Widget) => !userWidgets.includes(w))
          );
        };

        // Assign storage event handler to check changes of widget favourites
        storageEventListener = async (e: StorageEvent) => {
          // Update widgets if favourites has changed in another instance (window, tab)
          if (isWidgetStorageEvent(e)) {
            updateWidgets();
          }
        };

        // Add event listener to window
        window.addEventListener('storage', storageEventListener);

        //Initialize widgets states
        updateWidgets();

        setPeriod({
          name: filters.period,
          from: config.date_from,
          to: config.date_to
        });

        setIsDoneFetching(true);
        setTimeout(() => setIsLoading(false), 3000);
      } catch (e) {
        console.log('Analytics::getConfig', e);
        if (isAnalyticsNotFound(e)) {
          setIsNotAnalyticsReady(true);
          setIsLoading(false);
        } else {
          handleError(e, history);
        }
      }
    };

    getWidgets();

    return () => {
      // If exists, remove the listener from window
      if (storageEventListener) {
        window.removeEventListener('storage', storageEventListener);
      }
    };
  }, [history, customer, filters]);

  const getLabelType = (widget: WidgetComponent): string | undefined => {
    return widget.name === WIDGET_RATE_CARD
      ? widget.config.label_type
      : undefined;
  };

  const onModalOpen = (type: 'my' | 'new' | 'suggestions') => {
    let widgets: Widget[] = [];

    switch (type) {
      case 'my':
        widgets = nonFavWidgets;
        break;
      case 'new':
        widgets = newWidgets;
        break;
      case 'suggestions':
      default:
        widgets = suggestedWidgets;
    }

    setModalWidgets(
      widgets.map((w: Widget) => ({
        id: w.id,
        title: w.title,
        name: w.component.name,
        label_type: getLabelType(w.component)
      }))
    );

    setIsModalOpen(true);
  };

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const onAddWidgets = (widgetsId: number[]) => {
    addFavourites(widgetsId, hasSettingsPermission);

    const widgetsToAdd: Widget[] = nonFavWidgets.filter(
      (w: Widget) => widgetsId.indexOf(w.id) !== -1
    );

    setUserWidgets((userWidgets: Widget[]) => [
      ...userWidgets,
      ...widgetsToAdd
    ]);

    setSuggestedWidgets((widgets) =>
      widgets.filter((w: Widget) => widgetsId.indexOf(w.id) === -1)
    );

    setNewWidgets((widgets) =>
      widgets.filter((w: Widget) => widgetsId.indexOf(w.id) === -1)
    );

    setNonFavWidgets((widgets) =>
      widgets.filter((w: Widget) => widgetsId.indexOf(w.id) === -1)
    );

    onModalClose();
  };

  const onRemove = (id: number) => {
    removeFavourite(id, hasSettingsPermission);
    const widget = userWidgets.find((w: Widget) => w.id === id) as Widget;
    setUserWidgets((widgets) => widgets.filter((w: Widget) => w.id !== id));
    setNonFavWidgets((widgets) => insertWidgetInOrder(widgets, widget));

    if (defaultNewWidgetsRef.current) {
      setNewWidgets(
        filterRemovedWidgets(defaultNewWidgetsRef.current, newWidgets, id)
      );
    }

    if (defaultSuggestedWidgetsRef.current) {
      setSuggestedWidgets(
        filterRemovedWidgets(
          defaultSuggestedWidgetsRef.current,
          suggestedWidgets,
          id
        )
      );
    }
  };

  const onReorder = (ids: number[]) => {
    updateFavourites(ids, hasSettingsPermission);
  };

  const onFiltersSubmit = (newFilters: AnalyticsFiltersForm) => {
    if (newFilters.period !== filters.period) {
      setPeriod(undefined);
    }
    setFilters(newFilters);
    setIsFiltersOpen(false);
  };

  const onFilterDelete = (key: string) => {
    setFilters((filters) => ({
      ...filters,
      [key]: undefined
    }));
  };

  return (
    <div className={styles.analyticsWidgets}>
      <AddWidgetModal
        title={modalTitle}
        isOpen={isModalOpen}
        widgets={modalWidgets}
        onClose={onModalClose}
        onAddWidgets={onAddWidgets}
      />

      <FilterByModal
        isOpen={isFiltersOpen}
        filters={filters}
        onSubmit={onFiltersSubmit}
        onClose={() => setIsFiltersOpen(false)}
      />

      {isNotAnalyticsReady && <NoAnalyticsReady />}

      {!isNotAnalyticsReady && (
        <SFScrollable containerClassName={styles.scrollable}>
          <div>
            <MyWidgetHeader
              disabled={isLoading}
              onAddWidgets={() => {
                onModalOpen('my');
                setModalTitle('Add widgets');
              }}
            />

            <WidgetsFilter
              period={period}
              filters={filters}
              isLoading={isLoading}
              onFilterBy={() => setIsFiltersOpen(true)}
              onFilterDelete={onFilterDelete}
            />

            <Divider className={styles.divider} />
          </div>

          {isLoading && <AnalyticsWidgetsLoader done={isDoneFetching} />}

          {!isLoading && period && (
            <Fragment>
              <MyWidgets
                draggable={!filters.officer}
                widgets={userWidgets}
                period={period}
                onRemove={onRemove}
                onReorder={onReorder}
              />

              {suggestedWidgets.length > 0 && (
                <Fragment>
                  <Divider />

                  <WidgetsSection
                    title="Suggestions"
                    widgets={suggestedWidgets}
                    period={period}
                    onSeeAll={() => {
                      onModalOpen('suggestions');
                      setModalTitle('Suggestions');
                    }}
                    onAdd={(id: number) => onAddWidgets([id])}
                  />
                </Fragment>
              )}

              {newWidgets.length > 0 && (
                <Fragment>
                  <Divider />

                  <WidgetsSection
                    title="New widgets"
                    widgets={newWidgets}
                    period={period}
                    onSeeAll={() => {
                      onModalOpen('new');
                      setModalTitle('Add widgets');
                    }}
                    onAdd={(id: number) => onAddWidgets([id])}
                  />
                </Fragment>
              )}
            </Fragment>
          )}
        </SFScrollable>
      )}
    </div>
  );
};
