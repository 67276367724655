export const EMAIL_INVALID_MSG = (
  <span>
    Please enter an email in the correct format and domain.
    <br />
    For example: yourname@youragency.com
  </span>
);

export const INCIDENT_DATE_YEAR_ERROR_MSG = 'Enter a valid date from 2022.';

export const INCIDENT_NUMBER_HELP_MSG =
  'It must have at least 1 and up to 20 characters. Only letters, numbers, and hyphens are allowed.';

export const ORI_INVALID_MSG =
  'It must have 9 characters. Only uppercase letters and numbers are allowed.';
export const ORI_TAKEN_MSG = 'This ORI number is already taken.';

export const DATE_FUTURE_MSG = 'The date cannot be in the future.';
export const DATE_ERROR_MSG = 'Please enter a valid date.';

export const TIME_FUTURE_MSG = 'The time cannot be in the future.';
export const TIME_ERROR_MSG = 'Please enter a valid time. E.g.: 16:30';
export const TIME_HELP_MSG =
  'Enter a time in 24-hour notation format. E.g.: 16:30';

export const DURATION_HELP_MSG = 'Minimum 1, maximum 1440.';

export const CITIZENS_MIN_MSG = 'It takes at least one citizen to continue.';
export const CITIZENS_UOF_MIN_MSG =
  'It takes at least one citizen to whom force has been used.';

export const OFFICERS_NEEDS_CITIZEN_MSG =
  'It takes at least one citizen to add officers.';
export const OFFICERS_NEEDS_UOF_CITIZEN_MSG =
  'The officers must be linked to the citizens to whom force has been used.';

export const BYSTANDER_NEEDS_UOF_CITIZEN =
  'It takes at least one citizen to whom force has been used to add others with “Reason for Contact: Bystander”';

export const BYSTANDER_INVALID =
  'It takes at least one Bystander to whom force has been used and who has been injured.';

export const BYSTANDER_NO_ACTION =
  'Bystanders must have “Action Taken: No Action”.';
