import React, { Fragment, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContainer } from '../../Components/AuthContainer/AuthContainer';
import Loader from '../../Components/Loader/Loader';
import { OnlineStatusContext } from '../../Context/OnlineStatusContext';
import { ForgotPasswordInstructions } from './ForgotPasswordInstructions/ForgotPasswordInstructions';
import { ForgotPasswordSent } from './ForgotPasswordSent/ForgotPasswordSent';
import { forgotPassword } from 'ui-smartforce-settings';

export interface ForgotPasswordViewProps {}

export const ForgotPasswordView = (
  props: ForgotPasswordViewProps
): React.ReactElement<ForgotPasswordViewProps> => {
  const [step, setStep] = React.useState<number>(1);
  const [email, setEmail] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const history = useHistory();
  const { isOnline } = useContext(OnlineStatusContext);

  const onSend = async () => {
    setIsLoading(true);
    try {
      await forgotPassword(
        process.env.REACT_APP_SETTINGS_API_BASE_URL as string,
        email
      );
      setIsLoading(false);
      setStep(2);
    } catch (e) {
      console.error('ForgotPassword::SendEmail', e);
      setIsLoading(false);
      history.push('/error');
    }
  };

  React.useEffect(() => {
    if (!isOnline) {
      history.replace('/no-connection');
    }
  }, [isOnline, history]);

  return (
    <AuthContainer>
      <div>
        {isLoading && <Loader />}
        {!isLoading && (
          <Fragment>
            {step === 1 && (
              <ForgotPasswordInstructions
                value={email}
                onChange={(newEmail: string) => setEmail(newEmail)}
                onSend={onSend}
              />
            )}
            {step === 2 && <ForgotPasswordSent email={email} />}
          </Fragment>
        )}
      </div>
    </AuthContainer>
  );
};
