import React, { Fragment, useContext, useState } from 'react';
import styles from './MainAd.module.scss';
import { SFButton, SFFade, SFPaper, SFText } from 'sfui';
import {
  UserContext,
  isPlanAnalytics,
  isRoleManager,
  isRoleOwner,
  useSubscription
} from 'ui-smartforce-settings';
import { useHistory } from 'react-router-dom';

function isOwnerOrManager(roleId?: string) {
  return isRoleOwner(roleId) || isRoleManager(roleId);
}

export const MainAd = (): React.ReactElement<{}> => {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const subscription = useSubscription('cc');

  const onUpgrade = () => {
    setIsOpen(false);

    if (isRoleOwner(user?.role.id)) {
      history.push('/cc/plan-upgrade');
    } else if (isRoleManager(user?.role.id)) {
      history.push('/cc/analytics');
    }
  };

  return (
    <Fragment>
      {history.location.pathname !== '/cc/onboarding' &&
        isOwnerOrManager(user?.role.id) &&
        !isPlanAnalytics(subscription?.plan) && (
          <SFFade in={isOpen} mountOnEnter unmountOnExit>
            <SFPaper className={styles.mainAd}>
              <div className={styles.image}></div>

              <div className={styles.description}>
                <SFText type="component-title">
                  Comprehensive, Insightful, Actionable Data
                </SFText>

                <div className={styles.buttons}>
                  <SFButton
                    sfColor="grey"
                    variant="text"
                    onClick={() => setIsOpen(false)}
                  >
                    Close
                  </SFButton>

                  <SFButton onClick={onUpgrade}>Upgrade Now</SFButton>
                </div>
              </div>
            </SFPaper>
          </SFFade>
        )}
    </Fragment>
  );
};
