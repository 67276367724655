import React from 'react';
import styles from './MultipleReportsModalSelectOptionItem.module.scss';
import { SFIcon } from 'sfui';

export interface MultipleReportsModalSelectOptionItemProps {
  title: string;
  subTitle: string;
}

export const MultipleReportsModalSelectOptionItem = ({
  title,
  subTitle
}: MultipleReportsModalSelectOptionItemProps): React.ReactElement<MultipleReportsModalSelectOptionItemProps> => {
  return (
    <div>
      <div className={styles.multipleReportsModalSelectOptionItem}>
        <SFIcon icon="Blank-File" size={22} />
        <div className={styles.content}>
          <p className={styles.title}>{title}</p>
          <p className={styles.subTitle}>{subTitle}</p>
        </div>
      </div>
    </div>
  );
};
