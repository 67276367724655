import React from 'react';
import { Transition } from 'react-transition-group';

import {
  TransitionProps as _TransitionProps,
  TransitionActions
} from 'react-transition-group/Transition';

import {
  SFBackgroundDark,
  SFBackgroundLight,
  SFTextBlack,
  SFTextWhite,
  SFThemeType,
  useSFMediaQuery
} from 'sfui';

import { LARGE_SCREEN } from '../../Constants/Media';

import { ThemeTypeContext } from 'ui-smartforce-settings';

interface TransitionProps
  extends TransitionActions,
    Partial<Pick<_TransitionProps, 'in' | 'onEnter' | 'onExited'>> {}

const TRANSITION_TIMEOUT = 480;

const defaultStyle: React.CSSProperties = {
  height: '100%',
  position: 'absolute',
  zIndex: 2,
  transition: `width ${TRANSITION_TIMEOUT}ms ease-in-out`,
  width: '100%'
};

const transitionStyles: { [index: string]: React.CSSProperties } = {
  entered: { width: 0 },
  exited: { width: '100%' }
};

const getBgColor = (themeType: SFThemeType, isBigScreen: boolean) => {
  if (isBigScreen) {
    return themeType === 'day' ? SFBackgroundLight : SFBackgroundDark;
  } else {
    return themeType === 'day' ? SFTextWhite : SFTextBlack;
  }
};

export const TooltipTransition: React.FunctionComponent<
  TransitionProps & { children?: React.ReactElement<any, any> | undefined }
> = (props) => {
  const { themeType } = React.useContext(ThemeTypeContext);
  const isBigScreen: boolean = useSFMediaQuery(LARGE_SCREEN);

  return (
    <Transition
      appear
      in={props.in}
      onEnter={props.onEnter}
      onExited={props.onExited}
      timeout={{
        appear: 0,
        enter: TRANSITION_TIMEOUT,
        exit: TRANSITION_TIMEOUT
      }}
    >
      {(state) => (
        <div>
          <div
            style={{
              backgroundColor: getBgColor(themeType, isBigScreen),
              ...defaultStyle,
              ...transitionStyles[state as string]
            }}
          ></div>
          {props.children}
        </div>
      )}
    </Transition>
  );
};
