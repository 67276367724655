import React from 'react';
import styles from './PricingView.module.scss';
import { SFLink, SFPaper, SFScrollable } from 'sfui';
import { Footer } from 'footer-component';
import {
  ThemeTypeContext,
  ANNUALLY_FEE_ANALYTICS
} from 'ui-smartforce-settings';
import {
  PricingInformation,
  PricingInformationProps
} from './PricingInformation/PricingInformation';
import { LandingTopBar } from '../../Components/LandingTopBar/LandingTopBar';

const FOOTER_URL: string = `${process.env.REACT_APP_FOOTER_URL}`;

const PRICING_INFORMATION: PricingInformationProps[] = [
  {
    plan: 'Connect',
    price: {
      cost: 'Free',
      basis: 'for all agencies',
      description: ''
    },
    color: 'blue',
    features: ['Business Card', 'Support Page', 'Roles', 'Areas', 'Groups']
  },
  {
    plan: 'Analytics',
    color: 'purple',
    price: {
      cost: `$${ANNUALLY_FEE_ANALYTICS}`,
      basis: 'x member x year'
    },
    featuresTitle: 'Everything in Connect, plus...',
    features: [
      'Create Contacts',
      'Contacts History',
      'Amend Contacts',
      'Offline Reporting',
      'Amend Officers Contacts',
      'Add Investigations and Complaints',
      'Monthly and Weekly reports',
      '+45 different widgets',
      'Comparison of rates between periods',
      'Mapping (Heatmaps, Patterns, Clusters and Filtered by categories)',
      'Demographics',
      'FastFacts',
      'Advanced Filtering'
    ]
  }
];

export const PricingView = (): React.ReactElement<{}> => {
  const { themeType } = React.useContext(ThemeTypeContext);

  return (
    <SFPaper className={styles.pricingView}>
      <LandingTopBar />

      <SFScrollable>
        <div>
          <main className={styles.content}>
            <div className={styles.textBlock}>
              <h1 className={styles.subTitle}>Pricing and Upgrades</h1>
            </div>

            <div className={styles.pricing}>
              {PRICING_INFORMATION.map(
                (information: PricingInformationProps) => (
                  <PricingInformation key={information.plan} {...information} />
                )
              )}
            </div>

            <div className={styles.helpBlock}>
              <p className={styles.help}>Do you want CitizenContact?</p>
              <SFLink
                href="/registration"
                className={styles.link}
                color="primary"
                sfSize="medium"
              >
                Start Here
              </SFLink>
            </div>
          </main>

          <Footer theme={themeType} url={FOOTER_URL} />
        </div>
      </SFScrollable>
    </SFPaper>
  );
};
