import React from 'react';
import { SFSwitch } from 'sfui';
import { InputProps } from '../FieldInput';

export const SwitchField = (
  props: InputProps
): React.ReactElement<InputProps> => {
  const checked: boolean = props.value === true;

  const onSwitchChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    props.onChange(checked);
  };

  return (
    <SFSwitch
      label={props.label}
      required={props.required}
      checked={checked}
      disabled={props.disabled}
      onChange={onSwitchChange}
    />
  );
};
