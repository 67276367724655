import React from 'react';
import { SFPeopleOption, SFPeoplePicker } from 'sfui';
import {
  FieldValueType,
  PeoplePickerUserType,
  PeoplePickerValueType
} from '../../../../../Models';
import { InputProps } from '../FieldInput';
import { getUserSession } from 'ui-smartforce-settings';

const mapToPeoplePickerValue = (option: any): PeoplePickerUserType => {
  const mappedOption: PeoplePickerUserType = {
    ...option
  };

  if (option.title) {
    mappedOption.title = option.title;
  }

  if (option.height) {
    mappedOption.height_ft = option.height.ft;
    mappedOption.height_in = option.height.in;
  }

  return mappedOption;
};

const getInitValue = (value: FieldValueType): PeoplePickerValueType => {
  return value
    ? typeof value === 'string'
      ? { name: value }
      : (value as PeoplePickerValueType)
    : { name: '' };
};

export const PeoplePickerField = ({
  activeOnly = true,
  ...props
}: InputProps): React.ReactElement<InputProps> => {
  const value: PeoplePickerValueType = getInitValue(props.value);

  const onPeopleChange = (value: SFPeopleOption) => {
    props.onChange(value);
  };

  const formatUrlQuery = (value: string): string => {
    return `${
      process.env.REACT_APP_SETTINGS_API_BASE_URL
    }/agencies/me/users?active_only=${
      activeOnly ? 'True' : 'False'
    }&name=${value}`;
  };

  const formatOption = (option: any): SFPeopleOption => {
    return {
      name: option.name as string,
      avatarUrl: option.avatar_thumbnail_url as string,
      asyncObject: mapToPeoplePickerValue(option) as PeoplePickerUserType
    };
  };

  const fetchInit: RequestInit = {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `bearer ${getUserSession().access_token}`
    })
  };

  return (
    <SFPeoplePicker
      label={props.label}
      isAsync
      multiple={false}
      formatUrlQuery={formatUrlQuery}
      formatOption={formatOption}
      fetchInit={fetchInit}
      value={value}
      required={props.required}
      onChange={onPeopleChange}
    />
  );
};
