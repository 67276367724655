import React, { Fragment } from 'react';
import styles from './SelectWidgetItemImage.module.scss';
import {
  DMClusterMapImageLM,
  DMClusterMapImageS,
  DMDoughnutImage,
  DMFilterMapImageL,
  DMFilterMapImageM,
  DMFilterMapImageS,
  DMGradientLineImage,
  DMHeatMapImageLM,
  DMHeatMapImageS,
  DMPatternMapImageL,
  DMPatternMapImageM,
  DMPatternMapImageS,
  DMRankingCardImage,
  DMRateCardNumberImage,
  DMRateCardStringImage,
  DMSankeyImage,
  NMClusterMapImageLM,
  NMClusterMapImageS,
  NMDoughnutImage,
  NMFilterMapImageL,
  NMFilterMapImageM,
  NMFilterMapImageS,
  NMGradientLineImage,
  NMHeatMapImageLM,
  NMHeatMapImageS,
  NMPatternMapImageL,
  NMPatternMapImageM,
  NMPatternMapImageS,
  NMRankingCardImage,
  NMRateCardNumberImage,
  NMRateCardStringImage,
  NMSankeyImage
} from '../../../../../../../Images/Analytics/images';
import { MediaContext } from 'ui-smartforce-settings';
import {
  WIDGET_CLUSTER_MAP,
  WIDGET_DOUGHNUT,
  WIDGET_FILTER_MAP,
  WIDGET_GRADIENT_LINE,
  WIDGET_HEAT_MAP,
  WIDGET_PATTERN_MAP,
  WIDGET_RANKING_CARD,
  WIDGET_RATE_CARD,
  WIDGET_SANKEY
} from '../../../../../../../Constants/Analytics';
import { ScreenSizeType } from '../../AddWidgetModal';

export interface SelectWidgetItemImageProps {
  isDayMode: boolean;
  isOverflow: boolean;
  screenSize: ScreenSizeType;
  widgetName: string;
  widgetType?: string;
}

const getComponentImage = (
  name: string,
  isDayMode: boolean,
  screenSize: ScreenSizeType,
  type?: string
): React.ReactElement => {
  switch (name) {
    case WIDGET_CLUSTER_MAP:
      if (screenSize === 'small') {
        return isDayMode ? <DMClusterMapImageS /> : <NMClusterMapImageS />;
      }

      return isDayMode ? <DMClusterMapImageLM /> : <NMClusterMapImageLM />;

    case WIDGET_HEAT_MAP:
      if (screenSize === 'small') {
        return isDayMode ? <DMHeatMapImageS /> : <NMHeatMapImageS />;
      }

      return isDayMode ? <DMHeatMapImageLM /> : <NMHeatMapImageLM />;

    case WIDGET_FILTER_MAP:
      if (screenSize === 'large') {
        return isDayMode ? <DMFilterMapImageL /> : <NMFilterMapImageL />;
      }

      if (screenSize === 'medium') {
        return isDayMode ? <DMFilterMapImageM /> : <NMFilterMapImageM />;
      }

      return isDayMode ? <DMFilterMapImageS /> : <NMFilterMapImageS />;

    case WIDGET_PATTERN_MAP:
      if (screenSize === 'large') {
        return isDayMode ? <DMPatternMapImageL /> : <NMPatternMapImageL />;
      }

      if (screenSize === 'medium') {
        return isDayMode ? <DMPatternMapImageM /> : <NMPatternMapImageM />;
      }

      return isDayMode ? <DMPatternMapImageS /> : <NMPatternMapImageS />;

    case WIDGET_DOUGHNUT:
      return isDayMode ? <DMDoughnutImage /> : <NMDoughnutImage />;

    case WIDGET_SANKEY:
      return isDayMode ? <DMSankeyImage /> : <NMSankeyImage />;

    case WIDGET_GRADIENT_LINE:
      return isDayMode ? <DMGradientLineImage /> : <NMGradientLineImage />;

    case WIDGET_RANKING_CARD:
      return isDayMode ? <DMRankingCardImage /> : <NMRankingCardImage />;

    case WIDGET_RATE_CARD:
      if (type === 'number') {
        return isDayMode ? (
          <DMRateCardNumberImage />
        ) : (
          <NMRateCardNumberImage />
        );
      } else {
        return isDayMode ? (
          <DMRateCardStringImage />
        ) : (
          <NMRateCardStringImage />
        );
      }
    default:
      return <Fragment />;
  }
};

export const SelectWidgetItemImage = ({
  isDayMode,
  isOverflow,
  screenSize,
  widgetName,
  widgetType
}: SelectWidgetItemImageProps): React.ReactElement<SelectWidgetItemImageProps> => {
  const { isPhone } = React.useContext(MediaContext);
  const isRateCard: boolean = widgetName === WIDGET_RATE_CARD;

  return (
    <Fragment>
      <div
        className={`${styles.selectWidgetItemImage} ${
          isPhone && isOverflow ? styles.overflow : ''
        } ${isRateCard ? styles.rateCard : ''}`}
      >
        {getComponentImage(widgetName, isDayMode, screenSize, widgetType)}
      </div>
    </Fragment>
  );
};
