import React, { useRef } from 'react';
import { CheckboxField } from './CheckboxField/CheckboxField';
import { DateField } from './DateField/DateField';
import { NumberField } from './NumberField/NumberField';
import { RadioField } from './RadioField/RadioField';
import { SwitchField } from './SwitchField/SwitchField';
import { TextField } from './TextField/TextField';
import { TextArea } from './TextArea/TextArea';
import { TimeField } from './TimeField/TimeField';
import { Field } from '../../../../Models';
import { FieldValueType } from '../../../../Models';
import { AutocompleteField } from './AutocompleteField/AutocompleteField';
import { MultiSelectField } from './MultiSelectField/MultiSelectField';
import { AutocompleteChipField } from './AutocompleteChipField/AutocompleteChipField';
import { PeoplePickerField } from './PeoplePickerField/PeoplePickerField';
import { ItemListField } from './ItemListField/ItemListField';
import { CitizenChecklistField } from './CitizenChecklistField/CitizenChecklistField';
import { getFieldInvalidMsg } from '../../../../Helpers';
import { GroupsField } from './GroupsField/GroupsField';
import { DateRangeField } from './DateRangeField/DateRangeField';
import { AreasField } from './AreasField/AreasField';
import { NumberRangeField } from './NumberRangeField/NumberRangeField';
import { isFieldEmpty } from 'ui-smartforce-settings';
import { TitleField } from './TitleField/TitleField';

export interface InputProps {
  activeOnly?: boolean;
  disabled?: boolean;
  disabledMessage?: string;
  error?: boolean;
  helperText?: string;
  item_model?: Field[];
  item_name?: string;
  label: string;
  max_items?: number;
  minDate?: Date;
  maxDate?: Date;
  maxLength?: number;
  onChange: (value: FieldValueType) => void;
  options?: string[];
  required?: boolean;
  single_choice?: string;
  value: FieldValueType;
}

interface FieldComponentDictType {
  [key: string]: (props: InputProps) => React.ReactElement<InputProps>;
}

const FieldComponentDict: FieldComponentDictType = {
  areasfield: AreasField,
  autocomplete: AutocompleteField,
  autocompletechip: AutocompleteChipField,
  checkbox: CheckboxField,
  date: DateField,
  daterange: DateRangeField,
  multiselect: MultiSelectField,
  number: NumberField,
  numberrange: NumberRangeField,
  peoplepicker: PeoplePickerField,
  radio: RadioField,
  switch: SwitchField,
  textfield: TextField,
  textarea: TextArea,
  time: TimeField,
  itemlist: ItemListField,
  citizen_checklist: CitizenChecklistField,
  groupsfield: GroupsField,
  titlefield: TitleField
};

export interface FieldInputProps {
  activeOnly?: boolean;
  disabled?: boolean;
  disabledMessage?: string;
  field: Field;
  value: FieldValueType;
  onChange: (value: FieldValueType) => void;
}

export const FieldInput = ({
  activeOnly,
  disabled = false,
  disabledMessage,
  field,
  value,
  onChange
}: FieldInputProps): React.ReactElement<FieldInputProps> => {
  const FieldComponent = FieldComponentDict[field.component];
  const refPristine = useRef<boolean>(isFieldEmpty(value));

  const onFieldChange = (value: FieldValueType) => {
    if (refPristine.current) {
      refPristine.current = false;
    }
    onChange(value);
  };

  const fieldInvalidMsg = getFieldInvalidMsg(value, field);
  const error: boolean = !refPristine.current && fieldInvalidMsg.length > 0;

  return (
    <FieldComponent
      activeOnly={activeOnly}
      disabled={disabled}
      disabledMessage={disabledMessage}
      error={error}
      helperText={error ? fieldInvalidMsg : field.hint}
      label={field.label}
      required={field.required}
      options={field.options}
      single_choice={field.single_choice}
      item_model={field.item_model}
      item_name={field.item_name}
      max_items={field.max_items}
      value={value}
      onChange={onFieldChange}
    />
  );
};
