import React, { Fragment } from 'react';
import jwt_decode from 'jwt-decode';
import styles from './VerifyView.module.scss';
import { SFButton, SFLink } from 'sfui';
import { useHistory } from 'react-router-dom';
import Loader from '../../Components/Loader/Loader';
import { useEffect } from 'react';
import { AuthContainer } from '../../Components/AuthContainer/AuthContainer';
import { useQuery } from '../../Hooks';
import { isUserAlreadyVerified, isVerifyTokenExpired } from '../../Helpers';
import { verifyAccountCreated } from 'ui-smartforce-settings';

export const VerifyView = (): React.ReactElement<{}> => {
  const history = useHistory();
  const query = useQuery();

  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const onLogin = () => {
    history.push('/login');
  };

  useEffect(() => {
    const verifyEmail = async (token: string) => {
      try {
        await verifyAccountCreated(
          process.env.REACT_APP_SETTINGS_API_BASE_URL as string,
          token
        );
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        if (isUserAlreadyVerified(e)) {
          console.info(`VerifyAccount::UserAlreadyVerified`, e);
        } else if (isVerifyTokenExpired(e)) {
          console.error('VerifyAccount::VerifyTokenExpired', e);
          history.push('/verify-token-expired', { data: jwt_decode(token) });
        } else {
          console.error('VerifyAccount::GeneralError');
          history.push('/error');
        }
      }
    };

    if (isLoading) {
      verifyEmail(query.get('token') as string);
    }
  }, [query, isLoading, history]);

  return (
    <AuthContainer>
      <div className={styles.verifyView}>
        {isLoading && <Loader />}
        {!isLoading && (
          <Fragment>
            <h2 className={styles.title}>Verified account!</h2>
            <p className={styles.text}>
              Your account has been verified successfully.
            </p>
            <p className={styles.text}>
              Now, you can start using CitizenContact.
            </p>
            <SFButton size="large" fullWidth onClick={onLogin}>
              Done!
            </SFButton>
            <p className={styles.textSmall}>
              If you have any questions, please{' '}
              <SFLink
                sfSize="small"
                color="primary"
                href={`${process.env.REACT_APP_CC_SUPPORT_URL}`}
                target="_blank"
              >
                Contact Us.
              </SFLink>
            </p>
          </Fragment>
        )}
      </div>
    </AuthContainer>
  );
};
