import React from 'react';
import { SFIconButton } from 'sfui';

export interface OnlinePendingButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const OnlinePendingButton = ({
  onClick
}: OnlinePendingButtonProps): React.ReactElement<OnlinePendingButtonProps> => {
  return (
    <SFIconButton
      sfIcon="Cloud-Upload"
      iconSize={20}
      buttonSize={34}
      onClick={onClick}
      aria-label="Online with Pending Reports"
    />
  );
};
