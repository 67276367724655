import React from 'react';
import styles from './UseOfForceInformation.module.scss';
import {
  ConfigBase,
  Field,
  FieldValueType,
  UseOfForceValueType
} from '../../../../../Models';
import { FieldItem } from '../FieldItem/FieldItem';
import { getFieldType } from '../Helpers';

export interface UseOfForceInformationProps {
  useOfForce: UseOfForceValueType;
  config: ConfigBase;
}

export const UseOfForceInformation = ({
  useOfForce,
  config
}: UseOfForceInformationProps): React.ReactElement<UseOfForceInformationProps> => {
  const useOfForceFields: Field[] = config.sections[0].fields;
  return (
    <div className={styles.useOfForceInformation}>
      <h3 className={styles.title}>{config.title}</h3>

      {useOfForceFields.map((field): JSX.Element | undefined => {
        if (useOfForce[field.name]) {
          return (
            <FieldItem
              key={field.name}
              label={field.label}
              value={useOfForce[field.name] as FieldValueType}
              type={getFieldType(field)}
            />
          );
        }

        return undefined;
      })}
    </div>
  );
};
