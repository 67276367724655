import React from 'react';
import { SFTextField } from 'sfui';
import { InputProps } from '../FieldInput';

export const TextField = (
  props: InputProps
): React.ReactElement<InputProps> => {
  const value: string = (props.value as string) || '';

  const onTextChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    props.onChange(
      event.target.value.length > 0 ? event.target.value : undefined
    );

  return (
    <SFTextField
      label={props.label}
      value={value}
      disabled={props.disabled}
      required={props.required}
      error={props.error}
      helperText={props.helperText}
      inputProps={{ maxLength: props.maxLength }}
      onChange={onTextChange}
    />
  );
};
