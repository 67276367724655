import { dispatchCustomEvent } from 'ui-smartforce-settings';
import { MAIN_ALERT_MESSAGE } from '../Constants/Events';

export interface MainAlertEventData {
  message: React.ReactNode;
  autoHideDuration?: number;
  actionButtonText?: string;
}

export function dispatchMainAlertEvent(data: MainAlertEventData): void {
  dispatchCustomEvent(MAIN_ALERT_MESSAGE, data);
}

export function getMainAlertEventData(e: Event): MainAlertEventData {
  return (e as CustomEvent).detail;
}
