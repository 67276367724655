import React from 'react';
import { SFIconButton } from 'sfui';

export interface OnlineDoneButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const OnlineDoneButton = ({
  onClick
}: OnlineDoneButtonProps): React.ReactElement<OnlineDoneButtonProps> => {
  return (
    <SFIconButton
      sfIcon={'Done-Cloud'}
      iconSize={20}
      buttonSize={34}
      onClick={onClick}
      aria-label="Online"
    />
  );
};
