import React from 'react';
import styles from './DottedArrowIcon.module.scss';

export interface DottedArrowIconProps {
  className?: string;
}

export const DottedArrowIcon = ({
  className = ''
}: DottedArrowIconProps): React.ReactElement<DottedArrowIconProps> => {
  return (
    <div className={`${styles.dottedArrowIcon} ${className}`}>
      <div className={styles.dot}></div>
      <div className={`${styles.dot} ${styles.right}`}></div>
      <div className={styles.dot}></div>
    </div>
  );
};
