import React, { useContext } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { AGENCY_REPORTS_READ } from '../Constants/RolesAndPermissions';
import {
  Customer,
  isRoleWatcher,
  checkPermissions,
  User,
  Subscription,
  UserContext
} from 'ui-smartforce-settings';

export interface RouteOnboardingGuardProps extends RouteProps {
  customer?: Customer;
  url: string;
  user: User;
  subscription?: Subscription;
}

export const RouteOnboardingGuard = ({
  children,
  customer,
  path,
  url,
  subscription,
  user
}: RouteOnboardingGuardProps) => {
  const { isOnboarding } = useContext(UserContext);

  return (
    <Route
      path={path}
      children={() => {
        if (
          !user.agency_id ||
          (customer && customer.status === 'Inactive') ||
          isOnboarding ||
          !subscription
        ) {
          return children;
        } else {
          let redirectPath: string;

          if (isRoleWatcher(user.role.id)) {
            redirectPath = 'analytics';
          } else if (subscription?.plan === 'connect') {
            redirectPath = 'business-card';
          } else {
            const allowAgencyReports = checkPermissions(
              AGENCY_REPORTS_READ,
              user.role.permissions
            );
            redirectPath = allowAgencyReports ? 'agency-reports' : 'report';
          }

          return <Redirect to={{ pathname: `${url}/${redirectPath}` }} />;
        }
      }}
    ></Route>
  );
};
