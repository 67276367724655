import React, { Fragment } from 'react';
import styles from './ChecklistField.module.scss';
import { SFCheckbox } from 'sfui';

export interface ChecklistFieldProps {
  label: string;
  items: number[];
  elemLabel: string;
  value: number[];
  required?: boolean;
  helperText?: string;
  error?: boolean;
  onChange: (value: number[]) => void;
}

export const ChecklistField = (
  props: ChecklistFieldProps
): React.ReactElement<ChecklistFieldProps> => {
  const value: number[] = props.value ? (props.value as number[]) : [];

  const label: string = `${props.label} ${props.required ? '*' : ''}`;

  const onCheckboxChange = (index: number, checked: boolean) => {
    if (checked) {
      props.onChange([...value, index]);
    } else {
      props.onChange([
        ...value.slice(0, value.indexOf(index)),
        ...value.slice(value.indexOf(index) + 1)
      ]);
    }
  };

  return (
    <div className={styles.checklistField}>
      {props.items.length > 0 && (
        <Fragment>
          <label className={styles.fieldLabel}>{label}</label>
          <ul>
            {props.items.map((item: number) => {
              return (
                <li key={`checklist-item-${item}`}>
                  <SFCheckbox
                    label={`${props.elemLabel} ${item + 1}`}
                    checked={value.indexOf(item) !== -1}
                    onChange={(_e, checked) => onCheckboxChange(item, checked)}
                  />
                </li>
              );
            })}
          </ul>

          {props.helperText && (
            <p
              className={`${styles.helperText} ${
                props.error ? styles.error : ''
              }`}
            >
              {props.helperText}
            </p>
          )}
        </Fragment>
      )}
    </div>
  );
};
