import React from 'react';
import get from 'lodash.get';
import set from 'lodash.set';
import unset from 'lodash.unset';
import styles from './AgencyReports.module.scss';
import Loader from '../../../Components/Loader/Loader';
import { ReportList } from '../../../Components/ReportList/ReportList';
import {
  ReportValue,
  ReportListFilters,
  ApiError,
  ReportFiltersConfig,
  FieldValueType,
  Field,
  LocationAreaType,
  AgencyReportsPagination,
  AgencyReportsResponse,
  ReportsTotals
} from '../../../Models';
import {
  ReportListQuerySortBy,
  exportReports,
  getAgencyReports,
  getNextAgencyReports,
  getTotals
} from '../../../Services/ReportService';
import { NoReports } from '../../../Components/NoReports/NoReports';
import { useHistory } from 'react-router-dom';
import { OnlineStatusContext } from '../../../Context/OnlineStatusContext';
import { HttpStatusCode, SFPeopleOption, SFSpinner } from 'sfui';
import { handleError } from '../../../Helpers/errors';
import { cloneObject, isValueEmpty } from '../../../Helpers';
import { AGENCY_REPORTS_AMEND } from '../../../Constants/RolesAndPermissions';
import {
  UserContext,
  checkPermissions,
  isPlanAnalytics,
  UserGroup,
  useSubscription,
  useCloseTour
} from 'ui-smartforce-settings';
import { ReportListHeader } from './ReportListHeader/ReportListHeader';
import { NoReportsMatches } from './NoReportsMatches/NoReportsMatches';
import { ReportsFilterModal } from './ReportsFilterModal/ReportsFilterModal';
import { DownloadReportsModal } from '../../../Components/DownloadReportsModal/DownloadReportsModal';
import { NoReportsToDownloadModal } from './NoReportsToDownloadModal/NoReportsToDownloadModal';
import { UPDATE_REPORT_LIST } from '../../../Constants/Events';
import { useDispatchMainAlert } from '../../../Hooks';
import { TinyWidgets } from './TinyWidgets/TinyWidgets';
import { StateConfigContext } from '../../../Context/StateConfigContext';
import { IntersectionObserverHost } from '../../../IntersectionObserver';

function getPreparedFieldValue(field: Field, value: FieldValueType): any {
  if (field.component === 'areasfield') {
    return (value as LocationAreaType[]).map(
      (area: LocationAreaType) => area.id
    );
  } else if (field.component === 'peoplepicker') {
    return (value as SFPeopleOption).asyncObject.id;
  } else if (field.component === 'groupsfield') {
    return (value as UserGroup[]).map((group: UserGroup) => group.id);
  } else if (field.type === 'boolean') {
    if (value === 'Yes') return true;
    else if (value === 'No') return false;
    else return undefined;
  } else {
    if (value === 'All') return undefined;
    else return value;
  }
}
function getPreparedFilters(
  config: ReportFiltersConfig,
  filters: ReportListFilters
): ReportListFilters {
  const result = cloneObject(filters);

  for (let categoryName in config) {
    const category = config[categoryName];
    for (let section of category.sections) {
      for (let subsection of section.sub_sections) {
        for (let field of subsection.fields) {
          let path: string = field.name;

          if (subsection.name) {
            path = `${subsection.name}.${path}`;
          }

          if (section.name) {
            path = `${section.name}.${path}`;
          }

          const fieldValue = get(result[categoryName], path);
          if (fieldValue) {
            const value = getPreparedFieldValue(field, fieldValue);
            if (value !== undefined) {
              set(result[categoryName], path, value);
            } else {
              unset(result[categoryName], path);
            }
          }
        }
      }
    }
  }

  return result;
}

export const AgencyReports = (): React.ReactElement<{}> => {
  const history = useHistory();
  const { user } = React.useContext(UserContext);
  const { isOnline } = React.useContext(OnlineStatusContext);
  const subscription = useSubscription('cc');
  const filtersConfig = React.useContext(StateConfigContext).stateConfig
    ?.filters as ReportFiltersConfig;

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isFetchingReports, setIsFetchingReports] =
    React.useState<boolean>(false);

  const [isFetchingMoreReports, setIsFetchingMoreReports] =
    React.useState<boolean>(true);
  const [reports, setReports] = React.useState<ReportValue[]>([]);
  const [totals, setTotals] = React.useState<ReportsTotals>();

  const [isReportsFilterModalOpen, setIsReportsFilterModalOpen] =
    React.useState<boolean>(false);
  const [filters, setFilters] = React.useState<ReportListFilters>({});
  const [totalReports, setTotalReports] = React.useState<number>(0);
  const [isDownloadModalOpen, setIsDownloadModalOpen] =
    React.useState<boolean>(false);
  const [isNoReportsToDownloadModalOpen, setIsNoReportsToDownloadModalOpen] =
    React.useState<boolean>(false);
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);

  const refNext = React.useRef<AgencyReportsPagination | undefined>();
  const refSortBy = React.useRef<ReportListQuerySortBy>('newest');
  const refLastFilters = React.useRef<ReportListFilters>({});

  const hasAnalytics: boolean = isPlanAnalytics(subscription?.plan);

  useDispatchMainAlert();

  useCloseTour([{ tourId: 6, step: 1 }, 7, 8]);

  React.useEffect(() => {
    let isSubscribed: boolean = true;

    const getReports = async () => {
      try {
        setIsLoading(true);
        const response: AgencyReportsResponse = await getAgencyReports(
          refLastFilters.current,
          refSortBy.current,
          hasAnalytics
        );

        if (hasAnalytics) {
          try {
            const totals = await getTotals(refLastFilters.current);
            setTotals(totals);
          } catch (e) {
            console.error('AgencyReports::getTotals', e);
            setTotals(undefined);
          }
        }

        if (isSubscribed) {
          refNext.current = response.links.next;
          setReports(response.reports);
          setTotalReports(response.total ?? 0);
          setIsLoading(false);
          setIsFetchingMoreReports(false);
        }
      } catch (e) {
        console.error('AgencyReports::getReports', e);
        handleError(e, history);
      }
    };

    if (isOnline) getReports();

    document.addEventListener(UPDATE_REPORT_LIST, getReports);

    return () => {
      isSubscribed = false;
      document.removeEventListener(UPDATE_REPORT_LIST, getReports);
    };
  }, [history, isOnline, hasAnalytics]);

  const onScrollBottom = async () => {
    if (refNext.current && !isFetchingMoreReports) {
      setIsFetchingMoreReports(true);

      try {
        const response = await getNextAgencyReports(refNext.current);
        setReports([...reports, ...response.reports]);
        setIsFetchingMoreReports(false);
        refNext.current = response.links.next;
      } catch (e) {
        console.error('AgencyReports::getMoreReports', e);
        handleError(e, history);
      }
    }
  };

  const onUpdateReports = async (
    sortBy: ReportListQuerySortBy,
    filters: ReportListFilters
  ) => {
    setIsFetchingReports(true);
    setTotalReports(0);

    const queryFilters = !isValueEmpty(filters)
      ? getPreparedFilters(filtersConfig, filters)
      : filters;

    const response: AgencyReportsResponse = await getAgencyReports(
      queryFilters,
      sortBy,
      hasAnalytics
    );

    if (hasAnalytics) {
      try {
        const totals = await getTotals(queryFilters);
        setTotals(totals);
      } catch (e) {
        console.error('AgencyReports::getTotals', e);
        setTotals(undefined);
      }
    }

    refNext.current = response.links.next;
    setReports(response.reports);
    setIsFetchingReports(false);
    setTotalReports(response.total ?? 0);
  };

  const onChipFiltersChange = (newFilters: ReportListFilters) => {
    refLastFilters.current = newFilters;
    setFilters(newFilters);
    onUpdateReports(refSortBy.current, newFilters);
  };

  const onOpenFilters = () => {
    setIsReportsFilterModalOpen(true);
  };

  const onSubmitReportsFilterModal = (newFilters: ReportListFilters) => {
    refLastFilters.current = newFilters;
    setFilters(newFilters);
    onUpdateReports(refSortBy.current, newFilters);
    setIsReportsFilterModalOpen(false);
  };

  const onSortReportsChange = (sortBy: ReportListQuerySortBy) => {
    refSortBy.current = sortBy;
    onUpdateReports(sortBy, filters);
  };

  const onDownloadModalClose = () => {
    setIsDownloadModalOpen(false);
    onDownload();
  };

  const onDownload = async () => {
    setIsDownloading(true);
    try {
      await exportReports(
        getPreparedFilters(filtersConfig, filters),
        refSortBy.current,
        hasAnalytics
      );
      setIsDownloading(false);
    } catch (e) {
      const error: ApiError = e as ApiError;
      setIsDownloading(false);
      console.error('AgencyReports::exportReports', error);

      if (error.code === HttpStatusCode.NOT_FOUND) {
        setIsNoReportsToDownloadModalOpen(true);
      }
    }
  };

  const showAmendButton: boolean = checkPermissions(
    AGENCY_REPORTS_AMEND,
    user?.role?.permissions
  );

  const hasReports: boolean = reports.length > 0;
  const hasFilters: boolean = !isValueEmpty(filters);

  const isDownloadDisabled: boolean =
    isFetchingReports || !hasReports || isDownloading;

  return (
    <div className={styles.agencyReports}>
      {isLoading && <Loader />}
      {!isLoading && !isFetchingReports && !hasFilters && !hasReports && (
        <NoReports />
      )}

      {!isLoading && (isFetchingReports || hasFilters || hasReports) && (
        <div className={styles.content}>
          <DownloadReportsModal
            isOpen={isDownloadModalOpen}
            onClose={onDownloadModalClose}
          />
          <NoReportsToDownloadModal
            isOpen={isNoReportsToDownloadModalOpen}
            onClose={() => setIsNoReportsToDownloadModalOpen(false)}
          />

          <ReportsFilterModal
            isOpen={isReportsFilterModalOpen}
            filters={filters}
            onClose={() => setIsReportsFilterModalOpen(false)}
            onSubmit={onSubmitReportsFilterModal}
          />

          <ReportListHeader
            hasAnalytics={hasAnalytics}
            isDownloadDisabled={isDownloadDisabled}
            filters={filters}
            onDownload={() => setIsDownloadModalOpen(true)}
            onFiltersChange={onChipFiltersChange}
            onOpenFilters={onOpenFilters}
            onSortReportsChange={onSortReportsChange}
            reportsCount={hasFilters ? totalReports : 'all'}
          />

          {isFetchingReports && (
            <div className={styles.listSpinner}>
              <SFSpinner size={40} />
            </div>
          )}
          {!isFetchingReports && hasFilters && !hasReports && (
            <NoReportsMatches />
          )}

          {!isFetchingReports && hasReports && (
            <IntersectionObserverHost
              hostClassName={styles.scrollable}
              onScrollBottom={onScrollBottom}
            >
              {hasAnalytics && totals && (
                <TinyWidgets
                  totals={totals}
                  contactTypeFilter={
                    filters.general_information?.type as string
                  }
                />
              )}

              <ReportList
                reportListType="agency"
                reports={reports}
                showAmend={showAmendButton}
              />

              {isFetchingMoreReports && (
                <div className={styles.moreReportsSpinner}>
                  <SFSpinner size={40} />
                </div>
              )}
            </IntersectionObserverHost>
          )}
        </div>
      )}
    </div>
  );
};
