import React from 'react';
import ErrorPage from '../../Components/ErrorPage/ErrorPage';
import { useHistory, useLocation } from 'react-router-dom';
import { requestVerifyAccount } from 'ui-smartforce-settings';

interface VerifyTokenErrorViewLocationState {
  email: string;
}

export const UserNotVerifiedErrorView = (): React.ReactElement<{}> => {
  const history = useHistory();
  const location = useLocation();
  const locationState: VerifyTokenErrorViewLocationState =
    location.state as VerifyTokenErrorViewLocationState;

  const onResendEmail = async () => {
    if (locationState && locationState.email) {
      await requestVerifyAccount(
        process.env.REACT_APP_SETTINGS_API_BASE_URL as string,
        locationState.email
      );
    }
    history.replace('/login');
  };

  return (
    <ErrorPage
      icon="Announcement"
      title="Your account has not been verified yet."
      description="Click the button below to continue the registration process. If you don't see any emails from us within a few minutes, be sure to check your spam folder."
      buttonText="Resend Verification Email"
      onGoHome={onResendEmail}
    />
  );
};
