import React from 'react';
import { CitizensContext } from '../../ContactFormView/ContactFormView';
import { ChecklistField } from '../ChecklistField/ChecklistField';
import { InputProps } from '../FieldInput';
import { ItemValueType } from '../../../../../Models';

export const CitizenChecklistField = (
  props: InputProps
): React.ReactElement<InputProps> => {
  const citizens = React.useContext(CitizensContext);

  let dict: number[] = [];

  citizens.forEach((citizen: ItemValueType, index: number) => {
    if (citizen['incident_information']['use_of_force']) {
      dict = [...dict, index];
    }
  });

  return (
    <ChecklistField
      label={props.label}
      items={dict}
      elemLabel="Citizen"
      value={props.value as number[]}
      required={props.required}
      helperText={props.helperText}
      error={props.error}
      onChange={props.onChange}
    />
  );
};
