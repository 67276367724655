import React from 'react';
import styles from './PlanDescriptionList.module.scss';

export interface PlanDescriptionListProps {
  children: React.ReactNode;
}

export const PlanDescriptionList = ({
  children
}: PlanDescriptionListProps): React.ReactElement<PlanDescriptionListProps> => {
  return <div className={styles.planDescriptionList}>{children}</div>;
};
