import React from 'react';
import styles from './SelectWidgetItem.module.scss';
import { SFCard, SFCheckbox } from 'sfui';
import { getWidgetColSize } from '../../../../../../Helpers';
import { ScreenSizeType, WidgetItemProps } from '../AddWidgetModal';
import { TextOverflowTooltip } from '../../../../../../Components/TextOverflowTooltip/TextOverflowTooltip';
import { SelectWidgetItemImage } from './SelectWidgetItemImage/SelectWidgetItemImage';
import { MediaContext } from 'ui-smartforce-settings';
import {
  WIDGET_GRADIENT_LINE,
  WIDGET_SANKEY
} from '../../../../../../Constants/Analytics';

export interface WidgetModalItemProps {
  isDayMode: boolean;
  screenSize: ScreenSizeType;
  widget: WidgetItemProps;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const isOverflow = (name: string): boolean => {
  if (name === WIDGET_SANKEY || name === WIDGET_GRADIENT_LINE) {
    return true;
  }

  return false;
};

export const SelectWidgetItem = ({
  isDayMode,
  screenSize,
  widget,
  checked,
  onChange
}: WidgetModalItemProps): React.ReactElement<WidgetModalItemProps> => {
  const { isPhone } = React.useContext(MediaContext);
  const isWidgetOverflow: boolean = isOverflow(widget.name);

  return (
    <div
      className={`${styles.selectWidgetItem} ${
        styles[getWidgetColSize(widget.name)]
      }`}
    >
      <SFCard
        containerClassName={`${styles.widgetModalItem} ${
          isPhone && isWidgetOverflow ? styles.isWide : ''
        }`}
        sfElevation={2}
      >
        <div className={styles.header}>
          <TextOverflowTooltip
            PopperProps={{
              style: { zIndex: 1300 }
            }}
            title={widget.title}
          >
            <h3 className={styles.title}>{widget.title}</h3>
          </TextOverflowTooltip>

          <SFCheckbox
            checked={checked}
            onChange={(e, value) => onChange(value)}
          />
        </div>
        <SelectWidgetItemImage
          isDayMode={isDayMode}
          isOverflow={isWidgetOverflow}
          screenSize={screenSize}
          widgetName={widget.name}
          widgetType={widget.label_type}
        />
      </SFCard>
    </div>
  );
};
