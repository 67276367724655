import React from 'react';
import styles from './FilterDateRange.module.scss';
import { SFDatePicker, SFSwitch } from 'sfui';
import moment from 'moment';
import { DateRange } from '../../../../../Models';
import { getMomentDateString } from '../../../../../Helpers';

interface DateRangeError {
  dateFrom: boolean;
  dateTo: boolean;
}

const dateToday: string = moment().endOf('day').toISOString();

const isDateRangeError = (
  dateRange: DateRange,
  minDate?: string
): DateRangeError => {
  const dateFrom: string | undefined = dateRange.dateFrom;
  const dateTo: string | undefined = dateRange.dateTo;

  let dateFromError: boolean = !moment(dateRange.dateFrom).isValid();
  let dateToError: boolean = !moment(dateRange.dateTo).isValid();

  if (dateFrom) {
    if (minDate) {
      if (dateFrom < minDate || dateFrom > dateToday) {
        dateFromError = true;
      }
    } else {
      if (dateFrom > dateToday) {
        dateFromError = true;
      }
    }

    if (dateTo) {
      if (dateTo < dateFrom || dateTo > dateToday) {
        dateToError = true;
      }

      if (moment(dateTo).diff(dateFrom, 'days') > 365) {
        dateToError = true;
        dateFromError = true;
      }
    } else if (!dateTo && !dateRange.exactDate) {
      dateToError = true;
    }
  }

  if (dateTo && !dateFrom) {
    dateFromError = true;
    dateToError = true;
  }

  return { dateFrom: dateFromError, dateTo: dateToError };
};

const EMPTY_FIELD_VALUE: DateRange = {
  dateFrom: undefined,
  dateTo: undefined,
  exactDate: false
};

export interface FilterDateRangeProps {
  minDate?: string;
  onChange: (value: DateRange, hasError: boolean) => void;
  value: DateRange;
}

export const FilterDateRange = (
  props: FilterDateRangeProps
): React.ReactElement<FilterDateRangeProps> => {
  const value: DateRange = (props.value as DateRange) ?? EMPTY_FIELD_VALUE;
  const hasValueErrors = isDateRangeError(value, props.minDate);

  const onDateFromChange = (date: moment.Moment) => {
    const newDateRange = {
      ...value,
      dateFrom: getMomentDateString(date)
    };
    const hasError = isDateRangeError(newDateRange, props.minDate);
    props.onChange(newDateRange, hasError.dateFrom || hasError.dateTo);
  };

  const onDateToChange = (date: moment.Moment) => {
    console.log(date);
    const newDateRange = {
      ...value,
      dateTo: getMomentDateString(date)
    };
    const hasError = isDateRangeError(newDateRange, props.minDate);
    props.onChange(newDateRange, hasError.dateFrom || hasError.dateTo);
  };

  const onExactDateChange = (checked: boolean) => {
    const newDateRange = {
      ...value,
      dateTo: undefined,
      exactDate: checked
    };
    const hasError = isDateRangeError(newDateRange, props.minDate);
    props.onChange(newDateRange, hasError.dateFrom || hasError.dateTo);
  };

  return (
    <div className={styles.filterDateRange}>
      <SFDatePicker
        disableFuture
        label={value.exactDate ? 'On' : 'From'}
        minDate={props.minDate}
        error={hasValueErrors.dateFrom}
        helperText={
          hasValueErrors.dateFrom ? 'Please enter a valid date.' : undefined
        }
        value={value.dateFrom}
        onChange={(date, value) => onDateFromChange(date)}
      />
      {!value.exactDate && (
        <SFDatePicker
          disableFuture
          label="To"
          error={hasValueErrors.dateTo}
          helperText={
            hasValueErrors.dateTo ? 'Please enter a valid date.' : undefined
          }
          value={value.dateTo}
          onChange={(date, value) => onDateToChange(date)}
        />
      )}
      <SFSwitch
        label="Exact Date"
        checked={value.exactDate}
        onChange={(_e, checked: boolean) => onExactDateChange(checked)}
      />
    </div>
  );
};
