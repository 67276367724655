import React from 'react';
import styles from './LandingFeatureList.module.scss';
import { LandingFeatureListItemProps } from './LandingFeatureListItem/LandingFeatureListItem';

export interface LandingFeatureListProps {
  title: string;
  children: React.ReactElement<LandingFeatureListItemProps>[];
}

export const LandingFeatureList = ({
  title,
  children
}: LandingFeatureListProps): React.ReactElement<LandingFeatureListProps> => {
  return (
    <div className={styles.landingFeatureList}>
      <h2 className={styles.title}>{title}</h2>

      <hr />

      <ul className={styles.list}>{children}</ul>
    </div>
  );
};
