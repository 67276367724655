import React from 'react';
import styles from './AddWidgetModal.module.scss';
import { SFButton, SFDialog, SFScrollable } from 'sfui';
import { SelectWidgetItem } from './SelectWidgetItem/SelectWidgetItem';
import { WIDGET_RATE_CARD } from '../../../../../Constants/Analytics';
import { MediaContext, ThemeTypeContext } from 'ui-smartforce-settings';

export type ScreenSizeType = 'small' | 'medium' | 'large';
export interface WidgetItemProps {
  id: number;
  title: string;
  name: string;
  label_type?: string;
}

export interface AddWidgetModalProps {
  widgets: WidgetItemProps[];
  title: string;
  isOpen: boolean;
  onAddWidgets: (widgets: number[]) => void;
  onClose: () => void;
}

export const AddWidgetModal = ({
  widgets,
  title,
  isOpen,
  onAddWidgets,
  onClose
}: AddWidgetModalProps): React.ReactElement<AddWidgetModalProps> => {
  const [selectedWidgets, setSelectedWidgets] = React.useState<number[]>([]);

  const { themeType } = React.useContext(ThemeTypeContext);
  const { isPhone, isExtraLargeScreen } = React.useContext(MediaContext);
  const isDayMode: boolean = themeType === 'day';
  const currentScreenSize: ScreenSizeType = isExtraLargeScreen
    ? 'large'
    : isPhone
    ? 'small'
    : 'medium';

  const onChange = (id: number, checked: boolean) => {
    if (checked) {
      setSelectedWidgets([...selectedWidgets, id]);
    } else {
      const findIndex = selectedWidgets.indexOf(id);
      setSelectedWidgets([
        ...selectedWidgets.slice(0, findIndex),
        ...selectedWidgets.slice(findIndex + 1, selectedWidgets.length)
      ]);
    }
  };

  const smallWidgets = widgets.filter(
    (w: WidgetItemProps) => w.name === WIDGET_RATE_CARD
  );

  const otherWidgets = widgets.filter(
    (w: WidgetItemProps) => w.name !== WIDGET_RATE_CARD
  );

  React.useEffect(() => {
    if (isOpen) {
      setSelectedWidgets([]);
    }
  }, [isOpen]);

  return (
    <SFDialog PaperProps={{ className: styles.addWidgetModal }} open={isOpen}>
      <h2 className={styles.title}>{title}</h2>
      {widgets.length === 0 && (
        <div className={styles.noMoreWidgets}>
          There are no more widgets to add. All possible widgets are already
          included in the "My Widgets" section.
        </div>
      )}
      {widgets.length > 0 && (
        <SFScrollable>
          <div className={styles.widgetContent}>
            {smallWidgets.map((w: WidgetItemProps) => (
              <SelectWidgetItem
                key={w.id}
                isDayMode={isDayMode}
                screenSize={currentScreenSize}
                widget={w}
                checked={selectedWidgets.indexOf(w.id) !== -1}
                onChange={(value: boolean) => onChange(w.id, value)}
              />
            ))}
          </div>
          <div className={styles.widgetContent}>
            {otherWidgets.map((w: WidgetItemProps) => (
              <SelectWidgetItem
                key={w.id}
                isDayMode={isDayMode}
                screenSize={currentScreenSize}
                widget={w}
                checked={selectedWidgets.indexOf(w.id) !== -1}
                onChange={(value: boolean) => onChange(w.id, value)}
              />
            ))}
          </div>
        </SFScrollable>
      )}
      <div className={styles.footerButtons}>
        <SFButton variant="text" sfColor="grey" onClick={onClose}>
          Discard
        </SFButton>
        <SFButton
          variant="contained"
          sfColor="blue"
          disabled={selectedWidgets.length === 0}
          onClick={() => onAddWidgets(selectedWidgets)}
        >
          Add Widgets
        </SFButton>
      </div>
    </SFDialog>
  );
};
