//* Private Functions
function legacyIsMobile() {
  //Must be Ipad OS
  if (
    navigator.userAgent.match(/Mac/) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2
  ) {
    return true;
  } else {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      window.navigator.userAgent
    );
  }
}

//* Public Methods
export const isMobile = (): boolean => {
  const uaData = (window.navigator as any).userAgentData;
  return uaData ? uaData.mobile : legacyIsMobile();
};

export const isSafari = (): boolean => {
  let ua = window.navigator.userAgent;
  if (!window || !window.navigator || !ua) {
    return false;
  }
  return ua.search('Safari') !== -1 && ua.search('Chrome') === -1;
};
