import React from 'react';
import styles from './LandingPresentation.module.scss';
import { useHistory } from 'react-router-dom';
import { SFButton } from 'sfui';
import { ThemeTypeContext } from 'ui-smartforce-settings';
import { LandingConfigMain } from '../../../Models/Landing';
import { getLandingImageUrl } from '../../../Helpers';

export interface LandingPresentationProps {
  config: LandingConfigMain;
}

export const LandingPresentation = ({
  config
}: LandingPresentationProps): React.ReactElement<LandingPresentationProps> => {
  const history = useHistory();
  const { themeType } = React.useContext(ThemeTypeContext);
  const brand_image: string = `${process.env.REACT_APP_IMAGES_BASE_URL}/${config.brand_image}`;

  const onCreate = () => {
    history.push('/registration');
  };

  const onLogin = () => {
    history.push('/login');
  };

  return (
    <div className={styles.landingPresentation}>
      <div className={styles.content}>
        <img
          className={styles.brand}
          src={brand_image}
          alt="Citizen Contact Brand"
        />

        <div className={styles.info}>
          <h1 className={styles.title}>{config.title}</h1>
          <p className={styles.description}>{config.description}</p>
        </div>

        <div className={styles.userButtons}>
          <SFButton
            className={styles.createBtn}
            size="large"
            variant="outlined"
            onClick={onCreate}
          >
            Create an Account
          </SFButton>

          <SFButton size="large" onClick={onLogin}>
            Log in
          </SFButton>
        </div>
      </div>

      <div className={styles.image}>
        <img src={getLandingImageUrl(config.image, themeType)} alt="" />
      </div>
    </div>
  );
};
