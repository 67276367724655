import React, { useContext } from 'react';
import styles from './AnalyticsActivate.module.scss';
import { SFButton } from 'sfui';
import { YouTubeEmbed } from '../../../../Components/YouTubeEmbed/YouTubeEmbed';
import {
  UserContext,
  CustomerContext,
  checkPermissions,
  AGENCY_SUBSCRIPTION_UPDATE
} from 'ui-smartforce-settings';
import { useHistory } from 'react-router-dom';

export const AnalyticsActivate = (): React.ReactElement<{}> => {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const { customer } = useContext(CustomerContext);

  const canUpgrade = checkPermissions(
    AGENCY_SUBSCRIPTION_UPDATE,
    user?.role.permissions
  );

  const description = canUpgrade
    ? 'Access to your statistics, charts, and customized information.'
    : 'Contact the agency owner to activate the plan to access your statistics, charts, and customized information.';

  const btnLabel = canUpgrade ? 'Upgrade Plan' : 'Contact the Agency Owner';

  const onClick = () => {
    if (canUpgrade) {
      history.push('/cc/plan-upgrade');
    } else {
      const anchor = document.createElement('a');
      anchor.href = `mailto:${customer?.owner_email}?subject=CitizenContact Analytics Plan`;
      anchor.click();
    }
  };

  return (
    <div className={styles.analyticsActivate}>
      <div className={styles.video}>
        <YouTubeEmbed
          title="Introducing Analytics"
          src="https://www.youtube.com/embed/f0y24eer6tU"
        />
      </div>

      <h2 className={styles.title}>Activate Analytics</h2>

      <p className={styles.description}>{description}</p>

      <SFButton size="large" onClick={onClick}>
        {btnLabel}
      </SFButton>
    </div>
  );
};
