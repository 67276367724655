import React from 'react';
import styles from './WizardStepContent.module.scss';
import { SFText } from 'sfui';

export interface WizardStepContentProps {
  children: React.ReactNode;
  title: string;
  subtitle: string;
  className?: string;
}

export const WizardStepContent = ({
  children,
  title,
  subtitle,
  className
}: WizardStepContentProps): React.ReactElement<WizardStepContentProps> => {
  return (
    <div className={`${styles.wizardStepContent} ${className ?? ''}`}>
      <div className={styles.header}>
        <SFText type="component-title" className={styles.title}>
          {title}
        </SFText>

        {subtitle && (
          <SFText
            type="component-2"
            sfColor="neutral"
            className={styles.subtitle}
          >
            {subtitle}
          </SFText>
        )}
      </div>

      {children}
    </div>
  );
};
