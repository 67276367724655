import {
  AnalyticsConfig,
  AnalyticsFiltersRequest,
  AnalyticsPeriodType,
  ClusterMapWidget,
  FilterMapDataType,
  FilterMapWidget,
  GradientLineWidget,
  HeatMapWidget,
  IncidentData,
  MapBaseDataType,
  MapWidget,
  PatternMapWidget,
  Widget
} from '../Models';
import {
  WIDGET_CLUSTER_MAP,
  WIDGET_FILTER_MAP,
  WIDGET_HEAT_MAP,
  WIDGET_RATE_CARD
} from '../Constants/Analytics';
import { getUserSession } from 'ui-smartforce-settings';

const LOCAL_STORAGE_KEY = 'Smartforce.CitizenContact.WidgetsFavourites';

// Temporal fix CC-3258
function getTotalContactsWidget(
  from: string,
  to: string,
  widget: Widget
): Widget {
  const component = widget.component as GradientLineWidget;
  const categoriesLength = component.config.categories.length;

  let response = { ...widget };
  let categories: string[] = [];
  let data: number[] = [];
  let secondIndex = -1;

  for (let i = 0; i < categoriesLength; i++) {
    if (secondIndex === -1) {
      categories = [...categories, component.config.categories[i]];
      data = [...data, component.config.data[i]];

      if (
        i + 1 < categoriesLength &&
        +component.config.categories[i + 1] - +component.config.categories[i] >
          1
      ) {
        secondIndex++;
      }
    } else {
      categories.splice(secondIndex, 0, component.config.categories[i]);
      data.splice(secondIndex, 0, component.config.data[i]);
      secondIndex++;
    }
  }

  response.component.config = {
    categories,
    data
  };

  return response;
}

const getWidgetWithData = (
  config: AnalyticsConfig,
  id: number,
  position: number,
  period?: AnalyticsPeriodType
): Widget => {
  const widget: Widget = config.widgets.find(
    (widget: Widget) => widget.id === id
  ) as Widget;

  widget.position = position;

  if (widget.component.name.indexOf('_map') === -1) {
    // Temporal fix CC-3258
    if (
      period === 'weekly' &&
      widget.component.name === 'gradient_line' &&
      id === 8
    ) {
      return getTotalContactsWidget(config.date_from, config.date_to, widget);
    } else {
      return widget;
    }
  } else {
    const mapWidget: MapWidget = widget.component as MapWidget;
    const filter = mapWidget.config.filter;

    let data: IncidentData[] = config.incident_data;

    if (filter) {
      data = data.filter((value: IncidentData) => {
        if (!value[filter.key]) {
          return false;
        }

        const dataValue = value[filter.key];

        // Check if it's the special 'not null' filter
        if (filter.value === 'not null') {
          return dataValue !== null && dataValue !== undefined;
        } else {
          // Check if data is an array: string[]
          if (Array.isArray(dataValue)) {
            return dataValue.includes(filter.value);
          } else {
            return dataValue === filter.value;
          }
        }
      });
    }

    if (widget.component.name === WIDGET_FILTER_MAP) {
      const optionKey: string = widget.component.config.options_key;
      let mapData: FilterMapDataType[] = [];

      for (let incident of data) {
        let values: string[] = [];
        if (Array.isArray(incident[optionKey])) {
          values = incident[optionKey] as string[];
        } else {
          values = [incident[optionKey] as string];
        }

        for (let value of values)
          mapData.push({
            coords: incident.coords,
            incident_number: incident.incident_number,
            incident_date: incident.incident_date,
            author_name: incident.author_name,
            name: value
          });
      }

      mapWidget.config.mapData = mapData;

      return { ...widget, component: mapWidget as FilterMapWidget };
    } else {
      const mapData: MapBaseDataType[] = data.map((incident: IncidentData) => ({
        coords: incident.coords,
        incident_date: incident.incident_date,
        incident_number: incident.incident_number,
        author_name: incident.author_name
      }));

      mapWidget.config.mapData = mapData;

      if (widget.component.name === WIDGET_HEAT_MAP) {
        return { ...widget, component: mapWidget as HeatMapWidget };
      } else if (widget.component.name === WIDGET_CLUSTER_MAP) {
        return { ...widget, component: mapWidget as ClusterMapWidget };
      } else {
        // It's a 'pattern_map'
        return { ...widget, component: mapWidget as PatternMapWidget };
      }
    }
  }
};

export const getConfig = async (filters: AnalyticsFiltersRequest) => {
  let url = `${process.env.REACT_APP_API_BASE_URL}/analytics/generate`;

  const fetchResp = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `bearer ${getUserSession().access_token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      period: filters.period,
      filters:
        Object.keys(filters.filters).length > 0 ? filters.filters : undefined
    })
  });

  if (fetchResp.ok) {
    return fetchResp.json().then((config: AnalyticsConfig) => {
      // If doesnt exist create favourites list in local storage
      if (!localStorage.getItem(LOCAL_STORAGE_KEY)) {
        localStorage.setItem(
          LOCAL_STORAGE_KEY,
          JSON.stringify(config.default_widgets || [])
        );
      }

      config.widgets = config.widgets.map((w: Widget, index: number) =>
        getWidgetWithData(config, w.id, index, filters.period)
      );

      return config;
    });
  } else {
    const body = await fetchResp.json();
    return Promise.reject({
      code: fetchResp.status,
      text: fetchResp.statusText,
      detail: body.detail
    });
  }
};

export const saveMyWidgets = async (widgets: number[]) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/analytics/me/settings`;

  const fetchResp = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `bearer ${getUserSession().access_token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify({
      widgets
    })
  });

  if (fetchResp.ok) {
    const fetchData = await fetchResp.json();
    return Promise.resolve(fetchData);
  } else {
    const fetchData = await fetchResp.json();
    return Promise.reject({
      code: fetchResp.status,
      text: fetchResp.statusText,
      detail: fetchData.detail
    });
  }
};

export const getFavourites = (): number[] => {
  const favourites = localStorage.getItem(LOCAL_STORAGE_KEY);
  return favourites ? JSON.parse(favourites) : [];
};

export const isFavourite = (id: number): boolean =>
  getFavourites().indexOf(id) !== -1;

export const addFavourites = (
  ids: number[],
  hasSettingsPermission: boolean
) => {
  const favourites = getFavourites();
  const newList = [...favourites, ...ids];

  updateFavourites(newList, hasSettingsPermission);
};

export const removeFavourite = (id: number, hasSettingsPermission: boolean) => {
  const favourites = getFavourites();
  const index = favourites.indexOf(id);

  if (index !== -1) {
    const newFavs = [
      ...favourites.slice(0, index),
      ...favourites.slice(index + 1, favourites.length)
    ];

    updateFavourites(newFavs, hasSettingsPermission);
  }
};

export const updateFavourites = (
  ids: number[],
  hasSettingsPermission: boolean
) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(ids));
  if (hasSettingsPermission) {
    try {
      saveMyWidgets(ids);
    } catch (e) {
      console.error('WidgetService::UpdateFavourites');
    }
  }
};

const getWidgetById = (config: AnalyticsConfig, id: number): Widget => {
  return config.widgets.find((w: Widget) => w.id === id) as Widget;
};

const getWidgets = (config: AnalyticsConfig, ids: number[]) => {
  return ids.map((id: number) => getWidgetById(config, id));
};

export const getUserWidgets = (config: AnalyticsConfig): Widget[] => {
  const favouritesInConfig = getFavourites().filter(
    (id) => !!config.widgets.find((w) => w.id === id)
  );
  return getWidgets(config, favouritesInConfig);
};

export const getNonFavWidgets = (config: AnalyticsConfig): Widget[] => {
  const favourites = getFavourites();
  return config.widgets.filter((w: Widget) => favourites.indexOf(w.id) === -1);
};

export const getNewWidgets = (config: AnalyticsConfig): Widget[] => {
  const removeSuggestions = config.widgets.filter(
    (w: Widget) =>
      !config.suggested_widgets.find((value: Number) => w.id === value)
  );

  const newRateCards = removeSuggestions.filter(
    (widget: Widget) => widget.component.name === WIDGET_RATE_CARD
  );

  let newWidgets = newRateCards
    .slice(newRateCards.length - 5)
    .map((widget: Widget) => widget.id);

  const newOthers = removeSuggestions.filter(
    (widget: Widget) => widget.component.name !== WIDGET_RATE_CARD
  );

  newWidgets = [
    ...newWidgets,
    ...newOthers.slice(newOthers.length - 5).map((widget: Widget) => widget.id)
  ];

  return getWidgets(config, newWidgets);
};

export const getSuggestions = (config: AnalyticsConfig): Widget[] => {
  const suggested = config.suggested_widgets;

  return getWidgets(config, suggested);
};

export function isWidgetStorageEvent(e: StorageEvent) {
  return e.key === LOCAL_STORAGE_KEY;
}
