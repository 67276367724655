import React, { Fragment } from 'react';
import styles from './RankingCardBody.module.scss';
import { SFScrollable, SFText } from 'sfui';
import { RankingCardItem } from '../../../../../Models';
import { RankingCardElementList } from './RankingCardElementList/RankingCardElementList';

export interface RankingCardBodyProps {
  tooltipLabel?: string;
  list: RankingCardItem[];
}

export const RankingCardBody = ({
  list,
  tooltipLabel
}: RankingCardBodyProps): React.ReactElement<RankingCardBodyProps> => {
  return (
    <SFScrollable containerClassName={styles.rankingCardBody}>
      {list.map((item: RankingCardItem, index: number) => {
        const lastRow = index === list.length - 1;

        return (
          <Fragment key={`ranking-row-${index}`}>
            <SFText
              className={`${styles.order} ${lastRow ? styles.lastRowItem : ''}`}
              type="component-1-extraBold"
            >{`${index + 1}`}</SFText>

            <RankingCardElementList
              className={lastRow ? styles.lastRowItem : ''}
              list={item.elements}
              tooltipLabel={tooltipLabel}
            />

            <SFText
              className={lastRow ? styles.lastRowItem : ''}
              type="component-1"
            >
              {item.value}
            </SFText>
          </Fragment>
        );
      })}
    </SFScrollable>
  );
};
