import React from 'react';
import styles from './FullReportModalFooter.module.scss';
import { SFButton, useSFMediaQuery } from 'sfui';
import { PHONE_MEDIA_SCREEN } from '../../../Constants/Media';

export interface FullReportModalFooterProps {
  onClose: () => void;
}

export const FullReportModalFooter = ({
  onClose
}: FullReportModalFooterProps): React.ReactElement<FullReportModalFooterProps> => {
  const isPhoneScreen: boolean = useSFMediaQuery(PHONE_MEDIA_SCREEN);

  return (
    <div className={styles.footer}>
      <SFButton sfColor="grey" fullWidth={isPhoneScreen} onClick={onClose}>
        Close
      </SFButton>
    </div>
  );
};
