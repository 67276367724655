import React, { useContext } from 'react';
import styles from './AdvancedFiltersMenu.module.scss';
import { SFButton, SFMenuOption, SFSelect, SFText } from 'sfui';
import { AdvancedFiltersMenuItem } from './AdvancedFiltersMenuItem/AdvancedFiltersMenuItem';
import { MediaContext, TourTooltip } from 'ui-smartforce-settings';
import { FiltersCategoryMarker } from '../FiltersCategoryMarker/FiltersCategoryMarker';

function getOption(option: SFMenuOption, isMarked: boolean): SFMenuOption {
  return {
    ...option,
    item: <AdvancedFiltersMenuItem label={option.label} isMarked={isMarked} />
  };
}

function getOptions(
  categories: SFMenuOption[],
  categoriesMarked: string[]
): SFMenuOption[] {
  return categories.map((category: SFMenuOption) =>
    getOption(category, categoriesMarked.includes(category.value))
  );
}

export interface AdvancedFiltersMenuProps {
  className: string;
  categories: SFMenuOption[];
  categoriesMarked: string[];
  selected: string;
  onSelect: (selected: string) => void;
  onClear: () => void;
}

export const AdvancedFiltersMenu = ({
  className,
  categories,
  categoriesMarked,
  selected,
  onSelect,
  onClear
}: AdvancedFiltersMenuProps): React.ReactElement<AdvancedFiltersMenuProps> => {
  const { isMediumScreen } = useContext(MediaContext);
  const options = getOptions(categories, categoriesMarked);

  const onSelectChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => onSelect(e.target.value as string);

  const onKeyUp = (e: React.KeyboardEvent<HTMLLIElement>, selected: string) => {
    if (e.key === 'Enter') {
      onSelect(selected);
    }
  };

  return (
    <div className={`${styles.advancedFiltersMenu} ${className}`}>
      {!isMediumScreen && (
        <SFSelect
          label="Categories"
          options={options}
          value={selected}
          onChange={onSelectChange}
        />
      )}

      {isMediumScreen && (
        <ul className={styles.navMenu} role="menu">
          {categories.map((category: SFMenuOption) => {
            const isMarked = categoriesMarked.includes(category.value);
            const isSelected = selected === category.value;

            return (
              <li
                key={category.value}
                onClick={() => onSelect(category.value)}
                onKeyUp={(e: React.KeyboardEvent<HTMLLIElement>) =>
                  onKeyUp(e, category.value)
                }
                role="menuitem"
              >
                <SFText
                  className={`${styles.menuItem} ${
                    isSelected ? styles.isSelected : ''
                  }`}
                  type="component-1-medium"
                  sfColor="neutral"
                >
                  {category.label}
                </SFText>

                {isMarked && <FiltersCategoryMarker />}
              </li>
            );
          })}
        </ul>
      )}

      <TourTooltip
        title="Apply your filters"
        description='Select the section where is the filter you need to apply. If you need to clear all filters applied, you can click on the "Clear All" button.'
        step={2}
        lastStep={3}
        tourId={7}
        placement="bottom"
        topZIndex
      >
        <SFButton
          fullWidth
          disabled={categoriesMarked.length === 0}
          size="small"
          sfColor="grey"
          variant="outlined"
          onClick={onClear}
        >
          Clear All
        </SFButton>
      </TourTooltip>
    </div>
  );
};
