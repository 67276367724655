import React from 'react';
import {
  Customer,
  CustomerContext,
  User,
  UserContext
} from 'ui-smartforce-settings';
import { ReportValue } from '../../../Models';
import { ReportBody } from './ReportBody/ReportBody';
import { ReportHeader } from './ReportHeader/ReportHeader';
import { canAmendReport } from '../../../Helpers';

export interface FullReportProps {
  report: ReportValue;
  onAmend: () => void;
}

export const FullReport = ({
  report,
  onAmend
}: FullReportProps): React.ReactElement<FullReportProps> => {
  const customer = React.useContext(CustomerContext).customer as Customer;
  const user = React.useContext(UserContext).user as User;
  const canAmmend: boolean = canAmendReport(report, user, customer);

  return (
    <div>
      <ReportHeader
        customer={customer}
        canAmmend={canAmmend}
        onAmend={onAmend}
      />
      <ReportBody report={report} />
    </div>
  );
};
