import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './PlanUpgradeWizard.module.scss';
import { useHistory } from 'react-router-dom';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import {
  HttpStatusCode,
  SFButton,
  SFScrollable,
  SFScrollableRefHandler
} from 'sfui';
import {
  User,
  UserContext,
  CustomerContext,
  Customer,
  getStripeCardToken,
  BillingCycleType,
  Subscription,
  SubscriptionContext,
  SubscriptionPlan,
  SubscriptionValue,
  updateSubscription,
  getCardErrorMessage,
  StripeCardError,
  useSubscription,
  getPaidSubscription,
  PaymentFormValue,
  BillingFormValue,
  PaymentInformation,
  PurchaseDetails
} from 'ui-smartforce-settings';
import { ApiError } from '../../../../Models';
import { handleError } from '../../../../Helpers';
import { WizardCard } from '../../../../Components/WizardCard/WizardCard';
import { WizardCardStep } from '../../../../Components/WizardCard/WizardCardStep/WizardCardStep';
import { WizardStepContent } from '../../../../Components/WizardStepContent/WizardStepContent';
import { SelectPlan } from '../../../../Components/SelectPlan/SelectPlan';
import { WizardFinish } from '../../../../Components/WizardFinish/WizardFinish';

const initPaymentForm: PaymentFormValue = {
  acceptConditions: false,
  cardName: '',
  billing_details: { full_name: '', phone: '', full_address: '' }
};

const getInitBillingFormValue = (
  billingCycle: BillingCycleType | undefined
): BillingFormValue => {
  return {
    billing_cycle: billingCycle ?? 'annually',
    additional_seats: 0
  };
};

export const PlanUpgradeWizard = (): React.ReactElement<{}> => {
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const user = useContext(UserContext).user as User;
  const customer = useContext(CustomerContext).customer as Customer;
  const { subscriptions, setSubscriptions } = useContext(SubscriptionContext);
  const subscription = useSubscription('cc');
  const [step, setStep] = useState<number>(1);
  const [billingFormValue, setBillingFormValue] = useState<BillingFormValue>(
    getInitBillingFormValue(subscription?.billing_cycle)
  );
  const [paymentFormValue, setPaymentFormValue] =
    useState<PaymentFormValue>(initPaymentForm);
  const [paymentMethod, setPaymentMethod] = useState<string>(
    subscription?.payment?.method ?? 'card'
  );
  const [paymentError, setPaymentError] = useState<
    StripeCardError | undefined
  >();
  const [planSelected, setPlanSelected] = React.useState<SubscriptionPlan>(
    (subscription as Subscription).plan
  );
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const totalSeatsUsed = (subscription as Subscription).total_seats_used;
  const refScrollable = useRef<SFScrollableRefHandler>(null);
  const paidSubscription = getPaidSubscription(subscriptions);

  const refDebitPaymentUrl = useRef<string>('');

  useEffect(() => {
    setPaymentFormValue((prevValue) => ({
      ...prevValue,
      billing_details: {
        full_name: user.name,
        phone: user.phone ?? '',
        full_address: customer.address ? customer.address.full : ''
      }
    }));
  }, [user, customer]);

  const onClose = () => {
    history.goBack();
  };

  const onBack = () => {
    if (step === 1) {
      onClose();
    } else {
      // Reset selected plan
      if (step === 2) {
        setPlanSelected((subscription as Subscription).plan);
      } else if (step === 3) {
        setPaymentError(undefined);
      }

      setStep((step) => step - 1);
    }
  };

  const onNextStep = () => {
    setStep((step) => step + 1);
  };

  const onPlanSelect = (plan: SubscriptionPlan) => {
    setPlanSelected(plan);
    onNextStep();
  };

  const onConfirm = async () => {
    if (paymentMethod === 'card') {
      try {
        setIsSaving(true);
        const cardToken = await getStripeCardToken(
          paymentFormValue.cardName,
          stripe,
          elements
        );
        const subscriptionValue: SubscriptionValue = {
          plan: planSelected,
          payment_method: 'card',
          card_token: cardToken,
          additional_seats: billingFormValue.additional_seats,
          billing_cycle: billingFormValue.billing_cycle,
          product: 'cc',
          billing_details: paymentFormValue.billing_details
        };
        const updatedSubscription = await updateSubscription(
          process.env.REACT_APP_SETTINGS_API_BASE_URL as string,
          subscriptionValue
        );
        const updatedSubscriptionIndex = subscriptions.findIndex(
          (s) => s.id === updatedSubscription.id
        );

        setSubscriptions([
          ...subscriptions.slice(0, updatedSubscriptionIndex - 1),
          updatedSubscription,
          ...subscriptions.slice(updatedSubscriptionIndex)
        ]);
        setIsSaving(false);
        setStep((step) => step + 1);
      } catch (e) {
        setIsSaving(false);
        const error: ApiError = e as ApiError;

        if (error.code === HttpStatusCode.BAD_REQUEST) {
          const cardErrorMsg = getCardErrorMessage(error.detail);
          setPaymentError({
            title: 'We could not proceed with the payment.',
            message: cardErrorMsg
          });
          refScrollable.current?.scrollToTop('smooth');
        } else {
          handleError(error, history);
        }
      }
    } else {
      try {
        setIsSaving(true);

        const newSubscription = await updateSubscription(
          process.env.REACT_APP_SETTINGS_API_BASE_URL as string,
          {
            plan: planSelected,
            payment_method: 'debit',
            product: 'cc',
            additional_seats: billingFormValue.additional_seats,
            billing_cycle: billingFormValue.billing_cycle,
            billing_details: paymentFormValue.billing_details
          }
        );

        refDebitPaymentUrl.current = newSubscription.payment
          ?.payment_method_setup_url as string;

        setIsSaving(false);
        setStep((step) => step + 1);
      } catch (e) {
        handleError(e, history);
      }
    }
  };

  const onFinish = () => {
    if (paymentMethod === 'card') {
      history.replace('/cc');
    } else {
      window.location.href = refDebitPaymentUrl.current;
    }
  };

  return (
    <SFScrollable
      className={styles.scrollable}
      containerClassName={styles.planUpgradeWizard}
    >
      <WizardCard
        className={styles.wizardCard}
        isLoading={isSaving}
        show
        step={step}
        onBack={onBack}
        onClose={onClose}
      >
        <WizardCardStep title="Step" step={1} limit={3}>
          <WizardStepContent
            title="Upgrade your Agency Plan"
            subtitle="To continue, upgrade your plan."
          >
            <SelectPlan currentPlan={planSelected} onSelect={onPlanSelect} />
          </WizardStepContent>
        </WizardCardStep>
        <WizardCardStep title="Step" step={2} limit={3}>
          <WizardStepContent
            title="Review your Purchase Details"
            subtitle="Before making your payment, review your purchase information."
          >
            <PurchaseDetails
              onChange={(value: BillingFormValue) => setBillingFormValue(value)}
              onContinue={onNextStep}
              plan={planSelected}
              totalSeatsUsed={totalSeatsUsed}
              totalSeatsBilled={paidSubscription?.total_seats_billed}
              value={billingFormValue}
              additionalSeatsAvailable={false}
            />
          </WizardStepContent>
        </WizardCardStep>
        <WizardCardStep title="Step" step={3} limit={3}>
          <WizardStepContent
            title="Enter your Payment Information"
            subtitle="One more step before to finish."
          >
            <PaymentInformation
              method={paymentMethod}
              hideDebit={billingFormValue.billing_cycle === 'monthly'}
              onChange={(newValue) => setPaymentFormValue(newValue)}
              onConfirm={onConfirm}
              onMethodChange={(method: string) => setPaymentMethod(method)}
              error={paymentError}
              value={paymentFormValue}
              ref={refScrollable}
            />
          </WizardStepContent>
        </WizardCardStep>
        <WizardCardStep noHeader>
          <WizardFinish
            type={
              paymentMethod === 'card'
                ? 'upgrade-payment-accepted'
                : 'payment-pending'
            }
            ButtonElement={
              <SFButton onClick={onFinish}>
                {paymentMethod === 'card'
                  ? 'Go to Home'
                  : 'Complete Information'}
              </SFButton>
            }
          />
        </WizardCardStep>
      </WizardCard>
    </SFScrollable>
  );
};
