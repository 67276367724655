export const setNewVersionAvailable = (): void => {
  localStorage.setItem(
    'Smartforce.CitizenContact.NewVersionAvailable',
    'New Version'
  );
};
export const setNewVersionUpdated = (): void => {
  localStorage.setItem('Smartforce.CitizenContact.NewVersionUpdated', 'true');
};

export const removeNewVersionAvailable = (): void => {
  localStorage.removeItem('Smartforce.CitizenContact.NewVersionAvailable');
};

export const removeNewVersionUpdated = (): void => {
  localStorage.removeItem('Smartforce.CitizenContact.NewVersionUpdated');
};

export const isNewVersionAvailable = (): boolean => {
  return (
    localStorage.getItem('Smartforce.CitizenContact.NewVersionAvailable') !==
    null
  );
};

export const isNewVersionUpdated = (): boolean => {
  return (
    localStorage.getItem('Smartforce.CitizenContact.NewVersionUpdated') !== null
  );
};
