import { isColorado } from 'ui-smartforce-settings';
import {
  ItemValueType,
  UseOfForceValidation,
  ItemModelValueType
} from '../Models';
import { getSectionFieldValueByString } from './report';

interface Validator {
  isActive: boolean;
}

export interface UOFValidator extends Validator {
  areCitizensWithUOF?: boolean;
  areOfficersLinked?: boolean;
}

export interface BystanderValidator extends Validator {
  isCitizenWithUOF?: boolean;
  areBystanderValid?: boolean;
  areBystanderNoAction?: boolean;
}

export function checkCitizensWithUOF(
  citizens: ItemValueType[],
  config: UseOfForceValidation
): boolean {
  for (let citizen of citizens) {
    if (getSectionFieldValueByString(citizen, config.citizen.field)) {
      return true;
    }
  }

  return false;
}

// This function assumes that the config is setted with a field containing
// an array of numbers (citizens indexes)
function checkOfficerUOFLinked(
  officer: ItemValueType,
  config: UseOfForceValidation,
  citizenIndex: number
) {
  const [sectionName, fieldName, itemFieldName] =
    config.officers.field.split('.');
  const fieldValue = officer[sectionName][fieldName];

  if (!fieldValue) {
    return false;
  }

  // It's a simple field
  if (!itemFieldName) {
    return (fieldValue as number[]).includes(citizenIndex);
  } else {
    // It's an ItemList field
    return (fieldValue as ItemModelValueType[]).some(
      (value: ItemModelValueType) =>
        value[itemFieldName] &&
        (value[itemFieldName] as number[]).includes(citizenIndex)
    );
  }
}

export function checkOfficersUOFLinked(
  officers: ItemValueType[],
  citizens: ItemValueType[],
  config: UseOfForceValidation
): boolean {
  let citizenIndex = 0;
  // Set if there's at least one citizen with uof
  let wasUseOfForce = false;

  // For every citizen check if at least one officer is linked with it
  for (let citizen of citizens) {
    const citizenWithUOF = getSectionFieldValueByString(
      citizen,
      config.citizen.field
    );

    if (citizenWithUOF) {
      // At least one citizen with uof
      if (!wasUseOfForce) wasUseOfForce = true;

      // If there isn't any officer linked to current citizen, return false
      if (
        // eslint-disable-next-line no-loop-func
        !officers.some((officer: ItemValueType) =>
          checkOfficerUOFLinked(officer, config, citizenIndex)
        )
      ) {
        return false;
      }
    }

    citizenIndex++;
  }

  return wasUseOfForce;
}

export function getUOFValidator(
  config: UseOfForceValidation | undefined,
  citizens: ItemValueType[],
  officers: ItemValueType[]
): UOFValidator {
  if (!config) {
    return { isActive: false };
  } else {
    return {
      isActive: true,
      areCitizensWithUOF: checkCitizensWithUOF(citizens, config),
      areOfficersLinked: checkOfficersUOFLinked(officers, citizens, config)
    };
  }
}

export function updateUOFValidator(
  validator: UOFValidator,
  config: UseOfForceValidation,
  citizens: ItemValueType[],
  officers: ItemValueType[]
): UOFValidator {
  return {
    ...validator,
    areCitizensWithUOF: checkCitizensWithUOF(citizens, config),
    areOfficersLinked: checkOfficersUOFLinked(officers, citizens, config)
  };
}

export function isUOFValid(
  validator: UOFValidator,
  wasUseOfForce: boolean
): boolean {
  return (
    !validator.isActive ||
    !wasUseOfForce ||
    (!!validator.areCitizensWithUOF && !!validator.areOfficersLinked)
  );
}

const BYSTANDER_VALID_INJURIES = [
  'Death',
  'Suspected Serious Injury',
  'Suspected Minor Injury',
  'Possible Injury'
];

function checkBystandersNoAction(bystanders: ItemValueType[]): boolean {
  return bystanders.every((b) =>
    (b.incident_information.action_taken as string[]).includes('No Action')
  );
}

function checkBystander(bystander: ItemValueType): boolean {
  return (
    !!bystander.incident_information.use_of_force &&
    BYSTANDER_VALID_INJURIES.includes(
      bystander.incident_information.injuries as string
    )
  );
}

function checkBystandersValid(bystanders: ItemValueType[]): boolean {
  return bystanders.every((b) => checkBystander(b));
}

function checkBystanderUOFCitizen(citizens: ItemValueType[]) {
  return !!citizens.find(
    (c) =>
      c.incident_information.use_of_force &&
      c.incident_information.contact_reason !== 'Bystander'
  );
}

export function getBystanderValidator(
  stateName: string,
  citizens: ItemValueType[]
): BystanderValidator {
  if (!isColorado(stateName)) {
    return { isActive: false };
  } else {
    const bystanders = citizens.filter(
      (c) => c.incident_information.contact_reason === 'Bystander'
    );
    const bystandersEmpty = bystanders.length === 0;

    return {
      isActive: true,
      isCitizenWithUOF: bystandersEmpty || checkBystanderUOFCitizen(citizens),
      areBystanderValid: bystandersEmpty || checkBystandersValid(bystanders),
      areBystanderNoAction:
        bystandersEmpty || checkBystandersNoAction(bystanders)
    };
  }
}

export function isBystanderValid(validator: BystanderValidator): boolean {
  return (
    !validator.isActive ||
    (!!validator.isCitizenWithUOF &&
      !!validator.areBystanderValid &&
      !!validator.areBystanderNoAction)
  );
}
