import React, { useMemo } from 'react';
import styles from './ActivatePlan.module.scss';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripeAPI } from 'ui-smartforce-settings';
import { ActivatePlanWizard } from './ActivatePlanWizard/ActivatePlanWizard';

export interface ActivatePlanProps {}

export const ActivatePlan = (
  props: ActivatePlanProps
): React.ReactElement<ActivatePlanProps> => {
  const stripeAPI = useMemo(
    () => loadStripeAPI(`${process.env.REACT_APP_STRIPE_KEY}`),
    []
  );

  return (
    <div className={styles.activatePlan}>
      <Elements stripe={stripeAPI}>
        <ActivatePlanWizard />
      </Elements>
    </div>
  );
};
