import React, { useMemo } from 'react';
import styles from './GradientLine.module.scss';
import { SFGrey, SFScrollable, SFThemeType } from 'sfui';
import * as echarts from 'echarts/core';
import { EChartsOption } from 'echarts';
import { EChart } from '../../../EChart/EChart';
import { ANALYTICS_COLOR_BLUE } from '../../../../Constants/Colors';
import { ThemeTypeContext } from 'ui-smartforce-settings';

function getLinearGradient(themeType: SFThemeType) {
  return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: `rgba(13, 105, 242, ${themeType === 'day' ? '0.3' : '0.4'})`
    },
    {
      offset: 1,
      color: 'rgba(13, 105, 242, 0)'
    }
  ]);
}

function getChartOption(
  categories: string[],
  data: number[],
  themeType: SFThemeType
): EChartsOption {
  return {
    grid: {
      top: 10,
      bottom: 30,
      right: 46,
      left: 66
    },
    xAxis: {
      data: categories,
      type: 'category',
      boundaryGap: false,
      axisLabel: {
        margin: 18,
        color: themeType === 'day' ? SFGrey[600] : SFGrey[400]
      },
      axisTick: {
        show: false
      },
      axisLine: {
        lineStyle: {
          color: themeType === 'day' ? SFGrey[200] : SFGrey[700],
          width: 1
        }
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        margin: 12,
        color: themeType === 'day' ? SFGrey[600] : SFGrey[400]
      },
      splitLine: {
        lineStyle: {
          color: themeType === 'day' ? SFGrey[100] : SFGrey[800],
          type: [6, 4]
        }
      }
    },
    series: [
      {
        data: data,
        type: 'line',
        smooth: true,
        showSymbol: false,
        lineStyle: {
          color: ANALYTICS_COLOR_BLUE,
          width: 4
        },
        areaStyle: {
          color: getLinearGradient(themeType)
        },
        emphasis: {
          disabled: true
        }
      }
    ]
  };
}

export interface GradientLineProps {
  categories: string[];
  data: number[];
}

export const GradientLine = ({
  categories,
  data
}: GradientLineProps): React.ReactElement<GradientLineProps> => {
  const { themeType } = React.useContext(ThemeTypeContext);

  const option = useMemo(
    () => getChartOption(categories, data, themeType),
    [categories, data, themeType]
  );
  return (
    <SFScrollable
      className={styles.scrollable}
      containerClassName={styles.gradientLine}
    >
      <EChart className={styles.echart} option={option} />
    </SFScrollable>
  );
};
