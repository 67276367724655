import React from 'react';
import { SFIcon, SFTooltip } from 'sfui';
import styles from './MarkerNoCoordinates.module.scss';
import { InteractiveBox } from 'ui-smartforce-settings';

export interface MarkerNoCoordinatesProps {
  text: string;
  onClick?: () => void;
}

export const MarkerNoCoordinates = ({
  text,
  onClick
}: MarkerNoCoordinatesProps): React.ReactElement<MarkerNoCoordinatesProps> => {
  return (
    <SFTooltip title={text} placement="bottom-start">
      <InteractiveBox className={styles.markerNoCoordinates} onClick={onClick}>
        <SFIcon icon="Gps-Off" size={30} />
      </InteractiveBox>
    </SFTooltip>
  );
};
