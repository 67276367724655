import React, { useEffect, Fragment } from 'react';
import jwt_decode from 'jwt-decode';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../Hooks';
import { saveUserInvitation } from 'ui-smartforce-settings';

export const AgencyInvitation = (): React.ReactElement<{}> => {
  const history = useHistory();
  const query = useQuery();

  useEffect(() => {
    try {
      const invite: string = query.get('invite') as string;
      saveUserInvitation(invite);

      history.push('/registration', { data: jwt_decode(invite) });
    } catch (e) {
      console.error('AgencyInvitation::onMount', e);
      history.push('/error');
    }
  }, [query, history]);

  return <Fragment></Fragment>;
};
