import React from 'react';
import styles from './WizardCardFadeStep.module.scss';
import { SFFade } from 'sfui';

const TRANSITION_TIMEOUT = 360;

export interface WizardCardFadeStepProps {
  children: React.ReactNode;
  show?: boolean;
}

export const WizardCardFadeStep = ({
  children,
  show = false
}: WizardCardFadeStepProps): React.ReactElement<WizardCardFadeStepProps> => {
  return (
    <SFFade
      in={show}
      mountOnEnter
      unmountOnExit
      timeout={{
        enter: TRANSITION_TIMEOUT,
        exit: 0
      }}
    >
      <div className={styles.wizardCardFadeStep}>{children}</div>
    </SFFade>
  );
};
