import React from 'react';
import styles from './SaveObjectPanelHeader.module.scss';
import { SFIconButton } from 'sfui';

export interface SaveObjectPanelHeaderProps {
  title: string;
  onClose: () => void;
}

export const SaveObjectPanelHeader = ({
  title,
  onClose
}: SaveObjectPanelHeaderProps): React.ReactElement<SaveObjectPanelHeaderProps> => {
  return (
    <div className={styles.saveObjectPanelHeader}>
      <h4>{title}</h4>
      <SFIconButton sfIcon="Close" sfSize="medium" onClick={onClose} />
    </div>
  );
};
