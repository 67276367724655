import React from 'react';
import { MediaContext } from 'ui-smartforce-settings';
import { SaveObjectDrawer } from './SaveObjectDrawer/SaveObjectDrawer';
import { SaveObjectDialog } from './SaveObjectDialog/SaveObjectDialog';

export interface SaveObjectPanelProps {
  title: string;
  isOpen: boolean;
  children: React.ReactNode;
  isSaveDisabled: boolean;
  saveBtnLabel: string;
  onSave: () => void;
  onClose: () => void;
}

export const SaveObjectPanel = (
  props: SaveObjectPanelProps
): React.ReactElement<SaveObjectPanelProps> => {
  const { isPhone } = React.useContext(MediaContext);

  return isPhone ? (
    <SaveObjectDrawer {...props} />
  ) : (
    <SaveObjectDialog {...props} />
  );
};
