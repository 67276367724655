import React from 'react';
import styles from './FullReportModal.module.scss';
import { SFDialog, SFScrollable, SFSpinner } from 'sfui';
import { ReportValue } from '../../Models';
import { FullReportModalFooter } from './FullReportModalFooter/FullReportModalFooter';
import { FullReport } from './FullReport/FullReport';

export type FullReportGroupType = 'noCoords' | 'closeCoords';

export interface FullReportModalProps {
  isOpen: boolean;
  isLoading: boolean;
  report?: ReportValue;
  onAmend: () => void;
  onClose: () => void;
}

export const FullReportModal = ({
  isOpen = false,
  isLoading,
  report,
  onAmend,
  onClose
}: FullReportModalProps): React.ReactElement<FullReportModalProps> => {
  return (
    <SFDialog
      PaperProps={{
        className: `${styles.fullReportModal} ${
          isLoading ? styles.isLoading : ''
        }`
      }}
      open={isOpen}
    >
      {isLoading && <SFSpinner size={40} />}

      {!isLoading && report && (
        <div className={styles.container}>
          <SFScrollable className={styles.scrollableReport}>
            <FullReport report={report} onAmend={onAmend} />
          </SFScrollable>

          <FullReportModalFooter onClose={onClose} />
        </div>
      )}
    </SFDialog>
  );
};
