import React from 'react';
import styles from './LandingHighlightItem.module.scss';
import { SFBlue, SFIcon } from 'sfui';

export interface LandingHighlightItemProps {
  icon: string;
  count: string;
  label: string;
}

export const LandingHighlightItem = ({
  icon,
  count,
  label
}: LandingHighlightItemProps): React.ReactElement<LandingHighlightItemProps> => {
  return (
    <div className={styles.landingHighlightItem}>
      <SFIcon icon={icon} size={60} color={SFBlue[300]} />

      <div className={styles.text}>
        <h1>{count}</h1>
        <h2>{label}</h2>
      </div>
    </div>
  );
};
