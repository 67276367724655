import React, { useContext } from 'react';
import { SFButton } from 'sfui';
import { TourContext, TourTooltip } from 'ui-smartforce-settings';

export interface FilterByButtonProps {
  filterCount: number;
  hasAnalytics: boolean;
  onClick: () => void;
}

export const FilterByButton = ({
  filterCount,
  hasAnalytics,
  onClick
}: FilterByButtonProps): React.ReactElement<FilterByButtonProps> => {
  const { onNext: onTourNext } = useContext(TourContext);

  const label: string = `Filter by${
    filterCount > 0 ? ` (${filterCount})` : ''
  }`;

  const onButtonClick = () => {
    onTourNext({ tourId: 7, step: 1 });
    onClick();
  };

  return (
    <>
      {hasAnalytics && (
        <TourTooltip
          title="Search your reports"
          description="Click on the “Filter By” button"
          step={1}
          lastStep={3}
          tourId={7}
          preventOverflow
          placement="bottom"
        >
          <SFButton onClick={onButtonClick}>{label}</SFButton>
        </TourTooltip>
      )}

      {!hasAnalytics && <SFButton onClick={onClick}>{label}</SFButton>}
    </>
  );
};
