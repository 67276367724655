import React from 'react';
import styles from './WidgetsSectionHeader.module.scss';
import { SFButton } from 'sfui';

export interface WidgetsSectionHeaderProps {
  title: string;
  onSeeAll: () => void;
}

export const WidgetsSectionHeader = ({
  title,
  onSeeAll
}: WidgetsSectionHeaderProps): React.ReactElement<WidgetsSectionHeaderProps> => {
  return (
    <div className={styles.widgetsSectionHeader}>
      <div className={styles.headerLeft}>
        <h2>{title}</h2>
      </div>
      <SFButton variant="text" sfColor="grey" onClick={onSeeAll}>
        See All
      </SFButton>
    </div>
  );
};
