import React from 'react';
import { SFIcon } from 'sfui';
import styles from './NoReports.module.scss';

export const NoReports = (): React.ReactElement<{}> => {
  return (
    <div className={styles.noReports}>
      <div className={styles.content}>
        <div className={styles.icon}>
          <SFIcon icon="Open-Folder" size={96} />
        </div>

        <h2 className={styles.title}>No reports yet</h2>

        <p className={styles.subTitle}>
          The latest reports will be displayed here.
        </p>
      </div>
    </div>
  );
};
