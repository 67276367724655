import React, { Fragment, useContext } from 'react';
import styles from './ReportCardInfo.module.scss';
import { ReportConfig, ReportValue } from '../../../Models';
import { GeneralInformation } from './GeneralInformation/GeneralInformation';
import { ItemList } from './ItemList/ItemList';
import { StateConfigContext } from '../../../Context/StateConfigContext';
import { DurationInformation } from './DurationInformation/DurationInformation';
import { UserContext, checkPermissions } from 'ui-smartforce-settings';
import { AGENCY_REPORTS_READ } from '../../../Constants/RolesAndPermissions';

export interface ReportCardInfoProps {
  className?: string;
  report: ReportValue;
}

export const ReportCardInfo = ({
  className = '',
  report
}: ReportCardInfoProps): React.ReactElement<ReportCardInfoProps> => {
  const stateConfig = useContext(StateConfigContext)
    .stateConfig as ReportConfig;
  const { user } = React.useContext(UserContext);

  const showSupervision: boolean = checkPermissions(
    AGENCY_REPORTS_READ,
    user?.role.permissions
  );

  return (
    <div className={`${styles.reportCardInfo} ${className}`}>
      <div className={styles.content}>
        <GeneralInformation data={report.general_information} />
        <DurationInformation duration={report.general_information.duration} />

        {showSupervision && (
          <Fragment>
            {report.complaints && report.complaints.length > 0 && (
              <ItemList
                items={report.complaints}
                title="Complaints"
                itemsName="complaint"
                config={stateConfig.complaint}
              />
            )}

            {report.investigations && report.investigations.length > 0 && (
              <ItemList
                items={report.investigations}
                title="Investigations"
                itemsName="investigation"
                config={stateConfig.investigation}
              />
            )}
          </Fragment>
        )}
      </div>

      <div className={styles.content}>
        {stateConfig && (
          <ItemList
            items={report.citizens}
            title="Citizens Information"
            itemsName="citizen"
            config={stateConfig.citizen}
          />
        )}

        {stateConfig.use_of_force &&
          report.use_of_force &&
          stateConfig.use_of_force.other_agencies_involved &&
          report.use_of_force.other_agencies_involved.length > 0 && (
            <ItemList
              items={report.use_of_force.other_agencies_involved}
              title="Other Agencies Involved"
              itemsName="agency"
              config={stateConfig.use_of_force.other_agencies_involved}
            />
          )}

        {report.officers && report.officers.length > 0 && (
          <ItemList
            items={report.officers}
            title="Officers Involved"
            itemsName="officer"
            config={stateConfig.officer}
          />
        )}
      </div>
    </div>
  );
};
