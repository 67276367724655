import React, { Fragment } from 'react';
import styles from './ResetPasswordView.module.scss';
import { useHistory } from 'react-router-dom';
import { SFAlert, SFButton, SFTextField } from 'sfui';
import { AuthContainer } from '../../Components/AuthContainer/AuthContainer';
import Loader from '../../Components/Loader/Loader';
import { useQuery } from '../../Hooks';
import {
  PASSWORD_INVALID_MSG,
  isPasswordValid,
  logout,
  resetPassword
} from 'ui-smartforce-settings';

export interface ResetPasswordViewProps {}

export const ResetPasswordView = (
  props: ResetPasswordViewProps
): React.ReactElement<ResetPasswordViewProps> => {
  const history = useHistory();
  const [password, setPassword] = React.useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = React.useState<string>('');
  const [isPasswordError, setIsPasswordError] = React.useState<boolean>(false);
  const [isPasswordConfirmError, setIsPasswordConfirmError] =
    React.useState<boolean>(false);
  const [isMatchError, setIsMatchError] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const query = useQuery();

  const checkSaveDisabled = (): boolean => {
    return password.length === 0 || passwordConfirm.length === 0;
  };

  const onSave = async () => {
    if (
      isPasswordValid(password) &&
      isPasswordValid(passwordConfirm) &&
      password === passwordConfirm
    ) {
      setIsLoading(true);

      try {
        await resetPassword(
          process.env.REACT_APP_SETTINGS_API_BASE_URL as string,
          password,
          query.get('token') as string
        );
        setIsLoading(false);
        logout();
        history.push('/login');
      } catch (e) {
        setIsLoading(false);
        console.error('ResetPassword::GeneralError', e);
        history.push('/error');
      }
    } else {
      setIsMatchError(password !== passwordConfirm);
      setIsPasswordError(!isPasswordValid(password));
      setIsPasswordConfirmError(!isPasswordValid(passwordConfirm));
    }
  };

  return (
    <AuthContainer>
      <div className={styles.resetPasswordView}>
        {isLoading && <Loader />}
        {!isLoading && (
          <Fragment>
            <h2 className={styles.title}>Change Password</h2>

            {isMatchError && (
              <SFAlert
                type="error"
                title="The “New Password” and “Confirm Password” do not match."
              >
                Please try again.
              </SFAlert>
            )}

            <form>
              <SFTextField
                label="New Password"
                type="password"
                required
                value={password}
                error={isPasswordError}
                helperText={isPasswordError ? PASSWORD_INVALID_MSG : ''}
                onChange={(event) => setPassword(event.target.value)}
              />

              <SFTextField
                label="Confirm Password"
                type="password"
                required
                value={passwordConfirm}
                error={isPasswordConfirmError}
                helperText={PASSWORD_INVALID_MSG}
                onChange={(event) => setPasswordConfirm(event.target.value)}
              />
            </form>

            <SFButton
              size="large"
              fullWidth
              disabled={checkSaveDisabled()}
              onClick={onSave}
            >
              Save Password
            </SFButton>
          </Fragment>
        )}
      </div>
    </AuthContainer>
  );
};
