import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import { FullReportGroupType } from '../Components/FullReportModal/FullReportModal';
import {
  MAP_DEFAULT_ZOOM,
  USA_CENTER_LAT,
  USA_CENTER_LONG
} from '../Constants/Maps';
import {
  CustomerContext,
  Customer,
  GoogleMapProps
} from 'ui-smartforce-settings';
import { FullReportContext } from '../Context/FullReportContext';
import {
  getCloseItems,
  filterReportsByCoords,
  getCenter,
  getBounds
} from '../Helpers';
import { MapBaseDataType } from '../Models';
import {
  MainAlertEventData,
  dispatchMainAlertEvent
} from '../Events/MainAlertEvent';
import { useHistory } from 'react-router-dom';

const sortData = (data: MapBaseDataType[]): MapBaseDataType[] => {
  return data.sort(
    (prev: MapBaseDataType, next: MapBaseDataType) =>
      new Date(next.incident_date).getTime() -
      new Date(prev.incident_date).getTime()
  );
};

export const useQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

export function useFilteredReports<T extends MapBaseDataType>(
  data: T[]
): {
  reportsWithCoords: T[];
  reportsWithoutCoords: T[];
} {
  return useMemo(() => {
    return filterReportsByCoords(data) as {
      reportsWithCoords: T[];
      reportsWithoutCoords: T[];
    };
  }, [data]);
}

export interface useMarkerClickType {
  onMarkerClick: (
    groupType?: FullReportGroupType,
    item?: MapBaseDataType
  ) => void;
}

export const useMarkerClick = (
  userCanSeeReport: boolean,
  data: MapBaseDataType[]
): useMarkerClickType => {
  const { showMultipleReports, showFullReport } = useContext(FullReportContext);

  // Show full report on marker click
  const onMarkerClick = useCallback(
    (
      groupType: FullReportGroupType = 'closeCoords',
      item?: MapBaseDataType
    ) => {
      let incidents: MapBaseDataType[] = sortData([...data]);

      if (item) {
        incidents = getCloseItems(item, incidents);
      }

      if (userCanSeeReport) {
        if (incidents.length > 1) {
          showMultipleReports(
            incidents,
            groupType,
            item ? item.incident_number : incidents[0].incident_number
          );
        } else {
          showFullReport(
            item ? item.incident_number : incidents[0].incident_number
          );
        }
      }
    },
    [showMultipleReports, showFullReport, userCanSeeReport, data]
  );

  return { onMarkerClick };
};

export function useMapOptions(
  data: MapBaseDataType[]
): Partial<GoogleMapProps> {
  const customer = useContext(CustomerContext).customer as Customer;
  return useMemo(() => {
    const dataCenter = getCenter(data);
    if (dataCenter) {
      return { center: dataCenter, bounds: getBounds(data) };
    } else if (customer.address?.coords) {
      const center = new google.maps.LatLng(
        customer.address?.coords.latitude,
        customer.address?.coords.longitude
      );
      const bounds = new google.maps.LatLngBounds(center);
      return {
        center,
        bounds
      };
    } else {
      return {
        center: new google.maps.LatLng(USA_CENTER_LAT, USA_CENTER_LONG),
        zoom: MAP_DEFAULT_ZOOM
      };
    }
  }, [data, customer]);
}

interface LocationState {
  showMainAlert: boolean;
  mainAlertData: MainAlertEventData;
}

export function useDispatchMainAlert() {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const locationState: LocationState = location.state as LocationState;

    if (locationState && locationState.showMainAlert) {
      // Clear the history state
      history.replace({ state: {} });

      setTimeout(() => {
        dispatchMainAlertEvent({
          message: locationState.mainAlertData.message,
          autoHideDuration: locationState.mainAlertData.autoHideDuration
        });
      }, 100);
    }
  }, [history, location.state]);
}
