import React from 'react';
import { useHistory } from 'react-router-dom';
import ErrorPage from '../../Components/ErrorPage/ErrorPage';
import { removeUserInvitation } from 'ui-smartforce-settings';

export const UserOriErrorView = (): React.ReactElement<{}> => {
  const history = useHistory();

  const onGoHome = () => {
    removeUserInvitation();
    history.replace('/login');
  };

  return (
    <ErrorPage
      icon="Information"
      title="You already have an agency assigned"
      onGoHome={onGoHome}
    />
  );
};
