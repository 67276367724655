import React from 'react';
import styles from './LandingProduct.module.scss';
import { SFButton } from 'sfui';
import { ThemeTypeContext } from 'ui-smartforce-settings';
import { LandingSectionName } from '../LandingSectionName/LandingSectionName';
import { LandingConfigProduct } from '../../../Models/Landing';
import { getLandingImageUrl } from '../../../Helpers';

export interface LandingProductProps {
  config: LandingConfigProduct;
}

export const LandingProduct = ({
  config
}: LandingProductProps): React.ReactElement<LandingProductProps> => {
  const { themeType } = React.useContext(ThemeTypeContext);

  const onHowStart = () => window.open(config.video, '_blank');

  return (
    <div className={styles.landingProduct}>
      <div className={styles.content}>
        <LandingSectionName text={config.name} />

        <p className={styles.title}>{config.title}</p>
        <p className={styles.description}>{config.description}</p>

        <SFButton size="large" onClick={onHowStart}>
          Demo Video
        </SFButton>
      </div>

      <div className={styles.image}>
        <img src={getLandingImageUrl(config.image, themeType)} alt="" />
      </div>
    </div>
  );
};
