import { getReportDate, getReportTime } from '../../../../../Helpers';
import { Field, FieldValueType } from '../../../../../Models';
import { formatDateString, UserGroup, UserTitle } from 'ui-smartforce-settings';

interface FieldComponentDictType {
  [key: string]: string;
}

export const FieldComponentDict: FieldComponentDictType = {
  autocomplete: 'string',
  autocompletechip: 'array',
  checkbox: 'array',
  date: 'date',
  datetime: 'datetime',
  duration: 'duration',
  location: 'location',
  multiselect: 'array',
  number: 'number',
  radio: 'string',
  textfield: 'string',
  textarea: 'string',
  time: 'time',
  switch: 'boolean',
  itemlist: 'itemlist',
  citizen_checklist: 'citizen_checklist',
  groupsfield: 'grouplist',
  titlefield: 'title',
  peoplepicker: 'string'
};

export const getFieldFormattedValue = (
  fieldValue: FieldValueType,
  type: string
): string => {
  switch (type) {
    case 'date':
      return getReportDate(fieldValue as string);
    case 'time':
      return getReportTime(fieldValue as string);
    case 'datetime':
      return formatDateString(fieldValue as string, 'L - HH:mm');
    case 'duration':
      return `${fieldValue} ${fieldValue !== 1 ? 'minutes' : 'minute'}`;
    case 'boolean':
      return fieldValue ? 'Yes' : 'No';
    case 'array':
      return (fieldValue as FieldValueType[])
        .map((value: FieldValueType) => `${value}`)
        .join(', ');
    case 'grouplist':
      return [...(fieldValue as UserGroup[])]
        .sort((a: UserGroup, b: UserGroup) => a.name.localeCompare(b.name))
        .map((value: UserGroup) => `${value.name}`)
        .join(', ');
    case 'citizen_checklist':
      return (fieldValue as number[])
        .map((value: number) => `Citizen ${value + 1}`)
        .join(', ');
    case 'title':
      return (fieldValue as UserTitle).name;
    default:
      return `${fieldValue}`;
  }
};

export const getFieldType = (config: Field): string => {
  return FieldComponentDict[config.component];
};
