import React from 'react';
import { useHistory } from 'react-router-dom';
import ErrorPage from '../../Components/ErrorPage/ErrorPage';

export const ActionNotAllowedErrorView = (): React.ReactElement<{}> => {
  const history = useHistory();

  return (
    <ErrorPage
      icon="Attention"
      title="Sorry, you are not allowed to do this action."
      onGoHome={() => history.replace('/cc')}
    />
  );
};
