import React from 'react';
import styles from './PlanDescriptionCard.module.scss';
import { SFButton, SFChip } from 'sfui';

export interface PlanDescriptionCardProps {
  name: string;
  price: string;
  priceDetail: string;
  children: React.ReactNode;
  className?: string;
  current?: boolean;
  disabled?: boolean;
  buttonVariant?: 'outlined' | 'contained';
  buttonColor?: 'blue' | 'grey';
  onSelect: () => void;
}

export const PlanDescriptionCard = ({
  name,
  price,
  priceDetail,
  children,
  className,
  current,
  disabled = false,
  buttonVariant = 'outlined',
  buttonColor,
  onSelect
}: PlanDescriptionCardProps): React.ReactElement<PlanDescriptionCardProps> => {
  return (
    <div className={`${styles.planDescriptionCard} ${className}`}>
      <div className={styles.planName}>
        <h6>{name}</h6>
        {current && (
          <SFChip sfColor="primary" size="small" label="Current plan" />
        )}
      </div>

      <div className={styles.price}>
        <h3>{price}</h3>
        <span>{priceDetail}</span>
      </div>

      <div className={styles.items}>{children}</div>

      <div className={styles.button}>
        <SFButton
          variant={buttonVariant}
          sfColor={buttonColor}
          disabled={disabled}
          fullWidth
          size="large"
          onClick={onSelect}
        >
          Choose {name}
        </SFButton>
      </div>
    </div>
  );
};
