import React, { useContext } from 'react';
import styles from './WidgetsSection.module.scss';
import { useSFMediaQuery } from 'sfui';
import { AnalyticsPeriod, Widget } from '../../../../../Models';
import { WidgetList } from '../../../../../Components/WidgetList/WidgetList';
import { WidgetsSectionHeader } from './WidgetsSectionHeader/WidgetsSectionHeader';
import { MasonryElementSize } from '../../../../../Components/Masonry/MasonryElement/MasonryElement';
import { EXTRA_LARGE_SCREEN } from '../../../../../Constants/Media';
import { getWidgetColSize } from '../../../../../Helpers';
import { WIDGET_RATE_CARD } from '../../../../../Constants/Analytics';
import { MediaContext } from 'ui-smartforce-settings';

const getColSize = (name: string, screenSize?: string): number => {
  if (screenSize === 'xs') {
    return 1;
  }

  const size: MasonryElementSize = getWidgetColSize(name);

  if (size === 'xs' || size === 'sm') {
    return 1;
  } else if (size === 'md') {
    return 2;
  } else {
    return screenSize === 'xxl' ? 3 : 2;
  }
};

const getVisibleWidgets = (
  widgets: Widget[],
  screenSize?: string
): Widget[] => {
  let result: Widget[] = [];
  let countSmall = 0;
  let othersColSum = 0;

  let columns: number = 2;
  if (screenSize === 'xs') {
    columns = 1;
  } else if (screenSize === 'xxl') {
    columns = 3;
  }

  for (let widget of widgets) {
    // Stop looping when there are max rate cards in the first row
    // and no more place to put other widgets in the second row
    if (countSmall === columns && othersColSum === columns) {
      break;
    }

    if (widget.component.name === WIDGET_RATE_CARD) {
      if (countSmall < columns) {
        countSmall++;
        result = [...result, widget];
      }
    } else {
      othersColSum += getColSize(widget.component.name, screenSize);
      if (othersColSum <= columns) {
        result = [...result, widget];
      }
    }
  }

  return result;
};

export interface WidgetsSectionProps {
  title: string;
  widgets: Widget[];
  period: AnalyticsPeriod;
  onSeeAll: () => void;
  onAdd: (id: number) => void;
}

export const WidgetsSection = ({
  title,
  widgets,
  period,
  onSeeAll,
  onAdd
}: WidgetsSectionProps): React.ReactElement<WidgetsSectionProps> => {
  const { isPhone } = useContext(MediaContext);
  const isExtraLargeScreen: boolean = useSFMediaQuery(EXTRA_LARGE_SCREEN);

  let screenSize;
  if (isPhone) {
    screenSize = 'xs';
  } else if (isExtraLargeScreen) {
    screenSize = 'xxl';
  }

  const visibleWidgets = getVisibleWidgets(widgets, screenSize);

  return (
    <div className={styles.widgetsSection}>
      <WidgetsSectionHeader title={title} onSeeAll={onSeeAll} />
      <WidgetList period={period} widgets={visibleWidgets} onAdd={onAdd} />
    </div>
  );
};
