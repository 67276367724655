// List agency reports
export const AGENCY_REPORTS_READ: string = 'agency.reports.read';
// Amend agency reports
export const AGENCY_REPORTS_AMEND: string = 'agency.reports.amend';
// Download agency reports
export const AGENCY_REPORTS_DOWNLOAD: string = 'agency.reports.download';
// View the history of the agency reports (DOESN'T USE YET)
export const AGENCY_REPORTS_HISTORY_READ: string =
  'agency.reports.history.read';

// View the Agency analytics
export const AGENCY_ANALYTICS_READ: string = 'agency.analytics.read';
// Save my widgets settings (BE)
export const AGENCY_ANALYTICS_SETTINGS_CREATE: string =
  'agency.analytics.setting.create';
// this is an exception permission for the watcher role
export const AGENCY_ANALYTICS_WATCH: string = 'agency.analytics.watch';

/**
 * USER PERMISSIONS
 */

// Create their reports
export const USER_REPORTS_CREATE: string = 'self.reports.create';
// View their reports
export const USER_REPORTS_READ: string = 'self.reports.read';
// Amend their reports
export const USER_REPORTS_AMEND: string = 'self.reports.amend';
// Supervise user reports (Complaints and Investigations)
// View the history of their reports (DOESN'T USE YET)
export const USER_REPORTS_HISTORY_READ: string = 'self.reports.history.read';
