import { useContext, useMemo } from 'react';
import styles from './WidgetsFilter.module.scss';
import moment from 'moment';
import { SFButton } from 'sfui';
import { MediaContext } from 'ui-smartforce-settings';
import { formatUTCDateString } from '../../../../../Helpers';
import { RadioChipItem } from '../../../../../Components/RadioChip/RadioChip';
import { FilterChip, FilterChipList } from './FilterChipList/FilterChipList';
import { AnalyticsFiltersForm, AnalyticsPeriod } from '../../../../../Models';

function getListLabel<T extends { name: string }>(list: T[]): string {
  return list.map((item) => item.name).join(', ');
}

const getPeriodString = (period: AnalyticsPeriod): string => {
  const dateFormat: string = 'MMM D, YYYY';

  return `
    ${formatUTCDateString(period.from, dateFormat)}
    ${' - '}
    ${formatUTCDateString(period.to, dateFormat)}
  `;
};

const radioChipItems: RadioChipItem[] = [
  {
    label: 'Last week',
    value: 'weekly'
  },
  {
    label: 'Last month',
    value: 'monthly'
  }
];

function getDateChipLabel(
  filters: AnalyticsFiltersForm,
  period?: AnalyticsPeriod
): string {
  if (
    filters.period === 'custom' &&
    filters.dateRange?.from &&
    filters.dateRange.to
  ) {
    return `${moment(filters.dateRange.from).format('MMM D, YYYY')}-
      ${moment(filters.dateRange.to).format('MMM D, YYYY')}
    `;
  } else {
    const periodString = period ? `: ${getPeriodString(period)}` : '';
    const periodLabel = radioChipItems.find(
      (item) => item.value === filters.period
    )?.label;
    return periodLabel + periodString;
  }
}

function getChips(
  filters: AnalyticsFiltersForm,
  period?: AnalyticsPeriod
): FilterChip[] {
  let chips: FilterChip[] = [
    {
      key: 'period',
      label: getDateChipLabel(filters, period),
      deleteable: false
    }
  ];

  if (filters.officer) {
    chips = [
      ...chips,
      {
        key: 'officer',
        label: filters.officer.name,
        deleteable: true
      }
    ];
  }

  if (filters.group) {
    chips = [
      ...chips,
      {
        key: 'group',
        label: `Group: ${getListLabel(filters.group)}`,
        deleteable: true
      }
    ];
  }

  if (filters.area) {
    chips = [
      ...chips,
      {
        key: 'area',
        label: `Area: ${getListLabel(filters.area)}`,
        deleteable: true
      }
    ];
  }

  return chips;
}

export interface WidgetsFilterProps {
  className?: string;
  isLoading: boolean;
  period?: AnalyticsPeriod;
  filters: AnalyticsFiltersForm;
  onFilterBy: () => void;
  onFilterDelete: (key: string) => void;
}

export const WidgetsFilter = ({
  className = '',
  isLoading,
  period,
  filters,
  onFilterBy,
  onFilterDelete
}: WidgetsFilterProps): React.ReactElement<WidgetsFilterProps> => {
  const { isPhone } = useContext(MediaContext);

  const chips: FilterChip[] = useMemo(
    () => getChips(filters, period),
    [filters, period]
  );

  const onChipDelete = (chip: FilterChip) => onFilterDelete(chip.key);

  return (
    <div className={`${styles.widgetsFilter} ${className}`}>
      <div className={styles.filters}>
        <FilterChipList chips={chips} onDelete={onChipDelete} />
      </div>

      <SFButton
        sfColor="blue"
        variant="contained"
        disabled={isLoading}
        size={isPhone ? 'large' : 'medium'}
        fullWidth={isPhone}
        onClick={onFilterBy}
      >
        {`Filter by (${chips.length})`}
      </SFButton>
    </div>
  );
};
