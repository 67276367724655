import React from 'react';
import styles from './BasicFilters.module.scss';
import moment from 'moment';
import set from 'lodash.set';
import unset from 'lodash.unset';
import { SFText, SFPeopleOption, SFButton } from 'sfui';
import { PeoplePickerField } from '../../../ReportWizard/FieldInput/PeoplePickerField/PeoplePickerField';
import { FilterChipList } from '../FilterChipList/FilterChipList';
import { FilterDateRange } from '../FilterDateRange/FilterDateRange';
import { clearEmptyObjects, cloneObject } from '../../../../../Helpers';
import { RadioField } from '../../../ReportWizard/FieldInput/RadioField/RadioField';
import {
  DateRange,
  FiltersSubSectionValueType,
  ReportListFilters
} from '../../../../../Models';

export const BASIC_FILTERS_MIN_DATE: string = moment()
  .startOf('day')
  .subtract(1, 'months')
  .toISOString();

export const DEFAULT_FILTERS_MIN_DATE: string = '01/01/2022';

const CONTACT_TYPE_OPTIONS = ['All', 'Call For Service', 'Officer Pro-active'];
const REPORT_SECTION_OPTIONS = ['Use of Force', 'Complaints', 'Investigations'];

function getSelectedSections(value: ReportListFilters): string[] {
  let selected: string[] = [];
  if (value.use_of_force?.was_use_of_force === 'Yes') {
    selected.push('Use of Force');
  }
  if (value.complaints?.exists === 'Yes') {
    selected.push('Complaints');
  }
  if (value.investigations?.exists === 'Yes') {
    selected.push('Investigations');
  }

  return selected;
}

export interface BasicFiltersProps {
  hasAnalytics: boolean;
  value: ReportListFilters;
  onClear: () => void;
  onChange: (value: ReportListFilters) => void;
}

export const BasicFilters = ({
  hasAnalytics,
  value,
  onClear,
  onChange
}: BasicFiltersProps): React.ReactElement<BasicFiltersProps> => {
  const onPeoplePickerChange = (people: SFPeopleOption) => {
    const newValue = cloneObject(value);

    if (people) {
      set(newValue, 'officers.officer_information.id', people);
    } else {
      unset(newValue, 'officers.officer_information.id');
      clearEmptyObjects(newValue);
    }

    onChange(newValue);
  };

  const onDateRangeChange = (dateRange: DateRange) => {
    const newValue = cloneObject(value);
    if (!dateRange.dateFrom && !dateRange.dateTo && !dateRange.exactDate) {
      unset(newValue, 'general_information.date');
      clearEmptyObjects(newValue);
    } else {
      set(newValue, 'general_information.date', dateRange);
    }
    onChange(newValue);
  };

  const onContactTypeFilterChange = (contactType: string): void => {
    const newValue = cloneObject(value);
    set(newValue, 'general_information.type', contactType);
    onChange(newValue);
  };

  const onReportSectionsFilterChange = (selected: string[]) => {
    const newValue = cloneObject(value);

    if (selected.includes('Use of Force')) {
      set(newValue, 'use_of_force.was_use_of_force', 'Yes');
    } else {
      unset(newValue, 'use_of_force.was_use_of_force');
    }

    if (selected.includes('Complaints')) {
      set(newValue, 'complaints.exists', 'Yes');
    } else {
      unset(newValue, 'complaints.exists');
    }

    if (selected.includes('Investigations')) {
      set(newValue, 'investigations.exists', 'Yes');
    } else {
      unset(newValue, 'investigations.exists');
    }

    clearEmptyObjects(newValue);
    onChange(newValue);
  };

  const isClearButtonDisabled: boolean = Object.keys(value).length === 0;

  const dateFilterMessage: string = `Filter agency reports by a time period ${
    hasAnalytics ? 'up to one year.' : 'in the last month'
  }`;

  return (
    <div className={styles.basicFilters}>
      <div className={styles.section}>
        <SFText type="component-title">Officer</SFText>
        <PeoplePickerField
          activeOnly={false}
          value={
            (value.officers?.officer_information as FiltersSubSectionValueType)
              ?.id
          }
          label="Name"
          onChange={(value) => onPeoplePickerChange(value as SFPeopleOption)}
        />
      </div>
      <div className={styles.section}>
        <div className={styles.title}>
          <SFText type="component-title">Date</SFText>
          <SFText type="component-2">{dateFilterMessage}</SFText>
        </div>

        <FilterDateRange
          value={value.general_information?.date as DateRange}
          minDate={
            hasAnalytics ? DEFAULT_FILTERS_MIN_DATE : BASIC_FILTERS_MIN_DATE
          }
          onChange={onDateRangeChange}
        />
      </div>

      <div className={styles.section}>
        <SFText type="component-title">Suggested Filters</SFText>

        <div className={styles.chips}>
          <div className={styles.contactTypes}>
            <SFText type="component-2">Contact Type</SFText>
            <RadioField
              className={styles.radioButtons}
              label=""
              options={CONTACT_TYPE_OPTIONS}
              value={value.general_information?.type}
              onChange={(value) => onContactTypeFilterChange(value as string)}
            />
          </div>

          <FilterChipList
            title="Contact Sections"
            selected={getSelectedSections(value)}
            options={REPORT_SECTION_OPTIONS}
            onChange={(filter) => onReportSectionsFilterChange(filter)}
          />
        </div>
      </div>

      <div>
        <SFButton
          disabled={isClearButtonDisabled}
          variant="text"
          onClick={onClear}
        >
          Clear All
        </SFButton>
      </div>
    </div>
  );
};
