import React from 'react';
import styles from './ReportHeader.module.scss';
import { SFButton } from 'sfui';
import { Customer } from 'ui-smartforce-settings';
import { getStringAbbreviation } from '../../../../Helpers';
import { Divider } from '../../../Divider/Divider';
import { AmendDisabledTooltip } from '../../../AmendDisabledTooltip/AmendDisabledTooltip';

export interface ReportHeaderProps {
  customer: Customer;
  canAmmend: boolean;
  onAmend: () => void;
}

export const ReportHeader = ({
  customer,
  canAmmend,
  onAmend
}: ReportHeaderProps): React.ReactElement<ReportHeaderProps> => {
  const onAmendClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    onAmend();
  };

  return (
    <div className={styles.reportHeader}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.logo}>
            {customer.badge && (
              <img className={styles.badge} src={customer.badge} alt="" />
            )}
            {!customer.badge && (
              <p className={styles.abbreviation}>
                {getStringAbbreviation(customer.full_name as string)}
              </p>
            )}
          </div>
          <div className={styles.text}>
            <h5>{customer.full_name}</h5>
            <p>{customer.state_name}</p>
          </div>
        </div>

        <AmendDisabledTooltip isActive={!canAmmend}>
          <SFButton
            disabled={!canAmmend}
            size="small"
            variant="outlined"
            onClick={onAmendClick}
          >
            Amend Report
          </SFButton>
        </AmendDisabledTooltip>
      </div>

      <Divider size={2} />
    </div>
  );
};
