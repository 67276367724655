import React, { useContext } from 'react';
import { InputProps } from '../FieldInput';
import { SFPeopleOption, SFPeoplePicker } from 'sfui';
import { UserGroup } from 'ui-smartforce-settings';
import { UserGroupsContext } from '../../ContactFormView/ItemListForm/ItemListForm';

const getOptionSelected = (o: SFPeopleOption, v: SFPeopleOption): boolean => {
  return v.asyncObject.id === o.asyncObject.id;
};

const getPickerValue = (value: UserGroup[]): SFPeopleOption[] => {
  const sortedValue: SFPeopleOption[] = value.map((i) => ({
    name: i.name,
    asyncObject: {
      id: i.id
    }
  }));

  return sortedValue.sort((a: SFPeopleOption, b: SFPeopleOption) =>
    a.name.localeCompare(b.name)
  );
};

const getOptions = (groups: UserGroup[]): SFPeopleOption[] => {
  return groups.map((o: UserGroup) => ({
    name: o.name,
    acronym: o.acronym,
    avatarUrl: o.avatar_thumbnail_url,
    asyncObject: { id: o.id }
  }));
};

export interface GroupsFieldProps extends InputProps {}

export const GroupsField = (
  props: GroupsFieldProps
): React.ReactElement<GroupsFieldProps> => {
  const groups = useContext(UserGroupsContext);

  const onChange = (v: SFPeopleOption[]) => {
    props.onChange(
      v.map((i) => ({
        id: i.asyncObject.id,
        name: i.name
      }))
    );
  };

  const value: SFPeopleOption[] = props.value
    ? getPickerValue(props.value as UserGroup[])
    : [];

  const options: SFPeopleOption[] = getOptions(groups);

  return (
    <div
      style={{
        display: options.length === 0 && value.length === 0 ? 'none' : 'block'
      }}
    >
      <SFPeoplePicker
        label="Groups"
        isAsync={false}
        options={options}
        multiple
        value={value}
        onChange={onChange}
        getOptionSelected={getOptionSelected}
      />
    </div>
  );
};
