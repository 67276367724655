import { SFButton, SFIcon } from 'sfui';
import styles from './NoAnalyticsReady.module.scss';

export interface NoAnalyticsReadyProps {}

export const NoAnalyticsReady =
  (): React.ReactElement<NoAnalyticsReadyProps> => {
    const onClick = (): void => {
      const anchor = document.createElement('a');
      anchor.href = `${process.env.REACT_APP_CC_SUPPORT_URL}`;
      anchor.target = '_blank';
      anchor.click();
    };

    return (
      <div className={styles.noAnalyticsReady}>
        <div className={styles.content}>
          <SFIcon
            className={styles.icon}
            icon="Database-Preferences"
            size={96}
          />

          <h2 className={styles.title}>Your information is being processed.</h2>
          <p className={styles.subtitle}>
            In the meantime, we suggest you to view our documentation.
          </p>

          <SFButton
            className={styles.button}
            sfColor="blue"
            variant="contained"
            size="large"
            onClick={onClick}
          >
            View our Documentation
          </SFButton>
        </div>
      </div>
    );
  };
