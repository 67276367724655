import React, { useState } from 'react';
import styles from './RankingCarHeader.module.scss';
import { SFIcon, SFText, SFTooltip } from 'sfui';
import { isMobile } from '../../../../../Helpers';
import { InteractiveBox } from 'ui-smartforce-settings';

export interface RankingCarHeaderProps {
  elements_label: string;
  value_label: string;
  info_message: string;
}

export const RankingCarHeader = ({
  elements_label,
  value_label,
  info_message
}: RankingCarHeaderProps): React.ReactElement<RankingCarHeaderProps> => {
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);

  const onInfoClose = () => {
    if (isMobile()) {
      setIsTooltipOpen(false);
    }
  };

  const onInfoClick = () => {
    if (isMobile() && !isTooltipOpen) {
      setIsTooltipOpen(true);
    }
  };

  return (
    <div className={styles.rankingCarHeader}>
      <div className={styles.headerElementInfo}>
        <SFText type="component-2" sfColor="neutral">
          {elements_label}
        </SFText>

        <SFTooltip
          title={info_message}
          placement="bottom-start"
          open={isMobile() ? isTooltipOpen : undefined}
          leaveTouchDelay={3500}
          onClose={onInfoClose}
          disableHoverListener={isMobile()}
        >
          <InteractiveBox className={styles.icon} onClick={onInfoClick}>
            <SFIcon icon="Information" size={12} />
          </InteractiveBox>
        </SFTooltip>
      </div>

      <SFText type="component-2" sfColor="neutral">
        {value_label}
      </SFText>
    </div>
  );
};
