import React from 'react';
import styles from './OnboardingOwnerStart.module.scss';
import { SFBlueMainLight, SFButton, SFIcon, SFText } from 'sfui';

export interface OnboardingOwnerStartProps {
  isInactive: boolean;
  onClick: () => void;
}

export const OnboardingOwnerStart = ({
  isInactive = false,
  onClick
}: OnboardingOwnerStartProps): React.ReactElement<OnboardingOwnerStartProps> => {
  const title: string = isInactive
    ? 'Complete your Agency Setup'
    : 'Create your Agency';

  const message: string = isInactive
    ? 'Complete your agency setup to start using CitizenContact.'
    : 'Create your Agency to start inviting members.';

  const buttonLabel: string = isInactive
    ? 'Finish Agency Setup'
    : 'Create Agency';

  return (
    <div className={styles.onboardingOwnerStart}>
      <SFIcon
        className={styles.icon}
        icon="Users"
        size={96}
        color={SFBlueMainLight}
      />

      <h2 className={styles.title}>{title}</h2>

      <SFText className={styles.subtitle} type="component-2" sfColor="neutral">
        {message}
      </SFText>

      <SFButton onClick={onClick}>{buttonLabel}</SFButton>
    </div>
  );
};
