import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { AGENCY_REPORTS_READ } from '../Constants/RolesAndPermissions';
import {
  Subscription,
  User,
  checkPermissions,
  isPlanConnect,
  isRoleWatcher
} from 'ui-smartforce-settings';

export interface RouteMainDefaultProps extends RouteProps {
  subscription?: Subscription;
  url: string;
  user: User;
}

export const RouteMainDefault = ({
  subscription,
  url,
  user
}: RouteMainDefaultProps) => (
  <Route
    children={() => {
      if (
        subscription?.status === 'Canceled' ||
        subscription?.status === 'Unpaid'
      ) {
        return (
          <Redirect
            to={{
              pathname: `/subscription-${
                subscription.status === 'Canceled' ? 'canceled' : 'paused'
              }`
            }}
          />
        );
      }

      let redirectPath: string = 'onboarding';

      if (
        subscription?.status === 'Active' ||
        subscription?.status === 'Past Due'
      ) {
        if (isPlanConnect(subscription?.plan)) {
          redirectPath = 'business-card';
        } else if (isRoleWatcher(user.role.id)) {
          redirectPath = 'analytics';
        } else {
          const allowAgencyReports = checkPermissions(
            AGENCY_REPORTS_READ,
            user.role.permissions
          );

          redirectPath = allowAgencyReports ? 'agency-reports' : 'report';
        }
      }

      return <Redirect to={{ pathname: `${url}/${redirectPath}` }} />;
    }}
  ></Route>
);
