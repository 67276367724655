import React from 'react';
import styles from './LandingHowTo.module.scss';
import { SFButton } from 'sfui';
import { YouTubeEmbed } from '../../../Components/YouTubeEmbed/YouTubeEmbed';
import { LandingSectionName } from '../LandingSectionName/LandingSectionName';
import { LandingConfigHowTo } from '../../../Models/Landing';

export interface LandingHowToProps {
  config: LandingConfigHowTo;
}

export const LandingHowTo = ({
  config
}: LandingHowToProps): React.ReactElement<LandingHowToProps> => {
  const onSeeMore = () => {
    window.open(config.more_videos, '_blank');
  };

  return (
    <div className={styles.landingHowTo}>
      <LandingSectionName text={config.name} />

      <div className={styles.videos}>
        <YouTubeEmbed title={config.video_1.title} src={config.video_1.link} />
        <YouTubeEmbed title={config.video_2.title} src={config.video_2.link} />
        <YouTubeEmbed title={config.video_3.title} src={config.video_3.link} />
      </div>

      <SFButton size="large" onClick={onSeeMore}>
        See more videos
      </SFButton>
    </div>
  );
};
