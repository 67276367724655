import React, { useContext } from 'react';
import styles from './LandingTopBar.module.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { SFButton, SFIconButton } from 'sfui';
import { LinksPanel } from './LinksPanel/LinksPanel';
import { LinksBar } from './LinksBar/LinksBar';
import { MediaContext } from 'ui-smartforce-settings';

export interface HeaderLink {
  name: string;
  href: string;
  label: string;
  targetBlank?: boolean;
}

const topBarLinks: HeaderLink[] = [
  {
    name: 'product',
    label: 'Product',
    href: '/'
  },
  {
    name: 'pricing',
    label: 'Pricing',
    href: '/pricing',
    targetBlank: true
  },
  {
    name: 'faq',
    label: 'FAQs',
    href: 'https://support.citizencontact.app/',
    targetBlank: true
  },
  {
    name: 'contact_us',
    label: 'Contact Us',
    href: '/#contact-us'
  }
];

export interface LandingTopBarProps {
  currentSection?: string;
}

export const LandingTopBar = ({
  currentSection
}: LandingTopBarProps): React.ReactElement<LandingTopBarProps> => {
  const history = useHistory();
  const location = useLocation();
  const { isMediumScreen } = useContext(MediaContext);

  const getCurrentLink = (): string => {
    const currentLink = topBarLinks.find(
      (topBarLink: HeaderLink) => topBarLink.href === location.pathname
    );

    if (currentLink) {
      return currentLink.name;
    }

    return '/';
  };

  const selectedLink = currentSection ?? getCurrentLink();

  const [isPanelOpen, setIsPanelOpen] = React.useState<boolean>(false);

  const onItemClick = (link: HeaderLink) => {
    if (link.targetBlank) {
      window.open(link.href, '_blank');
    } else {
      history.replace(link.href);
    }
  };

  return (
    <div className={styles.landingTopBar}>
      <LinksPanel
        selected={selectedLink}
        isOpen={isPanelOpen}
        links={topBarLinks}
        onClick={onItemClick}
        onClose={() => setIsPanelOpen(false)}
      />
      <button className={styles.brandButton} onClick={() => history.push('/')}>
        <img
          className={styles.brandIcon}
          src="https://smartforceprodcdn.azureedge.us/smartforce/citizencontact/public/support/assets/icon.svg"
          alt="Brand Icon"
        />
      </button>

      <div className={styles.navigation}>
        {isMediumScreen && (
          <LinksBar
            selected={selectedLink}
            links={topBarLinks}
            onClick={onItemClick}
          />
        )}
        <SFButton sfColor="blue" onClick={() => history.push('/login')}>
          Log in
        </SFButton>
        {!isMediumScreen && (
          <SFIconButton
            sfIcon="Menu-1"
            iconSize="large"
            buttonSize="medium"
            onClick={() => setIsPanelOpen(true)}
            aria-label="Menu"
          />
        )}
      </div>
    </div>
  );
};
