import React, { useContext, useState, useCallback } from 'react';
import styles from './WidgetCard.module.scss';
import { SFCard } from 'sfui';
import { AnalyticsPeriod, Widget, WidgetComponent } from '../../Models/Widget';
import { ClusterMap } from './Widgets/ClusterMap/ClusterMap';
import { Doughnut } from './Widgets/Doughnut/Doughnut';
import { FilterMap } from './Widgets/FilterMap/FilterMap';
import { GradientLine } from './Widgets/GradientLine/GradientLine';
import { HeatMap } from './Widgets/HeatMap/HeatMap';
import { PatternMap } from './Widgets/PatternMap/PatternMap';
import { RateCard } from './Widgets/RateCard/RateCard';
import { Sankey } from './Widgets/Sankey/Sankey';
import { NoData } from './Widgets/NoData/NoData';
import {
  isValueEmpty,
  downloadCanvas,
  onShareWidgetClone,
  toCanvas,
  replaceSpecialChars
} from '../../Helpers';
import { WidgetCardHeader } from './WidgetCardHeader/WidgetCardHeader';
import { MediaContext } from 'ui-smartforce-settings';
import {
  WIDGET_CLUSTER_MAP,
  WIDGET_DOUGHNUT,
  WIDGET_FILTER_MAP,
  WIDGET_GRADIENT_LINE,
  WIDGET_HEAT_MAP,
  WIDGET_PATTERN_MAP,
  WIDGET_RANKING_CARD,
  WIDGET_RATE_CARD,
  WIDGET_SANKEY
} from '../../Constants/Analytics';
import { RankingCard } from './Widgets/RankingCard/RankingCard';

function getWidgetFileName(widget: Widget, period: AnalyticsPeriod): string {
  return `${period.from.substring(0, 10)}_${period.to.substring(
    0,
    10
  )}_${widget.component.name.replace(/_/g, '-')}_${replaceSpecialChars(
    widget.title,
    '-'
  )}`;
}

function isWithPadding(componentName: string): boolean {
  return (
    componentName !== WIDGET_GRADIENT_LINE && componentName !== WIDGET_SANKEY
  );
}

function hasDrawerOption(componentName: string): boolean {
  return (
    componentName === WIDGET_FILTER_MAP || componentName === WIDGET_PATTERN_MAP
  );
}

function getDrawerOptionLabel(componentName: string): string {
  switch (componentName) {
    case WIDGET_FILTER_MAP:
      return 'Filter by';
    case WIDGET_PATTERN_MAP:
      return 'See pattern';
    default:
      return '';
  }
}

const getComponent = (widget: Widget) => {
  const component: WidgetComponent = widget.component;

  switch (component.name) {
    case WIDGET_CLUSTER_MAP:
      return !isValueEmpty(component.config.mapData) ? (
        <ClusterMap {...component.config} data={component.config.mapData} />
      ) : (
        <NoData />
      );
    case WIDGET_DOUGHNUT:
      return !isValueEmpty(component.config.data) ? (
        <Doughnut {...component.config} />
      ) : (
        <NoData />
      );
    case WIDGET_FILTER_MAP:
      return !isValueEmpty(component.config.mapData) ? (
        <FilterMap
          {...component.config}
          title={widget.title}
          data={component.config.mapData}
        />
      ) : (
        <NoData />
      );
    case WIDGET_GRADIENT_LINE:
      return !isValueEmpty(component.config.data) ? (
        <GradientLine {...component.config} />
      ) : (
        <NoData />
      );
    case WIDGET_HEAT_MAP:
      return !isValueEmpty(component.config.mapData) ? (
        <HeatMap {...component.config} data={component.config.mapData} />
      ) : (
        <NoData />
      );
    case WIDGET_PATTERN_MAP:
      return !isValueEmpty(component.config.mapData) ? (
        <PatternMap
          {...component.config}
          title={widget.title}
          data={component.config.mapData}
        />
      ) : (
        <NoData />
      );
    case WIDGET_RANKING_CARD:
      return !isValueEmpty(component.config.ranking_list) ? (
        <RankingCard {...component.config} />
      ) : (
        <NoData />
      );
    case WIDGET_RATE_CARD:
      return !isValueEmpty(component.config.percentage) ? (
        <RateCard {...component.config} />
      ) : (
        <NoData hasImage={false} />
      );
    case WIDGET_SANKEY:
      return !isValueEmpty(component.config.nodes) &&
        !isValueEmpty(component.config.links) ? (
        <Sankey {...component.config} />
      ) : (
        <NoData />
      );
  }
};

export interface WidgetCardProps {
  widget: Widget;
  isActive?: boolean;
  period: AnalyticsPeriod;
  onAdd?: (id: number) => void;
  onRemove?: (id: number) => void;
}

export const WidgetCard = ({
  widget,
  isActive = false,
  period,
  onAdd,
  onRemove
}: WidgetCardProps): React.ReactElement<WidgetCardProps> => {
  const { isPhone } = useContext(MediaContext);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const widgetElementRef = React.useRef<HTMLElement>();

  const drawerOption =
    isPhone && hasDrawerOption(widget.component.name)
      ? {
          label: getDrawerOptionLabel(widget.component.name),
          onClick: () => setIsDrawerOpen(true)
        }
      : undefined;

  const onDrawerClose = () => setIsDrawerOpen(false);

  const onShare = async () => {
    if (widgetElementRef.current) {
      const canvas = await toCanvas(widgetElementRef.current, onCanvasClone);
      const fileName = getWidgetFileName(widget, period);
      downloadCanvas(canvas, fileName);
    }
  };

  const onCanvasClone = useCallback(
    (_d: Document, element: HTMLElement) => {
      // Change height of widgets with overflowed content
      const changeHeight =
        widget.component.name === 'doughnut' ||
        widget.component.name === 'filter_map' ||
        widget.component.name === 'pattern_map' ||
        widget.component.name === 'ranking_card' ||
        widget.component.name === 'rate_card';

      onShareWidgetClone(
        element,
        changeHeight,
        !widget.component.name.includes('map')
      );
    },
    [widget.component.name]
  );

  return (
    <SFCard
      containerClassName={styles.widgetCard}
      sfElevation={2}
      ref={widgetElementRef}
    >
      <WidgetCardHeader
        title={widget.title}
        isActive={isActive}
        drawerMenuOption={drawerOption}
        onAdd={() => onAdd && onAdd(widget.id)}
        onRemove={() => onRemove && onRemove(widget.id)}
        onShare={onShare}
      />

      <div
        className={`${styles.content} ${
          isWithPadding(widget.component.name) ? styles.isWithPadding : ''
        }`}
      >
        {React.cloneElement(getComponent(widget), {
          isDrawerOpen,
          onDrawerClose
        })}
      </div>
    </SFCard>
  );
};
