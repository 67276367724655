import React from 'react';
import styles from './BusinessCardSection.module.scss';
import { BusinessCard } from 'business-card-component';
import { SFButton, SFScrollable } from 'sfui';
import { Divider } from '../../../Components/Divider/Divider';
import {
  UserContext,
  CustomerContext,
  Customer,
  User,
  MediaContext,
  getBusinessCardData,
  ThemeTypeContext,
  QRCodeModal,
  UserSettings
} from 'ui-smartforce-settings';
import { useHistory } from 'react-router-dom';
import { OnlineStatusContext } from '../../../Context/OnlineStatusContext';

export const BusinessCardSection = (): React.ReactElement<{}> => {
  const history = useHistory();
  const user = React.useContext(UserContext).user as User;
  const customer = React.useContext(CustomerContext).customer as Customer;
  const { themeType } = React.useContext(ThemeTypeContext);
  const businessCardSettings = (
    React.useContext(UserContext).userSettings as UserSettings
  ).business_card;
  const { isMediumScreen } = React.useContext(MediaContext);
  const { isOnline } = React.useContext(OnlineStatusContext);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const onEdit = () => {
    // Added state to identify the section to open
    history.push('/cc/settings', { section: 'business_card' });
  };

  return (
    <div className={styles.businessCardSection}>
      <QRCodeModal
        baseUrl={process.env.REACT_APP_CC_OFFICER_CARD_URL as string}
        user={user}
        title="Scan the QR code"
        subTitle={`Citizen can scan this QR code to get officer's information.`}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <SFScrollable containerClassName={styles.scrollable}>
        <div className={styles.buttons}>
          <SFButton
            disabled={!isOnline}
            size="large"
            variant="outlined"
            onClick={onEdit}
          >
            Edit Business Card
          </SFButton>
          <SFButton size="large" onClick={() => setIsModalOpen(true)}>
            Share Business Card
          </SFButton>
        </div>
        <Divider className={styles.divider} />
        <div className={styles.businessCard}>
          <BusinessCard
            mediaType={isMediumScreen ? 'desktop' : 'mobile'}
            themeType={themeType}
            data={getBusinessCardData(businessCardSettings, user, customer)}
          />
        </div>
      </SFScrollable>
    </div>
  );
};
