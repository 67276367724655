import React from 'react';
import styles from './WizardCardStepContainer.module.scss';
import { WizardCardHeader } from './WizardCardHeader/WizardCardHeader';
import { WizardCardStepProps } from '../WizardCardStep/WizardCardStep';

export interface WizardCardStepContainerProps {
  children: React.ReactElement<WizardCardStepProps>;
  className?: string;
  onBack?: () => void;
  onClose?: () => void;
}

export const WizardCardStepContainer = ({
  children,
  onBack,
  onClose
}: WizardCardStepContainerProps): React.ReactElement<WizardCardStepContainerProps> => {
  return (
    <div
      className={`${styles.wizardCardStepContainer} ${
        !children.props.noHeader ? styles.withHeader : ''
      }`}
    >
      {!children.props.noHeader && (
        <WizardCardHeader
          onBack={onBack}
          onClose={onClose}
          step={children.props.step}
          limit={children.props.limit}
          title={children.props.title}
        />
      )}

      {children}
    </div>
  );
};
