import React, { useRef } from 'react';
import { SFChip } from 'sfui';
import { DURATION_HELP_MSG } from '../../../../../Constants/Messages';
import { isDurationValid } from '../../../../../Helpers';
import { FieldValueType } from '../../../../../Models';
import { NumberField } from '../../FieldInput/NumberField/NumberField';
import styles from './DurationForm.module.scss';
import { isFieldEmpty } from 'ui-smartforce-settings';

export interface DurationFormProps {
  duration: number;
  value: string;
  onChange: (value: string) => void;
}

export const DurationForm = ({
  duration,
  value,
  onChange
}: DurationFormProps): React.ReactElement<DurationFormProps> => {
  const refPristine = useRef<boolean>(isFieldEmpty(value));

  const durationLabel: string = `${duration} minute${
    duration === 1 ? '' : 's'
  }`;

  const onFieldChange = (value: FieldValueType) => {
    if (refPristine.current) {
      refPristine.current = false;
    }
    onChange(value as string);
  };

  return (
    <div className={styles.durationForm}>
      <div className={styles.durationLabel}>
        <h3>Duration</h3>
        <SFChip sfColor="default" label={durationLabel} />
      </div>

      <NumberField
        label="Duration in Minutes"
        required
        value={value}
        error={!refPristine.current && !isDurationValid(value)}
        helperText={DURATION_HELP_MSG}
        onChange={onFieldChange}
      />
    </div>
  );
};
