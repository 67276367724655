import React from 'react';
import styles from './DownloadReportsModalContent.module.scss';
import { UserContext } from 'ui-smartforce-settings';

export const DownloadReportsModalContent = (): React.ReactElement<{}> => {
  const { user } = React.useContext(UserContext);

  return (
    <p className={styles.downloadReportsModalContent}>
      The information you have requested will be sent to:{' '}
      <span className={styles.userEmail}>{user?.email}</span>
    </p>
  );
};
