import {
  BillingCycleType,
  BillingFormValue,
  PLAN_CONNECT
} from 'ui-smartforce-settings';

export interface OnboardingData {
  step: number;
  plan: string;
  billing_cycle: BillingCycleType;
  additional_seats: number;
}

export function getData(userId: string): OnboardingData {
  const dataSaved = localStorage.getItem(
    `Smartforce.CitizenContact.OnboardingData.${userId}`
  );

  return dataSaved
    ? JSON.parse(dataSaved)
    : {
        step: 1,
        plan: PLAN_CONNECT,
        billing_cycle: 'annually',
        additional_seats: 0
      };
}

export function saveOnboardingStep(userId: string, step: number): void {
  const data = getData(userId);
  localStorage.setItem(
    `Smartforce.CitizenContact.OnboardingData.${userId}`,
    JSON.stringify({
      ...data,
      step
    })
  );
}

export function saveOnboardingPlan(userId: string, plan: string): void {
  const data = getData(userId);
  localStorage.setItem(
    `Smartforce.CitizenContact.OnboardingData.${userId}`,
    JSON.stringify({
      ...data,
      plan
    })
  );
}

export function saveOnboardingBilling(
  userId: string,
  billing: BillingFormValue
): void {
  const data = getData(userId);
  localStorage.setItem(
    `Smartforce.CitizenContact.OnboardingData.${userId}`,
    JSON.stringify({
      ...data,
      ...billing
    })
  );
}

export function removeData(userId: string): void {
  localStorage.removeItem(`Smartforce.CitizenContact.OnboardingData.${userId}`);
}
