import React from 'react';
import { SFAlertDialog } from 'sfui';
import { upperFirstChar } from '../../../../Helpers';

export interface CancelModalProps {
  isOpen: boolean;
  isUpdate?: boolean;
  onDiscard: () => void;
  onClose: () => void;
}

const CancelModal = ({
  isOpen,
  isUpdate = false,
  onDiscard,
  onClose
}: CancelModalProps): React.ReactElement<CancelModalProps> => {
  const label: string = isUpdate ? 'changes' : 'report';

  return (
    <SFAlertDialog
      open={isOpen}
      title={`Discard ${label}?`}
      contentText={`Your ${label} will be discarded and ${
        isUpdate ? '' : 'all its '
      }information will be deleted.`}
      leftAction={{
        label: 'Close',

        buttonProps: { onClick: onClose }
      }}
      rightAction={{
        label: `Discard ${upperFirstChar(label)}`,
        buttonProps: { sfColor: 'red', onClick: onDiscard }
      }}
    />
  );
};

export default CancelModal;
