import React from 'react';
import { SFPeopleOption, SFPeoplePicker } from 'sfui';
import { LocationAreaType } from '../../../../../Models';
import { Area } from 'ui-smartforce-settings';

const formatOptionsToPeoplePicker = (options: Area[]): SFPeopleOption[] => {
  return options.map((o: Area) => {
    return {
      name: o.name,
      acronym: o.acronym,
      asyncObject: { id: o.id }
    };
  });
};

const formatValueToPeoplePicker = (
  value: LocationAreaType[]
): SFPeopleOption[] => {
  return value.map((v: LocationAreaType) => {
    return {
      name: v.name,
      asyncObject: { id: v.id }
    };
  });
};

const formatPeoplePickerToValue = (
  value: SFPeopleOption[]
): LocationAreaType[] => {
  return value.map((v: SFPeopleOption) => ({
    id: v.asyncObject.id,
    name: v.name
  }));
};

const getOptionSelected = (option: SFPeopleOption, value: SFPeopleOption) => {
  return option.name === value.name;
};

export interface AreasFormProps {
  options: Area[];
  onChange: (value: LocationAreaType[]) => void;
  value?: LocationAreaType[];
}

export const AreasForm = ({
  options,
  onChange,
  value
}: AreasFormProps): React.ReactElement<AreasFormProps> => {
  const onPeoplePickerChange = (value: SFPeopleOption[]) => {
    onChange(formatPeoplePickerToValue(value));
  };

  return (
    <SFPeoplePicker
      label="Areas"
      isAsync={false}
      options={formatOptionsToPeoplePicker(options)}
      multiple={true}
      value={value ? formatValueToPeoplePicker(value) : []}
      getOptionSelected={getOptionSelected}
      onChange={onPeoplePickerChange}
    />
  );
};
