import React from 'react';
import styles from './NoData.module.scss';
import { SFGrey, SFIcon } from 'sfui';
import { ThemeTypeContext } from 'ui-smartforce-settings';

export interface NoDataProps {
  hasImage?: boolean;
}

export const NoData = ({
  hasImage = true
}: NoDataProps): React.ReactElement<NoDataProps> => {
  const { themeType } = React.useContext(ThemeTypeContext);

  return (
    <div className={`${styles.noData} ${!hasImage && styles.noImage}`}>
      {hasImage && (
        <SFIcon
          icon="Database-Warning"
          size={72}
          color={themeType === 'day' ? SFGrey['200'] : SFGrey['700']}
        />
      )}
      <div className={styles.content}>
        <div className={styles.title}>No Data</div>
        <p className={styles.description}>
          There is no data to display in this widget.
        </p>
      </div>
    </div>
  );
};
