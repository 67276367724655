import React from 'react';
import { MessageSupport } from '../../../Components/MessageSupport/MessageSupport';
import styles from './ForgotPasswordSent.module.scss';

export interface ForgotPasswordSentProps {
  email: string;
}

export const ForgotPasswordSent = ({
  email
}: ForgotPasswordSentProps): React.ReactElement<ForgotPasswordSentProps> => {
  return (
    <div className={styles.forgotPasswordSent}>
      <h2 className={styles.title}>Email sent</h2>

      <p className={styles.message}>
        An email with instructions to reset your password was sent to:{' '}
        <span className={styles.email}>{email}</span>
      </p>
      <MessageSupport />
    </div>
  );
};
