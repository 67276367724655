import React from 'react';
import styles from './MyReports.module.scss';

import Loader from '../../../Components/Loader/Loader';
import { ReportList } from '../../../Components/ReportList/ReportList';
import { ReportValue, ReportResponse } from '../../../Models';
import {
  getReportsByUrl,
  getUserReports
} from '../../../Services/ReportService';
import { NoReports } from '../../../Components/NoReports/NoReports';
import { useHistory } from 'react-router-dom';
import { OnlineStatusContext } from '../../../Context/OnlineStatusContext';
import { SFSpinner, SFText } from 'sfui';
import { handleError } from '../../../Helpers/errors';
import { USER_REPORTS_AMEND } from '../../../Constants/RolesAndPermissions';
import {
  UserContext,
  checkPermissions,
  useCloseTour,
  useIsMounted
} from 'ui-smartforce-settings';
import { UPDATE_REPORT_LIST } from '../../../Constants/Events';
import { useDispatchMainAlert } from '../../../Hooks';
import { IntersectionObserverHost } from '../../../IntersectionObserver';

export const MyReports = (): React.ReactElement<{}> => {
  const history = useHistory();
  const { user } = React.useContext(UserContext);

  const { isOnline } = React.useContext(OnlineStatusContext);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isFetchingMoreReports, setIsFetchingMoreReports] =
    React.useState<boolean>(true);
  const [reports, setReports] = React.useState<ReportValue[]>([]);

  const refNextUrl = React.useRef<string | undefined>();
  const isMounted = useIsMounted();

  useDispatchMainAlert();

  useCloseTour([{ tourId: 10, step: 1 }]);

  React.useEffect(() => {
    const getReports = async () => {
      try {
        setIsLoading(true);
        const response: ReportResponse = await getUserReports();

        if (isMounted()) {
          refNextUrl.current = response.links.next;
          setReports(response.reports);
          setIsLoading(false);
          setIsFetchingMoreReports(false);
        }
      } catch (e) {
        console.error('MyReports::getReports', e);
        handleError(e, history);
      }
    };

    if (isOnline) getReports();

    document.addEventListener(UPDATE_REPORT_LIST, getReports);

    return () => {
      document.removeEventListener(UPDATE_REPORT_LIST, getReports);
    };
  }, [history, isOnline, isMounted]);

  const onScrollBottom = async () => {
    if (refNextUrl.current && !isFetchingMoreReports) {
      setIsFetchingMoreReports(true);

      try {
        const response = await getReportsByUrl(refNextUrl.current);

        if (isMounted()) {
          setReports([...reports, ...response.reports]);
          setIsFetchingMoreReports(false);
          refNextUrl.current = response.links.next;
        }
      } catch (e) {
        console.error('MyReports::getMoreReports', e);
        handleError(e, history);
      }
    }
  };

  const showAmendButton: boolean = checkPermissions(
    USER_REPORTS_AMEND,
    user?.role?.permissions
  );

  const hasReports: boolean = reports.length > 0;

  return (
    <div className={styles.myReports}>
      {isLoading && <Loader />}
      {!isLoading && !hasReports && <NoReports />}

      {!isLoading && hasReports && (
        <IntersectionObserverHost
          hostClassName={styles.scrollable}
          onScrollBottom={onScrollBottom}
        >
          <SFText type="component-2" sfColor="neutral">
            SORTED BY NEWEST
          </SFText>

          <ReportList
            reports={reports}
            reportListType="me"
            showAmend={showAmendButton}
          />

          {isFetchingMoreReports && (
            <div className={styles.moreReportsSpinner}>
              <SFSpinner className={styles.spinner} size={40} />
            </div>
          )}
        </IntersectionObserverHost>
      )}
    </div>
  );
};
