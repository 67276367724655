import React from 'react';
import styles from './LandingFeatures.module.scss';
import { LandingSectionName } from '../LandingSectionName/LandingSectionName';
import { LandingFeatureList } from './LandingFeatureList/LandingFeatureList';
import { LandingFeatureListItem } from './LandingFeatureList/LandingFeatureListItem/LandingFeatureListItem';
import { LandingConfigFeatures } from '../../../Models/Landing';

export interface LandingFeatureProps {
  config: LandingConfigFeatures;
}
export const LandingFeatures = ({
  config
}: LandingFeatureProps): React.ReactElement<LandingFeatureProps> => {
  return (
    <div className={styles.landingFeatures}>
      <LandingSectionName text={config.name} />

      <div className={styles.features}>
        <LandingFeatureList title={config.first.title}>
          {config.first.items.map((item) => (
            <LandingFeatureListItem key={item.title}>
              <b>{item.title}:</b> {item.description}
            </LandingFeatureListItem>
          ))}
        </LandingFeatureList>

        <LandingFeatureList title={config.second.title}>
          {config.second.items.map((item) => (
            <LandingFeatureListItem key={item.title}>
              <b>{item.title}:</b> {item.description}
            </LandingFeatureListItem>
          ))}
        </LandingFeatureList>

        <LandingFeatureList title={config.third.title}>
          {config.third.items.map((item) => (
            <LandingFeatureListItem key={item.title}>
              <b>{item.title}:</b> {item.description}
            </LandingFeatureListItem>
          ))}
        </LandingFeatureList>
      </div>
    </div>
  );
};
