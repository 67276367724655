import React, { useRef } from 'react';
import { cloneDiv } from '../../../Helpers';
import styles from './MasonryElement.module.scss';

export type MasonryElementSize = 'xs' | 'sm' | 'md' | 'lg';

export interface MasonryElementProps {
  id: number;
  children: React.ReactNode;
  size?: MasonryElementSize;
  draggable?: boolean;
  masonryId?: string;
  onDragStart?: (e: React.DragEvent<HTMLDivElement>) => void;
  onDrop?: (e: React.DragEvent<HTMLDivElement>) => void;
  getDragImageClone?: (target: HTMLDivElement) => HTMLDivElement;
}

export const MasonryElement = ({
  children,
  size = 'xs',
  draggable = false,
  ...props
}: MasonryElementProps): React.ReactElement<MasonryElementProps> => {
  const colStyle = styles[`${size}`];

  const [isDragging, setIsDragging] = React.useState<boolean>(false);
  const [isDragEnter, setIsDragEnter] = React.useState<boolean>(false);

  const refCount = useRef<number>(0);
  const refClone = useRef<HTMLDivElement>();

  const onDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    const target: HTMLDivElement = e.target as HTMLDivElement;
    const rect = target.getBoundingClientRect();

    refClone.current = props.getDragImageClone
      ? props.getDragImageClone(target)
      : cloneDiv(target);

    refClone.current.style.width = `${target.offsetWidth}px`;
    refClone.current.style.height = `${target.offsetHeight}px`;
    refClone.current.style.position = 'absolute';
    refClone.current.style.top = `-100%`;

    document.body.appendChild(refClone.current);

    e.dataTransfer.setDragImage(
      refClone.current,
      e.clientX - rect.x,
      e.clientY - rect.y
    );
    e.dataTransfer.effectAllowed = 'move';
    setIsDragging(true);
    props.onDragStart && props.onDragStart(e);
  };

  const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();

    const dragMasonryId = e.dataTransfer.types[0];
    refCount.current++;
    if (!isDragEnter && dragMasonryId === props.masonryId) {
      setIsDragEnter(true);
    }
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    const dragMasonryId = e.dataTransfer.types[0];
    refCount.current = 0;
    if (dragMasonryId === props.masonryId) {
      setIsDragEnter(false);
      props.onDrop && props.onDrop(e);
    }
  };

  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    refCount.current--;
    if (refCount.current === 0) {
      setIsDragEnter(false);
    }
  };

  const onDragEnd = () => {
    if (refClone.current) {
      document.body.removeChild(refClone.current);
    }
    setIsDragging(false);
  };

  const dragProps = draggable
    ? {
        onDragStart,
        onDragEnter,
        onDragOver,
        onDragLeave,
        onDragEnd,
        onDrop
      }
    : [];

  return (
    <div
      className={`${styles.masonryElement} ${colStyle} ${
        isDragEnter ? styles.isDragEnter : ''
      } ${isDragging ? styles.isDragging : ''}`}
    >
      <div
        className={styles.dragContainer}
        draggable={draggable}
        {...dragProps}
      >
        {children}
      </div>
    </div>
  );
};
