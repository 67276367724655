import React from 'react';
import styles from './ErrorPage.module.scss';
import { SFPaper, SFIcon, SFLink, SFButton, SFText } from 'sfui';

export interface ErrorPageProps {
  className?: string;
  icon: string;
  title: string;
  description?: string;
  buttonText?: string;
  onGoHome: () => void;
}

const ErrorPage = ({
  className = '',
  icon,
  title,
  description,
  buttonText = 'Go to Home',
  onGoHome
}: ErrorPageProps): React.ReactElement<ErrorPageProps> => {
  return (
    <SFPaper className={`${className} ${styles.errorPage}`}>
      <div className={styles.content}>
        <SFIcon className={styles.icon} icon={icon} size={96} />

        <h1 className={styles.title}>{title}</h1>

        {description && (
          <SFText className={styles.description} type="component-1">
            {description}
          </SFText>
        )}

        <SFButton
          className={styles.button}
          sfColor="blue"
          variant="outlined"
          size="large"
          onClick={onGoHome}
        >
          {buttonText}
        </SFButton>
      </div>

      <div className={styles.contact}>
        <p>Would you like to contact us?</p>
        <p>Click on the link below.</p>
        <br />
        <SFLink
          color="primary"
          sfSize="medium"
          href="https://smartforce.atlassian.net/servicedesk/customer/portals"
          target="_blank"
          rel="noreferrer"
        >
          SmartForce® Support
        </SFLink>
      </div>
    </SFPaper>
  );
};

export default ErrorPage;
