import React, { Fragment } from 'react';
import { SFIconButton, SFMenu, SFMenuItem } from 'sfui';

export interface MoreOptionsProps {
  isDownloadDisabled: boolean;
  onDownload: () => void;
}

export const MoreOptions = (
  props: MoreOptionsProps
): React.ReactElement<{}> => {
  const [menuAnchorElement, setMenuAnchorElement] = React.useState<
    HTMLButtonElement | undefined
  >(undefined);

  const onMenuOpen = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setMenuAnchorElement(e.currentTarget);
  };

  const onMenuClose = () => setMenuAnchorElement(undefined);

  const onDownload = () => {
    onMenuClose();
    props.onDownload();
  };

  return (
    <Fragment>
      <SFIconButton
        rotate="left"
        sfIcon="Other"
        sfSize="small"
        onClick={onMenuOpen}
        aria-label="More Options"
      />

      <SFMenu
        autoFocus={false} // eslint-disable-line jsx-a11y/no-autofocus
        anchorEl={menuAnchorElement}
        open={Boolean(menuAnchorElement)}
        onClose={onMenuClose}
        variant="menu"
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <SFMenuItem disabled={props.isDownloadDisabled} onClick={onDownload}>
          Download Reports
        </SFMenuItem>
      </SFMenu>
    </Fragment>
  );
};
