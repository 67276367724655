import React, { ReactElement } from 'react';
import styles from './WidgetPanel.module.scss';
import { Transition } from 'react-transition-group';
import { SFGrey, SFIcon } from 'sfui';
import { InteractiveBox } from 'ui-smartforce-settings';

const TRANSITION_TIMEOUT = 120;

const defaultStyle: React.CSSProperties = {
  width: '50px',
  transition: `width ${TRANSITION_TIMEOUT}ms ease-in-out`
};

const transitionStyles: { [index: string]: React.CSSProperties } = {
  entering: { width: 'calc(50% + 50px)' },
  entered: { width: 'calc(50% + 50px)' },
  exited: { width: '50px' }
};

const onTransitionEnter = (node: HTMLElement): void => {
  node.style.height = '100%';
  node.style.top = '0';
};

const onTransitionExited = (node: HTMLElement): void => {
  setTimeout(() => {
    node.style.height = '218px';
    node.style.top = 'calc(50% - 109px)';
  }, TRANSITION_TIMEOUT);
};
export interface WidgetPanelProps {
  isOpen: boolean;
  onToggle: () => void;
  children: ReactElement;
}

export const WidgetPanel = ({
  isOpen,
  onToggle,
  children
}: WidgetPanelProps): React.ReactElement<WidgetPanelProps> => {
  return (
    <Transition
      appear
      in={isOpen}
      timeout={{
        enter: TRANSITION_TIMEOUT,
        exit: TRANSITION_TIMEOUT
      }}
      onEnter={onTransitionEnter}
      onExited={onTransitionExited}
    >
      {(state) => (
        <div
          className={styles.widgetPanel}
          style={{
            ...defaultStyle,
            ...transitionStyles[state as string]
          }}
        >
          <InteractiveBox
            className={styles.openBtn}
            onClick={onToggle}
            role="button"
            aria-label={`${isOpen ? 'Close' : 'Open'} Panel`}
          >
            <SFIcon
              icon={isOpen ? 'Right-2' : 'Left-2'}
              size={26}
              color={SFGrey[600]}
            />
          </InteractiveBox>

          {React.cloneElement(children, {
            className: styles.panel
          })}
        </div>
      )}
    </Transition>
  );
};
