import React from 'react';
import styles from './WidgetCardHeader.module.scss';
import { SFChip, SFDivider, SFIconButton, SFMenu, SFMenuItem } from 'sfui';
import { TextOverflowTooltip } from '../../TextOverflowTooltip/TextOverflowTooltip';

export interface WidgetCardHeaderMenuOption {
  label: string;
  onClick: () => void;
}

export interface WidgetCardHeaderProps {
  title: string;
  isActive?: boolean;
  drawerMenuOption?: WidgetCardHeaderMenuOption;
  onAdd?: () => void;
  onRemove?: () => void;
  onShare?: () => void;
}

export const WidgetCardHeader = ({
  title,
  isActive = false,
  drawerMenuOption,
  onAdd,
  onRemove,
  onShare
}: WidgetCardHeaderProps): React.ReactElement<WidgetCardHeaderProps> => {
  const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<any>();

  const onClick = (e: any) => {
    if (!isMenuOpen) {
      setAnchorEl(e.currentTarget);
      setIsMenuOpen(true);
    }
  };

  return (
    <div className={styles.widgetCardHeader}>
      <TextOverflowTooltip title={title}>
        <h3>{title}</h3>
      </TextOverflowTooltip>

      <SFIconButton
        sfIcon="Other"
        sfSize="small"
        rotate="right"
        onClick={onClick}
        aria-label="More Options"
      />

      <SFMenu
        MenuListProps={{ className: styles.menu }}
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={() => setIsMenuOpen(false)}
      >
        {drawerMenuOption && (
          <SFMenuItem
            onClick={() => {
              drawerMenuOption.onClick();
              setIsMenuOpen(false);
            }}
          >
            {drawerMenuOption.label}
          </SFMenuItem>
        )}

        <SFMenuItem
          className={styles.shareItem}
          onClick={() => {
            setIsMenuOpen(false);
            onShare && onShare();
          }}
        >
          Download
          <SFChip sfColor="default" label="BETA" />
        </SFMenuItem>

        <SFDivider />

        {!isActive && <SFMenuItem onClick={onAdd}>Add</SFMenuItem>}
        {isActive && <SFMenuItem onClick={onRemove}>Remove</SFMenuItem>}
      </SFMenu>
    </div>
  );
};
