import React from 'react';
import { ItemListConfig, ItemValueType } from '../../../../../Models';
import { Item } from './Item/Item';
import styles from './ItemList.module.scss';

export interface ItemListProps {
  items: ItemValueType[];
  itemsName: string;
  config: ItemListConfig;
}

export const ItemList = ({
  items,
  itemsName,
  config
}: ItemListProps): React.ReactElement<ItemListProps> => {
  return (
    <div className={styles.itemList}>
      <h3 className={styles.title}>{config.title}</h3>
      {items.map((value: ItemValueType, index: number) => (
        <Item
          key={`${itemsName}_${index + 1}`}
          title={`${itemsName} ${index + 1}`}
          item={value}
          config={config}
        />
      ))}
    </div>
  );
};
