import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import styles from './SetLocationModal.module.scss';
import { SFBlueMainLight, SFIcon, SFText, SFTextField } from 'sfui';
import { DeviceLocationContext } from '../../ReportWizard';
import { getStateCoords } from '../../../../../Helpers';
import {
  CustomerContext,
  GeoLocationCoords,
  LocationAddressType,
  State,
  StatesListConfigContext,
  Customer,
  PanelModal,
  GoogleMap
} from 'ui-smartforce-settings';

function getCenter(
  coords: GeoLocationCoords | undefined,
  deviceLocation: GeoLocationCoords | undefined,
  customer: Customer,
  statesList: State[]
) {
  const centerCoords =
    coords ??
    deviceLocation ??
    customer.address?.coords ??
    getStateCoords(statesList, customer.state_name);

  return new google.maps.LatLng({
    lat: centerCoords.latitude,
    lng: centerCoords.longitude
  });
}

function getIsDisabled(
  coords: GeoLocationCoords | undefined,
  center: google.maps.LatLngLiteral | undefined,
  defaultAddress: string,
  address: string
): boolean {
  return (
    address.length === 0 ||
    !center ||
    (defaultAddress === address &&
      coords?.latitude === center.lat &&
      coords?.longitude === center.lng)
  );
}

export interface SetLocationModalProps {
  defaultAddress: string;
  coords?: GeoLocationCoords;
  isOpen: boolean;
  onConfirm: (value: LocationAddressType) => void;
  onClose: () => void;
}

export const SetLocationModal = ({
  defaultAddress,
  coords,
  isOpen,
  onConfirm,
  onClose
}: SetLocationModalProps): React.ReactElement<SetLocationModalProps> => {
  const deviceLocation = useContext(DeviceLocationContext);
  const customer = useContext(CustomerContext).customer as Customer;
  const statesList = useContext(StatesListConfigContext).statesList;
  const [address, setAddress] = useState<string>(defaultAddress);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const refCenter = useRef<google.maps.LatLngLiteral | undefined>();

  useEffect(() => {
    setAddress(defaultAddress);
  }, [defaultAddress]);

  useEffect(() => {
    if (isOpen) {
      setIsDisabled(true);
    }
  }, [isOpen]);

  const center = useMemo(
    () => getCenter(coords, deviceLocation, customer, statesList),
    [coords, deviceLocation, customer, statesList]
  );

  useEffect(() => {
    refCenter.current = center.toJSON();
  }, [center]);

  const onCenterChange = useCallback(
    (center: google.maps.LatLngLiteral | undefined) => {
      refCenter.current = center;
      setIsDisabled(
        getIsDisabled(coords, refCenter.current, defaultAddress, address)
      );
    },
    [address, coords, defaultAddress]
  );

  const onAddressChange: React.ChangeEventHandler<HTMLTextAreaElement> = (
    e
  ) => {
    setAddress(e.target.value);
    setIsDisabled(
      getIsDisabled(coords, refCenter.current, defaultAddress, e.target.value)
    );
  };

  const onDiscard = () => {
    setAddress(defaultAddress);
    refCenter.current = center.toJSON();
    onClose();
  };

  return (
    <PanelModal
      classes={{
        dialog: {
          root: styles.setLocationModal,
          paper: styles.dialogPaper,
          container: styles.dialogContainer
        },
        drawer: {
          paper: styles.setLocationModal
        }
      }}
      dialogCloseButton={{
        label: 'Discard',
        variant: 'text',
        sfColor: 'grey',
        onClick: onDiscard
      }}
      actionButton={{
        label: 'Confirm Location',
        disabled: isDisabled,
        onClick: () =>
          onConfirm({
            full: address,
            coords: {
              latitude: (refCenter.current as google.maps.LatLngLiteral).lat,
              longitude: (refCenter.current as google.maps.LatLngLiteral).lng
            }
          })
      }}
      isOpen={isOpen}
      onClose={onDiscard}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <SFText type="component-title">Set your location</SFText>
          <SFText type="component-2" sfColor="neutral">
            By moving the map, you can set the location using the marker.
          </SFText>
        </div>

        <div className={styles.form}>
          <div className={styles.map}>
            <SFIcon
              className={styles.marker}
              icon="Loction-1"
              size={46}
              color={SFBlueMainLight}
            />

            <GoogleMap
              center={center}
              zoom={16}
              onCenterChange={onCenterChange}
            />
          </div>

          <SFTextField
            label="Location"
            required
            multiline
            inputProps={{ maxLength: 100 }}
            rows={1}
            helperText="It must be between 1 and 100 characters."
            value={address}
            onChange={onAddressChange}
          />
        </div>
      </div>
    </PanelModal>
  );
};
