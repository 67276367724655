import React, { useContext } from 'react';
import { SFPaper, SFScrollable, SFScrollableRefHandler } from 'sfui';
import styles from './AuthContainer.module.scss';
import { Brand } from '../Brand/Brand';
import { OnlineStatusContext } from '../../Context/OnlineStatusContext';

export interface AuthContainerProps {
  children: React.ReactElement;
}
export const AuthContainer = ({
  children
}: AuthContainerProps): React.ReactElement<AuthContainerProps> => {
  const { isOnline } = useContext(OnlineStatusContext);
  const brandText = `©${new Date().getFullYear()} SmartForce Technologies, Inc. All rights reserved.`;

  const prevScrollData = React.useRef<string>();
  const refScrollable = React.createRef<SFScrollableRefHandler>();

  React.useEffect(() => {
    const scrollData = children.props['data-scroll'];
    if (scrollData && scrollData !== prevScrollData) {
      refScrollable.current?.scrollToTop();
      prevScrollData.current = scrollData;
    }
  }, [children, refScrollable]);

  return (
    <SFPaper className={styles.authContainer}>
      <div className={styles.leftPanel}></div>

      <SFScrollable ref={refScrollable} containerClassName={styles.container}>
        <div className={styles.topBorder}></div>

        <div className={styles.content}>
          <Brand className={styles.brand} />

          <main>{children}</main>

          <footer>
            <p>
              {isOnline && (
                <a
                  href={process.env.REACT_APP_SMARTFORCE_SITE_URL}
                  target="_blank"
                  rel="noreferrer"
                  tabIndex={-1}
                >
                  {brandText}
                </a>
              )}

              {!isOnline && brandText}
            </p>
          </footer>
        </div>
      </SFScrollable>
    </SFPaper>
  );
};
