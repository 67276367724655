import React from 'react';
import styles from './LandingSectionName.module.scss';

export interface LandingSectionNameProps {
  text: string;
  className?: string;
}

export const LandingSectionName = ({
  text,
  className = ''
}: LandingSectionNameProps): React.ReactElement<LandingSectionNameProps> => {
  return (
    <h1 className={`${styles.landingSectionName} ${className}`}>{text}</h1>
  );
};
