import React from 'react';
import styles from './RoleCard.module.scss';
import { SFCard, SFIconButton } from 'sfui';
import { InteractiveBox } from 'ui-smartforce-settings';

export interface RoleCardProps {
  title: string;
  description: string;
  onClick: () => void;
}

export const RoleCard = ({
  title,
  description,
  onClick
}: RoleCardProps): React.ReactElement<RoleCardProps> => {
  return (
    <InteractiveBox className={styles.roleCard} onClick={onClick}>
      <SFCard containerClassName={styles.card} sfElevation={3}>
        <div className={styles.roleInfo}>
          <h3>{title}</h3>
          <p>{description}</p>
        </div>

        <SFIconButton
          sfSize="large"
          sfIcon="Right-2"
          aria-label={`Choose ${title}`}
        />
      </SFCard>
    </InteractiveBox>
  );
};
