import React from 'react';
import styles from './Item.module.scss';
import { ItemListConfig, ItemValueType } from '../../../../../../Models';
import { SFIcon } from 'sfui';
import { getItemSummary } from '../../../../../../Helpers';
import { InteractiveBox } from 'ui-smartforce-settings';

export interface ItemProps {
  index: number;
  item: ItemValueType;
  config: ItemListConfig;
  label: string;
  error?: boolean;
  onClick: () => void;
}

export const Item = ({
  index,
  item,
  label,
  config,
  error = false,
  onClick
}: ItemProps): React.ReactElement<ItemProps> => {
  return (
    <InteractiveBox className={styles.item} onClick={onClick}>
      <p className={`${styles.brief} ${error ? styles.error : ''}`}>
        <span className={styles.label}>{`${label} ${index + 1} `}</span>
        {`- ${getItemSummary(config, item)}`}
      </p>

      <SFIcon className={styles.icon} icon="Right-2" size="24px" />
    </InteractiveBox>
  );
};
