import moment from 'moment';

export const isIncidentNumberValid = (incidentNumber: string): boolean => {
  const re = /^[A-Za-z0-9-]{1,20}$/;
  return re.test(incidentNumber);
};

export const checkFutureDay = (date: moment.Moment): boolean => {
  if (!date) {
    return false;
  }

  const today = moment().startOf('day');
  return date.diff(today, 'days') > 0;
};

export const checkFutureTime = (
  date: moment.Moment,
  time: moment.Moment
): boolean => {
  if (!date || !time) return false;

  const now = moment();
  const nowWithTime = now
    .clone()
    .set('hour', time.hour())
    .set('minute', time.minute())
    .set('second', time.second());
  const today = moment().startOf('day');

  return (
    date.clone().startOf('day').diff(today, 'days') === 0 &&
    nowWithTime.diff(now, 'seconds') > 0
  );
};

export const isDurationValid = (value: string): boolean => {
  return +value >= 1 && +value <= 1440;
};

export const isOriValid = (ori: string): boolean => {
  return /^[A-Z0-9]{9}$/.test(ori);
};

export const isISOStringValid = (value: string): boolean => {
  return /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d{3}Z)?/.test(value);
};
