import React, { FC, Fragment, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  FullReportGroupType,
  FullReportModal
} from '../../Components/FullReportModal/FullReportModal';
import { MultipleReportsModalOption } from '../../Components/MultipleReportsModal/MultipleReportsModalSelect/MultipleReportsModalSelect';
import { MultipleReportsModal } from '../../Components/MultipleReportsModal/MultipleReportsModal';
import { handleError } from '../../Helpers';
import { ReportValue } from '../../Models';
import { getReport } from '../../Services/ReportService';

export type FullReportContextProps = {
  showMultipleReports: (
    options: MultipleReportsModalOption[],
    groupType: FullReportGroupType,
    selected?: string
  ) => void;
  showFullReport: (report: ReportValue | string) => void;
};

const contextDefaultValues: FullReportContextProps = {
  showMultipleReports: () => {},
  showFullReport: () => {}
};

export const FullReportContext =
  React.createContext<FullReportContextProps>(contextDefaultValues);

export const FullReportProvider: FC = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenMultiple, setIsOpenMultiple] = useState<boolean>(false);
  const [report, setReport] = useState<ReportValue | undefined>();
  const [options, setOptions] = useState<MultipleReportsModalOption[]>([]);
  const [groupType, setGroupType] =
    useState<FullReportGroupType>('closeCoords');
  const [selected, setSelected] = useState<string | undefined>();

  const showMultipleReports = async (
    options: MultipleReportsModalOption[],
    groupType: FullReportGroupType,
    selected?: string
  ) => {
    setIsOpenMultiple(true);
    setGroupType(groupType);
    setOptions(options);
    setSelected(selected);
  };

  // Can receive either a full report or an incident number (string)
  const showFullReport = async (report: ReportValue | string) => {
    setIsLoading(true);
    setIsOpen(true);

    if (typeof report === 'string') {
      try {
        const reportFull = await getReport('agency', report);
        setReport(reportFull);
      } catch (e) {
        handleError(e, history);
      }
    } else {
      setReport(report);
    }

    setIsLoading(false);
  };

  const onAmend = (report: ReportValue) => {
    setIsOpen(false);
    setIsOpenMultiple(false);
    history.push('/cc/amend', {
      report,
      type: location.pathname === '/cc/my-reports' ? 'me' : 'agency',
      redirectTo: location.pathname
    });
  };

  return (
    <FullReportContext.Provider value={{ showFullReport, showMultipleReports }}>
      <Fragment>
        <FullReportModal
          isOpen={isOpen}
          isLoading={isLoading}
          report={report}
          onAmend={() => onAmend(report as ReportValue)}
          onClose={() => setIsOpen(false)}
        />

        <MultipleReportsModal
          isOpen={isOpenMultiple}
          groupType={groupType}
          options={options}
          defaultSelected={selected}
          onAmend={onAmend}
          onClose={() => {
            setSelected(undefined);
            setIsOpenMultiple(false);
          }}
        />

        {children}
      </Fragment>
    </FullReportContext.Provider>
  );
};
