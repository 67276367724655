import React from 'react';
import { FieldValueType } from '../../../../../Models';
import { FieldItem } from '../FieldItem/FieldItem';
import styles from './DurationInformation.module.scss';

export interface DurationInformationProps {
  duration: FieldValueType;
}

export const DurationInformation = ({
  duration
}: DurationInformationProps): React.ReactElement<DurationInformationProps> => {
  return (
    <div className={styles.durationInformation}>
      <h3 className={styles.title}>Duration</h3>
      <FieldItem
        label={'Duration in Minutes'}
        value={duration}
        type="duration"
      />
    </div>
  );
};
