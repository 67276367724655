import React, { Fragment } from 'react';

export interface WizardCardStepBaseProps {
  children: React.ReactNode;
  disabled?: boolean;
  noHeader?: boolean;
}

export interface WizardCardStepWithHeaderProps extends WizardCardStepBaseProps {
  limit: number;
  step: number;
  title: string;
}

export interface WizardCardStepWithoutHeaderProps
  extends WizardCardStepBaseProps {
  noHeader: true;
}

export type WizardCardStepProps =
  | WizardCardStepWithHeaderProps
  | WizardCardStepWithoutHeaderProps;

export const WizardCardStep = ({
  children
}: WizardCardStepProps): React.ReactElement<WizardCardStepProps> => {
  return <Fragment>{children}</Fragment>;
};
