import React, { Fragment, useContext } from 'react';
import { StateConfigContext } from '../../../../../Context/StateConfigContext';
import {
  CreatorUserType,
  FieldValueType,
  GeneralInformationValueType,
  LocationAreaType,
  Section
} from '../../../../../Models';
import { FieldItem } from '../FieldItem/FieldItem';
import styles from './GeneralInformation.module.scss';
import { SectionField } from '../SectionField/SectionField';
import {
  FieldItemList,
  FieldItemListType
} from './FieldItemList/FieldItemList';

export interface GeneralInformationProps {
  report: GeneralInformationValueType;
}

const getDateAndLocationFields = (
  report: GeneralInformationValueType
): FieldItemListType[] => {
  let fields: FieldItemListType[] = [
    {
      label: 'Date',
      value: report['date'] as FieldValueType,
      type: 'date'
    },
    {
      label: 'Time',
      value: report['date'] as FieldValueType,
      type: 'time'
    },
    {
      label: 'Location',
      value: report['location'].address?.full,
      type: 'string'
    }
  ];

  if (report['location'].areas && report['location'].areas.length > 0) {
    fields = [
      ...fields,
      {
        label: 'Areas',
        value: report['location'].areas.map(
          (area: LocationAreaType) => area.name
        ),
        type: 'array'
      }
    ];
  }

  fields = [
    ...fields,
    {
      label: 'Location Details',
      value: report['location'].details,
      type: 'string'
    },
    {
      label: 'Location Type',
      value: report['location'].type,
      type: 'string'
    }
  ];

  return fields;
};

export const GeneralInformation = ({
  report
}: GeneralInformationProps): React.ReactElement<GeneralInformationProps> => {
  const { stateConfig } = useContext(StateConfigContext);
  const creatorUserGroups = report.created_by_user
    ? (report.created_by_user as CreatorUserType).groups
    : undefined;

  return (
    <div className={styles.generalInformation}>
      {stateConfig && (
        <Fragment>
          <h3 className={styles.title}>
            {stateConfig.general_information.title}
          </h3>
          {report.created_by_user?.title && (
            <FieldItem
              label="Officer Title"
              value={report.created_by_user.title.name}
              type="string"
            />
          )}
          <FieldItem
            label={'Officer Name'}
            value={report['officer_name'] as FieldValueType}
            type="string"
          />
          <FieldItem
            label={'Officer ID'}
            value={report['officer_id_number'] as FieldValueType}
            type="string"
          />
          {creatorUserGroups && creatorUserGroups.length > 0 && (
            <FieldItem
              label="Officer Groups"
              value={creatorUserGroups.map((val) => val.name)}
              type="array"
            />
          )}
          <FieldItem
            label={'Incident Number'}
            value={report['incident_number'] as FieldValueType}
            type="string"
          />
          <FieldItemList
            title="Date and Location"
            fields={getDateAndLocationFields(report)}
          />
          <FieldItem
            label={'Type'}
            value={report['type'] as FieldValueType}
            type="string"
          />

          {report['lighting_conditions'] &&
            (report['lighting_conditions'] as string[]).length !== 0 && (
              <FieldItem
                label={'Lighting conditions'}
                value={report['lighting_conditions'] as FieldItemListType[]}
                type="array"
              />
            )}

          {report['was_use_of_force'] && (
            <FieldItem
              label={'Was Use Of Force'}
              value={report['was_use_of_force'] as FieldValueType}
              type="boolean"
            />
          )}

          {stateConfig.general_information.sections.map((section: Section) => {
            if (report.state_related[section.name]) {
              return (
                <SectionField
                  key={section.name}
                  config={section}
                  value={report.state_related[section.name]}
                />
              );
            }

            return undefined;
          })}
        </Fragment>
      )}
    </div>
  );
};
