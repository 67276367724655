import React, { FC } from 'react';

export type OnlineStatusState = {
  isOnline: boolean;
};

const contextDefaultValues: OnlineStatusState = {
  isOnline: navigator.onLine
};

export const OnlineStatusContext =
  React.createContext<OnlineStatusState>(contextDefaultValues);

export const OnlineStatusProvider: FC = ({ children }) => {
  const [isOnline, setIsOnline] = React.useState<boolean>(navigator.onLine);

  React.useEffect(() => {
    const setOffline = () => setIsOnline(false);
    const setOnline = () => setIsOnline(true);

    window.addEventListener('offline', setOffline);
    window.addEventListener('online', setOnline);

    return () => {
      window.removeEventListener('offline', setOffline);
      window.removeEventListener('online', setOnline);
    };
  }, []);

  return (
    <OnlineStatusContext.Provider value={{ isOnline }}>
      {children}
    </OnlineStatusContext.Provider>
  );
};
