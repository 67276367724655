import React, { useCallback, useEffect, useRef } from 'react';
import styles from './HeatMap.module.scss';
import { transformCoordsToLatLng } from '../../../../Helpers';
import { Coords, MapBaseDataType } from '../../../../Models/Widget';
import { useFilteredReports, useMapOptions } from '../../../../Hooks';
import { ReportsMap } from '../../../ReportsMap/ReportsMap';

export interface HeatMapProps {
  data: MapBaseDataType[];
}

export const HeatMap: React.FC<HeatMapProps> = ({ data }: HeatMapProps) => {
  const options = useMapOptions(data);
  const refMap = useRef<google.maps.Map>();

  const { reportsWithCoords, reportsWithoutCoords } =
    useFilteredReports<MapBaseDataType>(data);

  useEffect(() => {
    if (refMap.current) {
      const heatMapLayer = new google.maps.visualization.HeatmapLayer({
        data: reportsWithCoords.map((value: MapBaseDataType) =>
          transformCoordsToLatLng(value.coords as Coords)
        ),
        opacity: 0.8,
        radius: 15
      });

      heatMapLayer.setMap(refMap.current);

      return () => {
        heatMapLayer.setMap(null);
      };
    }
  }, [reportsWithCoords]);

  const onCreate = useCallback((map: google.maps.Map) => {
    refMap.current = map;
  }, []);

  return (
    <div className={styles.heatMap}>
      <ReportsMap
        minZoom={3}
        maxZoom={18}
        {...options}
        onCreate={onCreate}
        reportsWithoutCoords={reportsWithoutCoords}
      />
    </div>
  );
};
