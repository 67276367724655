import React from 'react';
import styles from './FieldItem.module.scss';
import { FieldValueType } from '../../../../../Models';
import { getFieldFormattedValue } from '../Helpers';

export interface FieldItemProps {
  label: string;
  value: FieldValueType;
  type: string;
}

export const FieldItem = ({
  label,
  value,
  type
}: FieldItemProps): React.ReactElement<FieldItemProps> => {
  return (
    <div className={styles.fieldItem}>
      <h4 className={styles.label}>{label}</h4>
      <p className={styles.value}>{getFieldFormattedValue(value, type)}</p>
    </div>
  );
};
