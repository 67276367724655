import React from 'react';
import styles from './ForgotPasswordInstructions.module.scss';
import { SFButton, SFTextField } from 'sfui';
import { EMAIL_INVALID_MSG } from '../../../Constants/Messages';
import { isEmailValid } from 'ui-smartforce-settings';

export interface ForgotPasswordInstructionsProps {
  value: string;
  onChange: (value: string) => void;
  onSend: () => void;
}

export const ForgotPasswordInstructions = ({
  value,
  onChange,
  onSend
}: ForgotPasswordInstructionsProps): React.ReactElement<ForgotPasswordInstructionsProps> => {
  const [isEmailError, setIsEmailError] = React.useState<boolean>(false);

  const onClickSend = () => {
    const checkEmail: boolean = isEmailValid(value);
    setIsEmailError(!checkEmail);

    if (checkEmail) {
      onSend();
    }
  };

  return (
    <div className={styles.forgotPasswordInstructions}>
      <h2 className={styles.title}>Forgot Password?</h2>

      <p className={styles.message}>
        If you want to reset your password, please enter your email below for
        instructions on how you can reset it.
      </p>

      <SFTextField
        label="E-mail"
        type="email"
        value={value}
        error={isEmailError}
        helperText={isEmailError ? EMAIL_INVALID_MSG : ''}
        onChange={(event) => onChange(event.target.value.toLowerCase())}
      />

      <SFButton
        size="large"
        fullWidth
        disabled={value.length === 0}
        onClick={onClickSend}
      >
        Send Instructions
      </SFButton>
    </div>
  );
};
