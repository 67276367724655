import React, { useContext } from 'react';
import styles from './ReportListHeader.module.scss';
import { SFDropdownField, SFDropdownFieldOption, SFPeopleOption } from 'sfui';
import get from 'lodash.get';
import unset from 'lodash.unset';
import {
  ReportsCount,
  ReportsCountLabel
} from './ReportsCountLabel/ReportsCountLabel';
import { Divider } from '../../../../Components/Divider/Divider';
import { FilterByButton } from './FilterByButton/FilterByButton';
import { MoreOptions } from './MoreOptions/MoreOptions';
import { AGENCY_REPORTS_DOWNLOAD } from '../../../../Constants/RolesAndPermissions';
import {
  clearEmptyObjects,
  cloneObject,
  isValueEmpty
} from '../../../../Helpers';
import {
  UserContext,
  MediaContext,
  checkPermissions,
  formatDateString,
  UserGroup
} from 'ui-smartforce-settings';
import { FilterChip, FilterChipList } from './FilterChipList/FilterChipList';
import {
  DateRange,
  Field,
  FieldValueType,
  LocationAreaType,
  NumberRange,
  ReportFiltersConfig,
  ReportListFilters
} from '../../../../Models';
import { ReportListQuerySortBy } from '../../../../Services/ReportService';
import { StateConfigContext } from '../../../../Context/StateConfigContext';

function getChipLabel(
  categoryName: string,
  value: FieldValueType,
  field: Field
) {
  let label: string = `${categoryName} | `;

  if (field.component === 'daterange') {
    const dateRangeValue = value as DateRange;
    let dateLabel = formatDateString(
      dateRangeValue.dateFrom as string,
      'MM/DD/YYYY'
    );

    if (dateRangeValue.dateTo) {
      dateLabel = `From ${dateLabel} to ${formatDateString(
        dateRangeValue.dateTo as string,
        'MM/DD/YYYY'
      )}`;
    }

    label += dateLabel;
  } else if (field.component === 'numberrange') {
    const numberRange: NumberRange = value as NumberRange;
    label += `From ${numberRange.from} to ${numberRange.to}`;
  } else if (field.component === 'peoplepicker') {
    const peopleValue: SFPeopleOption = value as SFPeopleOption;
    label += `${field.label}: ${peopleValue.name}`;
  } else if (
    field.component === 'areasfield' ||
    field.component === 'groupsfield'
  ) {
    const listValue: (LocationAreaType | UserGroup)[] = value as (
      | LocationAreaType
      | UserGroup
    )[];
    label += `${field.label}: ${listValue.map(
      (v: LocationAreaType | UserGroup) => v.name
    )}`;
  } else {
    label += `${field.label}: ${value}`;
  }

  return label;
}

function getChips(
  config: ReportFiltersConfig,
  filters: ReportListFilters
): FilterChip[] {
  if (isValueEmpty(filters)) {
    return [];
  }

  let chips: FilterChip[] = [];

  for (let categoryKey in config) {
    const categoryTitle = config[categoryKey].title;
    for (let section of config[categoryKey].sections) {
      for (let subsection of section.sub_sections) {
        for (let field of subsection.fields) {
          let fieldValue: FieldValueType;
          let path: string = field.name;

          if (subsection.name) {
            path = `${subsection.name}.${path}`;
          }

          if (section.name) {
            path = `${section.name}.${path}`;
          }

          path = `${categoryKey}.${path}`;

          fieldValue = get(filters, path);
          if (fieldValue) {
            const label = getChipLabel(categoryTitle, fieldValue, field);

            chips.push({
              label,
              onDelete: (filters: ReportListFilters) => {
                let newValue = cloneObject(filters);
                unset(newValue, path);
                clearEmptyObjects(newValue);
                return newValue;
              }
            });
          }
        }
      }
    }
  }

  return chips;
}

function getLabelSuffix(
  hasAnalytics: boolean,
  withoutFilters: boolean,
  withoutDateFilters: boolean
): string {
  let label = '';

  if (!hasAnalytics || withoutFilters) {
    label += 'REPORTS';
  }

  if (withoutDateFilters) {
    label += ` IN THE LAST ${hasAnalytics ? 'YEAR' : 'MONTH'}`;
  }

  return label;
}

export interface ReportListHeaderProps {
  hasAnalytics: boolean;
  isDownloadDisabled: boolean;
  filters: ReportListFilters;
  onDownload: () => void;
  onFiltersChange: (newFilters: ReportListFilters) => void;
  onSortReportsChange: (sortBy: ReportListQuerySortBy) => void;
  onOpenFilters: () => void;
  reportsCount: ReportsCount;
}

export const ReportListHeader = ({
  hasAnalytics,
  isDownloadDisabled,
  filters,
  onDownload,
  onFiltersChange,
  onOpenFilters,
  onSortReportsChange,
  reportsCount
}: ReportListHeaderProps): React.ReactElement<ReportListHeaderProps> => {
  const { user } = useContext(UserContext);
  const { isPhone } = useContext(MediaContext);
  const filtersConfig = useContext(StateConfigContext).stateConfig
    ?.filters as ReportFiltersConfig;

  const dropDownFieldOptions: SFDropdownFieldOption[] = [
    { label: 'Newest', onClick: () => onSortReportsChange('newest') },
    { label: 'Oldest', onClick: () => onSortReportsChange('oldest') }
  ];

  const renderSelectedOption = (label: string) => {
    return (
      <span className={styles.dropDownLabel}>
        {!isPhone ? 'Sorted by ' : ''}
        <strong className={styles.label}>{label}</strong>
      </span>
    );
  };

  const onChipDelete = (chip: FilterChip) => {
    const newFilters = chip.onDelete(filters as ReportListFilters);
    onFiltersChange(newFilters);
  };

  const chips = getChips(filtersConfig, filters);

  // If there's more options in the future, this will change
  const showMoreOptions: boolean = checkPermissions(
    AGENCY_REPORTS_DOWNLOAD,
    user?.role?.permissions
  );

  const withoutFilters = isValueEmpty(filters);
  const withoutDateFilters: boolean =
    !isValueEmpty(filters) &&
    !(filters.general_information?.date as DateRange)?.dateFrom;

  const noReportsLabel: boolean =
    !withoutFilters && hasAnalytics && !withoutDateFilters;

  const countSuffix = getLabelSuffix(
    hasAnalytics,
    withoutFilters,
    withoutDateFilters
  );

  return (
    <div className={styles.reportListHeader}>
      <div className={styles.content}>
        <div className={styles.actions}>
          <FilterByButton
            filterCount={chips.length}
            hasAnalytics={hasAnalytics}
            onClick={onOpenFilters}
          />
          <SFDropdownField
            sfColor="grey"
            variant="outlined"
            renderSelectedOption={renderSelectedOption}
            hideSelectedOption
            options={dropDownFieldOptions}
          />
          {showMoreOptions && (
            <MoreOptions
              isDownloadDisabled={isDownloadDisabled}
              onDownload={onDownload}
            />
          )}
        </div>

        <div
          className={`${styles.labels} ${
            noReportsLabel ? styles.noReportsLabel : ''
          }`}
        >
          {!noReportsLabel && (
            <ReportsCountLabel
              count={hasAnalytics && !withoutFilters ? 'none' : reportsCount}
              suffix={countSuffix}
            />
          )}
          <FilterChipList chips={chips} onDelete={onChipDelete} />
        </div>
      </div>

      <Divider className={styles.divider} />
    </div>
  );
};
