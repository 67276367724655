import React from 'react';
import { SFAutocomplete, SFMenuOption } from 'sfui';
import { InputProps } from '../FieldInput';

export const AutocompleteField = (
  props: InputProps
): React.ReactElement<InputProps> => {
  const options: SFMenuOption[] = props.options
    ? props.options.map((option: string) => ({ label: option, value: option }))
    : [];

  const onChange = (newValue: SFMenuOption | string) => {
    const value = typeof newValue === 'string' ? newValue : newValue.value;
    props.onChange(value.length > 0 ? value : undefined);
  };

  return (
    <SFAutocomplete
      allowEmpty
      label={props.label}
      required={props.required}
      options={options}
      onChange={onChange}
      value={props.value ? props.value.toString() : ''}
      freeSolo={false}
      popupIconType="none"
      clearOnBlur
    />
  );
};
