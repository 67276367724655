import React from 'react';
import { MessageSupport } from '../../../../Components/MessageSupport/MessageSupport';
import styles from './RegistrationVerifyView.module.scss';

export interface RegistrationVerifyViewProps {
  email: string;
}

export const RegistrationVerifyView = ({
  email
}: RegistrationVerifyViewProps): React.ReactElement<RegistrationVerifyViewProps> => {
  return (
    <div className={styles.registrationVerifyView}>
      <h2 className={styles.title}>Verify your email</h2>

      <p className={styles.msgEmail}>
        An email with a link to verify your account was sent to:{' '}
        <span>{email}</span>
      </p>

      <MessageSupport />
    </div>
  );
};
