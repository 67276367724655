import React, { useCallback, useContext, useEffect, useRef } from 'react';
import styles from './ClusterMap.module.scss';
import { Cluster, MarkerClusterer } from '@googlemaps/markerclusterer';
import { Coords, MapBaseDataType } from '../../../../Models/Widget';
import {
  ANALYTICS_COLOR_BLUE,
  ANALYTICS_COLOR_PURPLE,
  ANALYTICS_COLOR_RED
} from '../../../../Constants/Colors';
import { UserContext, checkPermissions, User } from 'ui-smartforce-settings';
import { AGENCY_REPORTS_READ } from '../../../../Constants/RolesAndPermissions';
import {
  useFilteredReports,
  useMapOptions,
  useMarkerClick
} from '../../../../Hooks';
import {
  getCountMarker,
  getMarker,
  transformCoordsToLatLng
} from '../../../../Helpers';
import { ReportsMap } from '../../../ReportsMap/ReportsMap';

export interface ClusterMapProps {
  data: MapBaseDataType[];
}

const renderClusterMarker = (cluster: Cluster): google.maps.Marker => {
  const numberOfMarkers = cluster.count;

  let color: string = ANALYTICS_COLOR_BLUE;

  if (numberOfMarkers >= 10) {
    color = ANALYTICS_COLOR_RED;
  } else if (numberOfMarkers > 5) {
    color = ANALYTICS_COLOR_PURPLE;
  }

  return new google.maps.Marker(
    getCountMarker(cluster.position, numberOfMarkers, color)
  );
};

const getCluster = () => {
  return new MarkerClusterer({
    renderer: { render: renderClusterMarker }
  });
};

export const ClusterMap = ({
  data
}: ClusterMapProps): React.ReactElement<ClusterMapProps> => {
  const user = useContext(UserContext).user as User;
  const refCluster = useRef<MarkerClusterer>(getCluster());

  const { reportsWithCoords, reportsWithoutCoords } =
    useFilteredReports<MapBaseDataType>(data);

  const userCanSeeReport: boolean = checkPermissions(
    AGENCY_REPORTS_READ,
    user.role.permissions
  );

  const { onMarkerClick } = useMarkerClick(userCanSeeReport, reportsWithCoords);

  useEffect(() => {
    const cluster = refCluster.current;

    // remove marker cluster from map on unmount
    return () => {
      cluster.setMap(null);
    };
  }, []);

  useEffect(() => {
    refCluster.current.clearMarkers();

    const markers = reportsWithCoords.map((value: MapBaseDataType) => {
      const marker = new google.maps.Marker(
        getMarker(transformCoordsToLatLng(value.coords as Coords))
      );

      // Add onClick listener to individual markers
      marker.addListener('click', () => onMarkerClick('closeCoords', value));

      return marker;
    });

    refCluster.current.addMarkers(markers);
  }, [reportsWithCoords, onMarkerClick]);

  const onCreate = useCallback((map: google.maps.Map) => {
    refCluster.current.setMap(map);
  }, []);

  const options = useMapOptions(data);

  return (
    <div className={styles.clusterMap}>
      <ReportsMap
        {...options}
        onCreate={onCreate}
        reportsWithoutCoords={reportsWithoutCoords}
      />
    </div>
  );
};
