import React, { useState } from 'react';
import styles from './AmendDisabledTooltip.module.scss';
import { SFTooltip } from 'sfui';
import { isMobile } from '../../Helpers';
import { InteractiveBox } from 'ui-smartforce-settings';

export interface AmendDisabledTooltipProps {
  isActive: boolean;
  children: React.ReactNode;
}

export const AmendDisabledTooltip = ({
  isActive,
  children
}: AmendDisabledTooltipProps):
  | React.ReactElement<AmendDisabledTooltipProps>
  | JSX.Element => {
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);

  const onClick = () => {
    if (isMobile() && !isTooltipOpen) {
      setIsTooltipOpen(true);
    }
  };

  const onTooltipClose = () => {
    if (isMobile()) {
      setIsTooltipOpen(false);
    }
  };

  return isActive ? (
    <SFTooltip
      sfColor="inverted"
      title=""
      content={
        <div className={styles.tooltipContent}>
          <p>Sorry, you do not have permission to amend</p>
          <p>this report.</p>
        </div>
      }
      open={isMobile() ? isTooltipOpen : undefined}
      onClose={onTooltipClose}
      leaveTouchDelay={3500}
      disableHoverListener={isMobile()}
    >
      <InteractiveBox onClick={onClick}>{children}</InteractiveBox>
    </SFTooltip>
  ) : (
    <>{children}</>
  );
};
