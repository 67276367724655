import React from 'react';
import styles from './WizardCardSlideStep.module.scss';
import { Transition } from 'react-transition-group';

const TRANSITION_TIMEOUT = 360;

const defaultStyle: React.CSSProperties = {
  left: '100%',
  transition: `left ${TRANSITION_TIMEOUT}ms ease-in-out`
};

const transitionStyles: { [index: string]: React.CSSProperties } = {
  entering: { left: '0' },
  entered: { left: '0' }
};

export interface WizardCardSlideStepProps {
  children: React.ReactNode;
  show?: boolean;
}

export const WizardCardSlideStep = ({
  children,
  show = false
}: WizardCardSlideStepProps): React.ReactElement<WizardCardSlideStepProps> => {
  return (
    <Transition
      in={show}
      mountOnEnter
      unmountOnExit
      timeout={TRANSITION_TIMEOUT}
    >
      {(state) => (
        <div
          className={styles.wizardCardSlideStep}
          style={{
            ...defaultStyle,
            ...transitionStyles[state as string]
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  );
};
