import React, { Fragment, useCallback, useContext, useEffect } from 'react';
import { SFAlert, SFButton, SFLink, SFTextField } from 'sfui';
import styles from './LoginView.module.scss';
import { AuthContainer } from '../../Components/AuthContainer/AuthContainer';
import { useHistory } from 'react-router-dom';
import { OnlineStatusContext } from '../../Context/OnlineStatusContext';
import Loader from '../../Components/Loader/Loader';
import { AuthContext } from '../../Context/AuthContext';
import {
  isBadCredentials,
  isOfficerIdAlreadyExist,
  isUserInvitationNotExist,
  isOwnerInvited,
  isOfficerAlreadyWithAgency,
  isUserNotVerified
} from '../../Helpers/errors';
import {
  isNewVersionAvailable,
  removeNewVersionAvailable,
  setNewVersionUpdated
} from '../../Services/SoftwareUpdateService';
import {
  acceptInvitation,
  getUserInvitation,
  isLogin,
  login,
  removeUserInvitation
} from 'ui-smartforce-settings';

export const LoginView = (): React.ReactElement<{}> => {
  const history = useHistory();
  const { isOnline } = useContext(OnlineStatusContext);
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isError, setIsError] = React.useState<boolean>(false);
  const { setIsLogged } = React.useContext(AuthContext);
  const isLoginDisabled: boolean = email.length === 0 || password.length === 0;

  useEffect(() => {
    if (isNewVersionAvailable()) {
      removeNewVersionAvailable();
      setNewVersionUpdated();
    }
  }, []);

  const onCreate = () => {
    history.push('/registration');
  };

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value.toLowerCase());
  };

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const onForgetPassword = () => {
    history.push('/forgot-password');
  };

  const onLogin = useCallback(async () => {
    setIsLoading(true);
    try {
      await login(
        process.env.REACT_APP_SETTINGS_API_BASE_URL as string,
        email,
        password
      );
      setIsLogged(isLogin());

      const userInvitation: string | null = getUserInvitation();
      if (userInvitation) {
        await acceptInvitation(
          process.env.REACT_APP_SETTINGS_API_BASE_URL as string,
          userInvitation
        );
      }

      setIsLoading(false);

      history.push('/cc', { fromLogin: true });
    } catch (e) {
      if (isBadCredentials(e)) {
        console.error(`Login::BadCredentials`, e);
        setIsLoading(false);
        setIsLogged(false);
        setIsError(true);
      } else if (isUserNotVerified(e)) {
        console.error(`Login::UserNotVerified`, e);
        history.push('/user-not-verified', { email });
      } else if (isOwnerInvited(e)) {
        console.error(`Login::AcceptInvitation::OwnerUsersCanNotBeInvited`, e);
        removeUserInvitation();
        history.push('/cc');
      } else if (isUserInvitationNotExist(e)) {
        // CC-1009: Redirect user to onboarding view as a new officer with no agency assigned yet.
        console.error(`Login::AcceptInvitation::UserInvitationNotExist`, e);
        removeUserInvitation();
        history.push('/cc/onboarding');
      } else if (isOfficerIdAlreadyExist(e)) {
        console.error(`Login::AcceptInvitation::OfficerIdAlreadyExists`, e);
        history.push('/user-id-error');
      } else if (isOfficerAlreadyWithAgency(e)) {
        console.error(`Login::AcceptInvitation::isOfficerAlreadyWithAgency`, e);
        history.push('/user-ori-error');
      } else {
        console.error(`Login::GeneralError`, e);
        history.push('/error');
      }
    }
  }, [email, history, password, setIsLogged]);

  const onKeyUp = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter' && !isLoginDisabled) {
        onLogin();
      }
    },
    [onLogin, isLoginDisabled]
  );

  React.useEffect(() => {
    window.addEventListener('keyup', onKeyUp, false);
    return () => window.removeEventListener('keyup', onKeyUp, false);
  }, [onKeyUp]);

  React.useEffect(() => {
    if (!isOnline) {
      history.replace('/no-connection');
    }
  }, [isOnline, history]);

  return (
    <AuthContainer>
      <div className={styles.loginView}>
        {isLoading && <Loader />}
        {!isLoading && (
          <Fragment>
            <h2 className={styles.title}>Log in to your account</h2>

            <div className={styles.content}>
              {isError && (
                <SFAlert
                  type="error"
                  title="The email and password combination entered is incorrect."
                >
                  Please check your email and password and try again.
                </SFAlert>
              )}

              <form>
                <SFTextField
                  label="E-mail"
                  type="email"
                  value={email}
                  autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                  onChange={onEmailChange}
                />

                <SFTextField
                  label="Password"
                  type="password"
                  value={password}
                  onChange={onPasswordChange}
                />
              </form>

              <div className={styles.forgotPassword}>
                <SFLink
                  sfSize="medium"
                  tabIndex={-1}
                  onClick={onForgetPassword}
                >
                  Forgot Password?
                </SFLink>
              </div>
            </div>

            <SFButton
              size="large"
              fullWidth
              disabled={isLoginDisabled}
              onClick={onLogin}
            >
              Log in
            </SFButton>

            <div className={styles.msgAccount}>
              <span>No account?</span>

              <SFLink
                sfSize="small"
                color="primary"
                tabIndex={-1}
                onClick={onCreate}
              >
                Create one
              </SFLink>
            </div>
          </Fragment>
        )}
      </div>
    </AuthContainer>
  );
};
