import React, { Fragment, useEffect, useRef, useState } from 'react';
import styles from './FilterChipList.module.scss';
import { SFChip, SFIconButton, SFMenu, SFMenuItem } from 'sfui';
import { ReportListFilters } from '../../../../../Models';

export interface FilterChip {
  label: string;
  onDelete: (filters: ReportListFilters) => ReportListFilters;
}

const CHIP_FONT = '400 13px Roboto, sans-serif';
const CHIP_PADDING_SIZE = 16;
const CHIP_BORDER_SIZE = 2;
const CHIP_DELETE_BUTTON_SIZE = 20;
const CHIP_GAP_SIZE = 12;
const SHOW_MORE_BUTTON_SIZE = 34;

interface GroupedChips {
  menu: FilterChip[];
  visible: FilterChip[];
}

//Get estimated width of chip
function getChipWidth(text: string): number {
  let canvas: HTMLCanvasElement | null = document.createElement('canvas');
  let context = canvas.getContext('2d') as CanvasRenderingContext2D;
  context.font = CHIP_FONT;
  const width = context.measureText(text).width;
  // Remove canvas
  canvas = null;
  return (
    Math.ceil(width) +
    CHIP_PADDING_SIZE +
    CHIP_BORDER_SIZE +
    CHIP_DELETE_BUTTON_SIZE
  );
}

function getGroupedChips(chips: FilterChip[], width: number): GroupedChips {
  let result = [];
  let remainingSpace = width;
  let index = 0;

  for (let chip of chips) {
    const chipWidth = getChipWidth(chip.label);
    if (chipWidth <= remainingSpace) {
      result.push(chip);
      // 12px is the gap between chips
      remainingSpace = remainingSpace - chipWidth - CHIP_GAP_SIZE;
      index++;
    } else {
      // Check if there's available space for the 'more chips button''
      // If not, remove the previous visible chip
      if (remainingSpace - SHOW_MORE_BUTTON_SIZE <= 0) {
        result.pop();
        index--;
      }
      break;
    }
  }

  return {
    menu: chips.slice(index),
    visible: result
  };
}

export interface FilterChipListProps {
  chips: FilterChip[];
  onDelete: (chip: FilterChip) => void;
}

export const FilterChipList = ({
  chips,
  onDelete
}: FilterChipListProps): React.ReactElement<FilterChipListProps> => {
  const refContainer = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | undefined>(
    undefined
  );
  const [groupedChips, setGroupedChips] = useState<GroupedChips>(
    getGroupedChips(chips, refContainer.current?.clientWidth ?? 0)
  );

  // Recalculate visible chips if chips prop changes
  useEffect(() => {
    const container = refContainer.current;

    setGroupedChips(
      getGroupedChips(chips, refContainer.current?.clientWidth ?? 0)
    );

    if (container) {
      // Observe changes of size to recalculate the visible chips
      const resizeObserver = new ResizeObserver(() => {
        setGroupedChips(
          getGroupedChips(chips, refContainer.current?.clientWidth ?? 0)
        );
      });

      resizeObserver.observe(container);

      // Clean up
      return () => {
        if (container) {
          resizeObserver.unobserve(container);
        }
      };
    }
  }, [chips]);

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(undefined);
  };

  const onMenuChipDelete = (chip: FilterChip) => {
    setAnchorEl(undefined);
    onDelete(chip);
  };

  const isMenuOpen = Boolean(anchorEl);

  return (
    <div className={styles.filterChipList} ref={refContainer}>
      {groupedChips.visible.map((chip: FilterChip) => {
        return (
          <SFChip
            deleteable
            key={chip.label}
            label={chip.label}
            onDelete={() => onDelete(chip)}
            sfColor="primary"
            size="small"
            variant="outlined"
          />
        );
      })}

      {groupedChips.menu.length > 0 && (
        <Fragment>
          <SFIconButton
            rotate={isMenuOpen ? 'invert' : 'none'}
            sfIcon="Down-2"
            sfSize="small"
            onClick={onClick}
          />

          <SFMenu
            MenuListProps={{ className: styles.menu }}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            getContentAnchorEl={null}
            onClose={onClose}
            open={isMenuOpen}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            variant="menu"
          >
            {groupedChips.menu.map((chip: FilterChip) => (
              <SFMenuItem className={styles.menuItem} key={chip.label}>
                <SFChip
                  className={styles.menuItemChip}
                  deleteable
                  label={chip.label}
                  onDelete={() => onMenuChipDelete(chip)}
                  sfColor="primary"
                  size="small"
                  variant="outlined"
                />
              </SFMenuItem>
            ))}
          </SFMenu>
        </Fragment>
      )}
    </div>
  );
};
