import React from 'react';
import styles from './WidgetDrawer.module.scss';
import { SFDrawer, SFIconButton } from 'sfui';

export interface WidgetDrawerProps {
  isOpen: boolean;
  title: string;
  children: React.ReactNode;
  onClose: () => void;
}

export const WidgetDrawer = ({
  isOpen,
  title,
  children,
  onClose
}: WidgetDrawerProps): React.ReactElement<WidgetDrawerProps> => {
  return (
    <SFDrawer open={isOpen} anchor="right">
      <div className={styles.widgetDrawer}>
        <div className={styles.header}>
          <SFIconButton sfSize="medium" sfIcon="Close" onClick={onClose} />
        </div>

        <div className={styles.content}>
          <div className={styles.title}>{title}</div>

          {children}
        </div>
      </div>
    </SFDrawer>
  );
};
