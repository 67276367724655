import React, { Fragment, createContext, useContext } from 'react';
import styles from './ReportWizard.module.scss';
import { ReportValue, LocationAreaType, ApiError } from '../../../Models';
import ContactTypeView from './ContactTypeView/ContactTypeView';
import IncidentNumberView from './IncidentNumberView/IncidentNumberView';
import { ContactFormView } from './ContactFormView/ContactFormView';
import ReportSubmittedView from './ReportSubmittedView/ReportSubmittedView';
import {
  saveReport,
  SaveReportResponse
} from '../../../Services/ReportService';
import Loader from '../../../Components/Loader/Loader';
import CancelModal from './CancelModal/CancelModal';
import { useHistory } from 'react-router-dom';
import { IncidentNumberModal } from '../../../Components/IncidentNumberModal/IncidentNumberModal';
import {
  UserContext,
  AreasContext,
  GeoLocationCoords
} from 'ui-smartforce-settings';
import { StateConfigContext } from '../../../Context/StateConfigContext';
import { handleError, getCoordsAreas } from '../../../Helpers';

export const DeviceLocationContext = createContext<
  GeoLocationCoords | undefined
>(undefined);

const ReportWizard = (): React.ReactElement<{}> => {
  const deviceDate = React.useRef<string>();
  const history = useHistory();
  const areas = useContext(AreasContext).areas;
  const { stateConfig } = useContext(StateConfigContext);
  const { user } = React.useContext(UserContext);
  const [step, setStep] = React.useState<number>(1);
  const [contactType, setContactType] = React.useState<string>('');
  const [incidentNumber, setIncidentNumber] = React.useState<string>('');
  const [deviceLocation, setDeviceLocation] =
    React.useState<GeoLocationCoords>();
  const [deviceDuration, setDeviceDuration] = React.useState<number>(0);
  const [isCancelModalOpen, setCancelIsModalOpen] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);
  const [isIncidentNumberModalOpen, setIsIncidentNumberModalOpen] =
    React.useState<boolean>(false);
  const [submittedForm, setSubmittedForm] = React.useState<ReportValue>();
  const [isSessionExpire, setIsSessionExpire] = React.useState<boolean>(false);

  const [generalFormAreas, setGeneralFormAreas] = React.useState<
    LocationAreaType[]
  >([]);

  React.useEffect(() => {
    if (deviceLocation) {
      setGeneralFormAreas(getCoordsAreas(deviceLocation, areas));
    }
  }, [areas, deviceLocation]);

  React.useEffect(() => {
    let isSubscribed: boolean = true;

    //Set timer for device duration
    const timer = setInterval(
      () =>
        isSubscribed && setDeviceDuration((duration: number) => duration + 1),
      60000
    );

    //Check for coords for device location
    if (navigator.geolocation) {
      const onLocationError = (): void =>
        console.error('ReportWizard::Location', 'Cant get browser geolocation');

      const onLocationSuccess = (pos: GeolocationPosition): void => {
        const deviceLocation: GeoLocationCoords = {
          latitude: pos.coords.latitude,
          longitude: pos.coords.longitude
        };

        isSubscribed && setDeviceLocation(deviceLocation);
      };

      navigator.geolocation.getCurrentPosition(
        onLocationSuccess,
        onLocationError,
        {
          enableHighAccuracy: true
        }
      );
    } else {
      console.error('ReportWizard::Location', 'Cant get browser geolocation');
    }

    //Cleanup
    return () => {
      isSubscribed = false;
      clearInterval(timer);
    };
  }, []);

  const onContactTypeSelected = (type: string) => {
    setDeviceDuration(0);
    deviceDate.current = new Date().toISOString();
    setContactType(type);
    setStep(2);
  };

  const onIncidentContinue = (incidentNumber: string) => {
    setIncidentNumber(incidentNumber);
    setStep(3);
  };

  const onContactFormSubmit = async (
    form: ReportValue,
    incidentNumber: string
  ) => {
    const newForm: ReportValue = { ...form };
    newForm.general_information.type = contactType;
    newForm.general_information.incident_number = incidentNumber;
    newForm.general_information.device = {
      coords: deviceLocation,
      duration: deviceDuration,
      date: deviceDate.current as string
    };
    newForm.created_in_version = process.env.REACT_APP_CC_VERSION;

    setIsLoading(true);
    try {
      const saveResponse: SaveReportResponse = await saveReport(
        user?.email as string,
        newForm
      );

      setIsSubmitted(saveResponse.success);
      setIsSessionExpire(saveResponse.detail === 'Unauthorized');
      setStep(4);
      setIsLoading(false);
    } catch (e) {
      const error: ApiError = e as ApiError;
      console.error('ReportWizard::CreateReport', error);

      if (error.detail === 'CREATED_REPORT_ALREADY_EXISTS') {
        setIsLoading(false);
        setIsIncidentNumberModalOpen(true);
      } else {
        handleError(e, history);
      }
    }
  };

  const onStartNewReport = () => {
    setContactType('');
    setIncidentNumber('');
    setDeviceDuration(0);
    setSubmittedForm(undefined);
    setStep(1);
  };

  const onCancel = () => {
    setCancelIsModalOpen(true);
  };

  const onModalClose = () => {
    setCancelIsModalOpen(false);
  };

  const onDiscardChanges = () => {
    setCancelIsModalOpen(false);
    onStartNewReport();
  };

  const onIncidentNumberModalClick = (newIncidentNumber: string) => {
    setIncidentNumber(newIncidentNumber);
    setIsIncidentNumberModalOpen(false);
    onContactFormSubmit(submittedForm as ReportValue, newIncidentNumber);
  };

  return (
    <Fragment>
      {(isLoading || !stateConfig) && <Loader />}
      {!isLoading && stateConfig && (
        <div className={styles.reportWizard}>
          {step === 1 && (
            <ContactTypeView onContactTypeSelected={onContactTypeSelected} />
          )}

          {step === 2 && (
            <IncidentNumberView
              onCancel={onCancel}
              onContinue={onIncidentContinue}
            />
          )}

          {step === 3 && (
            <DeviceLocationContext.Provider value={deviceLocation}>
              <ContactFormView
                generalFormAreas={generalFormAreas}
                deviceDuration={deviceDuration}
                onCancel={onCancel}
                onSubmit={(contactValue: ReportValue) => {
                  setSubmittedForm({ ...contactValue });
                  onContactFormSubmit(contactValue, incidentNumber);
                }}
              />
            </DeviceLocationContext.Provider>
          )}

          {step === 4 && (
            <ReportSubmittedView
              isSubmitted={isSubmitted}
              isSessionExpire={isSessionExpire}
              incidentNumber={incidentNumber}
              eventIdentifier={
                submittedForm?.general_information.state_related
                  ?.to_connect_reports?.event_identifier as string
              }
              onStartNewReport={onStartNewReport}
            />
          )}

          <CancelModal
            isOpen={isCancelModalOpen}
            onDiscard={onDiscardChanges}
            onClose={onModalClose}
          />

          <IncidentNumberModal
            value={incidentNumber}
            isOpen={isIncidentNumberModalOpen}
            onClick={onIncidentNumberModalClick}
          />
        </div>
      )}
    </Fragment>
  );
};

export default ReportWizard;
