import React, { useEffect, useRef } from 'react';
import styles from './EChart.module.scss';
import * as echarts from 'echarts/core';
import { BarChart, LineChart, PieChart, SankeyChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  LegendComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { EChartsOption } from 'echarts';

// Register the required components
echarts.use([
  GridComponent,
  TooltipComponent,
  LegendComponent,
  BarChart,
  LineChart,
  PieChart,
  SankeyChart,
  CanvasRenderer
]);

export interface EChartProps {
  option: EChartsOption;
  nightMode?: boolean;
  className?: string;
}

export const EChart = ({
  option,
  nightMode = false,
  className = ''
}: EChartProps): React.ReactElement<EChartProps> => {
  const refContainer = useRef<HTMLDivElement>(null);
  const refChart = useRef<echarts.ECharts | null>(null);
  const refnightMode = useRef<boolean>(nightMode);

  useEffect(() => {
    const container = refContainer.current;

    if (container) {
      const resizeObserver = new ResizeObserver(() => {
        if (refChart.current) {
          refChart.current.resize();
        }
      });

      resizeObserver.observe(container);

      if (!refChart.current) {
        refChart.current = echarts.init(container);
      }

      return () => {
        if (container) {
          resizeObserver.unobserve(container);
        }
      };
    }
  }, []);

  useEffect(() => {
    if (refContainer.current && refChart.current) {
      //Avoid disposing chart instance if nightMode doesn't change
      if (nightMode !== refnightMode.current) {
        refnightMode.current = nightMode;
        refChart.current.dispose();

        if (nightMode) {
          refChart.current = echarts.init(refContainer.current, 'night');
        } else {
          refChart.current = echarts.init(refContainer.current);
        }
      }

      refChart.current.setOption(option);
    }
  }, [option, nightMode]);

  const dragProps = {
    draggable: true,
    onDragStart: (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <div
      className={`${className} ${styles.eChart}`}
      ref={refContainer}
      {...dragProps}
    ></div>
  );
};
