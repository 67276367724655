import React from 'react';
import styles from './PlanDescriptionItem.module.scss';
import { SFIcon, SFText } from 'sfui';

export interface PlanDescriptionItemProps {
  label: string;
}

export const PlanDescriptionItem = ({
  label
}: PlanDescriptionItemProps): React.ReactElement<PlanDescriptionItemProps> => {
  return (
    <div className={styles.planDescriptionItem}>
      <SFIcon size={10} icon="Check-2" />
      <SFText type="component-2" sfColor="neutral">
        {label}
      </SFText>
    </div>
  );
};
