import React, { Fragment } from 'react';
import styles from './WizardCard.module.scss';
import { SFDrawer, SFPaper } from 'sfui';
import { MediaContext } from 'ui-smartforce-settings';
import { WizardCardFadeStep } from './WizardCardFadeStep/WizardCardFadeStep';
import { WizardCardSlideStep } from './WizardCardSlideStep/WizardCardSlideStep';
import { WizardCardStepProps } from './WizardCardStep/WizardCardStep';
import { WizardCardStepContainer } from './WizardCardStepContainer/WizardCardStepContainer';
import Loader from '../Loader/Loader';

const TRANSITION_TIMEOUT = 360;

export interface WizardCardProps {
  className?: string;
  children: React.ReactElement<WizardCardStepProps>[];
  isLoading: boolean;
  onBack?: () => void;
  onClose?: () => void;
  show: boolean;
  step: number;
}

export const WizardCard = ({
  className = '',
  children,
  isLoading = false,
  onBack,
  onClose,
  show,
  step
}: WizardCardProps): React.ReactElement<WizardCardProps> => {
  const { isPhone } = React.useContext(MediaContext);

  // Filter non visible steps
  const visibleSteps = React.Children.toArray(children).filter((child) => {
    if (React.isValidElement<WizardCardStepProps>(child)) {
      return !child.props.disabled;
    } else {
      return false;
    }
  });

  return (
    <Fragment>
      {!isPhone && show && (
        <SFPaper className={`${styles.wizardCard} ${className}`} elevation={2}>
          {visibleSteps.map((child, index) => {
            if (React.isValidElement<WizardCardStepProps>(child)) {
              return (
                <WizardCardFadeStep
                  key={`WizardCardFadeStep-${index}`}
                  show={index + 1 === step}
                >
                  <WizardCardStepContainer onBack={onBack} onClose={onClose}>
                    {child}
                  </WizardCardStepContainer>
                </WizardCardFadeStep>
              );
            } else {
              return false;
            }
          })}

          {isLoading && <Loader className={styles.loader} />}
        </SFPaper>
      )}

      {isPhone && (
        <SFDrawer
          PaperProps={{
            className: `${styles.wizardCardDrawer} ${className}`
          }}
          anchor="bottom"
          open={show}
          transitionDuration={TRANSITION_TIMEOUT}
        >
          {visibleSteps.map((child, index) => {
            if (React.isValidElement<WizardCardStepProps>(child)) {
              return (
                <WizardCardSlideStep
                  key={`WizardCardSlideStep-${index}`}
                  show={index < step}
                >
                  <WizardCardStepContainer onBack={onBack} onClose={onClose}>
                    {child}
                  </WizardCardStepContainer>
                </WizardCardSlideStep>
              );
            } else {
              return false;
            }
          })}

          {isLoading && <Loader className={styles.loader} />}
        </SFDrawer>
      )}
    </Fragment>
  );
};
