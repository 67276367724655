import React from 'react';
import { SFAlertDialog } from 'sfui';
import { FailedUploadModalContent } from './FailedUploadModalContent/FailedUploadModalContent';

export interface FailedUploadModalProps {
  isOpen: boolean;
  onClick: () => void;
}

export const FailedUploadModal = ({
  isOpen,
  onClick
}: FailedUploadModalProps): React.ReactElement<FailedUploadModalProps> => {
  return (
    <SFAlertDialog
      open={isOpen}
      title="Failed upload"
      rightAction={{
        label: 'Yes, I Understand',
        buttonProps: { onClick }
      }}
    >
      <FailedUploadModalContent />
    </SFAlertDialog>
  );
};
