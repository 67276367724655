import React, { Fragment } from 'react';
import { SFIcon, SFTooltip, useSFMediaQuery } from 'sfui';
import {
  EXTRA_EXTRA_LARGE_SCREEN,
  LARGE_SCREEN,
  PHONE_MEDIA_SCREEN
} from '../../../../Constants/Media';
import { isMobile } from '../../../../Helpers';
import styles from './ContactMapNoCoordinates.module.scss';
import { InteractiveBox } from 'ui-smartforce-settings';

export const ContactMapNoCoordinates = (): React.ReactElement<{}> => {
  const isXXLScreen: boolean = useSFMediaQuery(EXTRA_EXTRA_LARGE_SCREEN);
  const isLScreen: boolean = useSFMediaQuery(LARGE_SCREEN);
  const isPhone: boolean = useSFMediaQuery(PHONE_MEDIA_SCREEN);
  const isToolTipOnTop: boolean = isPhone || isLScreen || isXXLScreen;
  const [isToolTipOpen, setIsToolTipOpen] = React.useState<boolean>(false);

  const onClose = () => {
    if (isMobile()) {
      setIsToolTipOpen(false);
    }
  };

  const onClick = () => {
    if (isMobile() && !isToolTipOpen) {
      setIsToolTipOpen(true);
    }
  };

  return (
    <div className={styles.contactMapNoCoordinates}>
      <div className={styles.map}></div>
      <div className={styles.container}>
        <SFTooltip
          title="Coordinates not provided"
          content={
            <Fragment>
              <p className={styles.toolTipText}>
                To see the marker, the geolocation of
              </p>
              <p className={styles.toolTipText}>
                the device must be activated.
              </p>
            </Fragment>
          }
          placement={isToolTipOnTop ? 'top' : 'right'}
          open={isMobile() ? isToolTipOpen : undefined}
          onClose={onClose}
          leaveTouchDelay={3500}
          disableHoverListener={isMobile()}
        >
          <InteractiveBox className={styles.circle} onClick={onClick}>
            <div className={styles.icon}>
              <SFIcon icon="Question-Mark" size={28} />
            </div>
          </InteractiveBox>
        </SFTooltip>
      </div>
    </div>
  );
};
