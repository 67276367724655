import React from 'react';
import styles from './ReportsCountLabel.module.scss';
import { SFText } from 'sfui';

function getLabel(count: ReportsCount): string {
  if (count === 'all') {
    return 'ALL';
  } else if (typeof count === 'number') {
    return count.toString();
  } else {
    return '';
  }
}

export type ReportsCount = number | 'all' | 'none';

export interface ReportsCountLabelProps {
  count: ReportsCount;
  suffix?: string;
}

export const ReportsCountLabel = (
  props: ReportsCountLabelProps
): React.ReactElement<ReportsCountLabelProps> => {
  return (
    <div className={styles.reportsCountLabel}>
      <SFText type="component-1-extraBold">{getLabel(props.count)}</SFText>
      <SFText type="component-1">{props.suffix}</SFText>
    </div>
  );
};
