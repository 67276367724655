import React from 'react';
import styles from './SoftwareUpdateModal.module.scss';
import { SFButton, SFDialog } from 'sfui';
import { MediaContext } from 'ui-smartforce-settings';

export interface SoftwareUpdateModalProps {
  isOpen: boolean;
  onClick: () => void;
}

export const SoftwareUpdateModal = ({
  onClick,
  isOpen
}: SoftwareUpdateModalProps): React.ReactElement<SoftwareUpdateModalProps> => {
  const { isPhone } = React.useContext(MediaContext);

  return (
    <SFDialog
      PaperProps={{ className: styles.softwareUpdateModal }}
      open={isOpen}
    >
      <div className={styles.content}>
        {
          <div className={`${styles.text} ${styles.newFeature}`}>
            <h2 className={styles.title}>New version available</h2>
            <span className={styles.subTitle}>
              To get the new version, please close any other CitizenContact tab
              and click on the button below.
            </span>
          </div>
        }

        <div className={styles.updateButton}>
          <SFButton size="large" onClick={onClick} fullWidth={isPhone}>
            Update Now
          </SFButton>
        </div>
      </div>
    </SFDialog>
  );
};
