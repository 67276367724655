import React from 'react';
import styles from './ContactButton.module.scss';

import { SFPaper, SFIcon } from 'sfui';

export interface ContactButtonProps {
  icon: string;
  text: string;
  onClick: (contactType: string) => void;
}

const ContactButton = (
  props: ContactButtonProps
): React.ReactElement<ContactButtonProps> => {
  return (
    <SFPaper
      className={styles.contactButton}
      key={`contactType-${props.text}`}
      elevation={2}
      onClick={() => props.onClick(props.text)}
    >
      <SFIcon
        className={styles.buttonIcon}
        size="42px"
        icon={props.icon}
      />
      <h3 className={styles.buttonText}>{props.text}</h3>
    </SFPaper>
  );
};

export default ContactButton;
