import React from 'react';
import styles from './NotMemberView.module.scss';
import { SFIcon, SFPaper } from 'sfui';

export interface NotMemberViewProps {}

export const NotMemberView = (
  props: NotMemberViewProps
): React.ReactElement<NotMemberViewProps> => {
  return (
    <SFPaper className={styles.notMemberView}>
      <div className={styles.icon}>
        <SFIcon icon="Dismiss-App" size={96} />
      </div>

      <h1 className={styles.title}>Agency access disabled.</h1>

      <p className={styles.subText}>
        <span>
          Sorry! Your access to the Agency has been disabled by your
          administrator.{' '}
        </span>
        <span>If you have any questions, please contact your Agency.</span>
      </p>
    </SFPaper>
  );
};
