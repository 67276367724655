import React from 'react';
import styles from './ItemListSection.module.scss';
import { SFButton, SFText, SFTextColor } from 'sfui';
import { Item } from './Item/Item';
import { ItemListConfig, ItemValueType } from '../../../../../Models';
import { checkSectionsFields } from '../../../../../Helpers';
import { Divider } from '../../../../../Components/Divider/Divider';

export interface ItemListSectionMessage {
  value: string;
  color: SFTextColor;
}
export interface ItemListSectionProps {
  className?: string;
  title: string;
  items?: ItemValueType[];
  itemLabel: string;
  config: ItemListConfig;
  disabled?: boolean;
  message?: ItemListSectionMessage;
  onAdd: () => void;
  onSelect: (index: number) => void;
}

export const ItemListSection = ({
  className = '',
  title,
  items = [],
  itemLabel,
  config,
  disabled = false,
  message,
  onAdd,
  onSelect
}: ItemListSectionProps): React.ReactElement<ItemListSectionProps> => {
  return (
    <div className={`${styles.itemListSection} ${className}`}>
      <h3 className={styles.title}>{title}</h3>
      {message && (
        <SFText type="component-1" sfColor={message.color}>
          {message.value}
        </SFText>
      )}

      {items.length > 0 && (
        <div className={styles.list}>
          {items.map((item: ItemValueType, index: number) => (
            <React.Fragment key={`item-${index}`}>
              <Item
                error={!checkSectionsFields(config, item)}
                index={index}
                item={item}
                config={config}
                label={itemLabel}
                onClick={() => onSelect && onSelect(index)}
              />
              {index < items.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </div>
      )}

      <SFButton
        variant="outlined"
        size="large"
        onClick={onAdd}
        disabled={disabled}
        fullWidth
      >
        Add {itemLabel}
      </SFButton>
    </div>
  );
};
