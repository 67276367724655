import React from 'react';
import { SFAlertDialog } from 'sfui';
import { useHistory } from 'react-router-dom';
import { isPlanConnect, useSubscription } from 'ui-smartforce-settings';

function getMessage(plan?: string): string {
  if (isPlanConnect(plan)) {
    return 'Working offline, you can only share your business card.';
  } else {
    return 'Working offline, you can only create reports and share your business card.';
  }
}

export interface NoConnectionModalProps {
  isOpen: boolean;
}

export const NoConnectionModal = ({
  isOpen
}: NoConnectionModalProps): React.ReactElement<NoConnectionModalProps> => {
  const history = useHistory();
  const subscription = useSubscription('cc');

  const onClick = () => {
    if (isPlanConnect(subscription?.plan)) {
      history.push('/cc/business-card');
    } else {
      history.push('/cc/report');
    }
  };

  return (
    <SFAlertDialog
      open={isOpen}
      title="No internet connection."
      contentText={getMessage(subscription?.plan)}
      rightAction={{
        label: 'Go to Home',
        buttonProps: {
          onClick
        }
      }}
    />
  );
};
