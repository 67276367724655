import React from 'react';
import { DottedArrowIcon } from '../../../../../Components/DottedArrowIcon/DottedArrowIcon';
import styles from './LandingFeatureListItem.module.scss';

export interface LandingFeatureListItemProps {
  children: React.ReactNode;
}

export const LandingFeatureListItem = ({
  children
}: LandingFeatureListItemProps): React.ReactElement<LandingFeatureListItemProps> => {
  return (
    <li className={styles.landingFeatureListItem}>
      <DottedArrowIcon />
      <p className={styles.text}>{children}</p>
    </li>
  );
};
