import React from 'react';
import styles from './NumberRangeField.module.scss';
import { InputProps } from '../FieldInput';
import { SFNumericField, SFText } from 'sfui';
import { NumberRange } from '../../../../../Models';

const DEFAULT_NUMBER_VALUE: NumberRange = {
  from: undefined,
  to: undefined
};

interface NumberRangeErrors {
  fromError: boolean;
  toError: boolean;
}

const getFieldErrors = (value?: NumberRange): NumberRangeErrors => {
  let formErrors: NumberRangeErrors = {
    fromError: false,
    toError: false
  };

  if (!value) {
    return formErrors;
  }

  if (value.to === undefined || value.from === undefined) {
    formErrors = {
      fromError: value.from === undefined,
      toError: value.to === undefined
    };
  } else {
    formErrors = {
      fromError: value.to < value.from,
      toError: value.to < value.from
    };
  }

  return formErrors;
};

export const NumberRangeField = (
  props: InputProps
): React.ReactElement<InputProps> => {
  const value: NumberRange =
    (props.value as NumberRange) ?? DEFAULT_NUMBER_VALUE;

  const fieldErrors = getFieldErrors(props.value as NumberRange);

  const onChange = (value: NumberRange) => {
    props.onChange(
      value.from === undefined && value.to === undefined ? undefined : value
    );
  };

  const onNumberFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newNumber: number | undefined =
      e.target.value.length > 0 ? +e.target.value : undefined;

    const newNumberRange: NumberRange = {
      ...value,
      from: newNumber
    };

    onChange(newNumberRange);
  };

  const onNumberToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newNumber: number | undefined =
      e.target.value.length > 0 ? +e.target.value : undefined;

    const newNumberRange: NumberRange = {
      ...value,
      to: newNumber
    };

    onChange(newNumberRange);
  };

  return (
    <div className={styles.numberRangeField}>
      <SFText type="component-2">{props.label}</SFText>
      <div className={styles.fieldContainer}>
        <SFNumericField
          className={styles.field}
          label="From"
          required={props.required}
          value={value.from || ''}
          onChange={onNumberFromChange}
          error={fieldErrors.fromError}
          helperText={
            fieldErrors.fromError
              ? 'Please enter a valid number'
              : props.helperText
          }
          allowDecimals={false}
          numberFormatProps={{
            allowNegative: false
          }}
        />
        <SFNumericField
          className={styles.field}
          label="To"
          required={props.required}
          value={value.to || ''}
          onChange={onNumberToChange}
          error={fieldErrors.toError}
          helperText={
            fieldErrors.toError
              ? 'Please enter a valid number'
              : props.helperText
          }
          allowDecimals={false}
          numberFormatProps={{
            allowNegative: false
          }}
        />
      </div>
    </div>
  );
};
