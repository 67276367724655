import React from 'react';
import styles from './Brand.module.scss';
import { ReactComponent as BrandCenterSVG } from '../../Images/cc_brands-center.svg';
import { ReactComponent as BrandLeftSVG } from '../../Images/cc_brands-left.svg';
import { useHistory } from 'react-router-dom';
import { InteractiveBox } from 'ui-smartforce-settings';

export interface BrandProps {
  className?: string;
  align?: 'left' | 'center';
}

export const Brand = ({
  className = '',
  align = 'left'
}: BrandProps): React.ReactElement<BrandProps> => {
  const history = useHistory();

  const onClick = () => {
    history.replace('/');
  };

  return (
    <h1 style={{ margin: 0 }}>
      <InteractiveBox
        style={{ textAlign: align }}
        className={`${styles.brand} ${className} ${
          align === 'center' ? styles.isCentered : ''
        }`}
        aria-label="Go to home"
        onClick={onClick}
      >
        {align === 'center' ? <BrandCenterSVG /> : <BrandLeftSVG />}
      </InteractiveBox>
    </h1>
  );
};
