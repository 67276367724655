import React from 'react';
import styles from './RegistrationRoleView.module.scss';
import { RoleCard } from './RoleCard/RoleCard';
import { OFFICER_ROLE_ID, OWNER_ROLE_ID } from 'ui-smartforce-settings';

export interface RegistrationRoleViewProps {
  onSelect: (role: string) => void;
}

export const RegistrationRoleView = ({
  onSelect
}: RegistrationRoleViewProps): React.ReactElement<RegistrationRoleViewProps> => {
  return (
    <div className={styles.registrationRoleView}>
      <h2 className={styles.title}>Choose your role</h2>

      <div className={styles.content}>
        <RoleCard
          title="Member"
          description="Members can create, view and amend everything they do."
          onClick={() => onSelect(OFFICER_ROLE_ID)}
        />
        <RoleCard
          title="Owner"
          description="Owners can create and edit the agency information, invite members, assign managers, manage billing and subscriptions. There can be only one per agency."
          onClick={() => onSelect(OWNER_ROLE_ID)}
        />
      </div>
    </div>
  );
};
