import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ErrorPage from '../../Components/ErrorPage/ErrorPage';
import { requestVerifyAccount } from 'ui-smartforce-settings';

interface VerifyTokenErrorViewLocationState {
  data: {
    email: string;
  };
}

export const VerifyTokenErrorView = (): React.ReactElement<{}> => {
  const history = useHistory();
  const location = useLocation();
  const locationState: VerifyTokenErrorViewLocationState =
    location.state as VerifyTokenErrorViewLocationState;

  const onGoHome = async () => {
    if (locationState && locationState.data) {
      await requestVerifyAccount(
        process.env.REACT_APP_SETTINGS_API_BASE_URL as string,
        locationState.data.email as string
      );
    }
    history.replace('/login');
  };

  return (
    <ErrorPage
      icon="Announcement"
      title="The verification token has expired."
      buttonText="Resend Verification Email"
      onGoHome={onGoHome}
    />
  );
};
