import React from 'react';
import { SFPeopleOption, SFPeoplePicker } from 'sfui';
import { AreasContext, Area } from 'ui-smartforce-settings';

const formatOptionsToPeoplePicker = (options: Area[]): SFPeopleOption[] => {
  return options.map((o: Area) => {
    return {
      name: o.name,
      acronym: o.acronym,
      asyncObject: { id: o.id }
    };
  });
};

const getOptionSelected = (option: SFPeopleOption, value: SFPeopleOption) => {
  return option.name === value.name;
};

export interface AreaSelectProps {
  label: string;
  value: SFPeopleOption[];
  onChange: (value: SFPeopleOption[]) => void;
}

export const AreaSelect = (
  props: AreaSelectProps
): React.ReactElement<AreaSelectProps> => {
  const { areas } = React.useContext(AreasContext);

  return (
    <SFPeoplePicker
      label={props.label}
      isAsync={false}
      options={formatOptionsToPeoplePicker(areas)}
      multiple={true}
      value={props.value}
      getOptionSelected={getOptionSelected}
      onChange={props.onChange}
    />
  );
};
