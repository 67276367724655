import React from 'react';
import styles from './Item.module.scss';
import {
  ItemListConfig,
  ItemValueType,
  Section
} from '../../../../../../Models';
import { SectionField } from '../../SectionField/SectionField';

export interface ItemProps {
  title: string;
  item: ItemValueType;
  config: ItemListConfig;
}

export const Item = ({
  title,
  item,
  config
}: ItemProps): React.ReactElement<ItemProps> => {
  return (
    <div className={styles.item}>
      <h4 className={styles.title}>{title}</h4>
      <div className={styles.container}>
        {config.sections.map((section: Section, index: number) => {
          return (
            item[section.name] && (
              <SectionField
                isSubSection
                key={`${section.name}-${index}`}
                config={section}
                value={item[section.name]}
              />
            )
          );
        })}
      </div>
    </div>
  );
};
