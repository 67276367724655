import React, { useContext } from 'react';
import styles from './FooterButtons.module.scss';

import { SFButton } from 'sfui';
import { Divider } from '../../../../Components/Divider/Divider';
import { TourContext, TourTooltip } from 'ui-smartforce-settings';

export interface FooterButtonAction {
  label: string;
  disabled?: boolean;
  onClick: () => void;
}

export interface FooterButtonsProps {
  isAmend?: boolean;
  onCancel?: () => void;
  buttonAction?: FooterButtonAction;
}

const FooterButtons = (
  props: FooterButtonsProps
): React.ReactElement<FooterButtonsProps> => {
  const { tour } = useContext(TourContext);

  return (
    <div className={styles.footerButtons}>
      <Divider className={styles.divider} />

      <div className={styles.buttons}>
        {props.onCancel && (
          <SFButton
            sfColor="grey"
            variant="text"
            size="large"
            onClick={props.onCancel}
          >
            Cancel
          </SFButton>
        )}

        {props.buttonAction && (
          <>
            {(!props.isAmend || (tour?.id !== 6 && tour?.id !== 10)) && (
              <SFButton
                size="large"
                onClick={props.buttonAction.onClick}
                disabled={
                  props.buttonAction.disabled
                    ? props.buttonAction.disabled
                    : false
                }
              >
                {props.buttonAction.label}
              </SFButton>
            )}

            {props.isAmend && tour?.id === 6 && (
              <TourTooltip
                title="Submit your changes"
                description='When you are happy with your edits, click the "Submit" button to upload this new version.'
                step={3}
                lastStep={3}
                tourId={6}
                placement="top-end"
                preventOverflow
              >
                <SFButton
                  size="large"
                  onClick={props.buttonAction.onClick}
                  disabled={
                    props.buttonAction.disabled
                      ? props.buttonAction.disabled
                      : false
                  }
                >
                  {props.buttonAction.label}
                </SFButton>
              </TourTooltip>
            )}

            {props.isAmend && tour?.id === 10 && (
              <TourTooltip
                title="Submit your changes"
                description='When you are happy with your edits, click the "Submit" button to upload this new version.'
                step={3}
                lastStep={3}
                tourId={10}
                placement="top-end"
                preventOverflow
              >
                <SFButton
                  size="large"
                  onClick={props.buttonAction.onClick}
                  disabled={
                    props.buttonAction.disabled
                      ? props.buttonAction.disabled
                      : false
                  }
                >
                  {props.buttonAction.label}
                </SFButton>
              </TourTooltip>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default FooterButtons;
