import React from 'react';
import { SFMaterialUiPickersDate, SFTimeField } from 'sfui';
import { TIME_ERROR_MSG } from '../../../../../Constants/Messages';
import { InputProps } from '../FieldInput';

export const TimeField = (
  props: InputProps
): React.ReactElement<InputProps> => {
  const [isError, setIsError] = React.useState<boolean | undefined>(
    props.error
  );
  const [helperText, setHelperText] = React.useState<string | undefined>(
    props.helperText
  );

  const onTimeFieldChange = (
    date: SFMaterialUiPickersDate,
    value?: string | null | undefined
  ) => {
    if (date) {
      const isTimeValid: boolean = date.isValid();
      setIsError(!isTimeValid);
      setHelperText(isTimeValid ? props.helperText : TIME_ERROR_MSG);
    }

    props.onChange(date);
  };

  React.useEffect(() => {
    if (!isError) {
      setIsError(props.error);
      setHelperText(props.helperText);
    }
  }, [props.error, props.helperText, isError]);

  React.useEffect(() => {
    setIsError(props.error);
  }, [props.error]);

  return (
    <SFTimeField
      label={props.label}
      required={props.required}
      value={props.value as moment.Moment}
      error={isError}
      helperText={helperText}
      onChange={onTimeFieldChange}
      mask="__:__"
      format="HH:mm"
      ampm={false}
    />
  );
};
