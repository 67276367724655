import React from 'react';
import styles from './AdvancedFiltersMenuItem.module.scss';
import { SFText } from 'sfui';
import { FiltersCategoryMarker } from '../../FiltersCategoryMarker/FiltersCategoryMarker';

export interface AdvancedFiltersMenuItemProps {
  label: string;
  isMarked?: boolean;
}

export const AdvancedFiltersMenuItem = ({
  label,
  isMarked = false
}: AdvancedFiltersMenuItemProps): React.ReactElement<AdvancedFiltersMenuItemProps> => {
  return (
    <div className={styles.advancedFiltersMenuItem}>
      <SFText type="component-1">{label}</SFText>
      {isMarked && <FiltersCategoryMarker />}
    </div>
  );
};
