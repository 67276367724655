import React from 'react';
import styles from './RateCard.module.scss';
import { SFBlue, SFIcon } from 'sfui';
import { RateCardConfig } from '../../../../Models/Widget';
import { TextOverflowTooltip } from '../../../TextOverflowTooltip/TextOverflowTooltip';

function getIcon(inflection: number): string {
  if (inflection === -1) {
    return 'Down-7';
  } else if (inflection === 1) {
    return 'Up-7';
  } else {
    return 'Sharring-Rights';
  }
}

export interface RateCardProps extends RateCardConfig {}

export const RateCard = ({
  label,
  percentage,
  inflection,
  legend
}: RateCardProps): React.ReactElement<RateCardProps> => {
  return (
    <div className={styles.rateCard}>
      <div className={styles.data}>
        <TextOverflowTooltip title={label}>
          <span className={styles.value}>{label}</span>
        </TextOverflowTooltip>

        <div className={styles.chip}>
          <SFIcon icon={getIcon(inflection)} size={12} color={SFBlue[600]} />

          {`${Math.round(percentage)}%`}
        </div>
      </div>

      {<p className={styles.legend}>{legend}</p>}
    </div>
  );
};
