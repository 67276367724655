import React from 'react';
import styles from './SectionFieldItem.module.scss';
import { FieldValueType } from '../../../../../../Models';
import { getFieldFormattedValue } from '../../Helpers';

export interface SectionFieldItemProps {
  label: string;
  value: FieldValueType;
  type: string;
}

export const SectionFieldItem = ({
  label,
  value,
  type
}: SectionFieldItemProps): React.ReactElement<SectionFieldItemProps> => {
  return (
    <div className={styles.sectionFieldItem}>
      <h4 className={styles.label}>{label}</h4>
      <p className={styles.value}>{getFieldFormattedValue(value, type)}</p>
    </div>
  );
};
