import React from 'react';
import styles from './ItemListField.module.scss';
import { SFButton, SFText } from 'sfui';
import {
  Field,
  FieldValueType,
  ItemModelValueType
} from '../../../../../Models';
import { InputProps } from '../FieldInput';
import { SectionFormField } from '../../SectionForm/SectionFormField/SectionFormField';

export const ItemListField = (
  props: InputProps
): React.ReactElement<InputProps> => {
  const itemListValue = props.value as ItemModelValueType[];
  const itemLabel: string = props.item_name || 'Item';

  const onChangeValue = (
    field: Field,
    newValue: FieldValueType,
    index: number
  ) => {
    const itemToUpdate: ItemModelValueType = { ...itemListValue[index] };
    itemToUpdate[field.name] = newValue;

    const itemListUpdated: ItemModelValueType[] = [
      ...itemListValue.slice(0, index),
      itemToUpdate,
      ...itemListValue.slice(index + 1)
    ];

    props.onChange(itemListUpdated);
  };

  const newItemList = (itemModel: Field[]) => {
    const newItem: ItemModelValueType = {};

    itemModel.forEach((field: Field) => (newItem[field.name] = undefined));

    return newItem;
  };

  const onAddItem = () => {
    props.onChange([
      ...(itemListValue || []),
      newItemList(props.item_model as Field[])
    ]);
  };

  const onRemoveItem = (index: number) => {
    props.onChange([
      ...itemListValue.slice(0, index),
      ...itemListValue.slice(index + 1)
    ]);
  };

  const isAddDisabled: boolean =
    props.disabled ||
    (!!props.max_items && (itemListValue || []).length >= props.max_items);

  return (
    <div className={styles.itemListField}>
      <label className={styles.listLabel}>{props.label}</label>

      {!props.disabled &&
        props.required &&
        (!itemListValue || itemListValue.length === 0) && (
          <p className={styles.requiredMsg}>
            It takes at least one to continue.
          </p>
        )}

      {props.disabled && props.disabledMessage && (
        <SFText type="component-1" sfColor="neutral">
          {props.disabledMessage}
        </SFText>
      )}

      {itemListValue &&
        itemListValue.length > 0 &&
        itemListValue.map((item: ItemModelValueType, index: number) => {
          return (
            <div key={`item-${index}`} className={styles.itemListFieldContent}>
              <div className={styles.header}>
                <label>{`${itemLabel} ${index + 1}`}</label>
                <SFButton
                  size="small"
                  sfColor="grey"
                  variant="text"
                  onClick={() => onRemoveItem(index)}
                >
                  Delete
                </SFButton>
              </div>

              {props.item_model?.map((field: Field) => {
                return (
                  <SectionFormField
                    key={field.name}
                    field={field}
                    sectionValue={item}
                    onChange={(field: Field, value: FieldValueType) => {
                      onChangeValue(field, value, index);
                    }}
                  />
                );
              })}

              {index < itemListValue.length - 1 && (
                <div className={styles.separator}></div>
              )}
            </div>
          );
        })}

      <SFButton
        className={styles.addButton}
        disabled={isAddDisabled}
        variant="outlined"
        size="large"
        onClick={onAddItem}
        fullWidth
      >
        Add {itemLabel}
      </SFButton>

      {props.helperText && (
        <p
          className={`${styles.helperText} ${props.error ? styles.error : ''}`}
        >
          {props.helperText}
        </p>
      )}
    </div>
  );
};
