import React from 'react';
import { SFButton, SFDrawer, SFScrollable } from 'sfui';
import { SaveObjectPanelProps } from '../SaveObjectPanel';
import { SaveObjectPanelHeader } from '../SaveObjectPanelHeader/SaveObjectPanelHeader';
import styles from './SaveObjectDrawer.module.scss';

export interface SaveObjectDrawerProps extends SaveObjectPanelProps {}

export const SaveObjectDrawer = ({
  isOpen,
  title,
  children,
  saveBtnLabel,
  isSaveDisabled,
  onClose,
  onSave
}: SaveObjectDrawerProps): React.ReactElement<SaveObjectDrawerProps> => {
  return (
    <SFDrawer
      className={styles.saveObjectDrawer}
      anchor="bottom"
      open={isOpen}
      onClose={onClose}
      transitionDuration={{
        enter: 360,
        exit: 360
      }}
    >
      <SaveObjectPanelHeader title={title} onClose={onClose} />

      <SFScrollable className={styles.content}>{children}</SFScrollable>

      <footer>
        <SFButton
          size="large"
          fullWidth
          disabled={isSaveDisabled}
          onClick={(event) => {
            event.currentTarget.disabled = true;
            onSave();
          }}
        >
          {saveBtnLabel}
        </SFButton>
      </footer>
    </SFDrawer>
  );
};
