import { MasonryElementSize } from '../Components/Masonry/MasonryElement/MasonryElement';

interface WidgetComponentSizeDictType {
  [key: string]: MasonryElementSize;
}

const widgetComponentSizeDict: WidgetComponentSizeDictType = {
  rate_card: 'xs',
  ranking_card: 'sm',
  doughnut: 'sm',
  gradient_line: 'md',
  heat_map: 'md',
  cluster_map: 'md',
  sankey: 'md',
  filter_map: 'lg',
  pattern_map: 'lg'
};

export function getWidgetColSize(componentName: string): MasonryElementSize {
  return widgetComponentSizeDict[componentName];
}

export function onShareWidgetClone(
  element: HTMLElement,
  changeHeight: boolean,
  changeWidth: boolean
): void {
  // Add class to adjust element layout
  element.classList.add('SFAnalytics-ShareImage');
  if (changeHeight) {
    element.style.height = 'auto';
  }

  if (changeWidth) {
    element.style.width = 'fit-content';
  }

  //Remove mapZoomButtons
  const map = element.querySelector('div[class*=GoogleMap_module_googleMap]');

  if (map) {
    const zoomButtons: NodeListOf<Element> = map.querySelectorAll(
      'div[class=gmnoprint] > div[draggable="false"]'
    );
    zoomButtons.forEach((e: Element) => e.remove());
  }
}
