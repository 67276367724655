import React from 'react';
import styles from './AddMembers.module.scss';
import {
  ChipFieldValueType,
  SFButton,
  SFChipListInput,
  SFScrollable
} from 'sfui';
import { UserContext, isEmailValid } from 'ui-smartforce-settings';

export interface AddMembersProps {
  members: ChipFieldValueType[];
  onChange: (members: ChipFieldValueType[]) => void;
  onContinue: () => void;
}

export const AddMembers = ({
  members,
  onChange,
  onContinue
}: AddMembersProps): React.ReactElement<AddMembersProps> => {
  const { user } = React.useContext(UserContext);

  const isEmailListInvalid = members.some(
    (member: ChipFieldValueType) => member.isValid === false
  );

  return (
    <div className={styles.addMembers}>
      <SFScrollable className={styles.scrollable}>
        <SFChipListInput
          label="E-mail"
          items={members}
          onChange={onChange}
          isValid={(value: string) =>
            isEmailValid(value) &&
            value.toLocaleLowerCase() !== user?.email?.toLocaleLowerCase()
          }
          helperText="List the emails separated by commas and press ENTER."
          inputMinWidth="full-width"
        />
      </SFScrollable>

      <div className={styles.btn}>
        <SFButton
          size="large"
          fullWidth
          onClick={onContinue}
          disabled={isEmailListInvalid}
        >
          Continue
        </SFButton>
      </div>
    </div>
  );
};
