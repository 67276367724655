import React from 'react';
import { SFIconButton } from 'sfui';

export interface OfflineButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const OfflineButton = ({
  onClick
}: OfflineButtonProps): React.ReactElement<OfflineButtonProps> => {
  return (
    <SFIconButton
      sfIcon="Cloud-Off"
      iconSize={20}
      buttonSize={34}
      onClick={onClick}
      aria-label="Offline"
    />
  );
};
