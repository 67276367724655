import React, { FC } from 'react';
import { isLogin } from 'ui-smartforce-settings';

export type AuthContextState = {
  isLogged: boolean;
  setIsLogged: (isLogged: boolean) => void;
};

const contextDefaultValues: AuthContextState = {
  isLogged: isLogin(),
  setIsLogged: () => {}
};

export const AuthContext =
  React.createContext<AuthContextState>(contextDefaultValues);

export const AuthProvider: FC = ({ children }) => {
  const [isLogged, setIsLogged] = React.useState<boolean>(isLogin());

  return (
    <AuthContext.Provider
      value={{
        isLogged,
        setIsLogged
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
