import React from 'react';
import { SFDatePicker, SFMaterialUiPickersDate } from 'sfui';
import { DATE_ERROR_MSG } from '../../../../../Constants/Messages';
import { InputProps } from '../FieldInput';

export const DateField = (
  props: InputProps
): React.ReactElement<InputProps> => {
  const [isError, setIsError] = React.useState<boolean | undefined>(
    props.error
  );
  const [helperText, setHelperText] = React.useState<string | undefined>(
    props.helperText
  );

  const onDateFieldChange = (
    date: SFMaterialUiPickersDate,
    value?: string | null
  ) => {
    if (date) {
      const isDateValid: boolean = date.isValid();
      setIsError(!isDateValid);
      setHelperText(isDateValid ? props.helperText : DATE_ERROR_MSG);
    }

    props.onChange(date);
  };

  React.useEffect(() => {
    if (!isError) {
      setIsError(props.error);
      setHelperText(props.helperText);
    }
  }, [props.error, props.helperText, isError]);

  React.useEffect(() => {
    setIsError(props.error);
  }, [props.error]);

  return (
    <SFDatePicker
      disableFuture
      error={isError}
      helperText={helperText}
      label={props.label}
      minDate={props.minDate}
      onChange={onDateFieldChange}
      required={props.required}
      value={props.value as moment.Moment}
    />
  );
};
