import React, { Fragment } from 'react';
import { FieldValueType, ReportConfig, ReportValue } from '../../../../Models';
import styles from './ReportBody.module.scss';
import { GeneralInformation } from './GeneralInformation/GeneralInformation';
import { ItemList } from './ItemList/ItemList';
import { StateConfigContext } from '../../../../Context/StateConfigContext';
import { UseOfForceInformation } from './UseOfForceInformation/UseOfForceInformation';
import { DurationInformation } from './DurationInformation/DurationInformation';
import { Divider } from '../../../Divider/Divider';
import { UserContext, checkPermissions } from 'ui-smartforce-settings';
import { AGENCY_REPORTS_READ } from '../../../../Constants/RolesAndPermissions';

export interface ReportBodyProps {
  report: ReportValue;
}

export const ReportBody = ({
  report
}: ReportBodyProps): React.ReactElement<ReportBodyProps> => {
  const stateConfig = React.useContext(StateConfigContext)
    .stateConfig as ReportConfig;
  const { user } = React.useContext(UserContext);

  const showSupervision: boolean = checkPermissions(
    AGENCY_REPORTS_READ,
    user?.role.permissions
  );

  return (
    <div className={styles.reportBody}>
      <h2 className={styles.title}>{`Contact Report`}</h2>
      <GeneralInformation report={report.general_information} />

      {stateConfig && (
        <ItemList
          items={report.citizens}
          config={stateConfig.citizen}
          itemsName="citizen"
        />
      )}

      {stateConfig.use_of_force && report.use_of_force && (
        <Fragment>
          <Divider />

          <UseOfForceInformation
            useOfForce={report.use_of_force}
            config={stateConfig.use_of_force}
          />
          {stateConfig.use_of_force.other_agencies_involved &&
            report.use_of_force.other_agencies_involved.length > 0 && (
              <ItemList
                items={report.use_of_force.other_agencies_involved}
                config={stateConfig.use_of_force.other_agencies_involved}
                itemsName="agency"
              />
            )}
        </Fragment>
      )}

      {report.officers && report.officers.length > 0 && (
        <Fragment>
          <Divider />
          <ItemList
            items={report.officers}
            config={stateConfig.officer}
            itemsName="officer"
          />
        </Fragment>
      )}

      <Divider />
      <DurationInformation
        duration={report.general_information['duration'] as FieldValueType}
      />

      {showSupervision && (
        <Fragment>
          {report.complaints && report.complaints.length > 0 && (
            <Fragment>
              <Divider />
              <ItemList
                items={report.complaints}
                config={stateConfig.complaint}
                itemsName="complaint"
              />
            </Fragment>
          )}

          {report.investigations && report.investigations.length > 0 && (
            <Fragment>
              <Divider />
              <ItemList
                items={report.investigations}
                config={stateConfig.investigation}
                itemsName="investigation"
              />
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
};
