import React from 'react';
import styles from './NoReportsMatches.module.scss';
import { SFBlueMainLight, SFIcon, SFText } from 'sfui';

export interface NoReportsMatchesProps {}

export const NoReportsMatches = (
  props: NoReportsMatchesProps
): React.ReactElement<NoReportsMatchesProps> => {
  return (
    <div className={styles.noReportsMatches}>
      <SFIcon
        className={styles.icon}
        icon="Open-Folder1"
        size={96}
        color={SFBlueMainLight}
      />

      <h2>No matches</h2>

      <SFText type="component-2" sfColor="neutral">
        Please modify the filters to get a different result.
      </SFText>
    </div>
  );
};
