import React, { useMemo } from 'react';
import styles from './PlanUpgrade.module.scss';
import { PlanUpgradeWizard } from './PlanUpgradeWizard/PlanUpgradeWizard';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripeAPI } from 'ui-smartforce-settings';

export const PlanUpgrade = (): React.ReactElement<{}> => {
  const stripeAPI = useMemo(
    () => loadStripeAPI(`${process.env.REACT_APP_STRIPE_KEY}`),
    []
  );

  return (
    <div className={styles.planUpgrade}>
      <Elements stripe={stripeAPI}>
        <PlanUpgradeWizard />
      </Elements>
    </div>
  );
};
