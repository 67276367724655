import React from 'react';
import styles from './PendingReportsMenu.module.scss';
import { SFButton, SFIcon, SFMenu, SFMenuItem } from 'sfui';
import { OnlineStatusContext } from '../../../Context/OnlineStatusContext';
import { getReportDate, getPendingReportTime } from '../../../Helpers';
import { Divider } from '../../Divider/Divider';

const formatReportDate = (isoDate: string) => {
  return `${getReportDate(isoDate)} -
    ${getPendingReportTime(isoDate)}`;
};

export interface PendingReportInfo {
  incidentNumber: string;
  date: string;
}

export interface PendingReportsMenuProps {
  pendingReports: PendingReportInfo[];
  totalPendingReports: number;
  anchorEl: HTMLElement | null;
  isOpen: boolean;
  isUploading?: boolean;
  onClose: () => void;
  onRetry: () => void;
}

export const PendingReportsMenu = ({
  pendingReports,
  totalPendingReports,
  anchorEl,
  isOpen,
  isUploading = false,
  onClose,
  onRetry
}: PendingReportsMenuProps): React.ReactElement<PendingReportsMenuProps> => {
  const { isOnline } = React.useContext(OnlineStatusContext);

  return (
    <SFMenu
      className={styles.pendingReportsMenu}
      open={isOpen}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      {totalPendingReports === 0 && (
        <p className={styles.noReportsMsg}>
          There are no pending reports to be uploaded.
        </p>
      )}

      {totalPendingReports > 0 && (
        <div className={styles.menu}>
          <header>LAST FIVE PENDING REPORTS</header>

          <div className={styles.itemList}>
            {pendingReports.map((report: PendingReportInfo) => (
              <SFMenuItem
                className={styles.item}
                key={`${report.incidentNumber}-${report.date}`}
              >
                <SFIcon className={styles.icon} icon="File-2" />

                <div className={styles.itemText}>
                  <p className={styles.itemTitle}>{report.incidentNumber}</p>

                  <p className={styles.itemSubtitle}>
                    {formatReportDate(report.date as string)}
                  </p>
                </div>
              </SFMenuItem>
            ))}
          </div>

          <Divider />

          <footer>
            <span className={styles.footerMsg}>
              <strong>{totalPendingReports}</strong> pending report
              {totalPendingReports > 1 ? 's' : ''}
            </span>

            <SFButton
              size="small"
              variant="outlined"
              disabled={!isOnline}
              isLoading={isUploading}
              onClick={onRetry}
            >
              {isUploading ? 'Uploading' : 'Retry'}
            </SFButton>
          </footer>
        </div>
      )}
    </SFMenu>
  );
};
