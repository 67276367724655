import React, { Fragment } from 'react';
import styles from './TinyWidgetsContent.module.scss';
import { ReportsTotals } from '../../../../../Models';
import { Divider } from '../../../../../Components/Divider/Divider';
import { TinyWidget } from '../TinyWidget/TinyWidget';

function getTitle(key: string): string {
  if (key === 'uof') return 'Use of Force';
  else if (key === 'cfs') return 'Call for Service';
  else if (key === 'opa') return 'Officer Pro-active';
  else if (key === 'complaints') return 'Complaints';
  else if (key === 'consent_searches') return 'Consent Searches';
  else return 'Total Reports';
}

function isWidgetDisabled(
  totalsKey: string,
  contactTypeFilter?: string
): boolean {
  return (
    (totalsKey === 'cfs' && contactTypeFilter === 'Officer Pro-active') ||
    (totalsKey === 'opa' && contactTypeFilter === 'Call For Service')
  );
}

export interface TinyWidgetsContentProps {
  isExtraLargeScreen: boolean;
  isPhone: boolean;
  totals: ReportsTotals;
  contactTypeFilter?: string;
}

export const TinyWidgetsContent = ({
  isExtraLargeScreen,
  isPhone,
  totals,
  contactTypeFilter
}: TinyWidgetsContentProps): React.ReactElement<TinyWidgetsContentProps> => {
  return (
    <div className={styles.tinyWidgetsContent}>
      {Object.keys(totals).map((totalsKey: string, index: number) => (
        <Fragment key={totalsKey}>
          <TinyWidget
            className={styles.widget}
            title={getTitle(totalsKey)}
            value={totals[totalsKey]}
            disabled={isWidgetDisabled(totalsKey, contactTypeFilter)}
          />
          {index === 2 && !isExtraLargeScreen && !isPhone && (
            <Divider className={styles.horizontalDivider} />
          )}
        </Fragment>
      ))}
    </div>
  );
};
