import React from 'react';
import { SFAlertDialog } from 'sfui';
import { DownloadReportsModalContent } from './DownloadReportsModalContent/DownloadReportsModalContent';

export interface DownloadReportsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const DownloadReportsModal = ({
  isOpen,
  onClose
}: DownloadReportsModalProps): React.ReactElement<DownloadReportsModalProps> => {
  return (
    <SFAlertDialog
      title="Download all reports"
      open={isOpen}
      onClose={onClose}
      rightAction={{
        label: 'Yes, I Understand',
        buttonProps: {
          onClick: onClose
        }
      }}
    >
      <DownloadReportsModalContent />
    </SFAlertDialog>
  );
};
