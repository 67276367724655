import React from 'react';
import { SFAlertDialog, SFText } from 'sfui';

export interface NoReportsToDownloadModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const NoReportsToDownloadModal = ({
  isOpen,
  onClose
}: NoReportsToDownloadModalProps): React.ReactElement<NoReportsToDownloadModalProps> => {
  return (
    <SFAlertDialog
      title="No reports to download"
      open={isOpen}
      onClose={onClose}
      rightAction={{
        label: 'Yes, I Understand',
        buttonProps: {
          onClick: onClose
        }
      }}
    >
      <SFText type="component-2">
        No reports have been found to download either during the given period or
        by the applied filters.
      </SFText>
    </SFAlertDialog>
  );
};
