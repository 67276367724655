import React from 'react';
import styles from './SectionField.module.scss';
import {
  Field,
  FieldValueType,
  ItemModelValueType,
  Section,
  SectionValueType
} from '../../../../../Models';
import { getFieldType } from '../Helpers';
import { SectionFieldItem } from './SectionFieldItem/SectionFieldItem';
import { isFieldEmpty } from 'ui-smartforce-settings';

export interface SectionFieldProps {
  config: Section;
  value: SectionValueType;
  isSubSection?: boolean;
}

const isConditionMet = (
  conditionValue: string,
  value: FieldValueType,
  fieldType: string
) => {
  if (fieldType === 'boolean') {
    //Cast conditionValue string to boolean and compare with value
    return (conditionValue === 'true') === value;
  } else if (Array.isArray(value)) {
    return (value as string[]).indexOf(conditionValue) !== -1;
  } else {
    return conditionValue === value;
  }
};

const renderSectionFieldItem = (
  value: SectionValueType,
  field: Field,
  index: number
) => {
  return (
    <SectionFieldItem
      key={`${field.name}-${index}`}
      label={field.label}
      value={value[field.name]}
      type={getFieldType(field)}
    />
  );
};

const renderSectionFieldItemList = (
  value: FieldValueType,
  field: Field,
  index: number
) => {
  return (
    <div key={`${field.name}-${index}`} className={styles.itemList}>
      <label>{field.label}</label>
      {(value as ItemModelValueType[]).map(
        (itemValue: ItemModelValueType, indexValue: number) => {
          return (
            <div
              key={`${field.name}-${index}-${indexValue}`}
              className={styles.itemListContent}
            >
              {field.item_model
                ?.filter(
                  (item: Field) =>
                    !isFieldEmpty((itemValue as ItemModelValueType)[item.name])
                )
                .map((itemField: Field, indexItem: number) => {
                  let items: JSX.Element[] = getFieldItems(
                    itemField,
                    itemValue,
                    indexItem
                  );

                  return items;
                })}
              {indexValue < (value as ItemModelValueType[]).length - 1 && (
                <div className={styles.separator}></div>
              )}
            </div>
          );
        }
      )}
    </div>
  );
};

const getSectionFieldItem = (
  value: SectionValueType,
  field: Field,
  index: number
): JSX.Element => {
  return field.type === 'itemlist'
    ? renderSectionFieldItemList(value[field.name], field, index)
    : renderSectionFieldItem(value, field, index);
};

const getFieldItems = (
  field: Field,
  sectionValue: SectionValueType | ItemModelValueType,
  index: number
) => {
  let sectionFieldComponents: JSX.Element[] = [];

  function addFieldItems(
    field: Field,
    sectionValue: SectionValueType | ItemModelValueType,
    index: number
  ) {
    sectionFieldComponents = [
      ...sectionFieldComponents,
      getSectionFieldItem(sectionValue, field, index)
    ];

    if (field.condition_if) {
      let conditionMet = false;
      for (const condition of field.condition_if) {
        if (
          isConditionMet(
            condition.selected_option,
            sectionValue[field.name],
            field.type
          )
        ) {
          if (!conditionMet) conditionMet = true;
          for (const condField of condition.fields) {
            sectionValue[condField.name] &&
              addFieldItems(condField, sectionValue, index);
          }
        }
      }

      if (field.condition_else && !conditionMet) {
        for (const condField of field.condition_else) {
          sectionValue[condField.name] &&
            addFieldItems(condField, sectionValue, index);
        }
      }
    }

    if (field.condition_if_not) {
      for (const condition of field.condition_if_not) {
        if (
          !isConditionMet(
            condition.selected_option,
            sectionValue[field.name],
            field.type
          )
        ) {
          for (const condField of condition.fields) {
            sectionValue[condField.name] &&
              addFieldItems(condField, sectionValue, index);
          }
        }
      }
    }
  }

  if (sectionValue && !isFieldEmpty(sectionValue[field.name])) {
    addFieldItems(field, sectionValue, index);
  }

  return sectionFieldComponents;
};

export const SectionField = ({
  config,
  value,
  isSubSection = false
}: SectionFieldProps): React.ReactElement<SectionFieldProps> => {
  return (
    <div
      className={`${styles.sectionField} ${
        isSubSection ? styles.isSubSection : ''
      }`}
    >
      <h4 className={styles.label}>{config.label}</h4>
      <div className={styles.container}>
        {config.fields.map((field: Field, index: number) => {
          let sectionFieldComponents: JSX.Element[] = getFieldItems(
            field,
            value,
            index
          );

          return sectionFieldComponents.length > 0
            ? sectionFieldComponents
            : undefined;
        })}
      </div>
    </div>
  );
};
