import React, { Fragment, useState } from 'react';
import { ItemListSection } from '../../ItemListSection/ItemListSection';
import { ItemListConfig, ItemValueType } from '../../../../../../Models';
import {
  checkSectionsFields,
  getSectionsDefaultValue
} from '../../../../../../Helpers';
import { SaveObjectPanel } from '../../../../../../Components/SaveObjectPanel/SaveObjectPanel';
import { ItemForm } from '../../ItemForm/ItemForm';
import { isEqualObject } from 'ui-smartforce-settings';

export interface OtherAgenciesInvolvedFormProps {
  config: ItemListConfig;
  agencies: ItemValueType[];
  onChange: (agencies: ItemValueType[]) => void;
}

export const OtherAgenciesInvolvedForm = ({
  config,
  agencies,
  onChange
}: OtherAgenciesInvolvedFormProps): React.ReactElement<OtherAgenciesInvolvedFormProps> => {
  const [isOtherAgencyPanelOpen, setIsOtherAgencyPanelOpen] =
    useState<boolean>(false);

  const [selectedAgency, setSelectedAgency] = useState<number>(-1);

  const [agencyValue, setAgencyValue] = useState<ItemValueType>(
    getSectionsDefaultValue(config.sections)
  );

  const onAddAgency = () => {
    setAgencyValue(getSectionsDefaultValue(config.sections));
    setIsOtherAgencyPanelOpen(true);
  };

  const onSelectAgency = (index: number) => {
    setSelectedAgency(index);
    setAgencyValue(agencies[index]);
    setIsOtherAgencyPanelOpen(true);
  };

  const onSaveAgency = () => {
    let newList;

    if (selectedAgency === -1) {
      newList = [...agencies, agencyValue];
    } else {
      newList = [
        ...agencies.slice(0, selectedAgency),
        agencyValue,
        ...agencies.slice(selectedAgency + 1)
      ];
    }

    onChange(newList);
    onCloseAgencyPanel();
  };

  const onCloseAgencyPanel = () => {
    setSelectedAgency(-1);
    setIsOtherAgencyPanelOpen(false);
  };

  const isAgencySaveDisabled: boolean =
    !checkSectionsFields(config, agencyValue) ||
    (selectedAgency !== -1 &&
      isEqualObject<ItemValueType>(agencies[selectedAgency], agencyValue));

  return (
    <Fragment>
      <SaveObjectPanel
        title={`Other Agency ${
          selectedAgency === -1 ? agencies.length + 1 : selectedAgency + 1
        }`}
        isOpen={isOtherAgencyPanelOpen}
        isSaveDisabled={isAgencySaveDisabled}
        saveBtnLabel={selectedAgency !== -1 ? 'Save Changes' : 'Add Agency'}
        onClose={onCloseAgencyPanel}
        onSave={onSaveAgency}
      >
        <ItemForm
          config={config}
          value={agencyValue}
          onChange={(value: ItemValueType) => setAgencyValue(value)}
        />
      </SaveObjectPanel>

      <ItemListSection
        title="Other Agencies Involved"
        items={agencies}
        config={config}
        itemLabel="Other Agency"
        onAdd={onAddAgency}
        onSelect={onSelectAgency}
      />
    </Fragment>
  );
};
