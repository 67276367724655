import styles from './RadioField.module.scss';
import { SFRadio } from 'sfui';
import { InputProps } from '../FieldInput';
import React from 'react';

export interface RadioFieldProps extends InputProps {
  className?: string;
}

export const RadioField = (
  props: RadioFieldProps
): React.ReactElement<RadioFieldProps> => {
  return (
    <div className={`${styles.radioInput} ${props.className ?? ''}`}>
      <label className={styles.fieldLabel}>
        {props.label} {props.required ? '*' : ''}
      </label>

      <div className={styles.options}>
        {props.options?.map((option: string) => (
          <SFRadio
            key={option}
            label={option}
            checked={props.value === option}
            onChange={(_event: React.ChangeEvent<HTMLInputElement>, checked) =>
              checked && props.onChange(option)
            }
          />
        ))}
      </div>
    </div>
  );
};
