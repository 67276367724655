import React from 'react';
import styles from './LandingHighlight.module.scss';
import { LandingHighlightItem } from './LandingHighlightItem/LandingHighlightItem';
import { LandingConfigStats } from '../../../Models/Landing';

export interface LandingHighlightProps {
  config: LandingConfigStats;
}

export const LandingHighlight = ({
  config
}: LandingHighlightProps): React.ReactElement<LandingHighlightProps> => {
  return (
    <div className={styles.landingHighlight}>
      <LandingHighlightItem
        icon={config.first.icon_name}
        count={config.first.count}
        label={config.first.label}
      />
      <LandingHighlightItem
        icon={config.second.icon_name}
        count={config.second.count}
        label={config.second.label}
      />
      <LandingHighlightItem
        icon={config.third.icon_name}
        count={config.third.count}
        label={config.third.label}
      />
    </div>
  );
};
