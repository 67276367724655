import React, { Fragment, useContext } from 'react';
import Loader from '../../Components/Loader/Loader';
import { AuthContainer } from '../../Components/AuthContainer/AuthContainer';
import { RegistrationRoleView } from './Steps/RegistrationRoleView/RegistrationRoleView';
import {
  RegistrationCreateView,
  RegistrationUser
} from './Steps/RegistrationCreateView/RegistrationCreateView';
import { RegistrationVerifyView } from './Steps/RegistrationVerifyView/RegistrationVerifyView';
import { useHistory, useLocation } from 'react-router-dom';
import { OnlineStatusContext } from '../../Context/OnlineStatusContext';
import { isUserAlreadyExist } from '../../Helpers';
import {
  OFFICER_ROLE_ID,
  createAccount,
  requestVerifyAccount,
  User
} from 'ui-smartforce-settings';

interface RegistrationViewLocationState {
  data: {
    email: string;
  };
}

const initUser: RegistrationUser = {
  email: '',
  password: '',
  name: '',
  officerId: '',
  checkPolicies: false
};

export const RegistrationView = (): React.ReactElement<{}> => {
  const history = useHistory();
  const location = useLocation();
  const { isOnline } = useContext(OnlineStatusContext);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [step, setStep] = React.useState<number>(1);
  const [role, setRole] = React.useState<string>();
  const [email, setEmail] = React.useState<string>('');
  const [newUser, setNewUser] = React.useState<RegistrationUser>(initUser);

  const onRoleSelect = (selectedRole: string) => {
    setRole(selectedRole);
    setStep(2);
  };

  const onCreate = async (user: RegistrationUser) => {
    setIsLoading(true);
    setHasError(false);
    setNewUser(user);

    try {
      const newUser: User = await createAccount(
        process.env.REACT_APP_SETTINGS_API_BASE_URL as string,
        user.email,
        user.password,
        user.name,
        user.officerId,
        role as string
      );

      await requestVerifyAccount(
        process.env.REACT_APP_SETTINGS_API_BASE_URL as string,
        newUser.email as string
      );

      setEmail(newUser.email as string);
      setStep(3);
      setIsLoading(false);
    } catch (e) {
      console.error('Registration::CreateAccount', e);
      setIsLoading(false);

      if (isUserAlreadyExist(e)) {
        setHasError(true);
      } else {
        history.push('/error');
      }
    }
  };

  React.useEffect(() => {
    const locationState: RegistrationViewLocationState =
      location.state as RegistrationViewLocationState;

    if (locationState && locationState.data) {
      // Clear the history state
      history.replace({ state: {} });

      setRole(OFFICER_ROLE_ID);
      setNewUser({ ...initUser, email: locationState.data.email });
      setStep(2);
    }
  }, [location, history]);

  React.useEffect(() => {
    if (!isOnline) {
      history.replace('/no-connection');
    }
  }, [isOnline, history]);

  return (
    <AuthContainer>
      <div data-scroll={`RegistrationView-Step-${step}`}>
        {isLoading && <Loader />}
        {!isLoading && (
          <Fragment>
            {step === 1 && <RegistrationRoleView onSelect={onRoleSelect} />}
            {step === 2 && (
              <RegistrationCreateView
                user={newUser}
                isEmailDisabled={!hasError && newUser.email.length > 0}
                onCreate={onCreate}
                hasError={hasError}
              />
            )}
            {step === 3 && <RegistrationVerifyView email={email} />}
          </Fragment>
        )}
      </div>
    </AuthContainer>
  );
};
