import React from 'react';
import styles from './MultipleReportsModalSelectList.module.scss';
import { MultipleReportsModalSelectListOption } from './MultipleReportsModalSelectListOption/MultipleReportsModalSelectListOption';
import { MultipleReportsModalOption } from '../MultipleReportsModalSelect';

export interface MultipleReportsModalSelectListProps {
  className: string;
  options: MultipleReportsModalOption[];
  selected: string;
  onClick: (incidentNumber: string) => void;
}

export const MultipleReportsModalSelectList = ({
  className,
  options,
  selected,
  onClick
}: MultipleReportsModalSelectListProps): React.ReactElement<MultipleReportsModalSelectListProps> => {
  return (
    <ul
      className={`${styles.multipleReportsModalSelectList} ${className}`}
      role="menu"
    >
      {options.map((option: MultipleReportsModalOption) => (
        <MultipleReportsModalSelectListOption
          key={option.incident_number}
          {...option}
          isSelected={option.incident_number === selected}
          onClick={() => onClick(option.incident_number)}
        />
      ))}
    </ul>
  );
};
