import React from 'react';
import { SFLink } from 'sfui';
import styles from './MessageSupport.module.scss';

export interface MessageSupportProps {}

export const MessageSupport = (
  props: MessageSupportProps
): React.ReactElement<MessageSupportProps> => {
  return (
    <p className={styles.messageSupport}>
      If you have not recieved the email in a few minutes, please check your
      spam folder or{' '}
      <SFLink
        sfSize="small"
        color="primary"
        href={`${process.env.REACT_APP_CC_SUPPORT_URL}`}
        target="_blank"
      >
        Contact Support.
      </SFLink>
    </p>
  );
};
