import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { SFScrollable, SFScrollableProps } from 'sfui';

interface IntersectionObserverContextProps {
  observer?: IntersectionObserver;
  intersections: IntersectionObserverEntry[];
}

export const IntersectionObserverContext =
  createContext<IntersectionObserverContextProps>({
    intersections: []
  });

export interface IntersectionObserverHostProps extends SFScrollableProps {
  hostClassName?: string;
  rootMargin?: string;
}

export function IntersectionObserverHost({
  hostClassName,
  children,
  rootMargin = '800px',
  ...props
}: IntersectionObserverHostProps): React.ReactElement<IntersectionObserverHostProps> {
  const refScrollableContainer = useRef<HTMLDivElement>(null);
  const [observer, setObserver] = useState<IntersectionObserver | undefined>();
  const [intersections, setIntersections] = useState<
    IntersectionObserverEntry[]
  >([]);

  useEffect(() => {
    let isSubscribed = true;

    setObserver(
      new IntersectionObserver(
        (entries) => {
          if (isSubscribed) {
            const intersections = entries.filter((e) => e.isIntersecting);
            setIntersections(intersections);
          }
        },
        {
          root: refScrollableContainer.current?.parentElement,
          rootMargin
        }
      )
    );

    return () => {
      isSubscribed = false;
    };
  }, [rootMargin]);

  return (
    <SFScrollable {...props}>
      <div className={hostClassName} ref={refScrollableContainer}>
        <IntersectionObserverContext.Provider
          value={{ observer, intersections }}
        >
          {children}
        </IntersectionObserverContext.Provider>
      </div>
    </SFScrollable>
  );
}

export interface useIntersectionObserverType {
  containerRef: React.RefObject<HTMLDivElement>;
  isVisible: boolean;
}

export const useIntersectionObserver = (): useIntersectionObserverType => {
  const { observer, intersections } = useContext(IntersectionObserverContext);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    if (observer && containerRef.current) {
      observer.observe(containerRef.current);
    }
  }, [observer]);

  useEffect(() => {
    const isIntersecting = intersections.find(
      (i) => i.target === containerRef.current
    );

    if (isIntersecting) {
      setIsVisible(!!isIntersecting);
    }
  }, [intersections]);

  return { containerRef, isVisible };
};

export interface IntersectionObserverClientProps {
  children: React.ReactNode;
}

export function IntersectionObserverClient({
  children
}: IntersectionObserverClientProps): React.ReactElement<IntersectionObserverClientProps> {
  const { observer, intersections } = useContext(IntersectionObserverContext);
  const refContainer = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    if (observer && refContainer.current) {
      observer.observe(refContainer.current);
    }
  }, [observer]);

  useEffect(() => {
    const isIntersecting = intersections.find(
      (i) => i.target === refContainer.current
    );

    if (isIntersecting) {
      setIsVisible(!!isIntersecting);
    }
  }, [intersections]);

  return (
    <div ref={refContainer} style={{ height: '100%' }}>
      {isVisible ? children : null}
    </div>
  );
}
