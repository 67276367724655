import React, { Fragment, useContext } from 'react';
import {
  Field,
  FieldValueType,
  LocationAreaType,
  LocationFormValueType
} from '../../../../Models';
import { AutocompleteField } from '../FieldInput/AutocompleteField/AutocompleteField';
import { LocationField } from '../FieldInput/LocationField/LocationField';
import { TextField } from '../FieldInput/TextField/TextField';
import {
  AreasContext,
  Customer,
  CustomerContext,
  LocationAddressType,
  State,
  StatesListConfigContext
} from 'ui-smartforce-settings';
import { AreasForm } from './AreasForm/AreasForm';
import { getCoordsAreas } from '../../../../Helpers';
import { OnlineStatusContext } from '../../../../Context/OnlineStatusContext';
import { SetLocationModal } from './SetLocationModal/SetLocationModal';

function getCustomerStateShortName(customer: Customer, stateList: State[]) {
  const state = stateList.find(
    (s) => s.name.toLowerCase() === customer.state_name.toLowerCase()
  );

  return state?.abbr;
}

export interface LocationFormProps {
  config: Field;
  value: LocationFormValueType;
  onChange: (value: LocationFormValueType) => void;
}

export const LocationForm = ({
  config,
  value,
  onChange
}: LocationFormProps): React.ReactElement<LocationFormProps> => {
  const { areas } = React.useContext(AreasContext);
  const customer = useContext(CustomerContext).customer as Customer;
  const stateList = useContext(StatesListConfigContext).statesList as State[];
  const isOnline = React.useContext(OnlineStatusContext).isOnline;
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [isCityDisabled, setIsCityDisabled] = React.useState(false);
  const [isZipCodeDisabled, setIsZipCodeDisabled] = React.useState(false);
  const refPrevIsGooglePlace = React.useRef<boolean>(false);

  const onLocationChange = (
    locationValue: LocationAddressType,
    isGooglePlace: boolean = false
  ) => {
    let newValue: LocationFormValueType = {
      ...value
    };

    if (isGooglePlace) {
      newValue.address = locationValue;
    } else {
      // If the previous value was an google place then clear values
      if (refPrevIsGooglePlace.current) {
        newValue.address.city = '';
        newValue.address.zip = '';
        newValue.address.main = '';
        newValue.address.state_id = '';
      }

      if (locationValue.coords) {
        newValue.address.coords = locationValue.coords;
      } else {
        newValue.address.coords = undefined;
      }

      newValue.address.full = locationValue.full;
    }

    if (locationValue.coords) {
      newValue.areas = getCoordsAreas(locationValue.coords, areas);
    } else if (isOnline) {
      newValue.areas = [];
    }

    setIsCityDisabled(!!locationValue.city);
    setIsZipCodeDisabled(!!locationValue.zip);
    onChange(newValue);

    refPrevIsGooglePlace.current = isGooglePlace;
  };

  const onDetailsChange = (details: FieldValueType) =>
    onChange({ ...value, details: details as string });

  const onAddressPropChange = (
    prop: keyof LocationAddressType,
    propValue: string
  ) =>
    onChange({
      ...value,
      address: {
        ...value.address,
        [prop]: propValue
      }
    });

  const onAutocompleteChange = (autocompleteValue: FieldValueType) =>
    onChange({ ...value, type: autocompleteValue as string });

  const onAreasChange = (areas: LocationAreaType[]) => {
    onChange({
      ...value,
      areas: areas
    });
  };

  const onConfirmLocation = (customLocation: LocationAddressType) => {
    onLocationChange(customLocation);
    setIsModalOpen(false);
  };

  const currentLocationState = getCustomerStateShortName(customer, stateList);

  return (
    <Fragment>
      <SetLocationModal
        defaultAddress={value.address.full}
        coords={value.address.coords}
        isOpen={isModalOpen}
        onConfirm={onConfirmLocation}
        onClose={() => setIsModalOpen(false)}
      />

      <LocationField
        label="Location"
        required
        value={value.address}
        currentLocation
        currentLocationState={currentLocationState}
        multiline
        showSetLocation={isOnline}
        onChange={onLocationChange}
        onSetLocation={() => setIsModalOpen(true)}
      />

      <TextField
        required
        maxLength={40}
        label="City"
        disabled={isCityDisabled}
        value={value.address.city}
        onChange={(value: FieldValueType) =>
          onAddressPropChange('city', value as string)
        }
      />

      <TextField
        required
        maxLength={5}
        label="Zip Code"
        disabled={isZipCodeDisabled}
        value={value.address.zip}
        onChange={(value: FieldValueType) =>
          onAddressPropChange('zip', value as string)
        }
      />

      <AreasForm value={value.areas} options={areas} onChange={onAreasChange} />

      <TextField
        label="Location Details"
        value={value.details}
        onChange={onDetailsChange}
      />

      <AutocompleteField
        label={config.label}
        required={config.required}
        value={value.type}
        options={config.options}
        onChange={onAutocompleteChange}
      />
    </Fragment>
  );
};
