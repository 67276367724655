import React, { Fragment, useContext } from 'react';
import {
  UserContext,
  checkPermissions,
  User,
  GoogleMap,
  GoogleMapProps
} from 'ui-smartforce-settings';
import { MapBaseDataType } from '../../Models';
import { MarkerNoCoordinates } from '../MarkerNoCoordinates/MarkerNoCoordinates';
import { AGENCY_REPORTS_READ } from '../../Constants/RolesAndPermissions';
import { useMarkerClick } from '../../Hooks';

export interface ReportsMapProps extends GoogleMapProps {
  reportsWithoutCoords: MapBaseDataType[];
}

export const ReportsMap = ({
  reportsWithoutCoords,
  ...props
}: ReportsMapProps): React.ReactElement<ReportsMapProps> => {
  const user = useContext(UserContext).user as User;

  const userCanSeeReport: boolean = checkPermissions(
    AGENCY_REPORTS_READ,
    user.role.permissions
  );

  const { onMarkerClick } = useMarkerClick(
    userCanSeeReport,
    reportsWithoutCoords
  );

  return (
    <Fragment>
      {reportsWithoutCoords.length > 0 && (
        <MarkerNoCoordinates
          text={`${reportsWithoutCoords.length} ${
            reportsWithoutCoords.length === 1 ? 'Report' : 'Reports'
          } without coordinates`}
          onClick={() => onMarkerClick('noCoords')}
        />
      )}
      <GoogleMap {...props} />
    </Fragment>
  );
};
