import React from 'react';
import {
  DeviceValueType,
  FieldValueType,
  StateRelatedValueType
} from '../../../../Models';
import styles from './DurationInformation.module.scss';
import { SFText } from 'sfui';

export interface DurationInformationProps {
  duration: FieldValueType | StateRelatedValueType | DeviceValueType;
}

export const DurationInformation = ({
  duration
}: DurationInformationProps): React.ReactElement<DurationInformationProps> => {
  const durationString: string = `${duration} minute${
    duration !== 1 ? 's' : ''
  }`;

  return (
    <div className={styles.durationInformation}>
      <div className={styles.h4}>Duration</div>
      <div className={styles.infoItem}>
        <SFText type="component-messages" sfColor="neutral">
          DURATION IN MINUTES
        </SFText>
        <SFText type="component-1">{durationString}</SFText>
      </div>
    </div>
  );
};
