import React from 'react';
import styles from './FiltersCategoryMarker.module.scss';

export interface FiltersCategoryMarkerProps {

};

export const FiltersCategoryMarker = (props: FiltersCategoryMarkerProps): React.ReactElement<FiltersCategoryMarkerProps> => {
    return (
        <div className={styles.filtersCategoryMarker}>
        </div>
    );
}
