import React from 'react';
import { SFNumericField } from 'sfui';
import { InputProps } from '../FieldInput';

export const NumberField = (
  props: InputProps
): React.ReactElement<InputProps> => {
  const value = props.value || props.value === 0 ? props.value.toString() : '';

  const onNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e.target.value.length > 0 ? +e.target.value : undefined);
  };

  return (
    <SFNumericField
      label={props.label}
      required={props.required}
      value={value}
      onChange={onNumberChange}
      error={props.error}
      helperText={props.helperText}
      allowDecimals={false}
      numberFormatProps={{
        allowNegative: false
      }}
    />
  );
};
