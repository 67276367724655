import React, { FC } from 'react';
import { ReportConfig } from '../../Models';

export type UserStateConfig = {
  stateConfig: ReportConfig | undefined;
  setStateConfig: (config: ReportConfig) => void;
};

const contextDefaultValues: UserStateConfig = {
  stateConfig: undefined,
  setStateConfig: () => {}
};

export const StateConfigContext =
  React.createContext<UserStateConfig>(contextDefaultValues);

export const StateConfigProvider: FC = ({ children }) => {
  const [stateConfig, setStateConfig] = React.useState<ReportConfig>();

  return (
    <StateConfigContext.Provider
      value={{
        stateConfig,
        setStateConfig
      }}
    >
      {children}
    </StateConfigContext.Provider>
  );
};
