import React from 'react';
import { SFIcon } from 'sfui';
import styles from './FailedUploadModalContent.module.scss';

export const FailedUploadModalContent = (): React.ReactElement<{}> => {
  return (
    <p className={styles.failedUploadModalContent}>
      Some reports could not be uploaded correctly. Please try later by clicking
      the <SFIcon icon="Cloud-Upload" /> icon above.
    </p>
  );
};
