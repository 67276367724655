import moment from 'moment';
import { HttpStatusCode } from 'sfui';
import set from 'lodash.set';
import {
  AgencyReportsPagination,
  AgencyReportsResponse,
  ReportListFilters,
  ReportResponse,
  ReportValue,
  ReportsTotals
} from '../Models';
import PendingReportsService from './PendingReportsService';
import { apiPost, cloneObject, isValueEmpty } from '../Helpers';
import { getUserSession } from 'ui-smartforce-settings';

const DEFAULT_INCIDENT_DATE_FROM = '2022-01-01T00:00:00Z';
const END_OF_TODAY = moment().endOf('day').toISOString();
const DEFAULT_FILTERS = {
  general_information: {
    date: {
      from: DEFAULT_INCIDENT_DATE_FROM,
      to: END_OF_TODAY
    }
  }
};

interface DateRange {
  from: string;
  to: string;
}

function getIncidentDateRange(
  from: string | undefined,
  to: string | undefined,
  limit: 'months' | 'year'
): DateRange {
  const dateFrom = from
    ? moment(from).startOf('day').toISOString()
    : moment().startOf('day').subtract(1, limit).toISOString();

  let dateTo = '';

  if (to) {
    dateTo = moment(to).endOf('day').toISOString();
  } else if (dateFrom) {
    // Exact date
    dateTo = moment(from).endOf('day').toISOString();
  } else {
    // End of today
    dateTo = END_OF_TODAY;
  }

  return { from: dateFrom, to: dateTo };
}

function getFiltersWithIncidentDate(
  filters: ReportListFilters,
  hasAnalytics: boolean
): any {
  if (isValueEmpty(filters)) {
    return DEFAULT_FILTERS;
  }

  const newFilters: any = cloneObject(filters);

  set(
    newFilters,
    'general_information.date',
    getIncidentDateRange(
      newFilters.general_information?.date?.dateFrom,
      newFilters.general_information?.date?.dateTo,
      hasAnalytics ? 'year' : 'months'
    )
  );

  return newFilters;
}

export type ReportListType = 'me' | 'agency';

export interface SaveReportResponse {
  success: boolean;
  detail?: string;
}

export const sendReport = async (report: ReportValue): Promise<void> => {
  try {
    const url: string = `${process.env.REACT_APP_API_BASE_URL}/reports/`;
    const { id, ...reportToSend } = report;

    const fetchResp = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `bearer ${getUserSession().access_token}`
      }),
      body: JSON.stringify({ ...reportToSend })
    });

    const fetchData = await fetchResp.json();

    if (!fetchResp.ok) {
      return Promise.reject({
        code: fetchResp.status,
        text: fetchResp.statusText,
        detail: fetchData.detail
      });
    }
  } catch (e) {
    return Promise.reject(e);
  }
};

export const saveReport = async (
  user: string,
  report: ReportValue
): Promise<SaveReportResponse> => {
  try {
    if (navigator.onLine) {
      const url: string = `${process.env.REACT_APP_API_BASE_URL}/reports/`;

      const fetchResp = await fetch(url, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `bearer ${getUserSession().access_token}`
        }),
        body: JSON.stringify({ ...report })
      });

      const fetchData = await fetchResp.json();

      if (fetchResp.ok) {
        return { success: true };
      } else {
        if (
          fetchResp.status === HttpStatusCode.BAD_REQUEST ||
          fetchData.detail === 'CREATED_REPORT_ALREADY_EXISTS'
        ) {
          return Promise.reject({
            code: fetchResp.status,
            text: fetchResp.statusText,
            detail: fetchData.detail
          });
        } else {
          await PendingReportsService.saveReport(user, report);
          return { success: false, detail: fetchData.detail };
        }
      }
    } else {
      await PendingReportsService.saveReport(user, report);
      return { success: false, detail: 'ERR_INTERNET_DISCONNECTED' };
    }
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getReportsByUrl = async (url: string): Promise<ReportResponse> => {
  const fetchResponse = await fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `bearer ${getUserSession().access_token}`
    })
  });

  if (fetchResponse.ok) {
    return await fetchResponse.json();
  } else {
    const fetchData = await fetchResponse.json();
    return Promise.reject({
      code: fetchResponse.status,
      text: fetchResponse.statusText,
      detail: fetchData.detail
    });
  }
};

export const getUserReports = async (): Promise<ReportResponse> => {
  const url: string = `${process.env.REACT_APP_API_BASE_URL}/reports/me?limit=10`;
  return getReportsByUrl(url);
};

export type ReportListQuerySortBy = 'newest' | 'oldest';

interface ReportSearchBody {
  filters: ReportListFilters;
  sort_by: ReportListQuerySortBy;
}

export const getAgencyReports = async (
  filters: ReportListFilters,
  sortBy: ReportListQuerySortBy = 'newest',
  hasAnalytics: boolean
): Promise<AgencyReportsResponse> => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/reports/agency/search?limit=10`;
  const token = getUserSession().access_token;

  return apiPost<ReportSearchBody, AgencyReportsResponse>(
    url,
    {
      filters: getFiltersWithIncidentDate(filters, hasAnalytics),
      sort_by: sortBy
    },
    token
  );
};

export const getNextAgencyReports = async ({
  url,
  body
}: AgencyReportsPagination): Promise<AgencyReportsResponse> => {
  return apiPost(url, body, getUserSession().access_token);
};

export const exportReports = async (
  filters: ReportListFilters,
  sort_by: ReportListQuerySortBy = 'newest',
  hasAnalytics: boolean
): Promise<void> => {
  const url: string = `${process.env.REACT_APP_API_BASE_URL}/reports/agency/export`;

  const exportBody: ReportSearchBody = {
    filters: getFiltersWithIncidentDate(filters, hasAnalytics),
    sort_by
  };

  const fetchResp = await fetch(url, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `bearer ${getUserSession().access_token}`
    }),
    body: JSON.stringify(exportBody)
  });

  if (fetchResp.ok) {
    const fetchData = await fetchResp.json();
    return Promise.resolve(fetchData);
  } else {
    const fetchData = await fetchResp.json();
    return Promise.reject({
      code: fetchResp.status,
      text: fetchResp.statusText,
      detail: fetchData.detail
    });
  }
};

export const getReport = async (
  reportListType: ReportListType,
  incidentNumber: string,
  createdAt?: string
): Promise<ReportValue> => {
  const createdAtQuery: string = createdAt ? `?created_at=${createdAt}` : '';
  const url: string = `${process.env.REACT_APP_API_BASE_URL}/reports/${reportListType}/${incidentNumber}${createdAtQuery}`;

  const fetchResp = await fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `bearer ${getUserSession().access_token}`
    })
  });

  if (fetchResp.ok) {
    const fetchData = await fetchResp.json();
    return Promise.resolve(fetchData);
  } else {
    const fetchData = await fetchResp.json();
    return Promise.reject({
      code: fetchResp.status,
      text: fetchResp.statusText,
      detail: fetchData.detail
    });
  }
};

export const amendReport = async (
  type: ReportListType,
  report: ReportValue
): Promise<void> => {
  const baseURL: string = `${process.env.REACT_APP_API_BASE_URL}/reports${
    type === 'agency' ? '/agency' : ''
  }`;
  const url: string = `${baseURL}/${report.general_information.incident_number}/amend`;

  const fetchResp = await fetch(url, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `bearer ${getUserSession().access_token}`
    }),
    body: JSON.stringify({ ...report })
  });

  if (fetchResp.ok) {
    const fetchData = await fetchResp.json();
    return Promise.resolve(fetchData);
  } else {
    const fetchData = await fetchResp.json();
    return Promise.reject({
      code: fetchResp.status,
      text: fetchResp.statusText,
      detail: fetchData.detail
    });
  }
};

export const getTotals = async (
  filters: ReportListFilters
): Promise<ReportsTotals> => {
  const url: string = `${process.env.REACT_APP_API_BASE_URL}/reports/agency/calculate-totals`;
  return apiPost(
    url,
    getFiltersWithIncidentDate(filters, true),
    getUserSession().access_token
  );
};
