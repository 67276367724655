import React from 'react';
import styles from './PatternMapItem.module.scss';
import {
  PatterMapItemIncident,
  PatterMapItemIncidentData
} from './PatterMapItemIncident/PatterMapItemIncident';

export interface PatternMapItemProps {
  title: string;
  incidents: PatterMapItemIncidentData[];
  isClickable: boolean;
  onIncidentClick?: (data: PatterMapItemIncidentData) => void;
}

export const PatternMapItem = ({
  title,
  incidents,
  isClickable,
  onIncidentClick
}: PatternMapItemProps): React.ReactElement<PatternMapItemProps> => {
  return (
    <div className={styles.patternMapItem}>
      <span className={styles.title}>{title}</span>

      {incidents.map((incident: PatterMapItemIncidentData) => (
        <PatterMapItemIncident
          key={`${incident.incident_number}-${incident.index}`}
          {...incident}
          isClickable={isClickable}
          onClick={() => onIncidentClick && onIncidentClick(incident)}
        />
      ))}
    </div>
  );
};
