import { ANALYTICS_COLOR_BLUE } from '../Constants/Colors';
import {
  MARKER_MAX_DISTANCE,
  TOLERANCE_RADIUS_MTS_DISTANCE
} from '../Constants/Maps';
import {
  MARKER_003D99,
  MARKER_0096CC,
  MARKER_0D69F2,
  MARKER_1F7A37,
  MARKER_3DC4F5,
  MARKER_40BF62,
  MARKER_66A3FF,
  MARKER_840AC2,
  MARKER_84E09D,
  MARKER_99E4FF,
  MARKER_B447EB,
  MARKER_C20A0A,
  MARKER_CC6900,
  MARKER_CCAD00,
  MARKER_D742BE,
  MARKER_DB9EFA,
  MARKER_EB4747,
  MARKER_F075DB,
  MARKER_F59C3D,
  MARKER_F5D93D,
  MARKER_FA9E9E,
  MARKER_FBB6F0,
  MARKER_FFCE99,
  MARKER_FFF099
} from '../Images/Analytics/Markers';

import {
  FilteredReportsDataType,
  Coords,
  MapBaseDataType
} from '../Models/Widget';

import booleanIntersects from '@turf/boolean-intersects';
import { point as TurfPoint, polygon as TurfPolygon } from '@turf/helpers';
import TurfCircle from '@turf/circle';
import {
  Area,
  GeoLocationCoords,
  LocationAreaType
} from 'ui-smartforce-settings';

interface MarkerDict {
  [key: string]: string;
}

export const transformCoordsToLatLng = (coords: Coords): google.maps.LatLng => {
  return new google.maps.LatLng(coords.lat, coords.long);
};

export const getFormattedNumber = (index: number) => {
  return index < 10 ? '0' + String(index) : String(index);
};

const MARKERS: MarkerDict = {
  '#0D69F2': MARKER_0D69F2,
  '#3DC4F5': MARKER_3DC4F5,
  '#40BF62': MARKER_40BF62,
  '#F5D93D': MARKER_F5D93D,
  '#F59C3D': MARKER_F59C3D,
  '#EB4747': MARKER_EB4747,
  '#F075DB': MARKER_F075DB,
  '#B447EB': MARKER_B447EB,
  '#66A3FF': MARKER_66A3FF,
  '#99E4FF': MARKER_99E4FF,
  '#84E09D': MARKER_84E09D,
  '#FFF099': MARKER_FFF099,
  '#FFCE99': MARKER_FFCE99,
  '#FA9E9E': MARKER_FA9E9E,
  '#FBB6F0': MARKER_FBB6F0,
  '#DB9EFA': MARKER_DB9EFA,
  '#1F7A37': MARKER_1F7A37,
  '#003D99': MARKER_003D99,
  '#0096CC': MARKER_0096CC,
  '#840AC2': MARKER_840AC2,
  '#C20A0A': MARKER_C20A0A,
  '#CC6900': MARKER_CC6900,
  '#CCAD00': MARKER_CCAD00,
  '#D742BE': MARKER_D742BE
};

export const getCenter = (
  data: MapBaseDataType[]
): google.maps.LatLng | undefined => {
  let latitudes: number[] = [];
  let longitudes: number[] = [];

  data.forEach((value: MapBaseDataType): void => {
    if (value.coords) {
      const coords = value.coords;
      latitudes.push(coords.lat);
      longitudes.push(coords.long);
    }
  });

  if (latitudes.length === 0) {
    return undefined;
  }

  const lat: number = (Math.max(...latitudes) + Math.min(...latitudes)) / 2;
  const long: number = (Math.max(...longitudes) + Math.min(...longitudes)) / 2;

  return transformCoordsToLatLng({ lat, long });
};

export const getBounds = (
  data: MapBaseDataType[]
): google.maps.LatLngBounds => {
  const bounds = new google.maps.LatLngBounds();

  data.forEach((value: MapBaseDataType) => {
    if (value.coords) {
      bounds.extend(transformCoordsToLatLng(value.coords));
    }
  });

  return bounds;
};

export const getMarker = (
  position: google.maps.LatLng,
  color: string = ANALYTICS_COLOR_BLUE,
  index: number = 1
): google.maps.MarkerOptions => {
  const marker = MARKERS[color];

  return {
    position: position,
    opacity: 0.8,
    zIndex: Number(google.maps.Marker.MAX_ZINDEX) + index,
    icon: {
      url: marker,
      scaledSize: new google.maps.Size(15, 15)
    }
  };
};

export const getCountMarker = (
  position: google.maps.LatLng,
  count: number,
  color: string = ANALYTICS_COLOR_BLUE
): google.maps.MarkerOptions => {
  const marker = MARKERS[color];

  return {
    position: position,
    opacity: 0.8,
    zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
    label: {
      text: getFormattedNumber(count),
      color: 'rgba(255,255,255,0.9)',
      fontSize: '12px'
    },
    icon: {
      url: marker,
      scaledSize: new google.maps.Size(40, 40)
    }
  };
};

export const filterReportsByCoords = (
  data: MapBaseDataType[]
): FilteredReportsDataType => {
  const reportsWithCoords: MapBaseDataType[] = [];
  const reportsWithoutCoords: MapBaseDataType[] = [];

  data.forEach((value: MapBaseDataType) => {
    if (!!value.coords) {
      reportsWithCoords.push(value);
    } else {
      reportsWithoutCoords.push(value);
    }
  });

  return { reportsWithCoords, reportsWithoutCoords };
};

export function getCloseItems(
  item: MapBaseDataType,
  data: MapBaseDataType[]
): MapBaseDataType[] {
  if (!item.coords) {
    return [];
  }

  const closeData: MapBaseDataType[] = [];
  for (let dataItem of data) {
    if (dataItem.coords) {
      const distance = google.maps.geometry.spherical.computeDistanceBetween(
        new google.maps.LatLng(item.coords.lat, item.coords.long),
        new google.maps.LatLng(dataItem.coords.lat, dataItem.coords.long)
      );

      if (distance <= MARKER_MAX_DISTANCE) {
        closeData.push(dataItem);
      }
    }
  }

  return closeData;
}

function getTurfPolygon(area: Area) {
  let array = area.paths.map((p: google.maps.LatLngLiteral) => [p.lng, p.lat]);
  array.push([area.paths[0].lng, area.paths[0].lat]);
  return TurfPolygon([array]);
}

// Check if the circle with a center and a radius (tolerance) intersects the area
function isCoordsInArea(coords: GeoLocationCoords, area: Area): boolean {
  const polygon = getTurfPolygon(area);
  const turfCenter = TurfPoint([coords.longitude, coords.latitude]);
  const tolerance = TurfCircle(turfCenter, TOLERANCE_RADIUS_MTS_DISTANCE, {
    units: 'meters'
  });

  return booleanIntersects(polygon, tolerance);
}

export function getCoordsAreas(
  coords: GeoLocationCoords,
  areas: Area[]
): LocationAreaType[] {
  return areas
    .filter(
      (area: Area) => area.paths.length > 0 && isCoordsInArea(coords, area)
    )
    .map((area: Area) => ({
      id: area.id,
      name: area.name
    }));
}
