import React from 'react';
import { SFPeopleOption, SFPeoplePicker } from 'sfui';
import { Group } from 'ui-smartforce-settings';

const formatOptionsToPeoplePicker = (options: Group[]): SFPeopleOption[] => {
  return options.map((o: Group) => {
    return {
      name: o.name,
      acronym: o.acronym,
      asyncObject: { id: o.id }
    };
  });
};

const getOptionSelected = (option: SFPeopleOption, value: SFPeopleOption) => {
  return option.name === value.name;
};

export interface GroupFilterProps {
  options: Group[];
  value: SFPeopleOption[];
  onChange: (value: SFPeopleOption[]) => void;
}

export const GroupFilter = (
  props: GroupFilterProps
): React.ReactElement<GroupFilterProps> => {
  return (
    <SFPeoplePicker
      label="Group"
      isAsync={false}
      options={formatOptionsToPeoplePicker(props.options)}
      multiple={true}
      value={props.value}
      getOptionSelected={getOptionSelected}
      onChange={props.onChange}
    />
  );
};
