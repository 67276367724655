import React from 'react';
import styles from './ReportSubmittedView.module.scss';

import { SFAlert, SFButton, SFIcon } from 'sfui';
import {
  UserContext,
  User,
  removeUserSession,
  ContactQRCode,
  QRCodeModal,
  InteractiveBox
} from 'ui-smartforce-settings';
import { useHistory } from 'react-router-dom';

export interface ReportSubmittedViewProps {
  incidentNumber: string;
  eventIdentifier?: string;
  isSubmitted: boolean;
  isSessionExpire: boolean;
  onStartNewReport?: () => void;
}

const ReportSubmittedView = ({
  isSubmitted,
  isSessionExpire,
  incidentNumber,
  eventIdentifier,
  onStartNewReport
}: ReportSubmittedViewProps): React.ReactElement<ReportSubmittedViewProps> => {
  const history = useHistory();
  const { user } = React.useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  let alertTitle: string = isSessionExpire
    ? 'This report could not be submitted because your session has expired.'
    : 'This report could not be submitted.';

  const onFinish = () => {
    if (isSessionExpire) {
      //Remove stored session
      removeUserSession();
      history.push('/login');
    } else {
      onStartNewReport && onStartNewReport();
    }
  };

  return (
    <div
      className={`${styles.reportSubmittedView} ${
        isSubmitted ? styles.isSubmitted : ''
      }`}
    >
      <div className={styles.container}>
        {!isSubmitted && (
          <SFAlert className={styles.alert} title={alertTitle} type="info">
            <span>
              Your data was saved on this device. Please try uploading it later
              by clicking the{' '}
              <SFIcon className={styles.icon} icon="Cloud-Upload" /> icon above.
            </span>
          </SFAlert>
        )}

        <InteractiveBox
          className={styles.qrCode}
          onClick={() => setIsModalOpen(true)}
        >
          <ContactQRCode
            baseUrl={process.env.REACT_APP_CC_PUBLIC_URL as string}
            className={styles.svgImage}
            size={200}
            user={user as User}
            incidentNumber={incidentNumber}
            eventIdentifier={eventIdentifier}
          />
        </InteractiveBox>

        <h2 className={styles.title}>
          Report {isSubmitted ? 'submitted' : 'saved'}
        </h2>

        <p className={styles.subTitle}>Tap the report's QR code to share it.</p>
        <div className={styles.footer}>
          <SFButton onClick={onFinish}>
            {isSessionExpire ? 'Go to Log in' : 'Create New Report'}
          </SFButton>
        </div>

        <QRCodeModal
          baseUrl={process.env.REACT_APP_CC_PUBLIC_URL as string}
          isOpen={isModalOpen}
          title="Scan the QR code"
          subTitle="The citizen can scan this QR code to follow the case."
          onClose={() => setIsModalOpen(false)}
          user={user as User}
          incidentNumber={incidentNumber}
          eventIdentifier={eventIdentifier}
        />
      </div>
    </div>
  );
};

export default ReportSubmittedView;
