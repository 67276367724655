import React from 'react';
import styles from './LinksPanel.module.scss';
import { SFDrawer, SFIconButton } from 'sfui';
import { HeaderLink } from '../LandingTopBar';

export interface LinksPanelProps {
  isOpen: boolean;
  links: HeaderLink[];
  selected: string;
  onClose: () => void;
  onClick: (link: HeaderLink) => void;
}

export const LinksPanel = ({
  isOpen,
  links,
  selected,
  onClose,
  onClick
}: LinksPanelProps): React.ReactElement<LinksPanelProps> => {
  const onLinkSelected = (link: HeaderLink) => {
    onClick(link);
    onClose();
  };

  const onKeyUp = (e: React.KeyboardEvent<HTMLLIElement>, link: HeaderLink) => {
    if (e.key === 'Enter') {
      onLinkSelected(link);
    }
  };

  return (
    <SFDrawer
      anchor="right"
      PaperProps={{ className: styles.linksPanel }}
      open={isOpen}
      onClose={onClose}
    >
      <div className={styles.header}>
        <SFIconButton sfIcon="Close" sfSize="medium" onClick={onClose} />
      </div>

      <ul className={styles.links} role="menu">
        {links.map((link: HeaderLink) => (
          <li
            key={link.label}
            className={`${styles.link} ${
              selected === link.name ? styles.selected : undefined
            }`}
            role="menuitem"
            onClick={() => onLinkSelected(link)}
            onKeyUp={(e) => onKeyUp(e, link)}
          >
            <div className={styles.pointer} />
            <p className={styles.text}> {link.label}</p>
          </li>
        ))}
      </ul>
    </SFDrawer>
  );
};
