import React, { useContext } from 'react';
import styles from './FeatureTourContentLink.module.scss';
import { SFIcon } from 'sfui';
import {
  InteractiveBox,
  MediaContext,
  TourContext,
  ToursReminderTooltip,
  hideTours
} from 'ui-smartforce-settings';
import { SFNavContentLinkProps } from '../SFNavContentLink/SFNavContentLink';

export interface FeatureTourContentLinkProps extends SFNavContentLinkProps {
  onClick: () => void;
}

const FeatureTourContentLink = ({
  link,
  disabled = false,
  onClick
}: FeatureTourContentLinkProps): React.ReactElement<FeatureTourContentLinkProps> => {
  const { isFeatureReminderOpen, setIsFeatureReminderOpen, onDisableReminder } =
    useContext(TourContext);
  const { isLargeScreen } = useContext(MediaContext);

  const onGotIt = (checked: boolean) => {
    if (checked) {
      hideTours(process.env.REACT_APP_SETTINGS_API_BASE_URL as string, 'cc');
      onDisableReminder();
    }
    setIsFeatureReminderOpen(false);
  };

  return (
    <div
      className={`${styles.linkContainer} ${disabled ? styles.disabled : ''}`}
    >
      <InteractiveBox className={styles.link} onClick={onClick}>
        <SFIcon icon={link.icon} className={styles.icon} size={20} />

        <ToursReminderTooltip
          open={isLargeScreen && isFeatureReminderOpen}
          onGotIt={onGotIt}
        >
          <div className={styles.linkContent}>
            <span className={styles.text}>{link.label}</span>
            <div className={styles.blueDot}></div>
          </div>
        </ToursReminderTooltip>
      </InteractiveBox>
    </div>
  );
};

export default FeatureTourContentLink;
