import React, { useContext, useMemo, useState } from 'react';
import styles from './AdvancedFilters.module.scss';
import { SFMenuOption, SFScrollable } from 'sfui';
import { AdvancedFiltersMenu } from './AdvancedFiltersMenu/AdvancedFiltersMenu';
import {
  FiltersCategoryValueType,
  ReportFiltersConfig,
  ReportListFilters
} from '../../../../../Models';
import { FilterCategoryForm } from './FilterCategoryForm/FilterCategoryForm';
import { StateConfigContext } from '../../../../../Context/StateConfigContext';
import { cloneObject, isValueEmpty } from '../../../../../Helpers';

function getCategories(config?: ReportFiltersConfig): SFMenuOption[] {
  if (!config) {
    return [];
  }

  let result: SFMenuOption[] = [];

  for (let key in config) {
    result.push({
      value: key,
      label: config[key].title
    });
  }

  return result;
}

function getMarkedCategories(filters: ReportListFilters): string[] {
  let categories: string[] = [];
  for (let key in filters) {
    if (!isValueEmpty(filters[key])) {
      categories.push(key);
    }
  }
  return categories;
}

export interface AdvancedFiltersProps {
  value: ReportListFilters;
  onChange: (value: ReportListFilters) => void;
}

export const AdvancedFilters = ({
  value,
  onChange
}: AdvancedFiltersProps): React.ReactElement<AdvancedFiltersProps> => {
  const filtersConfig = useContext(StateConfigContext).stateConfig
    ?.filters as ReportFiltersConfig;
  const [selectedCategory, setSelectedCategory] = useState<string>(
    'general_information'
  );

  const categories = useMemo(
    () => getCategories(filtersConfig),
    [filtersConfig]
  );

  const categoriesMarked = getMarkedCategories(value);

  return (
    <div className={styles.advancedFilters}>
      <AdvancedFiltersMenu
        className={styles.menu}
        selected={selectedCategory}
        categories={categories}
        categoriesMarked={categoriesMarked}
        onSelect={(selected: string) => setSelectedCategory(selected)}
        onClear={() => onChange({})}
      />

      <div className={styles.form}>
        <SFScrollable className={styles.scrollable}>
          <FilterCategoryForm
            config={filtersConfig[selectedCategory]}
            value={value[selectedCategory] || {}}
            onChange={(categoryValue: FiltersCategoryValueType) => {
              if (Object.keys(categoryValue).length === 0) {
                const newValue = cloneObject(value);
                delete newValue[selectedCategory];
                onChange(newValue);
              } else {
                onChange({
                  ...value,
                  [selectedCategory]: categoryValue
                });
              }
            }}
          />
        </SFScrollable>
      </div>
    </div>
  );
};
