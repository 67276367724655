import React from 'react';
import styles from './FieldItemList.module.scss';
import { FieldValueType } from '../../../../../../Models';
import { SectionFieldItem } from '../../SectionField/SectionFieldItem/SectionFieldItem';

export type FieldItemListType = {
  label: string;
  type: string;
  value: FieldValueType;
};

export interface FieldItemListProps {
  title: string;
  fields: FieldItemListType[];
}

export const FieldItemList = ({
  title,
  fields
}: FieldItemListProps): React.ReactElement<FieldItemListProps> => {
  return (
    <div className={styles.fieldItemList}>
      <h4 className={styles.title}>{title}</h4>
      <div className={styles.container}>
        {fields.map(
          (field: FieldItemListType) =>
            field.value && (
              <SectionFieldItem
                key={field.label}
                label={field.label}
                value={field.value}
                type={field.type}
              />
            )
        )}
      </div>
    </div>
  );
};
