import React from 'react';
import { InputProps } from '../FieldInput';
import { SFTextField } from 'sfui';
import { UserTitle } from 'ui-smartforce-settings';

export interface TitleFieldProps extends InputProps {}

export const TitleField = (
  props: TitleFieldProps
): React.ReactElement<TitleFieldProps> => {
  const titleValue = props.value as UserTitle;
  return (
    <>
      {titleValue && titleValue.name.length >= 0 && (
        <SFTextField label={props.label} disabled value={titleValue.name} />
      )}
    </>
  );
};
