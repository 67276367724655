import React from 'react';
import { checkConditional } from '../../../../../Helpers';
import { Field, FieldValueType, SectionValueType } from '../../../../../Models';
import { FieldInput } from '../../FieldInput/FieldInput';

export interface SectionFormFieldProps {
  disabled?: boolean;
  disabledMessage?: string;
  field: Field;
  sectionValue: SectionValueType;
  onChange: (field: Field, value: FieldValueType) => void;
}

export const SectionFormField = ({
  disabled,
  disabledMessage,
  field,
  sectionValue,
  onChange
}: SectionFormFieldProps): React.ReactElement<SectionFormFieldProps> => {
  const renderConditionalInputFields = (field: Field) => {
    let fieldsToAdd: Field[] = [];

    if (field.condition_if) {
      let conditionMet = false;
      for (let conditional of field.condition_if) {
        if (
          checkConditional(
            'conditional_if',
            conditional,
            sectionValue[field.name]
          )
        ) {
          if (!conditionMet) conditionMet = true;
          fieldsToAdd = [...fieldsToAdd, ...conditional.fields];
        }
      }

      if (field.condition_else && !conditionMet) {
        fieldsToAdd = [...fieldsToAdd, ...field.condition_else];
      }
    }

    if (field.condition_if_not) {
      for (let conditional of field.condition_if_not) {
        if (
          checkConditional(
            'conditional_if_not',
            conditional,
            sectionValue[field.name]
          )
        ) {
          fieldsToAdd = [...fieldsToAdd, ...conditional.fields];
        }
      }
    }

    return fieldsToAdd.map((field: Field) => (
      <SectionFormField
        key={field.name}
        field={field}
        sectionValue={sectionValue}
        onChange={onChange}
      />
    ));
  };

  return (
    <React.Fragment>
      <FieldInput
        disabled={disabled}
        disabledMessage={disabledMessage}
        key={field.name}
        field={field}
        value={sectionValue[field.name]}
        onChange={(value: FieldValueType) => onChange(field, value)}
      />

      {(field.condition_if || field.condition_if_not || field.condition_else) &&
        renderConditionalInputFields(field)}
    </React.Fragment>
  );
};
