import { isSafari } from './browser';

export function cloneDiv(node: HTMLDivElement): HTMLDivElement {
  const newClone = node.cloneNode(true) as HTMLDivElement;

  const canvases: HTMLCanvasElement[] = Array.from(
    node.querySelectorAll('canvas')
  );

  if (canvases.length > 0) {
    const newCanvases: NodeListOf<HTMLCanvasElement> =
      newClone.querySelectorAll('canvas');

    Array.from(newCanvases).forEach((newCanvas, i) => {
      let context = newCanvas.getContext('2d') as CanvasRenderingContext2D;
      context.drawImage(canvases[i], 0, 0);
    });
  }

  return newClone;
}

function drawNodes(
  context: CanvasRenderingContext2D,
  rectTarget: DOMRect,
  nodes: Element[],
  type: 'canvas' | 'tile' | 'svg' | 'text' | 'legend'
) {
  for (let node of nodes) {
    const rectNode = node.getBoundingClientRect();
    let sw, sh, sx;

    // It's outside the target, dont draw
    if (
      rectNode.x + rectNode.width < rectTarget.x ||
      rectNode.y + rectNode.height < rectTarget.y
    ) {
      continue;
    }

    const dw = rectNode.width;
    const dh = rectNode.height;
    const dx = Math.max(0, rectNode.x - rectTarget.x);
    const dy = rectNode.y - rectTarget.y;

    if (type === 'canvas') {
      const canvasNode = node as HTMLCanvasElement;
      const ratio = canvasNode.width / rectNode.width;
      sw = rectTarget.x < rectNode.x ? canvasNode.width : dw * ratio;
      sh = canvasNode.height;
      sx = rectTarget.x < rectNode.x ? 0 : (rectTarget.x - rectNode.x) * ratio;
      context.globalAlpha = 0.7;
      context.drawImage(canvasNode, sx, 0, sw, sh, dx, dy, dw, dh);
      context.restore();
    } else if (type === 'tile') {
      const imgNode = node as HTMLImageElement;
      const ratio = imgNode.naturalWidth / imgNode.width;
      sw = rectTarget.x < rectNode.x ? imgNode.naturalWidth : dw * ratio;
      sh = imgNode.naturalHeight;
      sx = rectTarget.x < rectNode.x ? 0 : (rectTarget.x - rectNode.x) * ratio;
      context.drawImage(imgNode, sx, 0, sw, sh, dx, dy, dw, dh);
    } else if (type === 'svg') {
      const imgNode = node as HTMLImageElement;
      // Check if svg its base64, to resolve Safari issues
      if (isSafari() && imgNode.src.indexOf('base64') !== -1) {
        const cloneImg = imgNode.cloneNode() as HTMLImageElement;
        const data = cloneImg.src.substring(26, cloneImg.src.length);
        cloneImg.src =
          imgNode.src.substring(0, 18) + ',' + encodeURIComponent(atob(data));
        context.drawImage(cloneImg, dx, dy, dw, dh);
      } else {
        context.drawImage(imgNode, dx, dy, dw, dh);
      }
    } else if (type === 'text') {
      const divNode = node as HTMLDivElement;
      const fontSize = +divNode.style.fontSize.replace('px', '');
      const space = rectNode.height - fontSize;
      context.font = `${divNode.style.fontSize} ${divNode.style.fontFamily}`;
      // This was a simple way to avoid text overlapping
      context.fillStyle = 'transparent';
      context.fillText(
        divNode.textContent || '',
        dx,
        dy + (rectNode.height - space * 2)
      );
    } else if (type === 'legend') {
      const divNode = node as HTMLDivElement;
      context.globalAlpha = 1;
      context.fillStyle = divNode.style.backgroundColor;
      context.fillRect(dx, dy, dw, dh);

      context.strokeStyle = divNode.style.borderColor;
      context.strokeRect(dx, dy, dw, dh);

      const padding = +divNode.style.padding.replace('px', '');
      const lineHeight = +divNode.style.lineHeight.replace('px', '');
      context.font = `${divNode.style.fontSize} Roboto`;
      context.fillStyle = divNode.style.color;
      context.fillText(
        divNode.textContent || '',
        dx + padding,
        dy + padding + lineHeight
      );
    }
  }
}

export function drawGoogleMap(target: HTMLDivElement): HTMLCanvasElement {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d') as CanvasRenderingContext2D;
  const rectTarget = target.getBoundingClientRect();

  canvas.width = target.offsetWidth;
  canvas.height = target.offsetHeight;

  const imgs = Array.from(target.querySelectorAll('img[draggable="false"]'));

  const markers = imgs.filter(
    (img: any) =>
      img.src &&
      (img.src.indexOf('svg') !== -1 || img.src.indexOf('png') !== -1)
  );

  const tiles = imgs.filter(
    (img: any) =>
      img.src && img.src.indexOf('svg') === -1 && img.src.indexOf('svg') === -1
  );

  const canvases = Array.from(
    target.querySelectorAll('canvas[draggable="false"]')
  );

  const labels = Array.from(target.querySelectorAll('div[aria-hidden="true"]'));

  const legend = [target.querySelector('.sfMapLegend') as Element];

  drawNodes(context, rectTarget, tiles, 'tile');
  drawNodes(context, rectTarget, markers, 'svg');
  drawNodes(context, rectTarget, canvases, 'canvas');
  drawNodes(context, rectTarget, labels, 'text');
  drawNodes(context, rectTarget, legend, 'legend');

  return canvas;
}
