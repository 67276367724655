import React from 'react';
import styles from './ContactMap.module.scss';
import { ContactMapNoCoordinates } from './ContactMapNoCoordinates/ContactMapNoCoordinates';
import { StaticMap } from './StaticMap/StaticMap';
import { GeoLocationCoords } from 'ui-smartforce-settings';
import { IntersectionObserverClient } from '../../../IntersectionObserver';

export interface ContactMapProps {
  className?: string;
  coords?: GeoLocationCoords;
}

export const ContactMap = ({
  className = '',
  coords
}: ContactMapProps): React.ReactElement<ContactMapProps> => {
  return (
    <div className={`${styles.contactMap} ${className}`}>
      {!coords && <ContactMapNoCoordinates />}

      {coords && (
        <IntersectionObserverClient>
          <StaticMap position={coords} />
        </IntersectionObserverClient>
      )}
    </div>
  );
};
