import moment from 'moment';
import {
  ConditionalIf,
  ConfigBase,
  Field,
  FieldValueType,
  GeneralInformationValueType,
  ItemListConfig,
  ItemModelValueType,
  ItemValueType,
  ReportValue,
  Section,
  StateRelatedValueType,
  UseOfForceValueType,
  VersionValueType
} from '../Models';
import {
  Customer,
  MemberRole,
  User,
  UserTitleBase,
  isFieldEmpty
} from 'ui-smartforce-settings';

//* Private Functions
export const getFieldValueString = (value: FieldValueType): string => {
  if (value === undefined || value === null) {
    return '';
  }

  if (Array.isArray(value)) {
    return value.join(', ');
  } else if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  } else {
    return value.toString();
  }
};

const getSummaryFieldConfig = (
  config: ItemListConfig,
  sectionName: string,
  fieldName: string,
  itemFieldName?: string
): Field | undefined => {
  let fieldConfig: Field | undefined;

  config.sections.forEach((section) => {
    if (section.name !== sectionName) return;
    section.fields.forEach((field) => {
      if (field.name !== fieldName) return;
      // It is an itemList field type
      if (itemFieldName && field.item_model) {
        field.item_model.forEach((itemField) => {
          if (itemField.name !== itemFieldName) return;
          fieldConfig = itemField;
        });
      } else {
        fieldConfig = field;
      }
    });
  });

  return fieldConfig;
};

//* Public Methods

export const getItemSummary = (config: ItemListConfig, item: ItemValueType) => {
  let brief: string = '';
  let lastSection: string;

  config.summary.forEach((summaryItem: string) => {
    const [sectionName, fieldName, itemFieldName] = summaryItem.split('.');

    if (!item[sectionName] || !item[sectionName][fieldName]) {
      return;
    }

    //Check if it is a new section to add '-'
    if (!lastSection) {
      lastSection = sectionName;
    } else if (lastSection !== sectionName) {
      lastSection = sectionName;
      brief = brief.slice(0, -2) + ' - ';
    }

    let fieldValue: FieldValueType = item[sectionName][fieldName];
    // Special case to show officer title
    if (
      sectionName === 'officer_information' &&
      fieldName === 'officer_name' &&
      item.officer_information.title
    ) {
      fieldValue = `${
        (item.officer_information.title as UserTitleBase).name
      } ${fieldValue}`;
    }

    const fieldConfig = getSummaryFieldConfig(
      config,
      sectionName,
      fieldName,
      itemFieldName
    );

    // Add summary field label
    if (fieldConfig && fieldConfig.label_summary) {
      brief += `${fieldConfig.label_summary}: `;
    }

    //It is an itemList field type componet
    if (itemFieldName && Array.isArray(fieldValue)) {
      // Check if it is a value type that can be stringify
      if (
        ['boolean', 'number', 'string', 'array'].includes(
          fieldConfig?.type as string
        )
      ) {
        let itemFieldValuesArray: string[] = [];

        fieldValue.forEach((itemValue) => {
          if (typeof itemValue === 'object') {
            const itemFieldValue = (itemValue as any)[itemFieldName] as
              | boolean
              | number
              | string
              | (number | string)[];

            if (
              fieldConfig?.type === 'array' &&
              Array.isArray(itemFieldValue)
            ) {
              itemFieldValue.forEach((value) => {
                // Is not in array
                if (
                  itemFieldValuesArray.findIndex((val) => val === value) === -1
                ) {
                  itemFieldValuesArray = [
                    ...itemFieldValuesArray,
                    value as string
                  ];
                }
              });
            } else {
              // Is not in array
              if (
                itemFieldValuesArray.findIndex(
                  (val) => val === (itemValue as any)[itemFieldName]
                ) === -1
              ) {
                itemFieldValuesArray = [
                  ...itemFieldValuesArray,
                  (itemValue as any)[itemFieldName] as string
                ];
              }
            }
          }
        });

        // HC: This is an exceptional situation because this values are related with other field value
        if (fieldConfig?.component === 'citizen_checklist') {
          itemFieldValuesArray = itemFieldValuesArray.map(
            (value) => `Citizen ${+value + 1}`
          );
        }
        brief += `${getFieldValueString(itemFieldValuesArray)}; `;
      } else {
        // Component type could not be stringify
        brief += 'Error; ';
      }
    } else {
      brief += `${getFieldValueString(fieldValue)}; `;
    }
  });

  return brief.slice(0, -2) + '.';
};

export const checkConditional = (
  conditionType: 'conditional_if' | 'conditional_if_not',
  conditional: ConditionalIf,
  value: FieldValueType
): boolean => {
  if (value === undefined || value === null) {
    return conditionType === 'conditional_if_not';
  } else {
    let conditionMet = false;
    if (Array.isArray(value)) {
      conditionMet =
        (value as string[]).indexOf(conditional.selected_option) !== -1;
    } else {
      conditionMet =
        value.toString() === conditional.selected_option.toString();
    }

    return conditionType === 'conditional_if' ? conditionMet : !conditionMet;
  }
};

// Loop field validations and returns invalid message (the first met)
// Returns empty string if its valid
export function getFieldInvalidMsg(
  value: FieldValueType,
  field: Field
): string {
  if (!field.validations) {
    return '';
  }

  for (let validation of field.validations) {
    switch (validation.name) {
      case 'regex': {
        const regExp = new RegExp(validation.value);
        if (!regExp.test(value as string)) {
          return validation.message;
        }
        break;
      }

      case 'min': {
        if (value !== undefined && value !== null && value < validation.value) {
          return validation.message;
        }
        break;
      }

      case 'max': {
        if (value !== undefined && value !== null && value > validation.value) {
          return validation.message;
        }
        break;
      }

      case 'minSize': {
        if (((value as unknown[]) || []).length < validation.value) {
          return validation.message;
        }
        break;
      }

      case 'maxSize': {
        if (((value as unknown[]) || []).length > validation.value) {
          return validation.message;
        }
        break;
      }

      default:
        return '';
    }
  }

  return '';
}

const isItemListValueInvalid = (
  itemListValue: ItemModelValueType[],
  itemModel: Field[]
): boolean => {
  for (let itemValue of itemListValue) {
    for (let itemField of itemModel) {
      if (
        (itemField.type !== 'itemlist' &&
          itemField.required &&
          isFieldEmpty(itemValue[itemField.name])) ||
        getFieldInvalidMsg(itemValue[itemField.name], itemField).length > 0
      ) {
        return true;
      } else if (
        itemField.type === 'itemlist' &&
        itemValue[itemField.name] &&
        isItemListValueInvalid(
          itemValue[itemField.name] as ItemModelValueType[],
          itemField.item_model as Field[]
        )
      ) {
        return true;
      }
    }
  }

  return false;
};

const checkField = (
  field: Field,
  sectionName: string,
  value: ItemValueType
): boolean => {
  if (
    (field.required &&
      (!value[sectionName] || isFieldEmpty(value[sectionName][field.name]))) ||
    (value[sectionName] &&
      getFieldInvalidMsg(value[sectionName][field.name], field).length > 0)
  ) {
    return false;
  } else if (
    field.type === 'itemlist' &&
    value[sectionName] &&
    value[sectionName][field.name] &&
    isItemListValueInvalid(
      value[sectionName][field.name] as ItemModelValueType[],
      field.item_model as Field[]
    )
  ) {
    return false;
  } else if (
    field.condition_if &&
    value[sectionName] &&
    value[sectionName][field.name]
  ) {
    let conditionMet = false;
    for (let conditional of field.condition_if) {
      if (
        checkConditional(
          'conditional_if',
          conditional,
          value[sectionName][field.name]
        )
      ) {
        if (!conditionMet) conditionMet = true;

        for (let condField of conditional.fields) {
          if (!checkField(condField, sectionName, value)) {
            return false;
          }
        }
      }
    }

    if (!conditionMet && field.condition_else) {
      for (let condField of field.condition_else) {
        if (!checkField(condField, sectionName, value)) {
          return false;
        }
      }
    }
  } else if (
    field.condition_if_not &&
    value[sectionName] &&
    value[sectionName][field.name]
  ) {
    for (let conditional of field.condition_if_not) {
      if (
        checkConditional(
          'conditional_if_not',
          conditional,
          value[sectionName][field.name]
        )
      ) {
        for (let condField of conditional.fields) {
          if (!checkField(condField, sectionName, value)) {
            return false;
          }
        }
      }
    }
  }

  return true;
};

export const checkSectionsFields = (
  config: ConfigBase,
  value: ItemValueType
): boolean => {
  for (let section of config.sections) {
    for (let field of section.fields) {
      if (!checkField(field, section.name, value)) {
        return false;
      }
    }
  }

  return true;
};

export const getReportDate = (isoDate: string): string => {
  return moment(new Date(isoDate)).format('MM/DD/YYYY');
};

export const getReportTime = (isoDate: string): string => {
  return moment(new Date(isoDate)).format('HH:mm');
};

export const getPendingReportTime = (isoDate: string): string => {
  return moment(new Date(isoDate)).format('HH:mm');
};

export const getSectionsDefaultValue = (
  sections: Section[]
): StateRelatedValueType | ItemValueType => {
  let form: StateRelatedValueType | ItemValueType = {};

  function setFieldDefaultValue(field: Field, sectionName: string) {
    if (field.default !== undefined && field.default !== null) {
      if (!form[sectionName]) {
        form[sectionName] = {};
      }

      form[sectionName][field.name] = field.default;

      if (field.condition_if) {
        let conditionMet = false;
        for (let conditional of field.condition_if) {
          if (checkConditional('conditional_if', conditional, field.default)) {
            if (!conditionMet) conditionMet = true;
            for (let field of conditional.fields) {
              setFieldDefaultValue(field, sectionName);
            }
          }
        }

        if (field.condition_else && !conditionMet) {
          for (let elseField of field.condition_else) {
            setFieldDefaultValue(elseField, sectionName);
          }
        }
      }

      if (field.condition_if_not) {
        for (let conditional of field.condition_if_not) {
          if (
            checkConditional('conditional_if_not', conditional, field.default)
          ) {
            for (let field of conditional.fields) {
              setFieldDefaultValue(field, sectionName);
            }
          }
        }
      }
    }
  }

  for (let section of sections) {
    for (let field of section.fields) {
      setFieldDefaultValue(field, section.name);
    }
  }

  return form;
};

export const getGeneralFormDefaultValue = (
  config: ConfigBase
): GeneralInformationValueType => {
  const now = moment();

  let form: GeneralInformationValueType = {
    date: now,
    time: now,
    state_related: {},
    location: { address: { full: '' }, type: '' }
  };

  form.state_related = getSectionsDefaultValue(config.sections);

  return form;
};

export const getUseOfForceDefaultValue = (
  config: ConfigBase | undefined
): UseOfForceValueType | undefined => {
  if (!config) {
    return undefined;
  }

  const useOfForceValue = getSectionsDefaultValue(config.sections);

  return {
    ...useOfForceValue['use_of_force'],
    other_agencies_involved: []
  };
};

export function getSectionFieldValueByString(
  item: ItemValueType,
  field: string
): FieldValueType {
  const [sectionName, fieldName] = field.split('.');
  return item[sectionName][fieldName];
}

export function canAmendReport(
  report: ReportValue,
  user: User | undefined,
  customer: Customer | undefined
): boolean {
  if (user && customer && report.versions) {
    const reportVersion: VersionValueType | undefined = report.versions.find(
      (version: VersionValueType) => version.v === 0
    );

    if (reportVersion) {
      const isUserAuthor: boolean = reportVersion.author.user_id === user.id;

      if (isUserAuthor) {
        return true;
      }

      const authorRole: MemberRole | undefined = customer.roles?.find(
        (rol: MemberRole) => rol.id === reportVersion.author.role
      );

      if (authorRole) {
        return authorRole.priority > user.role.priority;
      }
    }
  }

  return false;
}
