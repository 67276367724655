import React from 'react';
import { SFMultiSelect } from 'sfui';
import { InputProps } from '../FieldInput';

export const MultiSelectField = (
  props: InputProps
): React.ReactElement<InputProps> => {
  const value: string[] = props.value ? (props.value as string[]) : [];

  const onChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const newValue: string[] = event.target.value as string[];
    props.onChange(newValue.length > 0 ? newValue : undefined);
  };

  const options = props.options
    ? props.options.map((option: string) => ({
        label: option,
        value: option
      }))
    : [];

  return (
    <SFMultiSelect
      label={props.label}
      value={value}
      required={props.required}
      options={options}
      error={props.error}
      helperText={props.helperText}
      onChange={onChange}
    />
  );
};
