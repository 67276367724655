import React from 'react';
import styles from './RegistrationCreateView.module.scss';
import { useHistory } from 'react-router-dom';
import { SFButton, SFCheckbox, SFLink, SFTextField, SFAlert } from 'sfui';
import { EMAIL_INVALID_MSG } from '../../../../Constants/Messages';
import {
  PASSWORD_INVALID_MSG,
  isEmailValid,
  isFieldEmpty,
  isOfficerIdValid,
  isPasswordValid,
  OFFICER_ID_INVALID_MSG
} from 'ui-smartforce-settings';

export interface RegistrationUser {
  name: string;
  officerId: string;
  email: string;
  password: string;
  checkPolicies: boolean;
}

export interface RegistrationCreateViewProps {
  user: RegistrationUser;
  isEmailDisabled: boolean;
  hasError: boolean;
  onCreate: (value: RegistrationUser) => void;
}

export const RegistrationCreateView = ({
  user,
  isEmailDisabled = false,
  hasError = false,
  onCreate
}: RegistrationCreateViewProps): React.ReactElement<RegistrationCreateViewProps> => {
  const history = useHistory();
  const [name, setName] = React.useState<string>(user.name);
  const [officerId, setOfficerId] = React.useState<string>(user.officerId);
  const [email, setEmail] = React.useState<string>(user.email);
  const [password, setPassword] = React.useState<string>(user.password);
  const [checkPolicies, setCheckPolicies] = React.useState<boolean>(
    user.checkPolicies
  );
  const [isPasswordError, setIsPasswordError] = React.useState<boolean>(false);
  const [isEmailError, setIsEmailError] = React.useState<boolean>(false);
  const [isOfficerIdError, setIsOfficerIdError] =
    React.useState<boolean>(false);

  const checkFormValid = (): boolean => {
    return (
      !isFieldEmpty(name) &&
      !isFieldEmpty(officerId) &&
      !isFieldEmpty(email) &&
      !isFieldEmpty(password) &&
      checkPolicies
    );
  };

  const onClickCreate = () => {
    const checkPassword: boolean = isPasswordValid(password);
    const checkEmail: boolean = isEmailValid(email);
    const checkOfficerId: boolean = isOfficerIdValid(officerId);

    setIsPasswordError(!checkPassword);
    setIsEmailError(!checkEmail);
    setIsOfficerIdError(!checkOfficerId);

    if (checkPassword && checkEmail && checkOfficerId) {
      onCreate({
        name,
        officerId,
        email,
        password,
        checkPolicies
      });
    }
  };

  const onLogin = () => {
    history.push('/login');
  };

  return (
    <div className={styles.registrationCreateView}>
      <h2 className={styles.title}>Create your account</h2>

      {hasError && (
        <SFAlert type="error" title="This user already exists.">
          Please try with another one.
        </SFAlert>
      )}

      <div className={styles.content}>
        <form>
          <SFTextField
            label="Full Name"
            value={name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setName(e.target.value)
            }
          />

          <SFTextField
            label="Officer ID Number"
            value={officerId}
            error={isOfficerIdError}
            helperText={OFFICER_ID_INVALID_MSG}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setOfficerId(e.target.value)
            }
          />

          <SFTextField
            label="E-mail"
            type="email"
            autoComplete="username"
            disabled={isEmailDisabled}
            value={email}
            error={isEmailError}
            helperText={isEmailError ? EMAIL_INVALID_MSG : ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value.toLowerCase())
            }
          />

          <SFTextField
            label="Password"
            type="password"
            autoComplete="new-password"
            value={password}
            error={isPasswordError}
            helperText={isPasswordError ? PASSWORD_INVALID_MSG : ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPassword(e.target.value)
            }
          />
        </form>

        <div className={styles.checkPolicies}>
          <SFCheckbox
            label={
              <React.Fragment>
                By creating an account, you agree to the SmartForce®{' '}
                <SFLink
                  sfSize="small"
                  color="primary"
                  target="_blank"
                  href="https://smartforcetech.com/#/agreement-citizencontact"
                >
                  Privacy Policies.
                </SFLink>
              </React.Fragment>
            }
            checked={checkPolicies}
            onChange={(
              _e: React.ChangeEvent<HTMLInputElement>,
              checked: boolean
            ) => setCheckPolicies(checked)}
          />
        </div>
      </div>

      <SFButton
        size="large"
        fullWidth
        disabled={!checkFormValid()}
        onClick={onClickCreate}
      >
        Create Account
      </SFButton>

      <div className={styles.msgAccount}>
        <span>Already have an account?</span>

        <SFLink sfSize="small" color="primary" onClick={onLogin}>
          Log in
        </SFLink>
      </div>
    </div>
  );
};
