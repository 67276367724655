import React from 'react';
import { SFLink } from 'sfui';
import { HeaderLink } from '../LandingTopBar';
import styles from './LinksBar.module.scss';

export interface LinksBarProps {
  links: HeaderLink[];
  selected: string;
  onClick: (link: HeaderLink) => void;
}

export const LinksBar = ({
  links,
  selected,
  onClick
}: LinksBarProps): React.ReactElement<LinksBarProps> => {
  return (
    <ul className={styles.linksBar}>
      {links.map((link: HeaderLink) => (
        <li key={link.label}>
          <SFLink
            sfSize="medium"
            color={selected === link.name ? 'primary' : 'inherit'}
            onClick={() => onClick(link)}
          >
            {link.label}
          </SFLink>
        </li>
      ))}
    </ul>
  );
};
