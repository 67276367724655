import React from 'react';
import styles from './WizardCardHeader.module.scss';
import { SFIconButton, SFText } from 'sfui';

export interface WizardCardHeaderProps {
  limit: number;
  onBack?: () => void;
  onClose?: () => void;
  step: number;
  title: string;
}

export const WizardCardHeader = ({
  limit,
  onBack,
  onClose,
  step,
  title
}: WizardCardHeaderProps): React.ReactElement<WizardCardHeaderProps> => {
  return (
    <div className={styles.wizardCardHeader}>
      {onBack && (
        <SFIconButton sfIcon="Left-7" sfSize="medium" onClick={onBack} />
      )}

      <SFText className={styles.title} type="component-1" sfColor="neutral">
        <span>{`${title} `}</span>
        <span className={styles.active}>{`${step} `}</span>
        <span>{`| ${limit}`}</span>
      </SFText>

      {onClose && (
        <SFIconButton
          className={styles.btnClose}
          sfIcon="Close"
          sfSize="medium"
          onClick={onClose}
        />
      )}
    </div>
  );
};
