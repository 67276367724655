import React from 'react';
import styles from './OnboardingOfficer.module.scss';
import { SFButton, SFIcon } from 'sfui';
import { CustomerContext, SubscriptionContext } from 'ui-smartforce-settings';

export const OnboardingOfficer = (): React.ReactElement<{}> => {
  const { subscriptions } = React.useContext(SubscriptionContext);
  const { customer } = React.useContext(CustomerContext);
  const hasSubscription: boolean = subscriptions.length > 0;

  const onClick = () => {
    const anchor = document.createElement('a');
    anchor.href = `mailto:${customer?.owner_email}?subject=We need CitizenContact by SmartForce®`;
    anchor.click();
  };

  return (
    <div className={styles.onboardingOfficer}>
      <div className={styles.icon}>
        <SFIcon icon={!hasSubscription ? 'Tap' : 'Announcement'} size={96} />
      </div>

      <h2 className={styles.title}>
        {!hasSubscription ? 'Activate Subscription' : 'No Agency yet'}
      </h2>

      <p className={styles.subTitle}>
        {!hasSubscription
          ? 'Contact the agency owner to activate the subscription.'
          : 'Your agency administrator will invite you soon.'}
      </p>

      {!hasSubscription && (
        <div className={styles.btnAction}>
          <SFButton size="large" onClick={onClick}>
            Contact the Agency Owner
          </SFButton>
        </div>
      )}
    </div>
  );
};
